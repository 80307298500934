import { signOut as googleSignOut } from './google';
import { decodeJWT } from './jwt';
import Subject from './subject';
import type { Payload, User } from './types';

// Safari disables localStorage when in private browsing mode. If that's the
// case, store the token in memory so we can still log in.
let memoryToken: string | null = null;

/**
 * Retrieves the JSON Web Token from storage and validates it.
 * @returns The token, if it is set and valid, otherwise null.
 * @private
 */
function getToken(): string | null {
	const token: string | null = localStorage.getItem('jwt') || memoryToken;

	if (token != null) {
		try {
			const payload: Payload = decodeJWT(token);

			if (payload.exp * 1000 <= Date.now()) {
				localStorage.removeItem('jwt');

				return null;
			}

			return token;
		} catch (err) {
			localStorage.removeItem('jwt');
		}
	}

	return null;
}

/**
 * Deletes the JSON Web Token from storage.
 */
export function deleteToken(): void {
	localStorage.removeItem('jwt');
	googleSignOut();
	// Send auth event to possible auth_content script from extension.
	// Important to send AFTER localStorage has been removed
	const event = new Event('auth');
	document.dispatchEvent(event);

	memoryToken = null;
	subject.next(getUser());
}

/**
 * Sets the JSON Web Token in storage.
 * @param token The JSON Web Token.
 */
export function setToken(token: string): void {
	try {
		localStorage.setItem('jwt', token);
		// Send auth event to possible auth_content script from extension.
		// Important to send AFTER localStorage has been set
		const event = new Event('auth');
		document.dispatchEvent(event);
	} catch (error) {
		memoryToken = token;
	}

	subject.next(getUser());
}

/**
 * Gets the user from the JSON Web Token's payload.
 * @returns The user, or null if no token is present.
 */
export function getUser(): User | null {
	const token: string | null = getToken();

	if (token != null) {
		const payload: Payload = decodeJWT(token);

		return payload.user;
	}

	return null;
}

const subject: Subject<User | null> = new Subject();

export const subscribe: typeof subject.subscribe = (...args) =>
	subject.subscribe(...args);
