import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import Authorized from '../../components/Authorized';
import type { NoteData } from '../../notes';

type Data = Array<NoteData>;

const ReadOnlyCell = styled.td`
	line-height: 20px;
	max-width: 40ch;
	overflow: hidden;
	padding: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

function toolTip(notes: Data) {
	const titleText = notes
		.map((note) => {
			const timing = moment(note.added_at).fromNow();
			const formattedTiming = `${timing[0].toUpperCase()}${timing.slice(
				1,
			)}`;
			const commenter = note.commenter.first_name;
			const comment = note.comment;

			return `${formattedTiming}, ${commenter} wrote: ${comment}`;
		})
		.join('\n\n');
	return titleText;
}

export default class NotesColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	name: string;
	private select: (row: T) => Data;

	constructor({ name, select }: { name: string; select: (row: T) => Data }) {
		this.name = name;
		this.select = select;

		this.cell.displayName = 'TextColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ props, row }: CellArgs<T>) => {
		// array of id's
		const notes = this.select(row);
		const title = toolTip(notes);
		const display = notes.length ? notes.length.toString() : '';
		return (
			<Authorized auth="contacts.notes">
				<ReadOnlyCell title={title} {...props}>
					{display}
				</ReadOnlyCell>
			</Authorized>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<Authorized auth="contacts.notes">
				<SortableHeader
					initialSortDirection={sortDirections.ascending}
					onSort={onSort}
					sort={sort}
					sortIndex={sortIndex}
					{...props}
				>
					{this.name}
				</SortableHeader>
			</Authorized>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal: number = this.select(a).length;
		const bVal: number = this.select(b).length;

		if (aVal === 0 && bVal === 0) return 0;
		if (aVal === 0) return 1; // Sort a after b
		if (bVal === 0) return -1; // Sort b after a

		return direction === sortDirections.ascending
			? bVal - aVal
			: aVal - bVal;
	}
}
