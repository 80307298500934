import moment from 'moment';
import numeral from 'numeral';
import React from 'react';

import './month-by-month-funding.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import LineChart from './charts/line-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funding/amounts/';
let data = null;

const xAxisTickFormat = (timestamp) =>
	moment(timestamp * 1000).format(`MMM 'YY`);
const yAxisTickFormat = (d) => numeral(d).format('0.0a').toUpperCase();

const formatData = (dataArr) => [
	{ area: true, key: 'Amount', values: toDataSeries(dataArr) },
];
const toDataSeries = (dataArr) =>
	dataArr.map((point) => ({
		x: moment(point.month).unix(),
		y: point.total,
	}));

export default class MonthByMonthFunding extends React.Component {
	static getDetailCard() {
		return MonthByMonthFundingDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			forceY: [0],
			showXAxis: true,
			showYAxis: true,
			useInteractiveGuideline: true,
			showLegend: true,
		};

		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="Month-by-month Funding"
				xAxisLabel="Month"
				xAxisTickFormat={xAxisTickFormat}
				yAxisLabel="Amount Raised ($)"
				yAxisTickFormat={yAxisTickFormat}
				loadingComponent={HerbieLoader}
			>
				{LineChart}
			</GenericChart>
		);
	}
}

class MonthByMonthFundingDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/funding_amounts.png"
				statName="Month-by-month Funding"
			/>
		);
	}
}
