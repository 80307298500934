// @flow

import numeral from 'numeral';
import React from 'react';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import minimumSearchFilter from './minimum-filter';
import type { MinimumDisplayProps } from './minimum-filter';

const convertPositionToValue = (position) => {
	if (position === 0) {
		return null;
	}

	const base = ((position - 1) % 9) + 1;
	const exponent = Math.floor((position - 1) / 9) + 4;

	return base * 10 ** exponent;
};

const convertValueToPosition = (value) => {
	if (value === null) {
		return 0;
	}

	const floor = Math.floor(Math.log10(value));

	return 9 * (floor - 4) + value / 10 ** floor;
};

const convertValueToText = (value) => {
	return numeral(value).format('$0a').toUpperCase();
};

const DealCountSearchFilterDisplay = ({
	onRemove,
	punctuator = '',
	resultCount,
	value,
}: MinimumDisplayProps) =>
	value == null ? null : (
		<FilterDescription>
			{`${
				resultCount === 1 ? 'has' : 'have'
			} participated in a round of at least `}
			<DescriptionValue
				onRemove={onRemove}
				punctuator={punctuator}
				value={value}
			>
				{numeral(value).format('$0a').toUpperCase()}
			</DescriptionValue>
		</FilterDescription>
	);

export default minimumSearchFilter(
	'Deal Size',
	'investments.largest',
	DealCountSearchFilterDisplay,
	{
		convertPositionToValue,
		convertValueToPosition,
		convertValueToText,
		max: 1e9,
	},
	'deal-size-filter',
);
