import React, { type ComponentProps, useState } from 'react';
import { Button, DialogTrigger, Popover } from 'react-aria-components';
import styled from 'styled-components';

import { colors, effects } from '../../theme';
import { buttonStyles as controlButtonStyles } from '../../theme/components/control-button';
import { styles as iconButtonStyles } from '../../theme/components/icon-button';
import { ChevronDownIcon, ChevronUpIcon } from '../../theme/icons/arrows';
import { EllipsisHorizontalIcon } from '../../theme/icons/system';

import { Chevron } from './shared-styling/chevron';
import MenuPopover from './shared-styling/menu-popover';
import { MenuText } from './shared-styling/menu-text';

const StyledControlButton = styled(Button)`
	${controlButtonStyles}
`;
const StyledMenuButton = styled(Button)`
	${effects.shadow.shadow}
	align-items: center;
	appearance: none;
	background: ${colors.button.primary};
	border: none;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	gap: 4px;
	justify-self: stretch;
	padding: 0 0 0 12px;
	transition: all 0.1s ease-in-out;

	&:hover {
		${effects.shadow.hover}
		background: ${colors.button.primaryHover};
	}
`;
const StyledIconButton = styled(Button)<{ size?: 'default' | 'small' }>`
	${iconButtonStyles}
`;

interface Props {
	children: React.ReactNode;
	label: string;
	onHoverStart?: () => void;
	placement?: ComponentProps<typeof Popover>['placement'];
}

export default function Menu({
	children,
	label,
	onHoverStart,
	placement,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<DialogTrigger onOpenChange={setIsOpen}>
			<StyledMenuButton
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
			>
				<MenuText>{label}</MenuText>
				<Chevron as={isOpen ? ChevronUpIcon : ChevronDownIcon} />
			</StyledMenuButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

interface ControlMenuProps extends Props {
	Icon: React.ComponentType;
}
export function ControlMenu({
	Icon,
	children,
	label,
	onHoverStart,
	placement,
}: ControlMenuProps) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<DialogTrigger onOpenChange={setIsOpen}>
			<StyledControlButton
				variant="secondary"
				active={isOpen}
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
			>
				{<Icon />}
				{label}
			</StyledControlButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

type _BaseIconMenuProps = Omit<Props, 'label'> & {
	Icon?: React.ComponentType;
	buttonSize?: 'default' | 'small';
	disabled?: boolean;
	placement?: ComponentProps<typeof Popover>['placement'];
};

type _ControlledIconMenuProps = {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
};
type _UncontrolledIconMenuProps = {
	isOpen?: never;
	onOpenChange?: never;
};

type IconMenuProps = _BaseIconMenuProps &
	(_ControlledIconMenuProps | _UncontrolledIconMenuProps);

export function IconMenu({
	Icon = EllipsisHorizontalIcon,
	buttonSize,
	children,
	disabled = false,
	isOpen,
	onHoverStart,
	onOpenChange,
	placement,
}: IconMenuProps) {
	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
			<StyledIconButton
				isDisabled={disabled}
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
				size={buttonSize}
			>
				{<Icon />}
			</StyledIconButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}
