import React from 'react';
import { Label as AriaLabel } from 'react-aria-components';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';

const StyledLabel = styled(AriaLabel)<{ $isDisabled: boolean }>`
	${fonts.label.label}
	color: ${({ $isDisabled }) =>
		$isDisabled ? colors.text.disabled : colors.text.secondary};
`;
const Required = styled.span<{ $isDisabled: boolean }>`
	color: ${colors.text.danger};
`;

interface LabelProps
	extends Omit<React.ComponentProps<typeof AriaLabel>, 'children'> {
	children: React.ReactNode;
	isDisabled?: boolean;
	isRequired?: boolean;
}

export default function Label({
	children,
	isDisabled = false,
	isRequired = false,
	...props
}: LabelProps) {
	return (
		<StyledLabel $isDisabled={isDisabled} {...props}>
			{children}
			{isRequired && <Required $isDisabled={isDisabled}>*</Required>}
		</StyledLabel>
	);
}
