import React from 'react';
import styled from 'styled-components';

import UnstyledModal from '../../components/modal';
import { colors } from '../../theme';
import type {
	PipelineStage,
	PipelineStageItem,
	StageTransitionDataValue,
} from '../types';

import TransitionRuleEvaluation from './rule-evaluation';

const StyledModal = styled(UnstyledModal)`
	max-width: 800px;
	width: 50vw;

	.HerbieModal__container {
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 20px;
	}
`;

function defaultMapStageTitleToColors() {
	return {
		backgroundColor: colors.data.blue.layerSubtle,
		textColor: colors.data.blue.textSecondary,
	};
}

interface Props {
	destinationStage: PipelineStage;
	isOpen: boolean;
	item: PipelineStageItem | null;
	mapStageTitleToColors?: (title: string) => {
		backgroundColor: string;
		textColor: string;
	};
	onClose: () => void;
	onSubmit: (
		destinationStageId: number,
		transitionData: StageTransitionDataValue[],
	) => void;
	subHeader?: React.ReactNode;
	title?: React.ReactNode;
}

export default function Modal({
	destinationStage,
	isOpen,
	item,
	mapStageTitleToColors = defaultMapStageTitleToColors,
	onClose,
	onSubmit,
	subHeader,
	title,
}: Props) {
	return (
		<StyledModal
			isOpen={isOpen}
			onClose={onClose}
			title="Evaluating Card Transition Rules"
		>
			{item && (
				<TransitionRuleEvaluation
					item={item}
					mapStageTitleToColors={mapStageTitleToColors}
					onCancel={onClose}
					onSubmit={onSubmit}
					stage={destinationStage}
					subHeader={subHeader}
					title={title}
				/>
			)}
		</StyledModal>
	);
}
