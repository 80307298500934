/**
 * @file
 * Alert toast message component from [Figma][1].
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=813%3A25688&mode=dev
 */
import { animated } from '@react-spring/web';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { colors, effects, fonts } from '../../theme';
import SemanticSymbol from '../icons/semantic-symbol';
import { XIcon } from '../icons/system';

import { type Styles, useAlert } from './alert';

const TOAST_DURATION_MS = 3000;

const Container = styled.div`
	${effects.shadow.hover};
	align-items: flex-start;
	display: flex;
`;

const Content = styled.div`
	${fonts.label.strong};
	align-items: flex-start;
	background: ${colors.layer.inverse};
	border-bottom: 1px solid ${colors.border.inverse};
	border-radius: 0px 6px 6px 0px;
	border-right: 1px solid ${colors.border.inverse};
	border-top: 1px solid ${colors.border.inverse};
	color: ${colors.text.onColor};
	display: flex;
	flex-direction: column;
	flex: 1 0 0;
	gap: 4px;
	padding: 4px 8px;
`;

const Headline = styled.div`
	display: flex;
	flex: 1;
	gap: 8px;
	justify-content: space-between;
	width: 100%;
`;
const HeadlineLeft = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
`;
const CloseIcon = styled(XIcon)`
	height: 12px;
	width: 12px;
`;
const Message = styled.div``;

const rectangleColors = {
	danger: colors.border.danger,
	forbidden: colors.border.danger,
	generic: colors.border.inverse,
	info: colors.semantic.info,
	success: colors.semantic.success,
	warning: colors.semantic.warning,
} as const;
const LeftRectangle = styled.div<{ $type: MessageType }>`
	align-self: stretch;
	background: ${({ $type }) => rectangleColors[$type]};
	border-radius: 6px 0px 0px 6px;
	flex-shrink: 0;
	width: 6px;
`;

export type MessageType =
	| 'danger'
	| 'forbidden'
	| 'generic'
	| 'info'
	| 'success'
	| 'warning';

interface ToastData {
	headline: string;
	message: string;
	type: MessageType;
}

export function useToastData(duration: number = TOAST_DURATION_MS) {
	const [toastData, setToastData] = useState<ToastData | null>(null);

	const handleAlertClose = useCallback(() => {
		setToastData(null);
	}, []);

	const [alertStyle, openAlert] = useAlert({
		onClose: handleAlertClose,
		visibleSeconds: duration * 0.001,
	});

	useEffect(() => {
		if (toastData) {
			openAlert();
		}
	}, [openAlert, toastData]);

	return { alertStyle, toastData, setToastData };
}

interface Props {
	headline: string;
	onClose?: () => void;
	message: string;
	style: Styles;
	type?: MessageType;
}

export default function ToastMessage({
	headline,
	onClose,
	message,
	style,
	type = 'generic',
}: Props) {
	return createPortal(
		<animated.div style={style}>
			<Container>
				<LeftRectangle $type={type} />
				<Content>
					<Headline>
						<HeadlineLeft>
							<SemanticSymbol size="small" type={type} />
							{headline}
						</HeadlineLeft>
						{onClose && <CloseIcon onClick={onClose} />}
					</Headline>
					<Message>{message}</Message>
				</Content>
			</Container>
		</animated.div>,
		document.body,
	);
}
