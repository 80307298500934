/**
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=249%3A148047&mode=dev
 */
import React from 'react';
import styled, { css } from 'styled-components';

import { colors, effects, fonts } from '..';

const border = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorder}`,
	danger: 'none',
	warning: `1px solid ${colors.button.secondaryBorder}`,
} as const;

const borderHover = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorderHover}`,
	danger: 'none',
	warning: `1px solid ${colors.button.secondaryBorderHover}`,
} as const;

const borderActive = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorder}`,
	danger: 'none',
	warning: `1px solid ${colors.button.secondaryBorder}`,
} as const;

const backgroundColors = {
	primary: colors.button.primary,
	secondary: colors.button.secondary,
	danger: colors.button.danger,
	warning: colors.button.secondary,
} as const;

const backgroundHoverColors = {
	primary: colors.button.primaryHover,
	secondary: colors.button.secondary,
	danger: colors.button.dangerHover,
	warning: colors.button.secondary,
} as const;

const backgroundActiveColors = {
	primary: colors.button.primaryActive,
	secondary: colors.button.secondaryActive,
	danger: colors.button.dangerActive,
	warning: colors.button.secondaryActive,
} as const;

const textColors = {
	primary: colors.text.onColor,
	secondary: colors.text.secondary,
	danger: colors.text.onColor,
	warning: colors.text.danger,
} as const;

const textHoverColors = {
	primary: colors.text.onColor,
	secondary: colors.text.secondaryHover,
	danger: colors.text.onColor,
	warning: colors.text.dangerHover,
} as const;

const textActiveColors = {
	primary: colors.text.onColor,
	secondary: colors.text.secondary,
	danger: colors.text.onColor,
	warning: colors.text.danger,
} as const;

export const buttonStyles = css<{ active: boolean; variant: Variant }>`
	${fonts.label.label};
	align-items: center;
	background: ${({ variant, active }) =>
		active ? backgroundActiveColors[variant] : backgroundColors[variant]};
	border: ${({ variant, active }) =>
		active ? borderActive[variant] : border[variant]};
	border-radius: 6px;
	color: ${({ variant, active }) =>
		active ? textActiveColors[variant] : textColors[variant]};
	cursor: pointer;
	display: flex;
	gap: 2px;
	justify-content: flex-start;
	max-width: fit-content;
	min-width: fit-content;
	padding: 4px 8px;
	transition: background 0.1s ease-in-out, border-color 0.1s ease-in-out;
	white-space: nowrap;

	&:disabled {
		background: ${colors.button.disabled};
		border: 1px solid ${colors.border.disabled};
		color: ${colors.text.disabled};
		cursor: not-allowed;
	}

	&:hover:not(:disabled) {
		background: ${({ variant, active }) =>
			active
				? backgroundActiveColors[variant]
				: backgroundHoverColors[variant]};
		border: ${({ variant }) => borderHover[variant]};
		color: ${({ variant }) => textHoverColors[variant]};
	}
`;

const Button = styled.button<{
	active: boolean;
	variant: Variant;
}>`
	${buttonStyles}
`;

type Variant = 'primary' | 'secondary' | 'danger' | 'warning';
interface Props extends React.ComponentPropsWithoutRef<'button'> {
	active: boolean;
	variant?: Variant;
}

export default function ControlButton({
	active,
	variant = 'primary',
	...props
}: Props) {
	return <Button {...props} active={active} variant={variant} />;
}

const controlBackgroundHoverColors = {
	...backgroundHoverColors,
	secondary: colors.button.secondaryActive,
} as const;

const menuButtonStyles = css<{ active: boolean; variant: Variant }>`
	${buttonStyles}
	background-clip: padding-box;
	border-color: transparent;
	margin: -1px;
	transition: background 0.1s ease-in-out, border-color 0.1s ease-in-out,
		color 0.1s ease-in-out;

	&:hover:not(:disabled) {
		background-color: ${({ variant }) =>
			controlBackgroundHoverColors[variant]};
		border-color: transparent;
		background-clip: padding-box;
	}

	svg {
		height: 12px;
		width: 12px;
	}

	&:disabled {
		border-color: transparent;
	}
`;

const ControlMenuWrapper = styled.div<{ active: boolean; variant: Variant }>`
	align-items: stretch;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	gap: 0;
	justify-content: flex-start;
	padding: 0;
	position: relative;
	transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

	&:hover:has(button:not(:disabled)) {
		${effects.shadow.hover}
		border: ${({ variant }) => borderHover[variant]};
	}

	&:has(button:not(:disabled)) {
		border: ${({ variant, active }) =>
			active ? borderActive[variant] : border[variant]};
	}

	&:has(button:disabled) {
		border: 1px solid ${colors.border.disabled};
		cursor: not-allowed;
	}

	button {
		${menuButtonStyles}

		&:first-child:not(:last-child) {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;

			&:hover:not(:disabled) {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
			}
		}

		&:last-child:not(:first-child) {
			border-bottom-left-radius: 0;
			border-top-left-radius: 0;

			&:hover:not(:disabled) {
				border-bottom-left-radius: 0;
				border-top-left-radius: 0;
			}
		}
	}
`;

interface ControlMenuButtonProps {
	active: boolean;
	className?: string;
	children: React.ReactNode;
	variant?: Variant;
}

/**
 * Wrap two buttons together to create a split button as defined in [Figma][1]
 *
 * Note: The lack of inner border radius and color change on hover is intentional to match other implementations of a split button
 *
 * [1]: https://www.figma.com/design/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2029-19888&node-type=frame&m=dev
 */
export function ControlMenuButton({
	active,
	className,
	children,
	variant = 'primary',
}: ControlMenuButtonProps) {
	return (
		<ControlMenuWrapper
			active={active}
			className={className}
			variant={variant}
		>
			{children}
		</ControlMenuWrapper>
	);
}
