const randomString = (length: number): string => {
	const charSet =
		'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split(
			'',
		);
	const randomNess = [];

	for (let i = 0; i < length; i++) {
		randomNess.push(charSet[Math.floor(Math.random() * charSet.length)]);
	}

	return randomNess.join('');
};

export default randomString;
