import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import HerbieLoader from '../../components/herbie-loader';
import { get } from '../api';
import useAbortableEffect from '../hooks/use-abortable-effect';
import reportError from '../sentry';

const CenteredLoader = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
`;

export default function useGet<T>(
	url: string,
	query: Record<string, string | number> = {},
	callback: ((response: T) => void) | null = null,
): [T | null, (props: React.HTMLAttributes<HTMLDivElement>) => JSX.Element] {
	const [data, setData] = useState<T | null>(null);
	const queryString = Object.keys(query)
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`,
		)
		.join('&');

	useAbortableEffect(
		useCallback(
			async (signal) => {
				try {
					const response = await get<T>(`${url}?${queryString}`, {
						signal,
					});
					setData(response);
					// eslint-disable-next-line callback-return
					if (callback) callback(response);
				} catch (error) {
					if (error instanceof Error) {
						reportError(error);
					} else {
						console.error(error);
					}
				}
			},
			[callback, queryString, url],
		),
	);

	return [
		data,
		(props) => (
			<CenteredLoader {...props}>
				<HerbieLoader />
			</CenteredLoader>
		),
	];
}
