import {
	QueriesObserver,
	type Query,
	type QueryKey,
	type QueryObserverResult,
	queryOptions,
	useMutation,
	useQuery,
	type UseQueryOptions,
	useSuspenseQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { get, patch, post, queryClient, updateQueryData } from '../utils/api';
import DefaultMap from '../utils/default-map';
import useSearchParam from '../utils/hooks/use-search-param';
import nonNullable from '../utils/non-nullable';

import RegionTree, { type MarketMapTree } from './region-tree';
import {
	type DetailedMarketMapData,
	type DetailedMarketMapRegionData,
	type MarketMapData,
	Referrer,
} from './types';
import { buildReferrerQuery, decodeReferrerQuery } from './utils';

type ListFilters = {
	readonly owner?: number;
	readonly status?: MarketMapData['status'];
	readonly viewed_by?: number;
};

export const marketMapQueryKeys = {
	all: () => ['market-maps'] as const,
	list: (filters?: ListFilters) =>
		[...marketMapQueryKeys.all(), 'list', filters] as const,
	details: () => [...marketMapQueryKeys.all(), 'details'] as const,
	detail: (marketMapId: number) =>
		[...marketMapQueryKeys.details(), marketMapId] as const,
	companies: () => [...marketMapQueryKeys.all(), 'companies'] as const,
	company: (companyId: number) =>
		[...marketMapQueryKeys.companies(), companyId] as const,
	companyRecommendations: (marketMapId: number) =>
		[
			...marketMapQueryKeys.detail(marketMapId),
			'company-recommendations',
		] as const,
} as const;

function marketMapsQueryOptions(filters?: ListFilters) {
	return queryOptions({
		queryKey: marketMapQueryKeys.list({
			owner: filters?.owner,
			status: filters?.status,
			viewed_by: filters?.viewed_by,
		}),
		queryFn: ({ signal }) =>
			get<
				ReadonlyArray<MarketMapData<DetailedMarketMapRegionData<never>>>
			>('/market-maps', {
				signal,
				query: {
					owner: filters?.owner,
					status: filters?.status,
					viewed_by: filters?.viewed_by,
				},
			}),
		select: (marketMaps) =>
			marketMaps.map((marketMap) => RegionTree.withMarketMap(marketMap)),
		staleTime: 15 * 60 * 1000,
	});
}

export async function prefetchMarketMaps(): Promise<void> {
	await queryClient.ensureQueryData(marketMapsQueryOptions());
}

export function useMarketMaps(
	options: Pick<UseQueryOptions, 'enabled' | 'staleTime'> = {},
	filters: ListFilters | undefined = void 0,
) {
	return useQuery({
		...marketMapsQueryOptions(filters),
		...options,
	});
}

export function useMarketMapsSuspense(filters?: ListFilters) {
	return useSuspenseQuery({
		...marketMapsQueryOptions(filters),
	});
}

function updateMarketMapsQueryDataWithMarketMap(
	marketMap: MarketMapData<DetailedMarketMapRegionData<unknown>>,
) {
	updateQueryData(marketMapsQueryOptions(), (marketMaps) =>
		marketMaps.map((map) =>
			map.id === marketMap.id
				? {
						...marketMap,
						regions: marketMap.regions.map((region) => ({
							...region,
							companies: [],
						})),
				  }
				: map,
		),
	);
}

function marketMapQueryOptions(id: number) {
	return queryOptions({
		queryKey: marketMapQueryKeys.detail(id),
		queryFn: ({ signal }) =>
			get<DetailedMarketMapData>(`/market-maps/${id}`, { signal }),
		select: (marketMap) => RegionTree.withMarketMap(marketMap),
	});
}

export function useMarketMap(id: number) {
	return useQuery(marketMapQueryOptions(id));
}

export type CompanyMarketMaps = ReadonlyArray<
	MarketMapData<DetailedMarketMapRegionData<number>>
>;

function companyMarketMapsQueryOptions(id: number) {
	return queryOptions({
		queryKey: marketMapQueryKeys.company(id),
		queryFn: ({ signal }) =>
			get<CompanyMarketMaps>(`/companies/${id}/market-maps`, { signal }),
		select: (marketMaps) =>
			marketMaps.map((marketMap) => RegionTree.withMarketMap(marketMap)),
	});
}

export async function prefetchCompanyMarketMaps(id: number): Promise<void> {
	await queryClient.ensureQueryData(companyMarketMapsQueryOptions(id));
}

export function useCompanyMarketMaps(
	id: number,
	options?: Pick<UseQueryOptions, 'enabled' | 'staleTime'>,
) {
	return useQuery({
		...options,
		...companyMarketMapsQueryOptions(id),
	});
}

export function useCompanyMarketMapsSuspense(id: number) {
	return useSuspenseQuery({
		...companyMarketMapsQueryOptions(id),
	});
}

/**
 * Get all market maps containing the provided company IDs.
 *
 * Note: This hook pulls from the query cache populated by useCompanyMarketMaps and **does not** fetch data from the server. It expects the cache to be pre-populated
 *
 * Returns an array of MarketMapTree objects, with each region's companies filtered to only the ones provided
 **/
export function useCompaniesMarketMapsData(companyIds: number[]) {
	const [companiesMarketMaps, setCompaniesMarketMaps] = useState<
		MarketMapTree<number>[]
	>([]);

	useEffect(() => {
		const unsubscribe = new QueriesObserver<
			Array<QueryObserverResult<CompanyMarketMaps>>
		>(
			queryClient,
			companyIds.map((id) => ({
				queryKey: marketMapQueryKeys.company(id),
			})),
		).subscribe((results) => {
			const regionCompanies: DefaultMap<
				number,
				Set<number>
			> = new DefaultMap(() => new Set());

			const marketMapResults = (
				results as Array<QueryObserverResult<CompanyMarketMaps>>
			)
				.map(({ data }) => data)
				.filter(nonNullable)
				.flat();

			for (const marketMap of marketMapResults) {
				for (const region of marketMap.regions) {
					for (const company of region.companies) {
						regionCompanies.get(region.id).add(company);
					}
				}
			}

			const marketMapsWithCompanies = marketMapResults.map((marketMap) =>
				RegionTree.withMarketMap({
					...marketMap,
					regions: marketMap.regions.map((region) => ({
						...region,
						companies: Array.from(regionCompanies.get(region.id)),
					})),
				}),
			);

			setCompaniesMarketMaps(marketMapsWithCompanies);
		});

		return unsubscribe;
	}, [companyIds]);

	return companiesMarketMaps;
}

export function updateCompaniesMarketMaps(
	data: Array<{ companyId: number; marketMaps: CompanyMarketMaps }>,
) {
	for (const { companyId, marketMaps } of data) {
		updateQueryData(companyMarketMapsQueryOptions(companyId), marketMaps);
	}
}

export function useCreateMarketMap() {
	return useMutation({
		mutationFn: (name: string) =>
			post<MarketMapData<DetailedMarketMapRegionData<never>>>(
				'/market-maps',
				{
					body: { name },
				},
			),
		onSuccess: (marketMap) => {
			updateQueryData(marketMapsQueryOptions(), (maps) => [
				...maps,
				marketMap,
			]);
		},
	});
}

export function useUpdateMarketMap() {
	return useMutation({
		mutationFn: ({
			id,
			...updates
		}: {
			created_regions?: ReadonlyArray<{
				name: string;
				top: number;
				bottom: number;
				left: number;
				right: number;
			}>;
			deleted_regions?: ReadonlyArray<number>;
			id: number;
			name?: string;
			status?: MarketMapData['status'];
			updated_regions?: ReadonlyArray<{
				id: number;
				name: string;
				top: number;
				bottom: number;
				left: number;
				right: number;
			}>;
		}) =>
			patch<DetailedMarketMapData>(`/market-maps/${id}`, {
				body: updates,
			}),
		onSuccess: (marketMap) => {
			updateMarketMapsQueryDataWithMarketMap(marketMap);
			updateQueryData(marketMapQueryOptions(marketMap.id), marketMap);

			const companyQueries = queryClient.getQueryCache().findAll({
				queryKey: marketMapQueryKeys.companies(),
			}) as Array<
				Query<
					CompanyMarketMaps,
					Error,
					CompanyMarketMaps,
					QueryKey // readonly ['market-maps', 'companies', number]
				>
			>;
			for (const companyQuery of companyQueries) {
				const data = companyQuery.state.data;
				const [, , companyId] = companyQuery.queryKey as ReturnType<
					typeof companyMarketMapsQueryOptions
				>['queryKey'];
				if (data?.find(({ id }) => id === marketMap.id)) {
					updateQueryData(
						companyMarketMapsQueryOptions(companyId),
						(companyMarketMaps) => [
							...companyMarketMaps.filter(
								(map) => map.id !== marketMap.id,
							),
							{
								...marketMap,
								regions: marketMap.regions.map((region) => ({
									...region,
									companies: region.companies.find(
										(company) => company.id === companyId,
									)
										? [companyId]
										: [],
								})),
							},
						],
					);
				}
			}
		},
	});
}

function useUpdateMarketMapRegionCompanies() {
	const [encodedParams] = useSearchParam('referrer');
	const referrerQuery = decodeReferrerQuery(encodedParams);

	return useMutation({
		mutationFn: ({
			marketMapId,
			removeFromRegionId,
			addToRegionId,
			companyIds,
			referrer,
		}:
			| {
					marketMapId: number;
					removeFromRegionId?: number;
					addToRegionId: number;
					companyIds: ReadonlyArray<number>;
					referrer?: Referrer;
			  }
			| {
					marketMapId: number;
					removeFromRegionId: number;
					addToRegionId?: number;
					companyIds: ReadonlyArray<number>;
					referrer?: Referrer;
			  }) =>
			post<DetailedMarketMapData>(
				`/market-maps/${marketMapId}/companies`,
				{
					body: {
						company_ids: companyIds,
						remove_from_region_id: removeFromRegionId,
						add_to_region_id: addToRegionId,
						referrer: referrer
							? buildReferrerQuery(referrer)
							: referrerQuery,
					},
				},
			),
		onSuccess: (
			response,
			{ addToRegionId, companyIds, marketMapId, removeFromRegionId },
		) => {
			updateMarketMapsQueryDataWithMarketMap(response);
			updateQueryData(
				marketMapQueryOptions(marketMapId),
				(marketMap) => ({
					...marketMap,
					regions: marketMap.regions.map((region) => {
						if (region.id === removeFromRegionId) {
							return {
								...region,
								companies: region.companies.filter(
									(company) =>
										!companyIds.includes(company.id),
								),
							};
						}
						if (region.id === addToRegionId) {
							const updatedRegion = response.regions.find(
								({ id }) => id === addToRegionId,
							);
							if (updatedRegion) {
								return {
									...region,
									companies: [
										...region.companies.filter(
											(company) =>
												!companyIds.includes(
													company.id,
												),
										),
										...updatedRegion.companies,
									].toSorted((a, b) => a.id - b.id),
								};
							}
						}
						return region;
					}),
				}),
			);
			for (const companyId of companyIds) {
				updateQueryData(
					companyMarketMapsQueryOptions(companyId),
					(companyMarketMaps) => {
						const companyMarketMapsExcludingCurrent = [
							...companyMarketMaps.filter(
								(marketMap) => marketMap.id !== marketMapId,
							),
						];
						const isCompanyInOtherRegions = response.regions.some(
							(region) =>
								region.companies.find(
									(company) => company.id === companyId,
								),
						);

						if (isCompanyInOtherRegions) {
							return [
								...companyMarketMapsExcludingCurrent,
								{
									...response,
									regions: response.regions.map((region) => ({
										...region,
										companies: region.companies.find(
											(company) =>
												company.id === companyId,
										)
											? [companyId]
											: [],
									})),
								},
							];
						}

						return companyMarketMapsExcludingCurrent;
					},
				);
			}
		},
	});
}

export function useMoveMarketMapRegionCompanies() {
	const updateMarketMapCompanies = useUpdateMarketMapRegionCompanies();

	return {
		...updateMarketMapCompanies,
		mutate: ({
			marketMapId,
			fromRegionId,
			toRegionId,
			companyIds,
		}: {
			marketMapId: number;
			fromRegionId: number;
			toRegionId: number;
			companyIds: ReadonlyArray<number>;
		}) =>
			updateMarketMapCompanies.mutate({
				marketMapId,
				removeFromRegionId: fromRegionId,
				addToRegionId: toRegionId,
				companyIds,
			}),
		mutateAsync: ({
			marketMapId,
			fromRegionId,
			toRegionId,
			companyIds,
		}: {
			marketMapId: number;
			fromRegionId: number;
			toRegionId: number;
			companyIds: ReadonlyArray<number>;
		}) =>
			updateMarketMapCompanies.mutateAsync({
				marketMapId,
				removeFromRegionId: fromRegionId,
				addToRegionId: toRegionId,
				companyIds,
			}),
	};
}

export function useAddMarketMapRegionCompanies() {
	const updateMarketMapCompanies = useUpdateMarketMapRegionCompanies();

	return {
		...updateMarketMapCompanies,
		mutate: ({
			marketMapId,
			regionId,
			companyIds,
			referrer,
		}: {
			marketMapId: number;
			regionId: number;
			companyIds: ReadonlyArray<number>;
			referrer?: Referrer;
		}) =>
			updateMarketMapCompanies.mutate({
				marketMapId,
				addToRegionId: regionId,
				companyIds,
				referrer,
			}),
		mutateAsync: ({
			marketMapId,
			regionId,
			companyIds,
			referrer,
		}: {
			marketMapId: number;
			regionId: number;
			companyIds: ReadonlyArray<number>;
			referrer?: Referrer;
		}) =>
			updateMarketMapCompanies.mutateAsync({
				marketMapId,
				addToRegionId: regionId,
				companyIds,
				referrer,
			}),
	};
}

export function useRemoveMarketMapRegionCompanies() {
	const updateMarketMapCompanies = useUpdateMarketMapRegionCompanies();

	return {
		...updateMarketMapCompanies,
		mutate: ({
			marketMapId,
			regionId,
			companyIds,
		}: {
			marketMapId: number;
			regionId: number;
			companyIds: ReadonlyArray<number>;
		}) =>
			updateMarketMapCompanies.mutate({
				marketMapId,
				removeFromRegionId: regionId,
				companyIds,
			}),
		mutateAsync: ({
			marketMapId,
			regionId,
			companyIds,
		}: {
			marketMapId: number;
			regionId: number;
			companyIds: ReadonlyArray<number>;
		}) =>
			updateMarketMapCompanies.mutateAsync({
				marketMapId,
				removeFromRegionId: regionId,
				companyIds,
			}),
	};
}
