import { Temporal } from '@js-temporal/polyfill';
import { range } from 'lodash';

import FactTableRow from './fact-table-row';
import { generateQuarters } from './quarters';

/**
 * Returns the index of the first fact table row whose surface date is on or
 * after `threshold`.
 *
 * Requires that `array` is sorted by `surfaced` in ascending order.
 */
function binarySearchIndex(
	array: Array<FactTableRow>,
	threshold: Temporal.PlainDate,
	startIndex = 0,
	endIndex = array.length,
): number {
	if (endIndex <= startIndex) return startIndex;
	const midIndex = Math.floor((startIndex + endIndex) / 2);
	const comparison = Temporal.PlainDate.compare(
		array[midIndex].surfaced,
		threshold,
	);
	if (comparison < 0) {
		return binarySearchIndex(array, threshold, midIndex + 1, endIndex);
	}
	if (comparison === 0) return midIndex;
	return binarySearchIndex(array, threshold, startIndex, midIndex);
}

export default class DateCohort {
	public readonly label: string;
	/** inclusive */
	public readonly start: Temporal.PlainDate;
	/** exclusive */
	public readonly end: Temporal.PlainDate;

	private static fromQuarter(start: Temporal.PlainDate): DateCohort {
		return new DateCohort(
			`${Math.floor((start.month - 1) / 3) + 1}Q${start.year - 2000}`,
			start,
			start.add({ months: 3 }),
		);
	}

	public static series(startDate: Temporal.PlainDate): Array<DateCohort> {
		return [
			...[
				...generateQuarters(
					startDate,
					new Temporal.PlainDate(2022, 1, 1),
				),
			].map((quarter) => DateCohort.fromQuarter(quarter)),
			...range(Math.max(startDate.year, 2022), 2024).map(
				(year) =>
					new DateCohort(
						`${year}`,
						new Temporal.PlainDate(year, 1, 1),
						new Temporal.PlainDate(year + 1, 1, 1),
					),
			),
			...[...generateQuarters(new Temporal.PlainDate(2024, 1, 1))].map(
				(quarter) => DateCohort.fromQuarter(quarter),
			),
		];
	}

	public static group(
		cohorts: Array<DateCohort>,
		factTable: Array<FactTableRow>,
	): Map<DateCohort, Array<FactTableRow>> {
		const groups = new Map<DateCohort, Array<FactTableRow>>();
		// The API promises and CI guarantees that the fact table is ordered by
		// ascending `surfaced` timestamps to allow binary search.
		let startIndex = 0;
		for (const cohort of cohorts) {
			startIndex = binarySearchIndex(factTable, cohort.start, startIndex);
			const endIndex = binarySearchIndex(
				factTable,
				cohort.end,
				startIndex,
			);
			groups.set(cohort, factTable.slice(startIndex, endIndex));
			startIndex = endIndex;
		}
		return groups;
	}

	constructor(
		label: string,
		start: Temporal.PlainDate,
		end: Temporal.PlainDate,
	) {
		this.label = label;
		this.start = start;
		this.end = end;
	}
}
