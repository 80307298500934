// @flow

import omit from 'lodash.omit';
import pluralize from 'pluralize';
import React from 'react';
import type { ComponentType as ReactComponentType } from 'react';

import './multi-select-filter.scss';
import Dropdown from '../../components/dropdown';

import type { DisplayProps } from './description';
import SearchFilter from './filter';
import type { FilterComponentProps } from './filters';
import MultiSelectSearchFilterOption from './multi-select-filter-option';

export type MultiSelectDisplayProps = {
	onRemove: () => void,
	punctuator: string,
	resultCount: ?number,
	value: Array<string>,
};

type Props = {|
	...FilterComponentProps,
	options: Array<string>,
|};

type State = {
	open: boolean,
	query: string,
	selectedIndex: number,
};

export default (
	label: string,
	field: string,
	Display: ReactComponentType<MultiSelectDisplayProps>,
	componentIdentifier: string,
): ReactComponentType<Props> => {
	const selectFilter = (filters) =>
		typeof filters[field] === 'undefined' ? [] : filters[field];

	const updateFilters = (filters, value) =>
		value.length === 0
			? omit(filters, field)
			: { ...filters, [field]: value };

	class MultiSelectSearchFilter extends React.Component<Props, State> {
		static get displayComponent() {
			return MultiSelectSearchFilterDisplay;
		}

		static get field() {
			return field;
		}

		state: State = {
			open: false,
			query: '',
			selectedIndex: 0,
		};

		getOptions() {
			const query = this.state.query.toLowerCase();
			const selected = selectFilter(this.props.filters);

			return this.props.options.filter(
				(option) =>
					!selected.includes(option)
					&& (!query || option.toLowerCase().includes(query)),
			);
		}

		setSelectedIndex = (
			index: number,
			options: Array<string> = this.getOptions(),
		) => {
			const max = options.length - 1;

			this.setState({
				selectedIndex: Math.max(0, Math.min(index, max)),
			});
		};

		handleBlur = () => {
			if (!this.state.open) {
				this.setState({
					query: '',
				});
			}
		};

		handleClose = () => {
			this.setState({ query: '', open: false });
		};

		handleKeyDown = ({ key }: SyntheticKeyboardEvent<HTMLInputElement>) => {
			const { selectedIndex } = this.state;
			const options = this.getOptions();

			switch (key) {
				case 'ArrowDown':
					if (options && selectedIndex + 1 < options.length) {
						this.setState({
							selectedIndex: selectedIndex + 1,
						});
					}
					break;

				case 'ArrowUp':
					if (selectedIndex > 0) {
						this.setState({
							selectedIndex: selectedIndex - 1,
						});
					}
					break;

				case 'Enter':
					if (options.length > 0) {
						const option = options[selectedIndex];
						this.handleSelect(option);
					}
					break;

				default:
				//Do nothing
			}
		};

		handleMouseEnter = (index: number) => {
			this.setState({
				selectedIndex: index,
			});
		};

		handleSearchChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
			const query = event.target.value;
			this.setState({
				open: query.length > 0,
				query,
				selectedIndex: 0,
			});
		};

		handleSelect = (selected: string) => {
			this.refs.filterText.focus();
			this.refs.filterText.setSelectionRange(0, selected.length);
			this.props.onChange(
				updateFilters(this.props.filters, [
					...selectFilter(this.props.filters),
					selected,
				]),
				componentIdentifier,
			);

			const options = this.getOptions();
			const index = options.indexOf(selected);
			const newOptions = [
				...options.slice(0, index),
				...options.slice(index + 1),
			];

			if (newOptions.length === 0) {
				this.setState({ open: false });
			} else {
				this.setSelectedIndex(index, newOptions);
			}
		};

		renderOptions() {
			if (!this.state.query) {
				return null;
			}

			if (this.getOptions().length === 0) {
				return (
					<p className="MultiSelectSearchFilter-options--empty">
						{`No ${pluralize(label)} Found`}
					</p>
				);
			}

			const options = this.getOptions().map((option, index) => (
				<li key={option}>
					<MultiSelectSearchFilterOption
						index={index}
						onClick={this.handleSelect}
						onMouseEnter={this.handleMouseEnter}
						selected={index === this.state.selectedIndex}
						value={option}
					/>
				</li>
			));

			return (
				<ul className="MultiSelectSearchFilter-options">{options}</ul>
			);
		}

		render() {
			const control = (
				<input
					className="MultiSelectSearchFilter-input"
					onBlur={this.handleBlur}
					onChange={this.handleSearchChange}
					onKeyDown={this.handleKeyDown}
					ref="filterText"
					type="text"
					value={this.state.query}
				/>
			);

			return (
				<SearchFilter label={label}>
					<Dropdown
						control={control}
						onClose={this.handleClose}
						open={this.state.open}
					>
						{this.renderOptions()}
					</Dropdown>
				</SearchFilter>
			);
		}
	}

	class MultiSelectSearchFilterDisplay extends React.Component<DisplayProps> {
		static defaultProps = {
			punctuator: '',
		};

		static get key() {
			return field;
		}

		static countValues(filters) {
			return selectFilter(filters).length;
		}

		handleRemove = (removed) => {
			this.props.onChange(
				updateFilters(
					this.props.filters,
					selectFilter(this.props.filters).filter(
						(option) => option !== removed,
					),
				),
				`clear-${componentIdentifier}`,
			);
		};

		render() {
			const sortedSelections = selectFilter(this.props.filters)
				.slice()
				.sort((a, b) => a.localeCompare(b));

			return (
				<Display
					onRemove={this.handleRemove}
					punctuator={this.props.punctuator}
					resultCount={this.props.resultCount}
					value={sortedSelections}
				/>
			);
		}
	}

	return MultiSelectSearchFilter;
};
