import React from 'react';
import type { SVGProps, VoidFunctionComponent } from 'react';

type Icon = VoidFunctionComponent<SVGProps<SVGSVGElement>>;
type IconWithTitle = VoidFunctionComponent<
	SVGProps<SVGSVGElement> & { title?: string }
>;

export const AssignIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<polygon
			fill="currentColor"
			points="46.7,46 36.5,35.8 37.7,34.5 44,28.3 45.4,26.8 41.6,23 39.9,24.7 23.4,10.1 10.8,22.7 25.4,39.2 23.7,40.9    27.5,44.7 29,43.3 35,37.3 45.2,47.5 "
		/>
	</svg>
);

export const BackIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z"
			fill="currentColor"
		/>
	</svg>
);

export const DigIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path
			d="M47.4,28.7c0.8-1.4,1.2-3,1.2-4.7c0-5.4-4.4-9.9-9.9-9.9c-3.8,0-7.1,2.2-8.8,5.4c0,0,0,0,0,0l0,0l0,0l0,0l0,0l0,0   c-1.6-3.2-4.9-5.4-8.8-5.4c-5.4,0-9.9,4.4-9.9,9.9c0,1.7,0.4,3.3,1.2,4.7c0,0,0,0,0,0C16.9,38.1,30,48.4,30,48.4l0,0l0,0l0,0l0,0   C30,48.4,43.1,38.1,47.4,28.7z"
			fill="currentColor"
		/>
	</svg>
);

export const PassIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path
			d="M30,13.5c-9.4,0-17,7.6-17,17s7.6,17,17,17s17-7.6,17-17S39.4,13.5,30,13.5z M30,19.5c2,0,3.9,0.5,5.5,1.5l-15,14.9   c-0.9-1.6-1.5-3.5-1.5-5.4C19,24.4,23.9,19.5,30,19.5z M30,41.5c-2.1,0-4-0.6-5.7-1.6l15.1-15c1,1.7,1.6,3.6,1.6,5.7   C41,36.6,36.1,41.5,30,41.5z"
			fill="currentColor"
		/>
	</svg>
);

export const SnoozeIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<polygon
			fill="currentColor"
			points="20.4,27.1 29.8,14.1 29.8,10.9 14.7,10.9 15.3,15.1 23.9,15.1 14.4,28.1 14.4,31.3 30.3,31.3 29.6,27.1"
		/>
		<polygon
			fill="currentColor"
			points="24,46.5 29.4,39.2 29.4,36.2 18.3,36.2 18.9,40.1 23.5,40.1 18.2,47.4 18.2,50.4 29.7,50.4 29.1,46.5 "
		/>
		<polygon
			fill="currentColor"
			points="45.6,35.1 38.1,35.1 45.8,24.5 45.8,21.6 32.8,21.6 33.4,25.4 40.3,25.4 32.6,36 32.6,38.9 46.2,38.9 "
		/>
	</svg>
);

export const SurfaceIcon: Icon = (props) => (
	<svg viewBox="0 0 35 35" {...props}>
		<path
			fill="currentColor"
			d="M26.9,16.4l0-2l0-1.3c0-3.1-4.2-5.6-9.4-5.5s-9.4,2.6-9.4,5.6l0,1.3l0,2c-1.3,0.2-2.4,1.7-2.4,3.5c0,1.7,0.9,3.1,2,3.4l0,3   l2.7,0l0-2.9l14.1-0.1l0,2.9l2.7,0l0-3c1.1-0.4,2-1.8,2-3.4C29.3,18.1,28.2,16.6,26.9,16.4z M12.1,21.5c-0.6,0-1.2-0.5-1.2-1.1   c0-0.6,0.5-1.2,1.1-1.2c0.6,0,1.2,0.5,1.2,1.1C13.2,20.9,12.7,21.5,12.1,21.5z M23.2,21.4c-0.6,0-1.2-0.5-1.2-1.1   c0-0.6,0.5-1.2,1.1-1.2c0.6,0,1.2,0.5,1.2,1.1C24.4,20.9,23.9,21.4,23.2,21.4z"
		/>
	</svg>
);

export const SearchIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path d="M40.2,35.7l-0.2,0.2c2-2.8,3.2-6.2,3.2-9.8c0-9.4-7.6-17-17-17s-17,7.6-17,17c0,9.4,7.6,17,17,17c3.9,0,7.4-1.3,10.3-3.5   L36,40l10.2,10.6l4.3-4.2L40.2,35.7z M26.1,37.1c-0.5,0-1,0-1.5-0.1c0,0-0.1,0-0.1,0c-4.3-0.6-7.8-3.8-9-8c0,0,0,0,0-0.1   c-0.1-0.4-0.2-0.8-0.3-1.3c0,0,0-0.1,0-0.1c-0.1-0.5-0.1-0.9-0.1-1.4c0-6.1,4.9-11,11-11c0.5,0,1,0,1.4,0.1c0,0,0.1,0,0.1,0   c0.4,0.1,0.9,0.2,1.3,0.3c0,0,0,0,0,0c4.2,1.1,7.3,4.6,7.9,9c0,0,0,0.1,0,0.1c0.1,0.5,0.1,1,0.1,1.5C37.1,32.2,32.2,37.1,26.1,37.1z" />
	</svg>
);

export const FunnelIcon: Icon = (props) => (
	<svg viewBox="0 0 16 16" {...props}>
		<path d="M3,2 L13,2 L9,9 L9,15 L7,13 L7,9" fill="currentColor" />
	</svg>
);

/** @deprecated Use NewCheckIcon instead. */
export const CheckIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path d="M25.8,46.6 12.6,33.8 16.8,29.5 25.1,37.6 42.9,14.7 47.6,18.3" />
	</svg>
);

export const NewCheckIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<g>
			<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
		</g>
	</svg>
);

export const ExpandIcon: Icon = (props) => (
	<svg viewBox="0 0 16 16" {...props}>
		<g
			strokeWidth="1"
			strokeLinejoin="round"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
		>
			<line x1="14.5" y1="1.5" x2="9.5" y2="6.5" stroke="currentColor" />{' '}
			<line x1="6.5" y1="9.5" x2="1.5" y2="14.5" stroke="currentColor" />{' '}
			<polyline points="8.5,1.5 14.5,1.5 14.5,7.5 " />{' '}
			<polyline points="1.5,8.5 1.5,14.5 7.5,14.5 " />
		</g>
	</svg>
);

export const PinchIcon: Icon = (props) => (
	<svg viewBox="0 0 16 16" {...props}>
		<g
			strokeWidth="1"
			strokeLinejoin="round"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
		>
			<polyline points="14.5 6.5 9.5 6.5 9.5 1.5" stroke="currentColor" />{' '}
			<line x1="15.5" y1="0.5" x2="9.5" y2="6.5" stroke="currentColor" />{' '}
			<polyline points="6.5 14.5 6.5 9.5 1.5 9.5" />{' '}
			<line x1="0.5" y1="15.5" x2="6.5" y2="9.5" />
		</g>
	</svg>
);

export const XIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path d="M44.9,19.6 40.6,15.4 30.4,26 20.1,15.4 15.8,19.6 26.2,30.3 15.8,41 20.1,45.2 30.4,34.6 40.6,45.2 44.9,41    34.5,30.3" />
	</svg>
);

export const DropDownIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path d="M45.7,25.7 41.4,21.5 31.1,32.1 20.9,21.5 16.6,25.7 31.1,40.7" />
	</svg>
);

export const PlusIcon: Icon = (props) => (
	<svg viewBox="0 0 1 1" {...props}>
		<g strokeWidth="0.1" stroke="currentColor">
			<line x1="0.1" y1="0.5" x2="0.9" y2="0.5" />
			<line x1="0.5" y1="0.1" x2="0.5" y2="0.9" />
		</g>
	</svg>
);

/** @deprecated Use NewTrashIcon instead. */
export const TrashIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path d="M45.2,16.2 42.8,16.2 32.5,16.2 32.5,13.2 28.3,13.2 28.3,16.2 18,16.2 15.6,16.2 15.6,22.5 18.4,22.5 20,48.4    40.8,48.4 42.4,22.5 45.2,22.5" />
	</svg>
);

export const NewTrashIcon: Icon = (props) => (
	<svg viewBox="0 0 64 64" {...props}>
		<g
			strokeLinecap="round"
			strokeWidth="3"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
		>
			<path d="M54,19V56a5,5,0,0,1-5,5H15a5,5,0,0,1-5-5V19" />
			<line x1="32" y1="27" x2="32" y2="49" stroke="currentColor" />
			<line x1="22" y1="27" x2="22" y2="49" stroke="currentColor" />
			<line x1="42" y1="27" x2="42" y2="49" stroke="currentColor" />
			<polyline points="22 13 22 3 42 3 42 13" />
			<line x1="60" y1="13" x2="4" y2="13" />
		</g>
	</svg>
);

export const OnePagerIcon: Icon = (props: SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path d="M16.2,12.3v35.6h27.5V12.3H16.2z M33.2,39.2h-4.4V26.1l-4,1.7v-4.3l6.4-2.5h2V39.2z" />
	</svg>
);

export const EmailIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<g fill="currentColor">
			<polygon points="29.8,29.7 47.2,17.8 47.2,16.1 12.4,16.1 12.4,17.8" />
			<polygon points="29.8,33.3 12.4,21.4 12.4,44.8 47.2,44.8 47.2,21.4" />
		</g>
	</svg>
);

export const CalendarIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<polygon points="41.5,13.3 41.5,17.6 38.9,17.6 38.9,13.3 31.7,13.3 31.7,17.6 29.2,17.6 29.2,13.3 22,13.3 22,17.6 19.4,17.6    19.4,13.3 12.2,13.3 12.2,17.6 12.2,34 12.2,46.3 48.7,46.3 48.7,33.8 48.7,17.6 48.7,13.3" />
	</svg>
);

export const CalendarOutlineIcon: Icon = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<rect
			x="4"
			y="6"
			width="40"
			height="36"
			stroke="currentColor"
			fill="transparent"
			rx="4"
			strokeWidth="4"
		/>
		<line
			x1="14"
			y1="0"
			x2="14"
			y2="12"
			stroke="currentColor"
			strokeWidth="4"
		/>
		<line
			x1="34"
			y1="0"
			x2="34"
			y2="12"
			stroke="currentColor"
			strokeWidth="4"
		/>

		<rect x="10" y="16" width="4" height="4" fill="currentColor" />
		<rect x="18" y="16" width="4" height="4" fill="currentColor" />
		<rect x="26" y="16" width="4" height="4" fill="currentColor" />
		<rect x="34" y="16" width="4" height="4" fill="currentColor" />

		<rect x="10" y="24" width="4" height="4" fill="currentColor" />
		<rect x="18" y="24" width="4" height="4" fill="currentColor" />
		<rect x="26" y="24" width="4" height="4" fill="currentColor" />
		<rect x="34" y="24" width="4" height="4" fill="currentColor" />

		<rect x="10" y="32" width="4" height="4" fill="currentColor" />
		<rect x="18" y="32" width="4" height="4" fill="currentColor" />
		<rect x="26" y="32" width="4" height="4" fill="currentColor" />
	</svg>
);

export const DriveIcon: Icon = (props) => (
	<svg viewBox="0 0 35 35" {...props}>
		<path d="M8,7 L27,7 V17 C27,25,20,30,17.5,30 C15,30,8,25,8,17 z" />
	</svg>
);

export const EmailOutlineIcon: Icon = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<rect
			x="4"
			y="8"
			width="40"
			height="30"
			stroke="currentColor"
			fill="transparent"
			strokeWidth="4"
			rx="4"
		/>
		<line
			x1="5"
			y1="11"
			x2="24"
			y2="25"
			stroke="currentColor"
			strokeLinecap="round"
			strokeWidth="4"
		/>
		<line
			x1="43"
			y1="11"
			x2="24"
			y2="25"
			stroke="currentColor"
			strokeLinecap="round"
			strokeWidth="4"
		/>
	</svg>
);

export const MarketMapSizeIcon: Icon = (props) => (
	<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1"
		>
			<polyline points="3.5 15.5 0.5 15.5 0.5 8.5 3.5 8.5" />
			<polyline points="12.5 15.5 15.5 15.5 15.5 8.5 12.5 8.5" />
			<polyline points="6.5 15.5 6.5 12.5 9.5 12.5 9.5 15.5" />
			<rect height="15" width="9" x="3.5" y="0.5" />
			<line x1="6.5" x2="9.5" y1="3.5" y2="3.5" />
			<line x1="6.5" x2="9.5" y1="6.5" y2="6.5" />
			<line x1="6.5" x2="9.5" y1="9.5" y2="9.5" />
		</g>
	</svg>
);

export const MidwestIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path
			d="M11.7,18.3c4.1,1.9,9,2.2,13.5,2.3c2.4,0.1,4.8,0,7.2,0c0.3,0,1.9,0,2,0.4c0,0.1-1.4,1.5-1.6,1.6c1.2-0.4,2.4-0.8,3.7-0.8
	c0.5,0,1.1,0,1.6,0.1c0.8,0.2,0.5,0.2,0,0.6c-0.3,0.2-0.9,0.4-1.2,0.7c-0.3,0.3-0.3,0.8-0.4,1.1c-0.2,0.5-0.4,1-0.2,1.6
	c0.2,0.5,0.6,1,0.9,1.5c0.3-0.8,0.5-1.2,0.4-2c0-0.3-0.2-1.2-0.1-1.5c0.1-0.3,0.9-0.7,1.1-0.8c0.5-0.2,0.5-0.1,0.8,0.3
	c0.5,0.7,1,1.3,0.9,2.2c-0.1,0.7-0.2,1.9,1,1.4c0.4-0.2,0.9-0.9,1.2-1.2c0.2-0.2,0.4-0.4,0.5-0.7c0-0.2-0.1-0.4,0-0.7
	c0.2-0.3,0.8-0.4,1.1-0.5c0.1,0,0.3-0.1,0.4-0.2c0.3-0.2,0.2-0.1,0.3-0.5c0.2-1,0.4-1.3,1.4-1.7c0.7-0.3,1.6-0.5,2-1.3
	c0.3-0.6-0.2-2,0.5-2.3c0.5-0.2,0.9,0.3,1.2,0.6c0.4,0.6,0.7,1.1,1.3,1.5c-1.1,1.2-3.1,2.4-1,3.8c-1.3,1.1-2.5,1.5-2.7,3.4
	c-0.1,0.8-0.1,1.6-0.2,2.5c-0.1,0.8,0.5,1.6,0.3,2.4c-0.2,1.2-1.9,2.3-2.6,3.2c-1.4,1.5-0.4,2.7,0.6,4.2c0.3,0.4,1.9,3.5,0.2,3
	c-0.7-0.2-1.5-1.1-1.9-1.7c-0.4-0.6-0.5-1.3-1-1.8c-0.5-0.5-0.8-0.5-1.4-0.2c-0.8,0.4-0.8,0-1.6-0.2c-0.7-0.1-1.6,0-2.2,0.2
	c-0.6,0.2-0.6,0.6-1,1c-0.9,1.3-2.7-0.1-4,0.4c-1.8,0.8-3.2,1.9-2.8,4c-0.7-0.2-1.4-0.3-1.8-0.9c-0.5-0.6-0.7-1.4-1.2-2
	c-0.3-0.4-0.9-1.1-1.4-1c-0.5,0.1-0.8,0.7-1.4,0.6c-1.2-0.2-1.6-2.1-2.6-2.7c-0.5-0.3-1-0.3-1.5-0.2c-0.8,0.1-1.4,0.3-2.2,0.1
	c-1.4-0.4-2.4-1.4-3.8-1.7c-0.4-0.1-1.3,0-1.7-0.4c-0.2-0.2-0.2-0.6-0.3-0.9c-0.7-1.4-2.2-2.1-2.7-3.7c-0.5-1.6-0.6-3.6-0.5-5.2
	c0.1-2.1,1.4-3.7,1.8-5.7c0.1-0.3,0-1,0.2-1.2c0.2-0.2,0.6,0.1,0.8,0c0.3-0.1,0.4-0.2,0.3-0.5C11.7,18.6,11.5,18.1,11.7,18.3z"
		/>
	</svg>
);

export const MoneyIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<path
			d="M29.3,11.9c-9.9,0-18,8.1-18,18c0,9.9,8.1,18,18,18c9.9,0,18-8.1,18-18C47.3,20,39.2,11.9,29.3,11.9z M31.1,38.5v2.8h-3.4
	v-2.9c-3.4-0.7-4.9-3-5.2-5.5h3.5c0.5,1.7,1.5,2.5,3.8,2.5c1.9,0,3-0.7,3-1.9c0-1.2-1.2-1.4-4.1-2.1c-3-0.6-5.7-1.6-5.7-4.9
	c0-3,2.2-4.7,4.8-5.2v-2.8h3.4v2.8c3.2,0.6,4.6,2.7,4.9,5.1h-3.5c-0.4-1.2-1.2-2-3.3-2c-2,0-2.9,0.9-2.9,1.9c0,1,0.8,1.2,3,1.7
	c4.5,1,6.8,1.9,6.8,5.3C36.2,36.7,33.6,38.2,31.1,38.5z"
		/>
	</svg>
);

export const ProhibitionIcon: Icon = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<circle
			cx="24"
			cy="24"
			fill="transparent"
			r="19"
			stroke="currentColor"
			strokeWidth="4"
		/>
		<line
			x1="10"
			y1="10"
			x2="38"
			y2="38"
			stroke="currentColor"
			strokeWidth="4"
		/>
	</svg>
);

export const AcquiredIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<polygon points="51.4,26.8 29.1,49 11,48.8 10.8,30.6 33,8.4" />
	</svg>
);

export const IPOIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<g>
			<path d="M11.7,19.4v22.6H6.6V19.4H11.7z" />
			<path d="M15.2,19.7c1.6-0.3,3.8-0.5,6.9-0.5c3.1,0,5.4,0.6,6.9,1.8c1.4,1.1,2.4,3,2.4,5.2c0,2.2-0.7,4.1-2.1,5.4   C27.6,33.2,25,34,22,34c-0.7,0-1.3,0-1.7-0.1v8.1h-5.1V19.7z M20.3,29.9c0.4,0.1,1,0.1,1.7,0.1c2.7,0,4.4-1.4,4.4-3.7   c0-2.1-1.4-3.3-4-3.3c-1,0-1.7,0.1-2.1,0.2V29.9z" />
			<path d="M54.4,30.4c0,7.4-4.5,11.9-11.1,11.9c-6.7,0-10.6-5.1-10.6-11.5c0-6.8,4.3-11.8,11-11.8C50.6,19,54.4,24.2,54.4,30.4z   M38.1,30.7c0,4.4,2.1,7.5,5.5,7.5c3.4,0,5.4-3.3,5.4-7.7c0-4.1-1.9-7.5-5.5-7.5C40.1,23.1,38.1,26.3,38.1,30.7z" />
		</g>
	</svg>
);

export const DiedIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<g>
			<path d="M22.5,25c0,3.1,2.5,5.6,5.6,5.6h3.1c3.1,0,5.6-2.5,5.6-5.6v-1.7c1.5-1.7,2.4-3.9,2.4-6.3c0-5.3-4.3-9.5-9.5-9.5   c-5.3,0-9.5,4.3-9.5,9.5c0,2.4,0.9,4.6,2.4,6.3V25z" />
			<path d="M45.3,40.5c-1.3-0.7-2.9-0.6-4.1,0l-5.7-2.8l5.6-2.9c1.3,0.6,2.8,0.6,4.1-0.1c2.3-1.2,3.2-4,2-6.3s-4-3.2-6.3-2   c-1.3,0.7-2.2,2-2.4,3.3l-9.2,4.8l-8.7-4.3c-0.3-1.4-1.1-2.6-2.5-3.3c-2.3-1.1-5.1-0.2-6.2,2.1s-0.2,5.1,2.1,6.2   c1.3,0.7,2.9,0.6,4.1,0l5,2.5l-4.9,2.6c-1.3-0.6-2.8-0.6-4.1,0.1c-2.3,1.2-3.2,4-2,6.3c1.2,2.3,4,3.2,6.3,2c1.3-0.7,2.2-2,2.4-3.3    l8.5-4.5l9.3,4.6c0.3,1.4,1.1,2.6,2.5,3.3c2.3,1.1,5.1,0.2,6.2-2.1C48.5,44.4,47.6,41.6,45.3,40.5z" />
		</g>
	</svg>
);

export const FoundedIcon: Icon = (props) => (
	<svg viewBox="0 0 60 60" {...props}>
		<circle cx="30" cy="30" r="15" />
	</svg>
);

export const PersonIcon: Icon = (props) => (
	<svg viewBox="0 0 30 30" {...props}>
		<g fill="currentColor">
			<path d="M7,23.5h14.5c0-5.3-2.4-9.7-5.6-10.9c1.5-0.6,2.5-2.1,2.5-3.8c0-2.3-1.9-4.2-4.2-4.2s-4.2,1.9-4.2,4.2c0,1.7,1,3.2,2.5,3.8   C9.4,13.7,7,18.2,7,23.5z" />
		</g>
	</svg>
);

export const HollowPersonIcon: Icon = (props) => (
	<svg viewBox="0 0 64 64" {...props}>
		<g
			strokeLinecap="round"
			strokeWidth="2"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
		>
			<path d="M38,39H26A18,18,0,0,0,8,57H8s9,4,24,4,24-4,24-4h0A18,18,0,0,0,38,39Z" />
			<path
				d="M19,17.067a13,13,0,1,1,26,0C45,24.283,39.18,32,32,32S19,24.283,19,17.067Z"
				stroke="currentColor"
			/>
		</g>
	</svg>
);

const LoadingDotsAnimation: Icon = (props) => (
	<animate
		attributeName="cy"
		calcMode="spline"
		dur="1.5s"
		from="15"
		keyTimes="0; 0.167; 0.333; 0.467; 1.0"
		keySplines="0.7 0.3 0.3 1; 0.7 0.3 0.3 1; 0.7 0 0.3 1; 0 0 0 0"
		repeatCount="indefinite"
		to="15"
		values="15; 9; 16; 15; 15"
		{...props}
	/>
);

export const LoadingDots: Icon = (props) => (
	<svg viewBox="0 0 30 30" {...props}>
		<circle id="left" cx="6" cy="15" r="3" fill="currentColor">
			<LoadingDotsAnimation begin="0s" />
		</circle>
		<circle id="middle" cx="15" cy="15" r="3" fill="currentColor">
			<LoadingDotsAnimation begin="0.095s" />
		</circle>
		<circle id="right" cx="24" cy="15" r="3" fill="currentColor">
			<LoadingDotsAnimation begin="0.19s" />
		</circle>
	</svg>
);

export const HelpIcon: Icon = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<path
			fill="none"
			stroke="currentColor"
			d="M24,1C11.31738,1,1,11.31787,1,24s10.31738,23,23,23s23-10.31787,23-23S36.68262,1,24,1z"
		/>
		<path
			fill="currentColor"
			d="M24,12c-2.6416,0-5.375,1.86816-6.5,4.44238c-0.22168,0.50586,0.00977,1.0957,0.51562,1.31689 c0.50781,0.22266,1.09668-0.00977,1.31641-0.51611C20.14062,15.39453,22.14746,14,24,14c2.66309,0,5,2.33643,5,5s-2.33691,5-5,5 c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1s1-0.44775,1-1v-3.07471c3.34082-0.50195,6-3.46973,6-6.92529 C31,15.20557,27.79395,12,24,12z"
		/>
		<circle fill="currentColor" cx="24" cy="35" r="2" />
	</svg>
);

export const HerbieIcon: Icon = (props) => (
	<svg viewBox="0 0 35 35" {...props}>
		<path
			fill="currentColor"
			d="M26.9,16.4l0-2l0-1.3c0-3.1-4.2-5.6-9.4-5.5s-9.4,2.6-9.4,5.6l0,1.3l0,2c-1.3,0.2-2.4,1.7-2.4,3.5c0,1.7,0.9,3.1,2,3.4l0,3   l2.7,0l0-2.9l14.1-0.1l0,2.9l2.7,0l0-3c1.1-0.4,2-1.8,2-3.4C29.3,18.1,28.2,16.6,26.9,16.4z M12.1,21.5c-0.6,0-1.2-0.5-1.2-1.1   c0-0.6,0.5-1.2,1.1-1.2c0.6,0,1.2,0.5,1.2,1.1C13.2,20.9,12.7,21.5,12.1,21.5z M23.2,21.4c-0.6,0-1.2-0.5-1.2-1.1   c0-0.6,0.5-1.2,1.1-1.2c0.6,0,1.2,0.5,1.2,1.1C24.4,20.9,23.9,21.4,23.2,21.4z"
		/>
	</svg>
);

export const GoogleIcon: Icon = (props) => (
	<svg width="18" height="18" {...props}>
		<g fill="#000" fillRule="evenodd">
			<path
				d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
				fill="#EA4335"
			/>
			<path
				d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
				fill="#4285F4"
			/>
			<path
				d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
				fill="#FBBC05"
			/>
			<path
				d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
				fill="#34A853"
			/>
			<path fill="none" d="M0 0h18v18H0z" />
		</g>
	</svg>
);

export const LockIcon: IconWithTitle = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		{props.title && <title>{props.title}</title>}
		<path
			d="M18 9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6V9H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"
			fill="currentColor"
		/>
	</svg>
);

export const UnlockIcon: IconWithTitle = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		{props.title && <title>{props.title}</title>}
		<g fill="currentColor">
			<path
				d="M17 6c0-2.76-2.24-5-5-5S7 3.24 7 6V9H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2H8.9zm-5 12c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm-3.1-9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1H17z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export const RefreshIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<g>
			<path d="M17.65 6.35A7.958 7.958 0 0 0 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0 1 12 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
		</g>
	</svg>
);

export const ResendEmailIcon: Icon = (props) => (
	<svg viewBox="0 0 200 200" {...props}>
		<g
			transform="matrix(.1 0 0 -.1 0 200)"
			fill="currentColor"
			stroke="currentColor"
		>
			<path d="M140 1250V800h410c403 0 410 0 410 20s-7 20-365 20c-201 0-365 3-365 8 0 7 302 309 396 396l42 38 62-46c34-25 69-46 77-46s32 13 52 28c20 16 49 37 64 47l28 18 64-63c43-42 68-61 79-57 26 10 19 26-43 90l-59 62 221 158 222 158 3-155c2-148 4-156 22-156 19 0 20 7 20 190v190H140v-450zm1230 403c0-7-221-167-500-365l-66-46-274 199c-151 109-279 204-284 209-7 7 182 10 557 10 312 0 567-3 567-7zm-965-181c110-80 204-148 209-153 8-8-400-418-425-427-5-2-9 163-9 368 0 294 3 370 13 364 6-4 102-72 212-152z" />
			<path d="M1573 1186c-112-13-124-22-92-68 59-85 111-148 123-148 6 0 21 19 33 43l21 42 55-56c71-73 100-145 100-249 0-105-29-178-102-251-36-36-76-64-114-79-58-24-73-51-34-58 31-6 129 49 183 102 163 164 160 414-7 577l-61 60 16 38c15 38 7 63-18 60-6-1-52-7-103-13zm51-83c-23-56-28-57-59-17-32 42-24 54 37 54h37l-15-37z" />
			<path d="M1273 1110c-151-76-245-260-215-422 16-89 63-177 122-232 27-26 50-51 50-57 0-5-7-24-16-41-26-51-9-57 113-44 56 6 108 16 114 23 9 9-3 32-53 100-35 48-72 89-81 91-12 2-22-8-32-32-9-20-19-36-24-36-18 0-94 94-119 148-22 48-27 71-27 142s5 94 27 142c36 78 98 142 175 180 43 21 63 36 61 47-5 26-30 23-95-9zm97-732c0-9-62-21-84-16-13 3-13 9 5 48l20 45 29-35c16-19 30-38 30-42z" />
		</g>
	</svg>
);

export const DragIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<g>
			<path d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
		</g>
	</svg>
);

export const DownloadIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M19.8,10.1C18.9,6.6,15.8,4,12,4C9,4,6.4,5.6,5,8.1C2.2,8.6,0,11,0,14c0,3.3,2.7,6,6,6c1,0,11.9,0,13,0c2.8,0,5-2.2,5-5 C24,12.5,22.2,10.4,19.8,10.1z M10,13V9h4v4h3l-5,5l-5-5H10z"
			fill="currentColor"
		/>
	</svg>
);

export const EditIcon: Icon = (props) => (
	<svg viewBox="0 0 16 16" {...props}>
		<g
			strokeWidth="1"
			strokeLinejoin="round"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
		>
			<polygon
				points=" 13,0.5 15.5,3 7.5,11 4,12 5,8.5 "
				stroke="currentColor"
			/>{' '}
			<line x1="11" y1="2.5" x2="13.5" y2="5" stroke="currentColor" />{' '}
			<path d="M13.5,9.5v5 c0,0.552-0.448,1-1,1h-11c-0.552,0-1-0.448-1-1v-11c0-0.552,0.448-1,1-1h5" />{' '}
		</g>
	</svg>
);

export const LocationPinIcon: Icon = (props) => (
	<svg viewBox="0 0 16 16" {...props}>
		<g
			strokeWidth="1"
			strokeLinejoin="round"
			fill="none"
			stroke="currentColor"
			strokeLinecap="round"
		>
			<path d="M13.5,6c0,4.5-5.5,9.5-5.5,9.5S2.5,10.5,2.5,6a5.5,5.5,0,0,1,11,0Z" />{' '}
			<circle cx="8" cy="6" r="2.5" stroke="currentColor" />
		</g>
	</svg>
);

export const TODOIcon: Icon = (props) => (
	<svg viewBox="0 0 48 48" {...props}>
		<g
			strokeLinecap="round"
			strokeWidth="2"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
		>
			<polyline points="37,9 42,9 42,46 6,46 6,9 11,9 " />{' '}
			<polyline points=" 17,28 22,33 31,24 " stroke="currentColor" />{' '}
			<path d="M29,7c0-2.761-2.239-5-5-5 s-5,2.239-5,5h-4v6h18V7H29z" />
		</g>
	</svg>
);

export const VerifiedIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<path
			d="M23 12l-2.44-2.79.34-3.69-3.61-.82-1.89-3.2L12 2.96 8.6 1.5 6.71 4.69 3.1 5.5l.34 3.7L1 12l2.44 2.79-.34 3.7 3.61.82L8.6 22.5l3.4-1.47 3.4 1.46 1.89-3.19 3.61-.82-.34-3.69L23 12zm-12.91 4.72l-3.8-3.81 1.48-1.48 2.32 2.33 5.85-5.87 1.48 1.48-7.33 7.35z"
			fill="currentColor"
		/>
	</svg>
);

export const PipelineIcon: Icon = (props) => (
	<svg viewBox="0 0 64 64" {...props}>
		<g
			strokeLinecap="round"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
		>
			<rect x="5" y="5" width="54" height="54" rx="5" />
			<rect x="13" y="13" width="15" height="38" />
			<rect x="36" y="13" width="15" height="19" />
		</g>
	</svg>
);

export const ForwardIcon: Icon = (props) => (
	<svg {...props} viewBox="0 0 32 32">
		<g
			strokeLinecap="round"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
		>
			<polyline points="5,2 19,16 5,30 " />
			<polyline points="13,2 27,16 13,30 " />
		</g>
	</svg>
);

export const WatchListIcon: Icon = (props) => (
	<svg viewBox="0 0 64 64" {...props}>
		<g
			strokeLinecap="round"
			strokeWidth="3"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="round"
		>
			<line x1="26" y1="10" x2="60" y2="10" stroke="currentColor" />{' '}
			<line x1="26" y1="32" x2="60" y2="32" stroke="currentColor" />{' '}
			<line x1="26" y1="54" x2="60" y2="54" stroke="currentColor" />{' '}
			<rect x="4" y="4" width="12" height="12" />{' '}
			<rect x="4" y="26" width="12" height="12" />{' '}
			<rect x="4" y="48" width="12" height="12" />
		</g>
	</svg>
);

export const ZoomInIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<title>zoom in</title>
		<g
			strokeLinecap="square"
			strokeWidth="2"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="miter"
			strokeMiterlimit="10"
		>
			<line x1="10" y1="7" x2="10" y2="13" stroke="currentColor" />{' '}
			<line x1="13" y1="10" x2="7" y2="10" stroke="currentColor" />{' '}
			<line x1="22" y1="22" x2="15.656" y2="15.656" />{' '}
			<circle cx="10" cy="10" r="8" />
		</g>
	</svg>
);

export const ZoomOutIcon: Icon = (props) => (
	<svg viewBox="0 0 24 24" {...props}>
		<title>zoom out</title>
		<g
			strokeLinecap="square"
			strokeWidth="2"
			fill="none"
			stroke="currentColor"
			strokeLinejoin="miter"
			strokeMiterlimit="10"
		>
			<line x1="13" y1="10" x2="7" y2="10" stroke="currentColor" />{' '}
			<line x1="22" y1="22" x2="15.656" y2="15.656" />{' '}
			<circle cx="10" cy="10" r="8" />
		</g>
	</svg>
);
