import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { IconMenu } from '../components/menus/menu';
import { colors } from '../theme';
import { EllipsisHorizontalIcon } from '../theme/icons/system';
import { trackEvent } from '../utils/analytics';

import DismissActionForm from './dismiss-action-form';
import HistoryPopover from './history-popover';
import OpenEmailLink from './open-email-link';
import SnoozeActionMenuForm from './snooze-action-menu-form';
import { type CompanyOutreach } from './types';

const Menus = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
	justify-content: flex-end;
`;
const MenuBody = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	padding: 6px 0px;
`;
const linkStyles = css`
	background: none;
	border: none;
	color: inherit;
	display: flex;
	flex: 1;
	font: inherit;
	justify-content: flex-start;
	padding 6px 8px;
	width: 100%;

	&:hover {
		background: ${colors.layer.hover};
		cursor: pointer;
	}
`;
const EmailLink = styled(OpenEmailLink)`
	${linkStyles}
`;
const DismissLink = styled.button`
	${linkStyles}

	color: ${colors.text.danger};
	&:hover {
		color: ${colors.text.dangerHover};
	}
`;
const SnoozeLink = styled.button<{ $disabled?: boolean }>`
	${linkStyles}

	${({ $disabled }) =>
		$disabled
		&& `
		color: ${colors.text.disabled};
		&:hover {
			background: ${colors.layer.disabled};
			cursor: not-allowed;
		}
	`}
`;

type ActiveMenuBody = 'actions' | 'dismiss' | 'snooze-form';

export default function OutreachActionsCell({
	companyOutreach,
}: {
	companyOutreach: CompanyOutreach;
}) {
	const [activeMenuBody, setActiveMenuBody] =
		useState<ActiveMenuBody>('actions');

	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const closeMenu = () => {
		setMenuIsOpen(false);
	};
	const handleMenuClose = useCallback((isOpen: boolean) => {
		setMenuIsOpen(isOpen);
		if (isOpen) {
			setActiveMenuBody('actions');
		}
	}, []);
	const snoozeDisabled = companyOutreach.state === 'snoozed';
	const emailLinkRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (menuIsOpen) {
			emailLinkRef.current?.focus();
		}
	}, [menuIsOpen]);

	return (
		<Menus>
			<HistoryPopover companyOutreach={companyOutreach} />
			<IconMenu
				Icon={EllipsisHorizontalIcon}
				buttonSize="small"
				isOpen={menuIsOpen}
				onOpenChange={handleMenuClose}
				placement="bottom end"
			>
				<MenuBody>
					{activeMenuBody === 'actions' && (
						<>
							<EmailLink
								analyticsComponentName="actions-cell"
								companyOutreach={companyOutreach}
								onClick={closeMenu}
								ref={emailLinkRef}
							/>
							<SnoozeLink
								$disabled={snoozeDisabled}
								onClick={() => {
									if (snoozeDisabled) return;

									setActiveMenuBody('snooze-form');
									trackEvent(
										'Snooze Outreach',
										'actions-cell',
										'home-page',
										{ trigger: 'button' },
									);
								}}
							>
								Follow Up Later
							</SnoozeLink>
							<DismissLink
								onClick={() => {
									setActiveMenuBody('dismiss');
									trackEvent(
										'Dismiss Outreach',
										'actions-cell',
										'home-page',
										{ trigger: 'button' },
									);
								}}
							>
								Dismiss
							</DismissLink>
						</>
					)}
					{activeMenuBody === 'dismiss' && (
						<DismissActionForm
							onClose={closeMenu}
							outreachIds={[companyOutreach.id]}
						/>
					)}
					{activeMenuBody === 'snooze-form' && (
						<SnoozeActionMenuForm
							onClose={closeMenu}
							outreach={companyOutreach}
						/>
					)}
				</MenuBody>
			</IconMenu>
		</Menus>
	);
}
