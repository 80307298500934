import React, { memo, useCallback, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import colors from 'tailwindcss/colors';

import '@reach/dialog/styles.css';

import { ExpandIcon as Expand } from '../components/icons';

import { ProfileCardDragItemType } from './constants';
import PriorityCircle from './profile-card-priority';
import type { PipelineStageItem } from './types';

const ExpandIcon = styled(Expand)`
	color: transparent;
	grid-area: expand;
	height: 24px;
	padding: 6px;
	transition: color 0.1s linear;
	width: 24px;

	&:hover {
		cursor: pointer;
	}
`;

const Body = styled.div<{ isDragging?: boolean; canDrag?: boolean }>`
	background-color: white;
	border-radius: 6px;
	box-shadow: 1px 1px 4px 0 ${colors.slate['900']}18;
	cursor: ${({ canDrag }) => (canDrag ? 'grab' : 'pointer')};
	display: grid;
	column-gap: 4px;
	grid-template:
		'image title priority expand' min-content
		'image subtitle subtitle subtitle' 1fr
		/ min-content max-content 1fr min-content;
	overflow: hidden;
	width: 294px;
	${({ isDragging }) => (isDragging ? 'opacity: 0.4;' : '')}

	&:hover ${ExpandIcon} {
		color: ${colors.slate[700]};
	}
`;

const ImageLink = styled.a`
	grid-area: image;
	height: 72px;
	overflow: hidden;
	width: 72px;

	&:hover {
		cursor: pointer;

		& ~ ${ExpandIcon} {
			color: transparent;
		}
	}
`;

const Image = styled.img`
	border-radius: 50%;
	height: 100%;
	object-fit: contain;
	object-position: 50% 50%;
	padding: 12px;
	width: 100%;
`;

const Title = styled.div`
	color: ${colors.slate[700]};
	grid-area: title;
	font-size: 15px;
	margin: 12px 0 6px;
`;

const Subtitle = styled.div`
	color: ${colors.slate[500]};
	font-size: 11.5px;
	grid-area: subtitle;
	margin: 0 12px 12px 0;
`;

const StyledPriority = styled(PriorityCircle)`
	grid-area: priority;
	margin: 12px 0 6px;
	align-self: center;
`;

interface CardBodyProps extends React.HTMLAttributes<HTMLDivElement> {
	canDrag?: boolean;
	isDragging?: boolean;
	onExpandClick?(): void;
	photoUrl: string;
	profileId: number;
	subTitle: string;
	suppressClicksWhileDragging?: React.MutableRefObject<boolean>;
	title: string;
	priority: '' | 'HIGH' | 'MEDIUM' | 'LOW';
}

export function CardBody({
	canDrag,
	isDragging,
	onExpandClick,
	photoUrl,
	profileId,
	subTitle,
	suppressClicksWhileDragging,
	title,
	priority,
	...props
}: CardBodyProps): JSX.Element {
	const onImageLinkClick = useCallback(
		(event: React.MouseEvent<HTMLAnchorElement>) => {
			event.stopPropagation();
			if (suppressClicksWhileDragging?.current) event.preventDefault();
		},
		[suppressClicksWhileDragging],
	);

	return (
		<Body
			{...props}
			canDrag={canDrag}
			isDragging={isDragging}
			onClick={onExpandClick}
		>
			<ImageLink
				href={`/people/${profileId}`}
				onClick={onImageLinkClick}
				target="_blank"
				rel="noopener noreferrer"
			>
				<Image src={photoUrl} alt={title} />
			</ImageLink>
			<Title>{title}</Title>
			<StyledPriority priority={priority} />
			<Subtitle>{subTitle}</Subtitle>
			<ExpandIcon />
		</Body>
	);
}

interface Props {
	canDrag: boolean;
	item: PipelineStageItem;
	onCardClick: (item: PipelineStageItem) => void;
}

function ProfileCard({ canDrag, item, onCardClick }: Props): JSX.Element {
	const suppressClicksWhileDragging = React.useRef<boolean>(false);
	const [isDragging, dragRef, dragPreviewRef] = useDrag(
		() => ({
			canDrag: () => canDrag,
			collect: (monitor) => monitor.isDragging(),
			end: () => {
				setTimeout(() => {
					suppressClicksWhileDragging.current = false;
				}, 100);
			},
			item: () => {
				suppressClicksWhileDragging.current = true;
				return item;
			},
			type: ProfileCardDragItemType,
		}),
		[canDrag],
	);

	const onExpandClick = useCallback(() => {
		if (!suppressClicksWhileDragging.current) onCardClick(item);
	}, [suppressClicksWhileDragging, onCardClick, item]);

	useEffect(() => {
		dragPreviewRef(getEmptyImage());
	}, [dragPreviewRef]);

	return (
		<div ref={dragRef}>
			<CardBody
				canDrag={canDrag}
				isDragging={isDragging}
				onExpandClick={onExpandClick}
				photoUrl={item.photoUrl}
				profileId={item.profileId}
				subTitle={item.subTitle}
				suppressClicksWhileDragging={suppressClicksWhileDragging}
				title={item.title}
				priority={item.priority}
			/>
		</div>
	);
}

export default memo<Props>(ProfileCard);
