import store from '../../store';
import { get } from '../../utils/api';
import type { OnePager, PartialOnePager } from '../types';

import {
	ONEPAGER_DELETE,
	ONEPAGER_LOAD_FAILURE,
	ONEPAGER_LOAD_REQUEST,
	ONEPAGER_LOAD_SUCCESS,
	ONEPAGER_SAVE,
	ONEPAGERS_LOAD_SUCCESS,
} from './constants';

interface DeleteOnePagerAction {
	type: typeof ONEPAGER_DELETE;
	payload: number;
}
export const deleteOnePager = (payload: number): DeleteOnePagerAction => ({
	type: ONEPAGER_DELETE,
	payload,
});

interface LoadOnePagerRequestAction {
	type: typeof ONEPAGER_LOAD_REQUEST;
	payload: {
		id: number;
	};
}
interface LoadOnePagerSuccessAction {
	type: typeof ONEPAGER_LOAD_SUCCESS;
	payload: OnePager;
}
interface LoadOnePagerFailureAction {
	type: typeof ONEPAGER_LOAD_FAILURE;
	error: unknown;
}
export const loadOnePager = (id: number): void => {
	store.dispatch({
		type: ONEPAGER_LOAD_REQUEST,
		payload: {
			id,
		},
	} as LoadOnePagerRequestAction);

	get(`/onepagers/${id}`)
		.then((payload: OnePager) => {
			store.dispatch({
				type: ONEPAGER_LOAD_SUCCESS,
				payload,
			} as LoadOnePagerSuccessAction);
		})
		.catch((error: unknown) => {
			store.dispatch({
				type: ONEPAGER_LOAD_FAILURE,
				error,
			} as LoadOnePagerFailureAction);
		});
};

interface LoadOnePagersSuccessAction {
	type: typeof ONEPAGERS_LOAD_SUCCESS;
	payload: Array<PartialOnePager>;
}
export const loadOnePagersSuccess = (
	payload: Array<PartialOnePager>,
): LoadOnePagersSuccessAction => ({
	type: ONEPAGERS_LOAD_SUCCESS,
	payload,
});

export const loadOnePagerSuccess = (
	payload: OnePager,
): LoadOnePagerSuccessAction => ({
	type: ONEPAGER_LOAD_SUCCESS,
	payload,
});

interface SaveOnePagerAction {
	type: typeof ONEPAGER_SAVE;
	payload: OnePager;
}
export const saveOnePager = (payload: OnePager): SaveOnePagerAction => ({
	type: ONEPAGER_SAVE,
	payload,
});

export type Action =
	| DeleteOnePagerAction
	| LoadOnePagerRequestAction
	| LoadOnePagerSuccessAction
	| LoadOnePagerFailureAction
	| LoadOnePagersSuccessAction
	| SaveOnePagerAction;
