import type { Action, Reducer } from 'redux';

import { ActionTypes } from '../constants';

type ActionTypeString = (typeof ActionTypes)[keyof typeof ActionTypes];

function createReducer<S, A extends Action<ActionTypeString>>(
	initialState: S,
	handlers: Record<string, Reducer<S, A>>,
) {
	// Redux reducers take an optional state on first call.
	// eslint-disable-next-line default-param-last
	return (state: S = initialState, action: A): S => {
		return handlers.hasOwnProperty(action.type)
			? handlers[action.type](state, action)
			: state;
	};
}

export default createReducer;
