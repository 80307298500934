// @flow

import { type Component as ReactComponent } from 'react';

import { and, or, type Permission } from '../../Authorized';
import CompaniesAge from '../companies-age';
import CompaniesContacted from '../companies-contacted';
import CompaniesContactedDrive from '../companies-contacted-drive';
import CompaniesInHerbie from '../companies-in-herbie';
import CompaniesMap from '../companies-map';
import FundSizeNormalized from '../fund-size-normalized-years';
import FundSizeRaw from '../fund-size-raw-years';
import FundSizes2016 from '../fund-sizes-2016';
import FundingMap from '../funding-map';
import FundingRoundBySize from '../funding-round-by-size';
import IndustriesContacted from '../industries-contacted';
import IndustriesFoundedByIndustry from '../industries-founded-by-industry';
import IndustriesFoundedByYear from '../industries-founded-by-year';
import IndustriesFoundedYearlyChange from '../industries-founded-yearly-change';
import IndustriesFunding from '../industries-funding';
import MidwestOnlyFunding from '../midwest-only-funding';
import MonthByMonthFundingExclude100 from '../month-by-month-exclude-100';
import MonthByMonthFunding from '../month-by-month-funding';
import MWGrowthRounds from '../mw-growth-rounds';
import PeopleContacted from '../people-contacted';
import PeopleContactedDrive from '../people-contacted-drive';
import PeopleMap from '../people-map';
import PMEIRRFundIData from '../pme-irr-fund-1';
import PMEIRRFundIIData from '../pme-irr-fund-2';
import SemiAnnualAllFundsPortfolioAmountInvestedByCompany from '../semi-annual-all-funds/portfolio-amount-invested-by-company';
import SemiAnnualAllFundsPortfolioBurnToRevenueByCompany from '../semi-annual-all-funds/portfolio-burn-to-revenue-by-company';
import SemiAnnualAllFundsPortfolioCurrentRevenueTimesOwnershipByCompany from '../semi-annual-all-funds/portfolio-current-revenue-times-ownership-by-company';
import SemiAnnualAllFundsPortfolioHeadcount from '../semi-annual-all-funds/portfolio-headcount';
import SemiAnnualAllFundsPortfolioOwnershipByCompany from '../semi-annual-all-funds/portfolio-ownership-by-company';
import SemiAnnualAllFundsPortfolioRevenue from '../semi-annual-all-funds/portfolio-revenue';
import SemiAnnualAllFundsPortfolioRevenueByCompany from '../semi-annual-all-funds/portfolio-revenue-by-company';
import SemiAnnualAllFundsPortfolioRevenueGrowthByCompany from '../semi-annual-all-funds/portfolio-revenue-growth-by-company';
import SemiAnnualFund1PortfolioAmountInvestedByCompany from '../semi-annual-fund-1/portfolio-amount-invested-by-company';
import SemiAnnualFund1PortfolioBurnByCompany from '../semi-annual-fund-1/portfolio-burn-by-company';
import SemiAnnualFund1PortfolioBurnPerEmployeeByCompany from '../semi-annual-fund-1/portfolio-burn-per-employee-by-company';
import SemiAnnualFund1PortfolioBurnToRevenueByCompany from '../semi-annual-fund-1/portfolio-burn-to-revenue-by-company';
import SemiAnnualFund1PortfolioCashAvailableByCompany from '../semi-annual-fund-1/portfolio-cash-available-by-company';
import SemiAnnualFund1PortfolioCurrentRevenueTimesOwnershipByCompany from '../semi-annual-fund-1/portfolio-current-revenue-times-ownership-by-company';
import SemiAnnualFund1PortfolioGMPercentageByCompany from '../semi-annual-fund-1/portfolio-gm-percentage-by-company';
import SemiAnnualFund1PortfolioHeadcount from '../semi-annual-fund-1/portfolio-headcount';
import SemiAnnualFund1PortfolioHeadcountByCompany from '../semi-annual-fund-1/portfolio-headcount-by-company';
import SemiAnnualFund1PortfolioOpexPerEmployeeByCompany from '../semi-annual-fund-1/portfolio-opex-per-employee-by-company';
import SemiAnnualFund1PortfolioOwnershipByCompany from '../semi-annual-fund-1/portfolio-ownership-by-company';
import SemiAnnualFund1PortfolioRevenue from '../semi-annual-fund-1/portfolio-revenue';
import SemiAnnualFund1PortfolioRevenueByCompany from '../semi-annual-fund-1/portfolio-revenue-by-company';
import SemiAnnualFund1PortfolioRevenueChangeByCompany from '../semi-annual-fund-1/portfolio-revenue-change-by-company';
import SemiAnnualFund1PortfolioRevenuePerEmployeeByCompany from '../semi-annual-fund-1/portfolio-revenue-per-employee-by-company';
import SemiAnnualFund1YearlyPortfolioHeadcount from '../semi-annual-fund-1/yearly-portfolio-headcount';
import SemiAnnualFund1YearlyPortfolioRevenue from '../semi-annual-fund-1/yearly-portfolio-revenue';
import SemiAnnualFund2PortfolioAmountInvestedByCompany from '../semi-annual-fund-2/portfolio-amount-invested-by-company';
import SemiAnnualFund2PortfolioBurnByCompany from '../semi-annual-fund-2/portfolio-burn-by-company';
import SemiAnnualFund2PortfolioBurnPerEmployeeByCompany from '../semi-annual-fund-2/portfolio-burn-per-employee-by-company';
import SemiAnnualFund2PortfolioBurnToRevenueByCompany from '../semi-annual-fund-2/portfolio-burn-to-revenue-by-company';
import SemiAnnualFund2PortfolioCashAvailableByCompany from '../semi-annual-fund-2/portfolio-cash-available-by-company';
import SemiAnnualFund2PortfolioCurrentRevenueTimesOwnershipByCompany from '../semi-annual-fund-2/portfolio-current-revenue-times-ownership-by-company';
import SemiAnnualFund2PortfolioGMPercentageByCompany from '../semi-annual-fund-2/portfolio-gm-percentage-by-company';
import SemiAnnualFund2PortfolioHeadcount from '../semi-annual-fund-2/portfolio-headcount';
import SemiAnnualFund2PortfolioHeadcountByCompany from '../semi-annual-fund-2/portfolio-headcount-by-company';
import SemiAnnualFund2PortfolioOpexPerEmployeeByCompany from '../semi-annual-fund-2/portfolio-opex-per-employee-by-company';
import SemiAnnualFund2PortfolioOwnershipByCompany from '../semi-annual-fund-2/portfolio-ownership-by-company';
import SemiAnnualFund2PortfolioRevenue from '../semi-annual-fund-2/portfolio-revenue';
import SemiAnnualFund2PortfolioRevenueByCompany from '../semi-annual-fund-2/portfolio-revenue-by-company';
import SemiAnnualFund2PortfolioRevenueChangeByCompany from '../semi-annual-fund-2/portfolio-revenue-change-by-company';
import SemiAnnualFund2PortfolioRevenuePerEmployeeByCompany from '../semi-annual-fund-2/portfolio-revenue-per-employee-by-company';
import SemiAnnualFund2YearlyPortfolioHeadcount from '../semi-annual-fund-2/yearly-portfolio-headcount';
import SemiAnnualFund2YearlyPortfolioRevenue from '../semi-annual-fund-2/yearly-portfolio-revenue';
import YearlyFundDollarsByBucket from '../yearly-fund-dollars-by-bucket';
import YearlyFundNumbersByBucket from '../yearly-fund-numbers-by-bucket';

type StatComponent = Class<ReactComponent<{||}>> & {
	getDetailCard(): Class<ReactComponent<{||}>>,
	getKey(): string,
};

export const statComponents: Array<{|
	auth: Permission,
	Component: StatComponent,
|}> = [
	{
		Component: PeopleMap,
		auth: 'contacts.stats',
	},
	{
		Component: CompaniesMap,
		auth: 'contacts.stats',
	},
	{
		Component: MonthByMonthFunding,
		auth: 'contacts.stats',
	},
	{
		Component: MidwestOnlyFunding,
		auth: 'contacts.stats',
	},
	{
		Component: MonthByMonthFundingExclude100,
		auth: 'contacts.stats',
	},
	{
		Component: MWGrowthRounds,
		auth: 'contacts.stats',
	},
	{
		Component: FundingMap,
		auth: 'contacts.stats',
	},
	{
		Component: FundingRoundBySize,
		auth: 'contacts.stats',
	},
	{
		Component: FundSizes2016,
		auth: 'contacts.stats',
	},
	{
		Component: FundSizeNormalized,
		auth: 'contacts.stats',
	},
	{
		Component: FundSizeRaw,
		auth: 'contacts.stats',
	},
	{
		Component: YearlyFundDollarsByBucket,
		auth: 'contacts.stats',
	},
	{
		Component: YearlyFundNumbersByBucket,
		auth: 'contacts.stats',
	},
	{
		Component: IndustriesFunding,
		auth: 'contacts.stats',
	},
	{
		Component: IndustriesContacted,
		auth: 'contacts.stats',
	},
	{
		Component: IndustriesFoundedByIndustry,
		auth: 'contacts.stats',
	},
	{
		Component: IndustriesFoundedByYear,
		auth: 'contacts.stats',
	},
	{
		Component: IndustriesFoundedYearlyChange,
		auth: 'contacts.stats',
	},
	{
		Component: PeopleContacted,
		auth: 'contacts.internal_drive_stats',
	},
	{
		Component: PeopleContactedDrive,
		auth: 'contacts.internal_drive_stats',
	},
	{
		Component: CompaniesContacted,
		auth: 'contacts.internal_drive_stats',
	},
	{
		Component: CompaniesContactedDrive,
		auth: 'contacts.internal_drive_stats',
	},
	{
		Component: CompaniesInHerbie,
		auth: 'contacts.internal_drive_stats',
	},
	{
		Component: CompaniesAge,
		auth: 'contacts.internal_drive_stats',
	},
	{
		Component: SemiAnnualFund1PortfolioOwnershipByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioRevenue,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1YearlyPortfolioHeadcount,
		auth: or(
			'contacts.fund_i_stats',
			and('contacts.internal_drive_stats', 'contacts.semiannuals'),
		),
	},
	{
		Component: SemiAnnualFund1PortfolioHeadcount,
		auth: or(
			'contacts.fund_i_stats',
			and('contacts.internal_drive_stats', 'contacts.semiannuals'),
		),
	},
	{
		Component: SemiAnnualFund1PortfolioHeadcountByCompany,
		auth: or(
			'contacts.fund_i_stats',
			and('contacts.internal_drive_stats', 'contacts.semiannuals'),
		),
	},
	{
		Component: SemiAnnualFund1PortfolioRevenueByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioRevenuePerEmployeeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component:
			SemiAnnualFund1PortfolioCurrentRevenueTimesOwnershipByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioRevenueChangeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioGMPercentageByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioBurnByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioBurnPerEmployeeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioBurnToRevenueByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioOpexPerEmployeeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioCashAvailableByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1YearlyPortfolioRevenue,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund1PortfolioAmountInvestedByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: PMEIRRFundIData,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2YearlyPortfolioHeadcount,
		auth: or(
			'contacts.fund_ii_stats',
			and('contacts.internal_drive_stats', 'contacts.semiannuals'),
		),
	},
	{
		Component: SemiAnnualFund2PortfolioHeadcount,
		auth: or(
			'contacts.fund_ii_stats',
			and('contacts.internal_drive_stats', 'contacts.semiannuals'),
		),
	},
	{
		Component: SemiAnnualFund2PortfolioHeadcountByCompany,
		auth: or(
			'contacts.fund_ii_stats',
			and('contacts.internal_drive_stats', 'contacts.semiannuals'),
		),
	},
	{
		Component: SemiAnnualFund2PortfolioOwnershipByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioRevenue,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioRevenueByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioRevenuePerEmployeeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component:
			SemiAnnualFund2PortfolioCurrentRevenueTimesOwnershipByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioRevenueChangeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioGMPercentageByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioBurnByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioBurnPerEmployeeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioBurnToRevenueByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioOpexPerEmployeeByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioCashAvailableByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2YearlyPortfolioRevenue,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualFund2PortfolioAmountInvestedByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: PMEIRRFundIIData,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioHeadcount,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioRevenue,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioRevenueByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioBurnToRevenueByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioRevenueGrowthByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioOwnershipByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component:
			SemiAnnualAllFundsPortfolioCurrentRevenueTimesOwnershipByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
	{
		Component: SemiAnnualAllFundsPortfolioAmountInvestedByCompany,
		auth: and('contacts.internal_drive_stats', 'contacts.semiannuals'),
	},
];

export function getRouteForKey(key: string): string {
	let route = key;
	if (route.startsWith('/stats')) route = route.slice('/stats'.length);
	if (route.startsWith('/')) route = route.slice('/'.length);
	if (route.endsWith('/')) route = route.slice(0, -'/'.length);
	return route.replace(/[/_?=]/giu, '-');
}
