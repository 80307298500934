import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { stickiness } from '../';
import type { CellArgs, HeaderArgs, IColumn } from '../';
import assertExhaustive from '../../utils/assert-exhaustive';

const SIZE = 40;

const Header = styled.th`
	/* Size is set to be 40 HxW, sticky-left adds padding that we want ignored */
	&.HerbieTable__header {
		height: ${SIZE}px;
		min-width: ${SIZE}px;
		padding: 0px;
		width: ${SIZE}px;
	}

	&:hover {
		cursor: pointer;
	}
`;
const Cell = styled.td`
	&.HerbieTable__cell {
		height: ${SIZE}px;
		min-width: ${SIZE}px;
		padding: 0;
		width: ${SIZE}px;
	}

	&:hover {
		cursor: pointer;
	}
`;
const InputContainer = styled.label`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
	}
`;
const Input = styled.input`
	&:hover {
		cursor: pointer;
	}
`;

function ColumnHeader({
	itemsSelected,
	onSelect,
	props,
}: HeaderArgs): JSX.Element {
	const input = useRef<HTMLInputElement>(null);
	const handleClick = useCallback(() => {
		if (!onSelect) return;

		switch (itemsSelected) {
			case 'NONE':
				onSelect('ALL');
				break;
			case 'SOME':
			case 'ALL':
				onSelect('NONE');
				break;
			default:
				assertExhaustive(itemsSelected);
		}
	}, [itemsSelected, onSelect]);

	useEffect(() => {
		if (!input.current) return;
		if (itemsSelected === 'SOME') {
			input.current.indeterminate = true;
		} else {
			input.current.indeterminate = false;
		}
	}, [itemsSelected]);

	return (
		<Header {...props}>
			<InputContainer>
				<Input
					checked={itemsSelected !== 'NONE'}
					onChange={handleClick}
					ref={input}
					type="checkbox"
				/>
			</InputContainer>
		</Header>
	);
}

export default class SelectionColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	readonly position = {
		sticky: stickiness.left,
		width: SIZE,
	};

	constructor() {
		this.cell.displayName = 'SelectionColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(
		({
			focused,
			index,
			onBlur,
			onFocus,
			onRowKeyDown,
			onSelect,
			props,
			row,
			rowKey,
			selected,
		}: CellArgs<T>) => {
			const inputRef = useRef<HTMLInputElement>(null);
			const handleClick = useCallback(
				(event: React.ChangeEvent<HTMLInputElement>) => {
					if (onSelect) {
						onSelect({ rowKey, selected: event.target.checked });
					}
				},
				[onSelect, rowKey],
			);
			const handleFocus = useCallback(() => {
				if (onFocus) {
					onFocus(index);
				}
			}, [index, onFocus]);
			const handleKeyDown = useCallback(
				(event: React.KeyboardEvent<HTMLInputElement>) => {
					if (onRowKeyDown) {
						onRowKeyDown(event, row);
					}
				},
				[onRowKeyDown, row],
			);

			useEffect(() => {
				if (!inputRef.current) return;
				if (focused && inputRef.current !== document.activeElement) {
					inputRef.current.focus();
				}
			}, [focused]);

			return (
				<Cell {...props}>
					<InputContainer>
						<Input
							type="checkbox"
							checked={selected}
							onBlur={onBlur}
							onChange={handleClick}
							onFocus={handleFocus}
							onKeyDown={handleKeyDown}
							ref={inputRef}
						/>
					</InputContainer>
				</Cell>
			);
		},
	);

	header = ColumnHeader;
}
