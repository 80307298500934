import d3 from 'd3';
import nv from 'nvd3';
import type { Nvd3Element } from 'nvd3';
import React, { Component } from 'react';

import './multi-bar-chart.scss';

type Props = {
	data: Array<{
		key: string;
		values: Array<ValueData>;
	}>;
	options: Record<string, unknown>;
	title?: string;
	xAxisLabel?: string;
	xAxisTickFormat?: (axis: string | number) => string;
	yAxisLabel?: string;
	yAxisTickFormat?: (axis: string | number) => string;
} & typeof defaultProps;

const formatIdentity = (axis: string | number) => axis.toString();

const defaultProps = {
	title: '',
	xAxisLabel: '',
	xAxisTickFormat: formatIdentity,
	yAxisLabel: '',
	yAxisTickFormat: formatIdentity,
};

interface ValueData {
	label: string;
	value: number;
}

export default class MultiBarChart extends Component<Props> {
	static defaultProps = defaultProps;

	buildMultiBarChart = (ref: SVGSVGElement | null): void => {
		if (!ref) return;

		nv.addGraph((): Nvd3Element => {
			const chart = nv.models
				.multiBarChart()
				.x((d: ValueData) => d.label)
				.y((d: ValueData) => d.value)
				.options(this.props.options);
			const {
				title,
				xAxisLabel,
				xAxisTickFormat,
				yAxisLabel,
				yAxisTickFormat,
			} = this.props;

			chart.xAxis.axisLabel(xAxisLabel).tickFormat(xAxisTickFormat);
			chart.yAxis.axisLabel(yAxisLabel).tickFormat(yAxisTickFormat);

			const selectedChart = d3
				.select(ref)
				.datum(this.props.data)
				.call(chart);

			// Get Chart width the top spacing for title alignment
			const { width, top } = ref.getBoundingClientRect();

			selectedChart
				.append('text')
				.attr('x', width / 2)
				.attr('y', top / 5)
				.attr('font-size', 16)
				.attr('text-anchor', 'middle')
				.text(title);

			nv.utils.windowResize(() => chart.update());
			return chart;
		});
	};

	render() {
		return <svg className="MultiBarChart" ref={this.buildMultiBarChart} />;
	}
}
