import { useCallback, useEffect } from 'react';

import { trackEvent, type ViewType } from '../../utils/analytics';
import type ImmutableURLSearchParams from '../../utils/immutable-url-search-params';
import useImmutableSearchParams from '../use-stable-immutable-search-params';

import { type IFilter } from './types';

const FILTERS_PARAM_NAME = 'filter';

const FILTERS_CACHE: { [key: string]: IFilter<string, unknown>[] } = {};

function buildCacheKey(filters: string[]) {
	return filters.toSorted().join(',');
}

function getFiltersFromCache<T extends IFilter<string, unknown>>(
	filters: string[],
): T[] {
	const cacheKey = buildCacheKey(filters);
	if (!FILTERS_CACHE[cacheKey]) {
		FILTERS_CACHE[cacheKey] = filters.map((str) => JSON.parse(str) as T);
	}
	return FILTERS_CACHE[cacheKey] as T[];
}

function getFiltersFromSearchParams<T extends IFilter<string, unknown>>(
	searchParams: ImmutableURLSearchParams,
): T[] {
	return getFiltersFromCache<T>(searchParams.getAll(FILTERS_PARAM_NAME));
}

export function buildSearchParamsFromFilters<
	T extends IFilter<string, unknown>,
>(filters: T[], searchParams: ImmutableURLSearchParams) {
	return filters.reduce((params, filter) => {
		return params.append(FILTERS_PARAM_NAME, JSON.stringify(filter));
	}, searchParams);
}

function removeFilterFromSearchParams(
	searchParams: ImmutableURLSearchParams,
	fieldName: string,
) {
	const filters = getFiltersFromSearchParams(searchParams);
	const updatedFilters = filters.filter(
		(filter) => filter.fieldName !== fieldName,
	);

	let newParams = searchParams.delete(FILTERS_PARAM_NAME);
	updatedFilters.forEach((filter) => {
		newParams = newParams.append(
			FILTERS_PARAM_NAME,
			JSON.stringify(filter),
		);
	});

	return newParams;
}

export default function useFilters<T extends IFilter<string, unknown>>(
	fieldNames: T['fieldName'][],
	analyticsConfig?: {
		componentIdentifier: string;
		viewType: ViewType;
	},
) {
	const [searchParams, setSearchParams] = useImmutableSearchParams();
	const filters = getFiltersFromSearchParams<T>(searchParams);

	useEffect(() => {
		setSearchParams((currentParams) => {
			const invalidFilters = getFiltersFromSearchParams(
				currentParams,
			).filter(({ fieldName }) => !fieldNames.includes(fieldName));
			return invalidFilters.reduce(
				(params, { fieldName }) =>
					removeFilterFromSearchParams(params, fieldName),
				currentParams,
			);
		});
	}, [fieldNames, setSearchParams]);

	const handleFilterClear = useCallback(
		(fieldName: string | 'ALL') => {
			if (analyticsConfig) {
				trackEvent(
					'Clear Search Filter',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{
						fieldName,
					},
				);
			}
			setSearchParams((currentParams) => {
				if (fieldName === 'ALL') {
					return currentParams.delete(FILTERS_PARAM_NAME);
				}
				return removeFilterFromSearchParams(currentParams, fieldName);
			});
		},
		[analyticsConfig, setSearchParams],
	);

	const handleFilterChange = useCallback(
		(filter: T) => {
			if (analyticsConfig) {
				trackEvent(
					'Apply Search Filter',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{
						filter,
					},
				);
			}

			setSearchParams((currentParams) =>
				removeFilterFromSearchParams(
					currentParams,
					filter.fieldName,
				).append(FILTERS_PARAM_NAME, JSON.stringify(filter)),
			);
		},
		[analyticsConfig, setSearchParams],
	);

	return {
		filters,
		handleFilterChange,
		handleFilterClear,
	};
}
