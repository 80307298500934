export default function delay(ms: number, signal?: AbortSignal): Promise<void> {
	return new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {
			resolve();
		}, ms);
		if (signal) {
			signal.addEventListener('abort', () => {
				clearTimeout(timeout);
				const abortError = new Error('The operation was aborted.');
				abortError.name = 'AbortError';
				reject(abortError);
			});
		}
	});
}
