// @flow

import pluralize from 'pluralize';
import React from 'react';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import minimumSearchFilter from './minimum-filter';
import type { MinimumDisplayProps } from './minimum-filter';

const DealCountSearchFilterDisplay = ({
	onRemove,
	punctuator = '',
	resultCount,
	value,
}: MinimumDisplayProps) =>
	value == null ? null : (
		<FilterDescription>
			{`${resultCount === 1 ? 'has' : 'have'} made at least `}
			<DescriptionValue
				onRemove={onRemove}
				punctuator={punctuator}
				value={value}
			>
				{pluralize('investments', value, true)}
			</DescriptionValue>
		</FilterDescription>
	);

export default minimumSearchFilter(
	'Num. Deals',
	'investments.count',
	DealCountSearchFilterDisplay,
	{
		// label: 'Count',
		max: 30,
	},
	'deal-count-filter',
);
