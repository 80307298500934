import type {
	EntityData,
	NormalizedEntityData,
	WatchListItem,
} from '../components/watchlists/types';
import { ActionTypes } from '../constants';

import type {
	CandidatePool,
	CandidatePoolApiResult,
	CandidatePoolIndexApiResult,
} from './types';

export interface AddCandidatePoolItemAction {
	payload: { item: WatchListItem; candidatePoolId: number };
	type: typeof ActionTypes.CANDIDATE_POOL_ITEM_ADD_SUCCESS;
}

export const addCandidatePoolItem = (
	item: WatchListItem,
	candidatePoolId: number,
): AddCandidatePoolItemAction => ({
	payload: { candidatePoolId, item },
	type: ActionTypes.CANDIDATE_POOL_ITEM_ADD_SUCCESS,
});

export interface AddCandidatePoolItemsAction {
	payload: { items: Array<WatchListItem>; candidatePoolId: number };
	type: typeof ActionTypes.CANDIDATE_POOL_ITEMS_ADD_SUCCESS;
}

export const addCandidatePoolItems = (
	items: Array<WatchListItem>,
	candidatePoolId: number,
): AddCandidatePoolItemsAction => ({
	payload: { candidatePoolId, items },
	type: ActionTypes.CANDIDATE_POOL_ITEMS_ADD_SUCCESS,
});

export interface EditCandidatePoolAction {
	payload: CandidatePool;
	type: typeof ActionTypes.CANDIDATE_POOL_EDIT_SUCCESS;
}

export const editCandidatePool = (
	candidatePool: CandidatePool,
): EditCandidatePoolAction => ({
	payload: candidatePool,
	type: ActionTypes.CANDIDATE_POOL_EDIT_SUCCESS,
});

export interface EditCandidatePoolItemAction {
	payload: {
		item: { entity: EntityData; extra: NormalizedEntityData['extra'] };
		candidatePoolId: number;
	};
	type: typeof ActionTypes.CANDIDATE_POOL_ITEM_EDIT_SUCCESS;
}

export const editCandidatePoolItem = (
	item: { entity: EntityData; extra: NormalizedEntityData['extra'] },
	candidatePoolId: number,
): EditCandidatePoolItemAction => ({
	payload: { item, candidatePoolId },
	type: ActionTypes.CANDIDATE_POOL_ITEM_EDIT_SUCCESS,
});

export interface InvalidateCandidatePoolsAction {
	type: typeof ActionTypes.CANDIDATE_POOLS_INVALIDATE;
}

export const invalidateCandidatePools = (): InvalidateCandidatePoolsAction => ({
	type: ActionTypes.CANDIDATE_POOLS_INVALIDATE,
});

export interface LoadCandidatePoolAction {
	payload: CandidatePoolApiResult;
	type: typeof ActionTypes.CANDIDATE_POOL_LOAD_SUCCESS;
}

export const loadCandidatePool = (
	payload: CandidatePoolApiResult,
): LoadCandidatePoolAction => ({
	payload,
	type: ActionTypes.CANDIDATE_POOL_LOAD_SUCCESS,
});

export interface LoadCandidatePoolsAction {
	payload: Array<CandidatePoolIndexApiResult>;
	type: typeof ActionTypes.CANDIDATE_POOLS_LOAD_SUCCESS;
}

export const loadCandidatePools = (
	payload: Array<CandidatePoolIndexApiResult>,
): LoadCandidatePoolsAction => ({
	payload,
	type: ActionTypes.CANDIDATE_POOLS_LOAD_SUCCESS,
});

export interface RemoveCandidatePoolItemsAction {
	payload: {
		candidatePoolId: number;
		people: Array<number>;
	};
	type: typeof ActionTypes.CANDIDATE_POOL_ITEMS_REMOVE_SUCCESS;
}

export const removeCandidatePoolItems = (
	people: Array<number>,
	id: number,
): RemoveCandidatePoolItemsAction => ({
	payload: { candidatePoolId: id, people },
	type: ActionTypes.CANDIDATE_POOL_ITEMS_REMOVE_SUCCESS,
});

export type Action =
	| AddCandidatePoolItemAction
	| AddCandidatePoolItemsAction
	| EditCandidatePoolAction
	| EditCandidatePoolItemAction
	| InvalidateCandidatePoolsAction
	| LoadCandidatePoolAction
	| LoadCandidatePoolsAction
	| RemoveCandidatePoolItemsAction;
