import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import loadable from 'react-loadable';

import './generic-map.scss';
import SpinLoader from '../../../components/spin-loader';
import cancelablePromise from '../../../utils/cancelable-promise';
import noop from '../../../utils/noop';
import reportError from '../../../utils/sentry';

const Datamap = loadable({
	loader: () => import('react-datamaps'),
	loading: () => null,
});

export default class DataMap extends Component {
	static propTypes = {
		className: PropTypes.string,
		fetchData: PropTypes.func.isRequired,
		loadingComponent: PropTypes.elementType,
		map: PropTypes.object,
		onDataFetch: PropTypes.func,
	};

	static defaultProps = {
		onDataFetch: noop,
		loadingComponent: SpinLoader,
	};

	state = { map: this.props.map };

	componentDidMount() {
		const { height, width } = this.refs.map.getBoundingClientRect();
		if (!this.state.map) {
			this.promise = cancelablePromise(this.props.fetchData());

			this.promise
				.then((formattedData) => {
					this.setState({
						map: {
							...formattedData,
							height,
							width,
						},
					});
					this.props.onDataFetch(formattedData);
					delete this.promise;
				})
				.catch((error) => reportError(error));
		} else {
			// eslint-disable-next-line react/no-did-mount-set-state
			this.setState((state) => ({
				map: {
					...state.map,
					height,
					width,
				},
			}));
		}
	}

	componentWillUnmount() {
		if (this.promise) {
			this.promise.cancel();
		}
	}

	render() {
		const Loader = this.props.loadingComponent;

		return (
			<div
				className={classnames('GenericMap', this.props.className)}
				ref="map"
			>
				{this.state.map ? <Datamap {...this.state.map} /> : <Loader />}
			</div>
		);
	}
}
