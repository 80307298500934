import styled, { keyframes } from 'styled-components';

import * as colors from '../colors';

const Spin = keyframes`
	from { transform: rotate(0deg); }
	to   { transform: rotate(360deg); }
`;

const SpinLoader = styled.div`
	position: relative;
	display: inline-block;
	margin: 0;
	width: 40px;
	height: 40px;
	border: 3px solid ${colors.mediumGray.string()};
	border-radius: 50%;

	animation: ${Spin} 0.85s infinite linear;
	display: inline-block;
	border-color: transparent;
	border-top-color: ${colors.mediumGray.string()};

	::before {
		left: -3px;
		top: -3px;
		position: absolute;
		content: '';
		width: 40px;
		height: 40px;
		border: inherit;
		border-radius: inherit;
		border-width: 3px;
		display: inline-block;
		box-sizing: border-box;
		border-color: transparent;
		border-top-color: ${colors.mediumGray.string()};
		animation: ${Spin} 1.3s infinite ease;
	}
`;

export default SpinLoader;
