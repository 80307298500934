// https://tools.ietf.org/html/rfc4180
const escape = (raw: string | undefined | number | null) => {
	if (!raw) return '';
	if (typeof raw === 'number') return raw.toString();
	let escaped = raw;

	// 2.7. ...A double-quote appearing inside a field must be escaped by
	//      preceding it with another double quote.  For example:
	//
	//      "aaa","b""bb","ccc"
	escaped = escaped.replace(/"/gu, '""');

	// 2.6. Fields containing line breaks (CRLF), double quotes, and commas
	//      should be enclosed in double-quotes.  For example:
	//
	//      "aaa","b CRLF
	//      bb","ccc" CRLF
	//      zzz,yyy,xxx
	if (/[\n",]/u.test(escaped)) {
		escaped = `"${escaped}"`;
	}

	return escaped;
};

export const stringify = (
	rows: Array<Array<string | undefined | number | null>>,
) => rows.map((row) => row.map(escape).join(',')).join('\r\n');

export const stringifyAsDataURI = (
	rows: Array<Array<string | undefined | number | null>>,
) => `data:text/csv;charset=utf-8,${encodeURIComponent(stringify(rows))}`;
