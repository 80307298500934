import numeral from 'numeral';
import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import assertExhaustive from '../../utils/assert-exhaustive';
import useEditableField, {
	EditingState,
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from '../../utils/state-machine/editable-field';

import {
	EditingCell,
	ErrorCell,
	Input,
	NormalCell,
	SavingCell,
	SuccessCell,
} from './numeric';

type Data = number | null;

export type FormatFunction = (value: number) => string;

const HeaderText = styled.div`
	text-align: right;
`;

type CurrencyCellProps = {
	format: (val: number | null) => string;
	initialValue: Data;
	onChange: (value: Data, signal: AbortSignal) => Promise<number | null>;
};

function EditableCurrencyCell({
	initialValue,
	format,
	onChange,
	...props
}: CurrencyCellProps) {
	const [currentState, transition] = useEditableField<Data>();

	if (currentState instanceof NormalState) {
		return (
			<NormalCell
				{...props}
				onFocus={() => {
					transition(currentState.onEdit(initialValue));
				}}
				tabIndex={0}
			>
				{initialValue == null ? '' : format(initialValue)}
			</NormalCell>
		);
	} else if (currentState instanceof EditingState) {
		return (
			<EditingCell {...props}>
				<Input
					autoFocus
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						transition(
							currentState.onChange(
								event.target.value.length === 0
									? null
									: parseFloat(event.target.value) * 1e6,
							),
						);
					}}
					onBlur={() => {
						transition(
							currentState.value === initialValue
								? currentState.onCancel()
								: currentState.onSubmit(onChange),
						);
					}}
					onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
						event.currentTarget.select();
					}}
					onKeyDown={(
						event: React.KeyboardEvent<HTMLInputElement>,
					) => {
						switch (event.key) {
							case 'Enter':
								transition(currentState.onSubmit(onChange));
								return;
							case 'Escape':
								transition(currentState.onCancel());
								return;
							default: // Do nothing
						}
					}}
					type="number"
					value={
						currentState.value == null
							? ''
							: currentState.value / 1e6
					}
				/>
			</EditingCell>
		);
	} else if (currentState instanceof SavingState) {
		return (
			<SavingCell {...props}>
				{currentState.value == null ? '' : format(currentState.value)}
			</SavingCell>
		);
	} else if (currentState instanceof SuccessState) {
		return (
			<SuccessCell {...props}>
				{currentState.value == null ? '' : format(currentState.value)}
			</SuccessCell>
		);
	} else if (currentState instanceof ErrorState) {
		return (
			<ErrorCell {...props} title={currentState.error.message}>
				{currentState.error.message}
			</ErrorCell>
		);
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class CurrencyColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	static formatMillions: FormatFunction = (value) =>
		// numeral returns NaN for values less than 1e-6, but we're about to
		// format as millions, so we can easily work around it by rounding to
		// the nearest integer.
		// https://github.com/adamwdraper/Numeral-js/issues/512
		numeral(Math.round(value)).format('($0,0.0am)').toUpperCase();

	protected readonly description: string;
	protected readonly format: (val: number | null) => string;
	public readonly name: string;
	protected readonly select: (row: T) => Data;
	protected readonly update: null | ((value: Data) => Partial<T>);

	constructor({
		format,
		description = '',
		name,
		select,
		update = null,
	}: {
		description?: string;
		format: (val: number | null) => string;
		name: string;
		select: (row: T) => Data;
		update?: null | ((value: Data) => Partial<T>);
	}) {
		this.description = description;
		this.format = format;
		this.name = name;
		this.select = select;
		this.update = update;

		this.cell.displayName = 'CurrencyColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const value = this.select(row);

		const update = this.update;
		if (deferred || !update || !onChange) {
			return <NormalCell {...props}>{this.format(value)}</NormalCell>;
		}

		return (
			<EditableCurrencyCell
				initialValue={value}
				format={this.format}
				onChange={async (newValue, signal) => {
					const patch = update(newValue);
					const response: T = await onChange(row, patch, signal);
					const updatedValue: Data = this.select(response);
					return updatedValue;
				}}
				{...props}
			/>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				initialSortDirection={sortDirections.descending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				title={this.description}
				{...props}
			>
				<HeaderText>{this.name}</HeaderText>
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal: Data = this.select(a);
		const bVal: Data = this.select(b);

		if (aVal === bVal) return 0;
		if (aVal === null) return 1;
		if (bVal === null) return -1;
		return direction === sortDirections.ascending
			? aVal - bVal
			: bVal - aVal;
	}

	toCSV(row: T): string {
		const value = this.select(row);
		return this.format(value);
	}
}
