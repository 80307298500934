import React, { useCallback, useRef, useState } from 'react';
import type { HandlerManager } from 'react-dnd';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';

import { disabledGray, herbieGray, red } from '../../colors';
import BaseButton from '../../components/button';
import TextEdit from '../../components/editing/text';
import {
	DragIcon,
	NewTrashIcon,
	LockIcon as UnstyledLockIcon,
} from '../../components/icons';
import type { ColumnDescriptor } from '../../components/watchlists/column';
import { trackEvent } from '../../utils/analytics';
import noop from '../../utils/noop';

export const Button = styled(BaseButton)`
	border-radius: 0;
	box-shadow: none;
	font-size: 12px;
	line-height: 34px;
	margin: 0;
	padding: 0 8px;
`;

const LockIcon = styled(UnstyledLockIcon)`
	color: ${disabledGray.string()};
	height: 34px;
	padding: 7px;
	width: 34px;
`;
const TrashIcon = styled(NewTrashIcon)`
	color: ${red.toString()};
	g {
		stroke-width: 5;
	}
`;

export const IconButton = styled.button`
	align-self: flex-start;
	background-color: white;
	border: none;
	border-left: 0.5px solid ${herbieGray.toString()};
	cursor: pointer;
	font-size: 0;
	opacity: 0.7;
	outline: 0;
	padding: 7px;

	&:disabled {
		cursor: not-allowed;
	}

	&:not(:disabled):hover {
		opacity: 1;
	}
	svg {
		height: 20px;
		width: 20px;
	}
`;

const Drag = styled(IconButton)`
	border-left: none;
	cursor: move;
	svg {
		padding: 0px;
	}
`;

const DeleteButton = styled(IconButton)`
	color: ${red.toString()};
	padding: 7.5px;

	svg {
		height: 19px;
		width: 19px;
	}
`;

export const Container = styled('li')<{ isDragging?: boolean }>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	background-color: white;
	align-items: center;
	border-radius: 3px;
	opacity: ${({ isDragging }) => (isDragging ? 0.2 : 1)};

	button:last-of-type {
		border-radius: 0px 3px 3px 0px;
	}
`;

export const DescriptorText = styled.input`
	border: none;
	flex: 1 1 0;
	font-size: 14px;
	line-height: 34px;
	min-width: 0;
	padding: 0 10px;
	text-overflow: ellipsis;

	&:disabled {
		color: ${disabledGray.toString()};
	}
`;

interface DragItem {
	index: number;
	id: string;
	type: string;
}

type Props = {
	columnDescriptor: ColumnDescriptor;
	editable: boolean;
	index: number;
	onDelete: (index: number) => void;
	onEdit: (index: number, descriptor: ColumnDescriptor) => void;
	onSort: (fromIndex: number, toIndex: number) => void;
};

function EditColumnDescriptor({
	columnDescriptor,
	editable,
	index,
	onDelete,
	onEdit,
	onSort,
}: Props): JSX.Element {
	const ref = useRef<HTMLLIElement>(null);
	const [deleting, setDeleting] = useState<boolean>(false);

	const handleDeleteConfirmation = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			const name = event.currentTarget.name;
			if (name === 'cancel') {
				trackEvent(
					'Deleting Watch List Column',
					'watchlist-column',
					'watchlist-detail',
					{
						action: 'cancel-deletion',
					},
				);
				setDeleting(false);
			} else {
				trackEvent(
					'Deleting Watch List Column',
					'watchlist-column',
					'watchlist-detail',
					{
						action: 'confirm-deletion',
					},
				);
				onDelete(index);
			}
		},
		[onDelete, index],
	);

	const handleDelete = useCallback(() => {
		trackEvent(
			'Deleting Watch List Column',
			'watchlist-column',
			'watchlist-detail',
			{
				action: 'open-deletion-form',
			},
		);
		setDeleting(true);
	}, []);

	const handleEdit = useCallback(
		(text: string) => {
			onEdit(index, { ...columnDescriptor, name: text });
		},
		[columnDescriptor, index, onEdit],
	);

	const [{ handlerId }, drop] = useDrop<
		DragItem,
		unknown,
		{ handlerId: ReturnType<HandlerManager['getHandlerId']> }
	>({
		accept: 'column',
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		// This method is called when we hover over an element while dragging
		hover(item: DragItem, monitor: DropTargetMonitor) {
			// item is the dragged element
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			// current element where the dragged element is hovered on
			const hoverIndex = index;
			// If the dragged element is hovered in the same place, then do nothing
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine container rectangle on screen
			const hoverBoundingRect = ref.current?.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY =
				(hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY =
				(clientOffset as { y: number }).y - hoverBoundingRect.top;

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// If it is dragged around other elements, then move the column and set the state with position changes
			onSort(dragIndex, hoverIndex);
			/*
			Update the index for dragged item directly to avoid flickering
			when the column was half dragged into the next
		  	*/
			item.index = hoverIndex;
		},
	});

	// pass in generics so isDragging has a type
	const [{ isDragging }, drag] = useDrag<
		{ index: number },
		unknown,
		{ isDragging: boolean }
	>({
		type: 'column',
		item: () => {
			return { index };
		},
		// add type for monitor. DnD doesn't come with this typed
		collect: (monitor: { isDragging: () => boolean }) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	drag(drop(ref));

	if (!editable) {
		const { field } = columnDescriptor;

		return (
			<Container>
				<DescriptorText
					disabled
					type="text"
					onChange={noop}
					value={
						columnDescriptor.name.length > 0
							? columnDescriptor.name
							: field
									.split('_')
									.map(
										(val) =>
											`${val
												.charAt(0)
												.toUpperCase()}${val.substr(
												1,
											)}`,
									)
									.join(' ')
					}
				/>
				<LockIcon />
			</Container>
		);
	}
	return (
		<Container
			ref={ref}
			data-handler-id={handlerId}
			isDragging={isDragging}
		>
			<Drag>
				<DragIcon fill={herbieGray.toString()} />
			</Drag>
			<TextEdit
				disabled={deleting}
				onEdit={handleEdit}
				originalText={columnDescriptor.name}
				render={(text) => {
					if (deleting) {
						return (
							<React.Fragment>
								<Button
									onClick={handleDeleteConfirmation}
									name="cancel"
								>
									Cancel
								</Button>
								<Button
									onClick={handleDeleteConfirmation}
									name="delete"
									danger
								>
									Delete
								</Button>
							</React.Fragment>
						);
					}

					return (
						text === columnDescriptor.name && (
							<DeleteButton onClick={handleDelete} type="button">
								<TrashIcon />
							</DeleteButton>
						)
					);
				}}
			/>
		</Container>
	);
}

export default EditColumnDescriptor;
