import type { State as StoreState } from '../../reducers';
import type { OnePager, PartialOnePager } from '../types';

export const selectAreOnePagersLoaded = (state: StoreState): boolean =>
	state.onePagers.loaded;

export const selectOnePagerById = (
	state: StoreState,
	id: number,
): OnePager | null => {
	const onePager = state.onePagers.byId[id];
	if (onePager?.loaded) return onePager.onePager;
	return null;
};

export const selectOnePagers = (state: StoreState): Array<PartialOnePager> =>
	[...Object.values(state.onePagers.byId)].map(({ onePager }) => onePager);

export const selectOnePagersByCompanyId = (
	state: StoreState,
	companyId: number,
): Array<PartialOnePager> =>
	selectOnePagers(state).filter(
		({ fused_company }) => fused_company.id === companyId,
	);
