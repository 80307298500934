// @flow

import numeral from 'numeral';
import React from 'react';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import rangeSearchFilter from './range-filter';
import type { RangeDisplayProps } from './range-filter';

const MAX_POSITION = 65;
const MAX_VALUE = 1e12;

const convertPositionToValue = ([min, max]) => {
	const convert = (position) => {
		const base = ((position - 1) % 9) + 1;
		const exponent = Math.floor((position - 1) / 9) + 4;

		return base * 10 ** exponent;
	};

	return [
		min === 0 ? null : convert(min),
		max === MAX_POSITION ? null : convert(max),
	];
};

const convertValueToPosition = ([min, max]) => {
	const convert = (value) => {
		const floor = Math.floor(Math.log10(value));

		return 9 * (floor - 4) + value / 10 ** floor;
	};

	return [
		min === null || min === 0 ? 0 : convert(min),
		max === null || max === MAX_VALUE ? MAX_POSITION : convert(max),
	];
};

const convertValueToText = ([min, max]) => [
	min === null ? '$0' : numeral(min).format('$0a').toUpperCase(),
	max === null ? '$1T' : numeral(max).format('$0a').toUpperCase(),
];

class FundingSearchFilterDisplay extends React.Component<RangeDisplayProps> {
	static defaultProps = {
		punctuator: '',
	};

	render() {
		const [min, max] = this.props.value;
		const [minText, maxText] = convertValueToText(this.props.value);

		if (min === null && max !== null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'has' : 'have'
					} less than `}
					<DescriptionValue onRemove={this.props.onRemoveMax}>
						{maxText}
					</DescriptionValue>
					{' in funding'}
					{this.props.punctuator}
				</FilterDescription>
			);
		} else if (min !== null && max === null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'has' : 'have'
					} at least `}
					<DescriptionValue onRemove={this.props.onRemoveMin}>
						{minText}
					</DescriptionValue>
					{' in funding'}
					{this.props.punctuator}
				</FilterDescription>
			);
		} else {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'has' : 'have'
					} between `}
					<DescriptionValue onRemove={this.props.onRemoveMin}>
						{minText}
					</DescriptionValue>
					{' and '}
					<DescriptionValue onRemove={this.props.onRemoveMax}>
						{maxText}
					</DescriptionValue>
					{' in funding'}
					{this.props.punctuator}
				</FilterDescription>
			);
		}
	}
}

export default rangeSearchFilter(
	'Funding',
	'data.funding',
	FundingSearchFilterDisplay,
	{
		convertPositionToValue,
		convertValueToPosition,
		convertValueToText,
		max: MAX_VALUE,
	},
	'funding-filter',
);
