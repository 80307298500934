import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import UnstyledIconButton from '../../theme/components/icon-button';
import { PageLine3Icon } from '../../theme/icons/document';
import { trackEvent, type ViewType } from '../../utils/analytics';

import { isEditorEmpty } from '.';

const IconButton = styled(UnstyledIconButton)`
	border-radius: 8px;
`;

interface Props {
	analyticsComponentIdentifier: string;
	analyticsViewType: ViewType;
	template: string;
}

export default function TemplatePlugin({
	analyticsComponentIdentifier,
	analyticsViewType,
	template,
}: Props) {
	const [editor] = useLexicalComposerContext();

	const onClick = useCallback(() => {
		editor.update(() => $convertFromMarkdownString(template, TRANSFORMERS));
		trackEvent(
			'Apply Note Template',
			analyticsComponentIdentifier,
			analyticsViewType,
		);
	}, [analyticsComponentIdentifier, analyticsViewType, editor, template]);

	return (
		<IconButton
			disabled={!isEditorEmpty(editor.getEditorState())}
			data-for="template-tooltip"
			onClick={onClick}
			onMouseDown={(evt: React.MouseEvent) => {
				// Prevent default on mouse down stops the content editable from blurring
				evt.preventDefault();
			}}
			title="Insert template"
		>
			<PageLine3Icon />
		</IconButton>
	);
}
