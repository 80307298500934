import moment from 'moment';

moment.defineLocale('en-relative', {
	parentLocale: 'en',
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'seconds',
		ss: '%ss',
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%dd',
		M: '1mo',
		MM: '%dmo',
		y: '1y',
		yy: '%dy',
	},
});
moment.locale('en');

export default function formatTimeSince(lastSentAt: string | null) {
	return lastSentAt ? moment(lastSentAt).locale('en-relative').fromNow() : '';
}
