import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import * as colors from '../colors';
import HerbieLoader from '../components/herbie-loader';
import type {
	EntityData,
	NormalizedEntityData,
	WatchListItem,
} from '../components/watchlists/types';
import {
	DataTable,
	useWatchListControlBase,
} from '../components/watchlists/watch-list';
import {
	useRefreshPeoplePipelines,
	useRefreshPipelineDetail,
} from '../pipelines';
import type { API_PipelineStageItem } from '../pipelines/api-helpers';
import type { State as StoreState } from '../reducers';
import { downloadTable } from '../table';
import type { AddPickerResult } from '../table/column';
import MultiSelectColumn from '../table/columns/multi-select';
import { trackEvent } from '../utils/analytics';
import { get, patch, post, remove } from '../utils/api';
import useAbortSignal from '../utils/hooks/use-abort-signal';
import reportError from '../utils/sentry';

import {
	addCandidatePoolItem,
	editCandidatePool,
	editCandidatePoolItem,
	loadCandidatePool,
	removeCandidatePoolItems,
} from './actions';
import CandidatePoolHeader from './candidate-pool-header';
import {
	selectCandidatePoolById,
	selectCandidatePoolColumnsById,
	selectCandidatePoolLoadedById,
} from './selectors';
import type { CandidatePool, CandidatePoolApiResult } from './types';

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
`;

const StyledTable = styled(DataTable)`
	.HerbieTable {
		transform: scale(2);
		&__head {
			background-color: ${colors.backgroundGray.string()};
		}
		&__header {
			border-right: none;
		}
		&__table {
			border-top: none;
		}
	}
`;

function useCandidatePool(
	id: number,
	jobOrderId: number,
	jobOrderPipelineId: number,
) {
	const dispatch = useDispatch();
	const signal = useAbortSignal();
	const apiUrlBase = `/talent/job-orders/${jobOrderId}/candidate-pools/${id}`;

	const candidatePool = useSelector((state: StoreState) =>
		selectCandidatePoolById(state, id),
	);
	const columns = useSelector((state: StoreState) =>
		selectCandidatePoolColumnsById(state, id),
	);
	const loaded = useSelector((state: StoreState) =>
		selectCandidatePoolLoadedById(state, id),
	);

	const refreshPeoplePipelines = useRefreshPeoplePipelines();
	const refreshPipelineDetail = useRefreshPipelineDetail();
	const onItemAdd = useCallback(
		(entity: AddPickerResult): void => {
			trackEvent(
				'Add to Candidate Pool',
				'candidate-pool',
				'job-order-detail',
				{ count: 1 },
			);
			post<{
				candidate_pool_items: Array<WatchListItem>;
				pipeline_id: number;
				pipeline_profiles: Array<API_PipelineStageItem>;
			}>(`${apiUrlBase}/items`, {
				body: { id: entity.id },
				signal,
			})
				.then((item) => {
					dispatch(
						addCandidatePoolItem(item.candidate_pool_items[0], id),
					);
					refreshPeoplePipelines([item.pipeline_id]);
					refreshPipelineDetail(jobOrderPipelineId);
				})
				.catch(reportError);
		},
		[
			apiUrlBase,
			dispatch,
			id,
			jobOrderPipelineId,
			signal,
			refreshPeoplePipelines,
			refreshPipelineDetail,
		],
	);
	const onItemEdit = useCallback(
		async (
			row: EntityData,
			body: Record<string, unknown>,
		): Promise<NormalizedEntityData['extra'] | null> => {
			try {
				const newEntity = await patch<{
					entity: EntityData;
					extra: NormalizedEntityData['extra'];
				}>(`${apiUrlBase}/items/${row.id}`, {
					body,
					signal,
				});

				dispatch(editCandidatePoolItem(newEntity, id));
				return newEntity.extra;
			} catch (error) {
				reportError(error as Error);
				return null;
			}
		},
		[apiUrlBase, dispatch, id, signal],
	);
	const onItemsDelete = useCallback(
		async (rows: Array<EntityData>): Promise<void> => {
			const peopleItems = rows.filter(({ type }) => type === 'people');
			const people = peopleItems.map((person) => person.id);
			trackEvent(
				'Remove from Candidate Pool',
				'candidate-pool',
				'job-order-detail',
				{ count: people.length },
			);
			try {
				await remove(`${apiUrlBase}/items`, {
					body: {
						people,
					},
					signal,
				});
				dispatch(removeCandidatePoolItems(people, id));
			} catch {
				// Probably a 404 if another tab deleted item already
			}
		},
		[apiUrlBase, dispatch, id, signal],
	);

	const onLoad = useCallback(() => {
		if (!loaded) {
			get<CandidatePoolApiResult>(apiUrlBase, { signal })
				.then((newPool) => {
					dispatch(loadCandidatePool(newPool));
				})
				.catch(reportError);
		}
	}, [apiUrlBase, dispatch, loaded, signal]);

	const onPoolSave = useCallback(
		async (newColumns: CandidatePool['columns']): Promise<void> => {
			let columnsChanged = false;

			if (columns.length !== newColumns.length) {
				columnsChanged = true;
			} else {
				columnsChanged = !columns.every((currentColumn, index) => {
					const newColumn = newColumns[index];

					return (
						currentColumn.editable === newColumn.editable
						&& currentColumn.field === newColumn.field
						&& currentColumn.name === newColumn.name
					);
				});
			}

			if (!columnsChanged) return;

			try {
				const newPool = await patch<CandidatePool>(apiUrlBase, {
					body: { columns: newColumns },
					signal,
				});

				dispatch(editCandidatePool(newPool));
			} catch (error) {
				reportError(error as Error);
			}
		},
		[apiUrlBase, columns, dispatch, signal],
	);
	const control = useWatchListControlBase<
		CandidatePool,
		CandidatePool['columns']
	>({
		columns,
		onItemAdd,
		onItemEdit,
		onItemsDelete,
		onLoad,
		onWatchListEdit: onPoolSave,
		watchList: candidatePool,
	});

	const handleMoveToPipeline = useCallback(async (): Promise<void> => {
		trackEvent('Add to Pipeline', 'candidate-pool', 'job-order-detail', {
			count: 1,
			type: 'people',
		});
		try {
			const people = control.filteredSelectedItems.map(
				(entityData) => entityData.id,
			);
			await post(`/pipelines/${jobOrderPipelineId}/items`, {
				body: {
					people,
				},
				signal,
			});
			refreshPeoplePipelines(people);
			refreshPipelineDetail(jobOrderPipelineId);
		} catch (error) {
			reportError(error as Error);
		}
	}, [
		control.filteredSelectedItems,
		jobOrderPipelineId,
		signal,
		refreshPeoplePipelines,
		refreshPipelineDetail,
	]);

	const handleDownload = useCallback(() => {
		downloadTable(
			control.watchListColumns,
			control.watchList?.items ?? [],
			control.watchList?.name ?? '',
			control.search,
			control.sort,
		);
	}, [
		control.search,
		control.sort,
		control.watchList?.items,
		control.watchList?.name,
		control.watchListColumns,
	]);

	return { ...control, handleDownload, handleMoveToPipeline };
}

export default function CandidatePoolDetail({
	id,
	jobOrderId,
	jobOrderPipelineId,
}: {
	id: number;
	jobOrderId: number;
	jobOrderPipelineId: number;
}): JSX.Element {
	const { watchList: candidatePool, ...control } = useCandidatePool(
		id,
		jobOrderId,
		jobOrderPipelineId,
	);

	const isOwner = candidatePool?.access_grant_level === 'OWNER';
	const isEditor = isOwner || candidatePool?.access_grant_level === 'EDITOR';

	// While the hook useWatchListControlBase uses the deprecated `shared_with`
	// property to render the multi-select column, we must "self-filter" based
	// on access grants in order to prevent showing VIEWER users with
	// shared_with access on the candidate pool
	const columns = useMemo(() => {
		if (isEditor) return control.watchListColumns;
		return control.watchListColumns.filter(
			(c) => !(c instanceof MultiSelectColumn),
		);
	}, [control.watchListColumns, isEditor]);

	if (!candidatePool) return <HerbieLoader />;

	return (
		<Container>
			<CandidatePoolHeader
				columnDescriptors={control.columns}
				isEditor={isEditor}
				isOwner={isOwner}
				onDownload={control.handleDownload}
				onItemsDelete={control.handleItemsDelete}
				onMoveToPipeline={control.handleMoveToPipeline}
				onSave={control.handleWatchListEdit}
				onSearch={control.handleSearch}
				search={control.search}
				selectedItemCount={control.selectedItemKeys.size}
			/>
			<StyledTable
				canEditCells={isEditor}
				onItemAdd={control.handleItemAdd}
				onItemEdit={control.handleItemEdit}
				onScroll={control.handleScroll}
				onSelect={control.handleItemSelection}
				onSort={control.handleSort}
				scrollX={control.scrollX}
				scrollY={control.scrollY}
				search={control.search}
				selectedRowKeys={control.selectedItemKeys}
				sort={control.sort}
				watchList={candidatePool}
				watchListColumns={columns}
			/>
		</Container>
	);
}
