import moment from 'moment';
import type { Moment } from 'moment';
import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import DateInput from '../../components/date-input';
import assertExhaustive from '../../utils/assert-exhaustive';
import delay from '../../utils/delay';
import isDateInputSupported from '../../utils/is-date-input-supported';
import noop from '../../utils/noop';
import type { IState } from '../../utils/state-machine';
import { useStateMachine } from '../../utils/state-machine';

type Data = Date | Moment | null;

export type DateFormatFunction = (date: Moment) => string;

const HeaderText = styled.div`
	text-align: right;
`;

const NormalCell = styled.td`
	line-height: 20px;
	overflow: hidden;
	padding: 10px;
	text-align: right;
	white-space: nowrap;
`;

const SavingCell = styled(NormalCell)`
	background-color: #eee;
`;

const SuccessCell = styled(NormalCell)`
	background-color: #dfd;
`;

const ErrorCell = styled(NormalCell)`
	color: #800;
	text-align: left;
`;

const EditingCell = styled.td`
	line-height: 20px;
	overflow: hidden;
	position: relative;
	white-space: nowrap;
`;

const EditingCellText = styled.div`
	padding: 10px;
	text-align: right;
`;

const StyledDateInput = styled.input`
	appearance: none;
	background-color: transparent;
	border: none;
	font-variant-numeric: tabular-nums;
	height: 100%;
	margin: 0;
	min-width: 12ch;
	padding: 10px;
	width: 100%;

	/*
	 * We want to open the calendar popup immediately when the cell is clicked.
	 * However, JS has no control over the popup; it can only be opened by an
	 * actual mouse click on the <input>. To achieve this behavior, we draw the
	 * <input> invisibly _in front of_ the formatted NormalState text. Any
	 * clicks on the cell will actually be clicks on the <input> itself, which
	 * will open the calendar popup.
	 */
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	::-webkit-calendar-picker-indicator {
		/*
		 * In Chrome, the button to open the calendar popup has entirely too
		 * much horizontal whitespace.
		 */
		margin: 0 -10px 0 -20px;
	}

	/*
	 * Unlike Firefox, which opens the calendar popup on click anywhere inside
	 * the <input>, Chrome only opens the calendar popup when clicking directly
	 * on the button, which typically sits over in the right side of the
	 * <input>. However, we want to open the calendar popup on the first click
	 * _anywhere_ inside the cell. These rules expand the button to cover the
	 * entire <input> area whenever the cell is not in the active editing state.
	 * When the user starts interacting with the <input> and we enter the
	 * editing state, these rules no longer apply, and the button returns to its
	 * normal place off to the side.
	 */
	:not(.EditableDateCell--editing)::-webkit-calendar-picker-indicator {
		border: 1px solid red;
		margin: 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: auto;
		width: auto;
	}
`;

const EditingCellPolyfill = styled.td`
	line-height: 20px;
	padding: 0;
`;

/*
 * The date input polyfill won't work with the styling tricks we use on native
 * date inputs, so we'll need to handle it separately.
 */
const StyledDateInputPolyfill = styled(DateInput)`
	appearance: none;
	background-color: transparent;
	border: none;
	font-variant-numeric: tabular-nums;
	height: 100%;
	margin: 0;
	min-width: 12ch;
	padding: 10px;
	width: 100%;
`;

export class NormalState<T> implements IState {
	onEdit(initialValue: T): EditingState<T> {
		return new EditingState(initialValue);
	}

	onMouseDown(): FocusingState<T> {
		return new FocusingState();
	}

	onExit(): void {
		return;
	}
}

/**
 * In Chrome, when the user clicks on the button to open the calendar popup, two
 * events are generated: `onFocus` and then `onClick`. We listen for `onFocus`
 * via keyboard or mouse to transition the cell into the editing state. The
 * calendar popup opens in response to the second `onClick` event. However, if
 * we transition immediately to the editing state, that shifts the calendar
 * button back to its normal spot off to the side, so it'll no longer be under
 * the mouse when calculating the target for the `onClick` event, which Chrome
 * will instead send to the y/m/d fields, and we'll have failed to open the
 * calendar popup.
 *
 * Instead, when we get the `onMouseDown` event, we transition to this
 * interstitial focusing state, which is rendered the same as the normal state
 * with the expanded calendar button. The `onClick` event hits the button and
 * opens the popup. Then when the focusing state gets the `onClick` event, it
 * can transition to the editing state. If the user aborts the click, we return
 * to the normal state.
 */
class FocusingState<T> implements IState {
	onEdit(initialValue: T): EditingState<T> {
		return new EditingState(initialValue);
	}

	onMouseOut(): NormalState<T> {
		return new NormalState();
	}

	onExit(): void {
		return;
	}
}

export class EditingState<T> implements IState {
	readonly value: T;

	constructor(value: T) {
		this.value = value;
	}

	onCancel(): NormalState<T> {
		return new NormalState();
	}

	onChange(value: T): EditingState<T> {
		return new EditingState(value);
	}

	onSubmit(
		submit: (value: T, signal: AbortSignal) => Promise<T>,
	): SavingState<T> {
		return new SavingState(this.value, submit);
	}

	onExit(): void {
		return;
	}
}

export class SavingState<T> implements IState {
	private abortController: AbortController = new AbortController();
	private submit: (value: T, signal: AbortSignal) => Promise<T>;
	readonly value: T;

	constructor(
		value: T,
		submit: (value: T, signal: AbortSignal) => Promise<T>,
	) {
		this.value = value;
		this.submit = submit;
	}

	async onEnter(): Promise<SuccessState<T> | ErrorState> {
		try {
			const result: T = await this.submit(
				this.value,
				this.abortController.signal,
			);
			return new SuccessState(result);
		} catch (e) {
			const error: Error = e as Error;
			if (error.name === 'AbortError') {
				throw error;
			} else {
				return new ErrorState(error);
			}
		}
	}

	onExit(): void {
		this.abortController.abort();
	}
}

export class SuccessState<T> implements IState {
	private abortController: AbortController = new AbortController();
	readonly value: T;

	constructor(value: T) {
		this.value = value;
	}

	async onEnter(): Promise<NormalState<T>> {
		await delay(1000, this.abortController.signal);
		return new NormalState();
	}

	onExit(): void {
		this.abortController.abort();
	}
}

export class ErrorState implements IState {
	readonly error: Error;

	constructor(error: Error) {
		this.error = error;
	}

	onExit(): void {
		return;
	}
}

export type State<T> =
	| NormalState<T>
	| FocusingState<T>
	| EditingState<T>
	| SavingState<T>
	| SuccessState<T>
	| ErrorState;

/**
 * Converts our easy-to-work-with date representation to the string value
 * expected by `<input>`.
 */
function serialize(data: Data): string {
	return data == null ? '' : moment(data).format(moment.HTML5_FMT.DATE);
}

/**
 * Converts an `<input>`'s possibly-blank date string value to an easy-to-work
 * with nullable `moment` instance.
 */
function deserialize(date: string): Moment | null {
	return date === '' ? null : moment(date, moment.HTML5_FMT.DATE);
}

type DateCellProps = {
	format: DateFormatFunction;
	initialValue: string;
	onChange: (value: string, signal: AbortSignal) => Promise<string>;
};

function EditableDateCell({
	format,
	initialValue,
	onChange,
	...props
}: DateCellProps) {
	const [currentState, transition] = useStateMachine<State<string>>(
		new NormalState(),
	);

	if (currentState instanceof NormalState) {
		/*
		 * Flow has a bug where it loses track of the type parameter when
		 * refining generic types with instanceof. Here, Flow incorrectly
		 * believes `currentState` is `NormalState<string | any>`. Since there
		 * are a lot of moving parts here, we explicitly cast the state to the
		 * correct type to ensure we're as protected as possible from typing
		 * mistakes.
		 */
		const normalState: NormalState<string> = currentState;
		const value = deserialize(initialValue);
		const defaultValue =
			initialValue || moment().format(moment.HTML5_FMT.DATE);
		if (isDateInputSupported()) {
			return (
				<EditingCell
					{...props}
					/*
					 * If we get a focus event before a mousedown event, we know
					 * focus came in by way of the keyboard. The calendar popup
					 * won't be opening, and we can go directly to the editing
					 * state.
					 */
					onFocus={() => {
						transition(normalState.onEdit(defaultValue));
					}}
					/*
					 * The mousedown event signals the beginning of a potential
					 * click event if it's followed by a mouseup event once in
					 * the focusing state that generates a click.
					 */
					onMouseDown={() => {
						transition(normalState.onMouseDown());
					}}
					title={value ? value.format('LLLL') : ''}
				>
					<EditingCellText>{value && format(value)}</EditingCellText>
					<StyledDateInput
						/*
						 * Normally we'd create a new `InvisibleDateInput`
						 * styled component and apply `opacity: 0` there.
						 * However, when this input is clicked, we want it to
						 * open the calendar picker popup, and that won't happen
						 * if React re-mounts the `<input>`. First, we give the
						 * `<input>` a consistent `key` prop so that React knows
						 * it is the same element even when the `<td>`'s child
						 * elements change between rendered states. Second, we
						 * cannot use a separate styled component because React
						 * uses elements' `displayName` as a heuristic for when
						 * to re-mount. Instead, we re-use the `StyledDateInput`
						 * and apply any state-specific customizations with
						 * inline `style` props.
						 */
						key="input"
						onChange={noop}
						style={{ opacity: '0' }}
						type="date"
						value={defaultValue}
					/>
				</EditingCell>
			);
		} else {
			return (
				<NormalCell
					{...props}
					onFocus={() => {
						transition(normalState.onEdit(defaultValue));
					}}
					/*
					 * Setting tabIndex makes the cell focusable by the mouse or
					 * in sequential order with the keyboard. `<input>`s have
					 * that behavior by default, but since this is a `<td>` in
					 * the normal state, we need to add it manually.
					 */
					tabIndex={0}
					title={value ? value.format('LLLL') : ''}
				>
					{value && format(value)}
				</NormalCell>
			);
		}
	} else if (currentState instanceof FocusingState) {
		const focusingState: FocusingState<string> = currentState;
		const value = deserialize(initialValue);
		const defaultValue =
			initialValue || moment().format(moment.HTML5_FMT.DATE);
		return (
			<EditingCell
				{...props}
				/*
				 * The mouseup event that caused us to enter the focusing state
				 * concluded in an actual click. Once Chrome's calendar popup
				 * button has received the click event, we can transition to the
				 * editing state.
				 */
				onClick={() => {
					transition(focusingState.onEdit(defaultValue));
				}}
				/*
				 * The user aborted the click action that brought us into this
				 * state.
				 */
				onMouseOut={() => {
					transition(focusingState.onMouseOut());
				}}
				title={value ? value.format('LLLL') : ''}
			>
				<EditingCellText>{value && format(value)}</EditingCellText>
				<StyledDateInput
					key="input"
					onChange={noop}
					style={{ opacity: '0' }}
					type="date"
					value={defaultValue}
				/>
			</EditingCell>
		);
	} else if (currentState instanceof EditingState) {
		const editingState: EditingState<string> = currentState;
		if (isDateInputSupported()) {
			return (
				<EditingCell {...props}>
					<StyledDateInput
						autoFocus
						className="EditableDateCell--editing"
						key="input"
						onBlur={() => {
							transition(
								editingState.value === initialValue
									? editingState.onCancel()
									: editingState.onSubmit(onChange),
							);
						}}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
						) => {
							/*
							 * We intentionally ignore the validation state here
							 * and just update the value. Invalid values map to
							 * null. If we ever need to show the validation
							 * message, we could perhaps transition to an
							 * `InvalidState` with its own branch in the render.
							 */
							transition(
								editingState.onChange(
									event.currentTarget.value,
								),
							);
						}}
						onKeyDown={(
							event: React.KeyboardEvent<HTMLInputElement>,
						) => {
							switch (event.key) {
								case 'Enter':
									transition(editingState.onSubmit(onChange));
									return;
								case 'Escape':
									transition(editingState.onCancel());
									return;
								default: // Do nothing
							}
						}}
						type="date"
						value={editingState.value}
					/>
				</EditingCell>
			);
		} else {
			return (
				<EditingCellPolyfill
					{...props}
					onBlur={() => {
						transition(
							editingState.value === initialValue
								? editingState.onCancel()
								: editingState.onSubmit(onChange),
						);
					}}
				>
					<StyledDateInputPolyfill
						autoFocus
						onChangeCompatible={(value) => {
							/*
							 * We intentionally ignore the second
							 * validationMessage argument here and just update
							 * the value. Invalid values map to null. If we ever
							 * need to show the validation message, we could
							 * perhaps transition to an `InvalidState` with its
							 * own branch in the render.
							 */
							transition(editingState.onChange(value));
						}}
						onKeyDown={(event) => {
							switch (event.key) {
								case 'Enter':
									transition(editingState.onSubmit(onChange));
									return;
								case 'Escape':
									transition(editingState.onCancel());
									return;
								default: // Do nothing
							}
						}}
						value={editingState.value}
					/>
				</EditingCellPolyfill>
			);
		}
	} else if (currentState instanceof SavingState) {
		const savingState: SavingState<string> = currentState;
		const value = deserialize(savingState.value);
		return (
			<SavingCell {...props} title={value ? value.format('LLLL') : ''}>
				{value && format(value)}
			</SavingCell>
		);
	} else if (currentState instanceof SuccessState) {
		const successState: SuccessState<string> = currentState;
		const value = deserialize(successState.value);
		return (
			<SuccessCell {...props} title={value ? value.format('LLLL') : ''}>
				{value && format(value)}
			</SuccessCell>
		);
	} else if (currentState instanceof ErrorState) {
		return (
			<ErrorCell {...props} title={currentState.error.message}>
				{currentState.error.message}
			</ErrorCell>
		);
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class DateColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	static formatDefault: DateFormatFunction = (date) => date.format("MMM 'YY");
	static formatRelative: DateFormatFunction = (date) => date.fromNow();

	private format: DateFormatFunction;
	name: string;
	private select: (row: T) => Data;
	private update: null | ((value: Data) => Partial<T>);
	private cellCanUpdate: null | ((row: T) => boolean);

	constructor({
		format = DateColumn.formatDefault,
		name,
		select,
		update = null,
		cellCanUpdate = null,
	}: {
		format?: DateFormatFunction;
		name: string;
		select: (row: T) => Data;
		update?: null | ((value: Data) => Partial<T>);
		cellCanUpdate?: null | ((row: T) => boolean);
	}) {
		this.format = format;
		this.name = name;
		this.select = select;
		this.update = update;
		this.cellCanUpdate = cellCanUpdate;

		this.cell.displayName = 'DateColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const data = this.select(row);

		const update = this.update;
		const canUpdate =
			update != null
			&& (this.cellCanUpdate == null || this.cellCanUpdate(row));
		if (deferred || !canUpdate || !onChange) {
			const value = data && moment(data);
			return (
				<NormalCell
					{...props}
					{...(value && { title: value.format('LLLL') })}
				>
					{value && this.format(value)}
				</NormalCell>
			);
		}

		return (
			<EditableDateCell
				initialValue={serialize(data)}
				format={this.format}
				onChange={async (newValue, signal) => {
					const patch = update(deserialize(newValue));
					const response = await onChange(row, patch, signal);
					const updatedValue = this.select(response);
					return serialize(updatedValue);
				}}
				{...props}
			/>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				initialSortDirection={sortDirections.descending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				<HeaderText>{this.name}</HeaderText>
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal = this.select(a);
		const bVal = this.select(b);

		if (aVal == null && bVal == null) return 0;
		if (aVal == null) return 1;
		if (bVal == null) return -1;
		return direction === sortDirections.ascending
			? +aVal - +bVal
			: +bVal - +aVal;
	}

	toCSV(row: T): string {
		const data = this.select(row);
		const date = data && this.format(moment(data));
		return date !== null ? date : '';
	}
}
