import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { authorize } from '../components/Authorized';
import authorizedRoute from '../components/authorized-route';
import Header from '../components/header';
import { Sidebar, Split } from '../components/split';
import {
	getRouteForKey,
	statComponents,
} from '../components/stats/chart-utils';
import Analytics from '../components/stats/chart-utils/analytics';
import StatsDetailList from '../components/stats/stats-detail-list';
import View from '../components/view';
import { selectUser } from '../selectors';
import useAnalytics from '../utils/hooks/use-analytics';

export const routes = authorizedRoute(
	{
		path: 'stats',
		element: <Stats />,
		children: [{ path: '*', element: <Stats /> }],
	},
	'contacts.stats',
);

export default function Stats() {
	useAnalytics({
		eventName: 'Visit New Page',
		viewType: 'stats',
		componentIdentifier: 'stats-view',
	});
	const user = useSelector(selectUser);
	const components = statComponents
		.filter(({ auth }) => user && authorize(auth)(user))
		.map(({ Component }) => {
			const key = Component.getKey();
			return (
				<Route
					key={key}
					element={<Component />}
					path={getRouteForKey(key)}
				/>
			);
		});

	return (
		<View>
			<Header />
			<Split>
				<Sidebar>
					<StatsDetailList />
				</Sidebar>
				<Routes>
					<Route path=":name" element={<Analytics />} />
				</Routes>
				<Routes>{components}</Routes>
			</Split>
		</View>
	);
}
