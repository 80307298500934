import { useCallback, useMemo } from 'react';
import { type Selection } from 'react-aria-components';

import useImmutableSearchParams from '../../utils/hooks/use-immutable-search-params';
import { equals } from '../../utils/sets';
import { useDrivePointOfContactOptionsSuspense } from '../api';

/** The special value representing no assigned Drive point of contact. */
export const NOBODY = '' as const;

const SEARCH_PARAM_NAME = 'poc';

export default function usePointOfContactData() {
	const { data: options } = useDrivePointOfContactOptionsSuspense();
	const all = useMemo(
		() => new Set([NOBODY, ...options.map(({ username }) => username)]),
		[options],
	);

	const [searchParams, setSearchParams] = useImmutableSearchParams();
	const selectedPointsOfContact = useMemo(() => {
		const selected = searchParams.getAll(SEARCH_PARAM_NAME);
		return selected.length === 0 ? 'all' : new Set(selected);
	}, [searchParams]);

	const isSelected = useCallback(
		(username: string | null) =>
			selectedPointsOfContact === 'all'
			|| selectedPointsOfContact.has(username ?? NOBODY),
		[selectedPointsOfContact],
	);

	const onChange = useCallback(
		(usernames: Selection) => {
			setSearchParams(
				(params) => {
					if (usernames === 'all' || equals(usernames, all)) {
						return params.delete(SEARCH_PARAM_NAME);
					} else {
						return [...usernames].reduce(
							(acc, username) =>
								typeof username === 'string'
								&& all.has(username)
									? acc.append(SEARCH_PARAM_NAME, username)
									: acc,
							params.delete(SEARCH_PARAM_NAME),
						);
					}
				},
				{ replace: true },
			);
		},
		[all, setSearchParams],
	);

	return {
		isSelected,
		onChange,
		options,
		selectedPointsOfContact,
	} as const;
}
