// @flow

import React from 'react';

import SentenceList from '../../components/sentence-list';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import multiSelectSearchFilter from './multi-select-filter';
import type { MultiSelectDisplayProps } from './multi-select-filter';

const LocationSearchFilterDisplay = ({
	onRemove,
	punctuator = '',
	resultCount,
	value,
}: MultiSelectDisplayProps) => (
	<FilterDescription>
		{`${resultCount === 1 ? 'is' : 'are'} associated with `}
		<SentenceList conjunction="or" punctuator={punctuator}>
			{value.map((location) => (
				<DescriptionValue
					key={location}
					onRemove={onRemove}
					value={location}
				>
					{location}
				</DescriptionValue>
			))}
		</SentenceList>
	</FilterDescription>
);

export default multiSelectSearchFilter(
	'Location',
	'data.location',
	LocationSearchFilterDisplay,
	'location-filter',
);
