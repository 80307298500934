import { Temporal } from '@js-temporal/polyfill';
import { queryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { updateCompaniesMarketMaps } from '../market-maps/api';
import type { CompanyMarketMaps } from '../market-maps/api';
import { type JobData } from '../profiles/sections/jobs-and-educations';
import {
	get,
	getQueryData,
	post,
	queryClient,
	updateQueryData,
} from '../utils/api';

import {
	type CompanyOutreach,
	type DismissReason,
	type EventHistoryItem,
	type OutreachState,
	type SnoozedEvent,
} from './types';

interface API_DismissedEvent {
	message: string;
	timestamp: string;
	type: 'dismissed_event';
}

interface API_ExpiredEvent {
	message: string;
	timestamp: string;
	type: 'expired_event';
}

interface API_InitialEvent {
	message: '';
	timestamp: string;
	type: 'initial_event';
}

interface API_MonitoringEvent {
	message: string;
	timestamp: string;
	type: 'monitoring_event';
}

interface API_SnoozedEvent {
	message: string;
	note: string | null;
	snoozed_until: string;
	timestamp: string;
	type: 'snoozed_event';
}

interface API_SuccessfulEvent {
	message: string;
	timestamp: string;
	type: 'successful_event';
}

type API_EventHistoryItem =
	| API_DismissedEvent
	| API_ExpiredEvent
	| API_InitialEvent
	| API_MonitoringEvent
	| API_SnoozedEvent
	| API_SuccessfulEvent;

interface API_CompanyOutreach {
	company_id: number;
	company_logo_url: string;
	company_name: string;
	contacts: ReadonlyArray<{
		current_job: JobData | null;
		email: string;
		id: number;
		name: string;
		photo_url: string;
	}>;
	history: ReadonlyArray<API_EventHistoryItem>;
	id: number;
	market_maps: CompanyMarketMaps;
	outreach_link_base: string | null;
	outreach_type: 'EMAIL';
	first_contacted_at: string;
	state: OutreachState;
}

export function calculateDaysAgo(companyOutreach: CompanyOutreach): number {
	return parseInt(
		Temporal.Now.zonedDateTimeISO('UTC')
			.since(companyOutreach.mostRecentOutreachAt)
			.total({ unit: 'days' })
			.toFixed(0),
		10,
	);
}

export function compareMarketMapDates(
	a: CompanyOutreach['marketMaps'][0],
	b: CompanyOutreach['marketMaps'][0],
	direction: 'ascending' | 'descending',
) {
	const compare = Temporal.Instant.compare(
		a.last_viewed_at || a.added_at,
		b.last_viewed_at || b.added_at,
	);
	if (direction === 'ascending') {
		return compare;
	}
	return -compare;
}

export function getRowKey(row: CompanyOutreach): React.Key {
	return row.id;
}

const queryKey = ['investor-outreach'];

function apiToCompanyOutreach(
	apiCompanyOutreach: API_CompanyOutreach,
): CompanyOutreach {
	const firstContactedAt = Temporal.Instant.from(
		apiCompanyOutreach.first_contacted_at,
	).toZonedDateTimeISO('UTC');
	const history: EventHistoryItem[] = apiCompanyOutreach.history
		.map((item) =>
			item.type === 'snoozed_event'
				? {
						...item,
						snoozedUntil: Temporal.Instant.from(
							item.snoozed_until,
						).toZonedDateTimeISO('UTC'),
						timestamp: Temporal.Instant.from(
							item.timestamp,
						).toZonedDateTimeISO('UTC'),
				  }
				: {
						...item,
						timestamp: Temporal.Instant.from(
							item.timestamp,
						).toZonedDateTimeISO('UTC'),
				  },
		)
		.toSorted((a, b) =>
			Temporal.ZonedDateTime.compare(b.timestamp, a.timestamp),
		);
	const outreachHistory = history.filter(({ type }) =>
		['initial_event', 'monitoring_event'].includes(type),
	);
	const latestSnooze = history
		.filter(({ type }) => type === 'snoozed_event')
		.toSorted((a, b) =>
			Temporal.ZonedDateTime.compare(
				(b as SnoozedEvent).snoozedUntil,
				(a as SnoozedEvent).snoozedUntil,
			),
		)[0] as SnoozedEvent | undefined;

	return {
		company: {
			id: apiCompanyOutreach.company_id,
			logoUrl: apiCompanyOutreach.company_logo_url,
			name: apiCompanyOutreach.company_name,
		},
		contacts: apiCompanyOutreach.contacts.map((contact) => ({
			currentJob:
				contact.current_job == null
					? null
					: {
							company: {
								id: contact.current_job.company.id,
								logoUrl: contact.current_job.company.logo_url,
								name: contact.current_job.company.name,
							},
							endDate: contact.current_job.end_date
								? Temporal.PlainDate.from(
										contact.current_job.end_date,
								  )
								: null,
							id: contact.current_job.id,
							startDate: contact.current_job.start_date
								? Temporal.PlainDate.from(
										contact.current_job.start_date,
								  )
								: null,
							title: contact.current_job.title,
					  },
			email: contact.email,
			id: contact.id,
			name: contact.name,
			photoUrl: contact.photo_url,
		})),
		firstContactedAt,
		history,
		id: apiCompanyOutreach.id,
		marketMaps: apiCompanyOutreach.market_maps,
		mostRecentOutreachAt: outreachHistory[0]?.timestamp || firstContactedAt,
		outreachLinkBase: apiCompanyOutreach.outreach_link_base,
		outreachType: apiCompanyOutreach.outreach_type,
		snoozedUntil: latestSnooze?.snoozedUntil || null,
		state: apiCompanyOutreach.state,
	};
}

// [Most recent api result, most recent transformed result]
// Problem: react-query does not cache the result of `select` whenever data is pulled from query cache.
// See https://github.com/TanStack/query/discussions/3387. This prevents us from having
// a stable reference to the transformed data which causes a bunch of unnecessary re-rendering
//
// Solution: Cache the most recently seen cache data and result of it's `select`. That allows
// us to keep a stable reference and only re-render on changes.
let cachedOutreachTransform: [API_CompanyOutreach[], CompanyOutreach[]] | null =
	null;

function companyOutreachesQueryOptions() {
	return queryOptions({
		queryKey,
		queryFn: async () => {
			const companyOutreaches = await get<API_CompanyOutreach[]>(
				'/company-outreach',
			);

			updateCompaniesMarketMaps(
				companyOutreaches.map((outreach) => ({
					companyId: outreach.company_id,
					marketMaps: outreach.market_maps,
				})),
			);

			return companyOutreaches;
		},
		select: (companyOutreaches) => {
			if (
				cachedOutreachTransform
				&& cachedOutreachTransform[0] === companyOutreaches
			) {
				return cachedOutreachTransform[1];
			} else {
				const transformed = companyOutreaches.map(apiToCompanyOutreach);
				cachedOutreachTransform = [companyOutreaches, transformed];
				return transformed;
			}
		},
	});
}

export function useCreateHerbieCatalyzedFollowUp() {
	return useMutation({
		mutationFn: (outreachId: number) =>
			post<void>(
				`/company-outreach/${outreachId}/herbie-catalyzed-follow-up`,
			),
	});
}

export function useOutreachCompanies() {
	return useQuery(companyOutreachesQueryOptions());
}

export function useDismissOutreachCompanies() {
	return useMutation({
		mutationFn: ({
			otherReason,
			outreachIds,
			reason,
		}: {
			otherReason: string;
			outreachIds: number[];
			reason: DismissReason;
		}) =>
			post<void>('/dismiss-company-outreach', {
				body: {
					dismissal_reason: otherReason || reason,
					outreach_ids: outreachIds,
				},
			}),
		onError: (
			_error,
			_variables,
			previousOutreach: API_CompanyOutreach[] | undefined,
		) => {
			updateQueryData(
				companyOutreachesQueryOptions(),
				(currentOutreach) => previousOutreach || currentOutreach,
			);
		},
		onMutate: async ({ outreachIds }) => {
			const options = companyOutreachesQueryOptions();
			await queryClient.cancelQueries(options);
			const previousOutreach = getQueryData(options);

			updateQueryData(options, (outreach) =>
				(outreach || []).filter(
					(company) => !outreachIds.includes(company.id),
				),
			);

			return previousOutreach;
		},
	});
}

export function useSnoozeOutreachCompanies() {
	return useMutation({
		mutationFn: ({
			notes,
			outreachIds,
			snoozeUntil,
		}: {
			notes: string | null;
			outreachIds: number[];
			snoozeUntil: string;
		}) =>
			post<API_CompanyOutreach[]>('/snooze-company-outreach', {
				body: {
					outreach_ids: outreachIds,
					reason: notes,
					snoozed_until: snoozeUntil,
				},
			}),
		onSuccess: (snoozedOutreaches) => {
			const byId = new Map(
				snoozedOutreaches.map((outreach) => [outreach.id, outreach]),
			);
			updateQueryData(companyOutreachesQueryOptions(), (outreaches) =>
				outreaches.map(
					(oldOutreach) => byId.get(oldOutreach.id) || oldOutreach,
				),
			);
		},
	});
}

type AcknowledgedFollowUpQueryData = Record<number, Temporal.ZonedDateTime>;

function companyOutreachAcknowledgedFollowUpQueryOptions() {
	return queryOptions({
		queryKey: ['investor-outreach-follow-ups'],
		queryFn: () => Promise.resolve({} as AcknowledgedFollowUpQueryData),
		initialData: {} as AcknowledgedFollowUpQueryData,
		staleTime: Infinity,
	});
}

export function useAcknowledgedOutreachFollowUps() {
	return useQuery(companyOutreachAcknowledgedFollowUpQueryOptions());
}

export function useSetOutreachCompanyPending() {
	return useMutation({
		mutationFn: ({}: { outreachId: number }) =>
			new Promise((resolve) => {
				setTimeout(resolve, 250);
			}),
		onSuccess: (_, { outreachId }) => {
			queryClient.setQueryData(
				companyOutreachAcknowledgedFollowUpQueryOptions().queryKey,
				(ackonwledgedFollowUps) =>
					ackonwledgedFollowUps
						? {
								...ackonwledgedFollowUps,
								[outreachId]:
									Temporal.Now.zonedDateTimeISO('UTC'),
						  }
						: {
								[outreachId]:
									Temporal.Now.zonedDateTimeISO('UTC'),
						  },
			);
		},
	});
}
