import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import type { State as StoreState } from '../../reducers';
import { get, post, remove, ResponseError } from '../../utils/api';
import type { OnePager, PartialOnePager } from '../types';

import { deleteOnePager, loadOnePagerSuccess } from './actions';
import { selectOnePagersByCompanyId } from './selectors';

function errorIs404(error: unknown) {
	return error instanceof ResponseError && error.response.status === 404;
}

export function useCompanyOnePagers(companyId: number) {
	const companyOnePagers = useSelector((store: StoreState) =>
		selectOnePagersByCompanyId(store, companyId),
	);

	return useQuery({
		queryKey: ['one-pagers', 'by-company', companyId],
		queryFn: ({ signal }) =>
			get<PartialOnePager[]>(`/onepagers`, {
				query: { companyId },
				signal,
			}),
		placeholderData: companyOnePagers,
		retry: (_, retryError) => !errorIs404(retryError),
	});
}

export function useOnePagerCreate(callback: (onePagerId: number) => void) {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (companyId: number) =>
			post('/onepagers', { body: { fused_company: companyId } }),
		onSuccess: (createdOnePager: OnePager, companyId) => {
			queryClient.setQueriesData(
				{ queryKey: ['one-pagers', 'by-company', companyId] },
				(
					currentCompanyOnePagers: Array<PartialOnePager> | undefined,
				) => [...(currentCompanyOnePagers || []), createdOnePager],
			);
			dispatch(loadOnePagerSuccess(createdOnePager));
			callback(createdOnePager.id);
		},
	});
}

type DeleteAction = {
	companyId: number;
	onePagerId: number;
};
export function useOnePagerDelete() {
	const dispatch = useDispatch();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: ({ onePagerId }: DeleteAction) =>
			remove(`/onepagers/${onePagerId}`),
		onSuccess: (_, { companyId, onePagerId }: DeleteAction) => {
			queryClient.setQueriesData(
				{ queryKey: ['one-pagers', 'by-company', companyId] },
				(currentCompanyOnePagers: Array<PartialOnePager> | undefined) =>
					(currentCompanyOnePagers || []).filter(
						({ id }) => id !== onePagerId,
					),
			);
			dispatch(deleteOnePager(onePagerId));
		},
	});
}
