import numeral from 'numeral';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import GenericChart from '../charts/generic-chart';
import PieChart from '../charts/pie-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/portfolio_amount_invested_by_company?fund=2';
let data = null;

const valueFormat = (d) => numeral(d).format('0.0a').toUpperCase();
const formatData = (dataArr) =>
	dataArr.map((company) => ({
		label: company.semi_annuals__fused_company__name,
		value: company.money_invested,
	}));

export default class SemiAnnualFund2PortfolioAmountInvestedByCompany extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund2PortfolioAmountInvestedByCompanyDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				title="$$ Invested"
				valueFormat={valueFormat}
			>
				{PieChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund2PortfolioAmountInvestedByCompanyDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/portfolio_invested_by_company.png"
				statName="Fund II $$ Invested by Company"
			/>
		);
	}
}
