export const probabilities = {
	/** Committed, signed, closed, done deal. */
	ZERO_POINT_FIVE: 9,
	/** Negotiating legal terms. */
	ONE: 1,
	/** Submitted IOI (Indication of Interest). */
	TWO: 2,
	/** They've come on site for diligence. */
	THREE: 3,
	/** We had a positive conversation with a potential commitment amount. */
	FOUR: 4,
	/** We know they exist but haven't spoken to them. Default for new LPs. */
	FOUR_POINT_FIVE: 10,
	/** Out. */
	FIVE: 5,
} as const satisfies Record<string, number>;

export type Probability = (typeof probabilities)[keyof typeof probabilities];

export const probabilityOptions = new Map<Probability, string>([
	[probabilities.ZERO_POINT_FIVE, '0.5'],
	[probabilities.ONE, '1'],
	[probabilities.TWO, '2'],
	[probabilities.THREE, '3'],
	[probabilities.FOUR, '4'],
	[probabilities.FOUR_POINT_FIVE, '4.5'],
	[probabilities.FIVE, '5'],
]);

const probabilityWeights = {
	[probabilities.ZERO_POINT_FIVE]: 1,
	[probabilities.ONE]: 0.9,
	[probabilities.TWO]: 0.5,
	[probabilities.THREE]: 0.1,
	[probabilities.FOUR]: 0.05,
	[probabilities.FOUR_POINT_FIVE]: 0,
	[probabilities.FIVE]: 0,
} as const satisfies Record<Probability, number>;

export function weight(amount: number, probability: Probability): number {
	const percentage = probabilityWeights[probability];
	return amount * percentage;
}
