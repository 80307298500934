import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	$getSelection,
	$isRangeSelection,
	COMMAND_PRIORITY_EDITOR,
	FORMAT_TEXT_COMMAND,
	SELECTION_CHANGE_COMMAND,
} from 'lexical';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { colors } from '../../theme';
import UnstyledIconButton from '../../theme/components/icon-button';
import {
	TextEditorBoldIcon,
	TextEditorItalicIcon,
	TextEditorStrikethroughIcon,
	TextEditorUnderlineIcon,
} from '../../theme/icons/document';
import { trackEvent, type ViewType } from '../../utils/analytics';

const Container = styled.div`
	display: flex;
	gap: 8px;
	grid-area: toolbar;
`;
const IconButton = styled(UnstyledIconButton)<{ $enabled: boolean }>`
	border-radius: 8px;
	${({ $enabled }) =>
		$enabled
			? `color: ${colors.icon.primary};`
			: `color: ${colors.icon.secondary}`};
	${({ $enabled }) =>
		$enabled
			? `background: ${colors.button.secondaryActive};`
			: `background: ${colors.button.secondary}`};
	> svg {
		height: 16px;
		width: 16px;
	}
`;

type FormattingState = Record<FormattingType, boolean>;
export type FormattingType = 'bold' | 'underline' | 'strikethrough' | 'italic';
const allFormats: ReadonlyArray<FormattingType> = [
	'bold',
	'italic',
	'strikethrough',
	'underline',
] as const;
const formatToIcon: Record<FormattingType, React.ReactNode> = {
	bold: <TextEditorBoldIcon />,
	italic: <TextEditorItalicIcon />,
	strikethrough: <TextEditorStrikethroughIcon />,
	underline: <TextEditorUnderlineIcon />,
};

interface FormattingButtonProps {
	analyticsComponentIdentifier: string;
	analyticsViewType: ViewType;
	enabled: boolean;
	format: FormattingType;
	onClick: (format: FormattingType) => void;
}
function FormattingButton({
	analyticsComponentIdentifier,
	analyticsViewType,
	enabled,
	format,
	onClick,
}: FormattingButtonProps) {
	const handleClick = useCallback(() => {
		onClick(format);
		trackEvent(
			'Apply Note Format',
			analyticsComponentIdentifier,
			analyticsViewType,
			{ format },
		);
	}, [analyticsComponentIdentifier, analyticsViewType, format, onClick]);

	return (
		<IconButton
			$enabled={enabled}
			onClick={handleClick}
			onMouseDown={(evt: React.MouseEvent) => {
				// Prevent default on mouse down stops the content editable from bluring
				evt.preventDefault();
			}}
		>
			{formatToIcon[format]}
		</IconButton>
	);
}

interface Props {
	analyticsComponentIdentifier: string;
	analyticsViewType: ViewType;
	extraControls?: React.ReactNode;
	formats: FormattingType[];
	open: boolean;
}

export default function ToolBar({
	analyticsComponentIdentifier,
	analyticsViewType,
	extraControls,
	formats,
	open,
}: Props) {
	const [editor] = useLexicalComposerContext();
	const [formattingState, setFormattingState] = useState<FormattingState>(
		allFormats.reduce((acc, format) => {
			acc[format] = false;
			return acc;
		}, {} as FormattingState),
	);
	const handleClick = useCallback(
		(format: FormattingType) => {
			editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
		},
		[editor],
	);

	useEffect(() => {
		return editor.registerCommand(
			SELECTION_CHANGE_COMMAND,
			() => {
				const selection = $getSelection();

				if ($isRangeSelection(selection)) {
					setFormattingState(
						allFormats.reduce((acc, format) => {
							acc[format] = selection.hasFormat(format);
							return acc;
						}, {} as FormattingState),
					);
				}
				return false;
			},
			COMMAND_PRIORITY_EDITOR,
		);
	}, [editor]);

	if (!open) {
		return null;
	}

	return (
		<Container>
			{formats.map((format) => (
				<FormattingButton
					analyticsComponentIdentifier={analyticsComponentIdentifier}
					analyticsViewType={analyticsViewType}
					enabled={formattingState[format]}
					format={format}
					key={format}
					onClick={handleClick}
				/>
			))}
			{extraControls}
		</Container>
	);
}
