import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as colors from '../colors';
import {
	DriveIcon as Drive,
	LocationPinIcon as Pin,
	PinchIcon as Pinch,
} from '../components/icons';
import { selectUser } from '../selectors';

import type { PipelineStageItem, PipelineStageTransition } from './types';

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
`;
const Top = styled.div`
	margin-bottom: 12px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	background-color: ${colors.backgroundGray.string()};
	padding: 10px 16px;
	border-radius: 10px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
`;
const Title = styled.div`
	display: flex;
`;
const PinchIcon = styled(Pinch)`
	height: 18px;
	width: 18px;

	&:hover {
		cursor: pointer;
	}
`;
const LocationIcon = styled(Pin)`
	height: 16px;
	width: 16px;
`;

const Logo = styled.img`
	background-color: white;
	border-radius: 24px;
	float: left;
	height: 48px;
	margin: 2px 12px 6px 0;
	object-fit: contain;
	object-position: 50% 50%;
	width: 48px;

	&:hover {
		cursor: pointer;
	}
`;
const TitleText = styled.div`
	font-size: 16px;
	font-weight: bold;
`;
const SubTitleText = styled.div`
	font-size: 12px;
	display: flex;
	align-items: bottom;
`;

const Bottom = styled.div`
	flex: 1;
	display: flex;
	overflow: hidden;
	padding: 4px 10px 0 10px;
`;

const Timeline = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	width: 50%;
	margin-bottom: 8px;
`;
const TimelineEvent = styled.div`
	position: relative;

	&:not(:last-child)::before {
		/* this is the vertical line */
		content: '';
		position: absolute;
		top: calc(0.25em + 17.5px);
		left: 29px;
		height: 100%;
		width: 1px;
		background: ${colors.disabledGray.string()};
	}
`;
const TimelineEventSpacer = styled.div`
	height: 28px;
`;
const AlignText = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-left: 60px;
`;
const EventTitle = styled.div<{ selected: boolean }>`
	line-height: 1.15em;
	overflow: hidden;
	padding-right: 60px;

	${({ selected }) =>
		selected
		&& `color: ${colors.primaryDriveBlue.string()}; font-size: 15px;`}

	&:hover {
		cursor: pointer;
		font-size: 15px;
	}
`;

const EventDate = styled.p`
	color: ${colors.charcoalGray.string()};
	font-size: 12px;
	font-weight: 300;
	margin-top: 4px;
`;

const DriveIcon = styled(Drive)`
	float: left;
	height: 35px;
	margin: 0.25em 10px 8px 12px;
	width: 35px;
	position: absolute;
	top: 0;
	left: 0;
	fill: ${colors.primaryDriveBlue.string()};
`;

const StageTransition = styled.div`
	width: 50%;
	margin-bottom: 8px;
`;
const StageTransitionTitle = styled.div`
	line-height: 1.15em;
	font-size: 15px;
	font-weight: 600;
	margin-bottom: 8px;
`;
const StageTransitionData = styled.div`
	height: 95%;
	overflow-y: scroll;
`;
const StageTransitionDatum = styled.div`
	&:not(:first-child) {
		margin-top: 12px;
	}
`;
const StageTransitionDatumTitle = styled.div`
	overflow: hidden;
	padding-right: 60px;
`;
const StageTransitionDatumValue = styled.p`
	color: ${colors.charcoalGray.string()};
	font-weight: 300;
	margin-top: 4px;
`;

export default function ProfileCardExpanded({
	item,
	onClose,
}: {
	item: PipelineStageItem;
	onClose: () => void;
}): JSX.Element {
	const [openedTransition, setOpenedTransition] =
		useState<PipelineStageTransition | null>(null);
	const user = useSelector(selectUser);

	const visibleTransitionData = (
		openedTransition?.transitionData || []
	).filter(({ value }) => Boolean(value));

	return (
		<Container>
			<Top>
				<Title>
					<Link
						to={`/people/${item.profileId}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<Logo
							src={item.photoUrl}
							alt={`${item.title} profile picture`}
						/>
					</Link>
					<div>
						<TitleText>{item.title}</TitleText>
						<SubTitleText>{item.subTitle}</SubTitleText>
						{item.location && (
							<SubTitleText>
								<LocationIcon />
								{item.location}
							</SubTitleText>
						)}
					</div>
				</Title>
				<PinchIcon onClick={onClose} />
			</Top>
			<Bottom>
				<Timeline>
					{item.stageTransitions.map((transition) => (
						<TimelineEvent key={transition.id}>
							<div>
								<DriveIcon />
								<AlignText>
									<EventTitle
										title={
											transition.destinationStage.title
										}
										onClick={() =>
											setOpenedTransition(transition)
										}
										selected={
											transition.id
											=== openedTransition?.id
										}
									>
										Moved to{' '}
										{transition.destinationStage.title}
									</EventTitle>
									<EventDate
										title={moment(
											transition.createdAt,
										).format('LLLL')}
									>
										{moment(transition.createdAt).format(
											'MMM Do, YYYY',
										)}{' '}
										&#8226;{' '}
										{user?.username
										=== transition.createdBy.username
											? 'You'
											: `${
													transition.createdBy
														.firstName
											  } ${
													Array.from(
														transition.createdBy
															.lastName,
													)[0]
											  }.`}
									</EventDate>
								</AlignText>
							</div>
							<TimelineEventSpacer />
						</TimelineEvent>
					))}
				</Timeline>
				{openedTransition ? (
					<StageTransition>
						<StageTransitionTitle>
							{openedTransition.destinationStage.title}
						</StageTransitionTitle>
						{visibleTransitionData.length > 0 ? (
							<StageTransitionData>
								{visibleTransitionData.map((datum) => (
									<StageTransitionDatum key={datum.id}>
										<StageTransitionDatumTitle>
											{datum.title}:
										</StageTransitionDatumTitle>
										<StageTransitionDatumValue>
											{datum.type === 'MULTI_VALUE'
												? datum.label || datum.value
												: datum.value}
										</StageTransitionDatumValue>
									</StageTransitionDatum>
								))}
							</StageTransitionData>
						) : (
							<StageTransitionDatum>
								<StageTransitionDatumTitle>
									No Data
								</StageTransitionDatumTitle>
							</StageTransitionDatum>
						)}
					</StageTransition>
				) : (
					<div />
				)}
			</Bottom>
		</Container>
	);
}
