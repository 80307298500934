/**
 * @file
 * Input Control Icons from [Figma][1].
 *
 * [1]: https://www.figma.com/design/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1-5217&m=dev
 */
import React from 'react';

import { colors } from '../';

interface MulticolorProps extends React.SVGProps<SVGSVGElement> {
	shapeColor?: string;
	surroundColor?: string;
}

export function RadioCheckCircleIcon({
	shapeColor = colors.icon.onColor,
	surroundColor = colors.icon.interactive,
	...props
}: MulticolorProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
				fill={surroundColor}
			/>
			<path
				d="M11.0026 16.0001L18.0737 8.92898L16.6595 7.51477L11.0026 13.1717L8.17423 10.3432L6.76001 11.7575L11.0026 16.0001Z"
				fill={shapeColor}
			/>
		</svg>
	);
}

export function RadioCheckboxIndeterminateSelectedIcon({
	shapeColor = colors.icon.onColor,
	surroundColor = colors.icon.interactive,
	...props
}: MulticolorProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3Z"
				fill={surroundColor}
			/>
			<path d="M7 13V11H17V13H7Z" fill={shapeColor} />
		</svg>
	);
}

export function RadioCheckboxSelectedIcon({
	shapeColor = colors.icon.onColor,
	surroundColor = colors.icon.interactive,
	...props
}: MulticolorProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3Z"
				fill={surroundColor}
			/>
			<path
				d="M18.0735 8.92892L11.0024 16L6.75977 11.7574L8.17399 10.3431L11.0024 13.1716L16.6593 7.51471L18.0735 8.92892Z"
				fill={shapeColor}
			/>
		</svg>
	);
}

export function RadioCheckboxUnselectedIcon({
	shapeColor = colors.icon.onColor,
	surroundColor = colors.icon.interactive,
	...props
}: MulticolorProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3Z"
				fill={surroundColor}
			/>
			<path d="M5 19V5H19V19H5Z" fill={shapeColor} />
		</svg>
	);
}

export function RadioSelectedMultiColorIcon({
	shapeColor = colors.icon.onColor,
	surroundColor = colors.icon.secondary,
	...props
}: MulticolorProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill={surroundColor}
			/>
			<path
				d="M12 17C14.7614 17 17 14.7614 17 12C17 9.23857 14.7614 7 12 7C9.23857 7 7 9.23857 7 12C7 14.7614 9.23857 17 12 17Z"
				fill={shapeColor}
			/>
		</svg>
	);
}

export function RadioUnselectedMultiColorIcon({
	shapeColor = colors.icon.onColor,
	surroundColor = colors.icon.interactive,
	...props
}: MulticolorProps) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
				fill={surroundColor}
			/>
			<path
				d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
				fill={shapeColor}
			/>
		</svg>
	);
}
