import numeral from 'numeral';
import React from 'react';

import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import DiscreteBarChart from './charts/discrete-bar-chart';
import GenericChart from './charts/generic-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funds/sizes?year=2016';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('$0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'Fund Size',
		values: dataArr
			.map((fund, index) => ({
				label: index,
				value: fund,
			}))
			.sort((a, b) => a.value - b.value),
	},
];

export default class FundSizes2016 extends React.Component {
	static getDetailCard() {
		return FundSizes2016DetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showXAxis: false,
		};

		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 25, right: 25 }}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="2016 Funds Raised Sizes"
				xAxisLabel="Fund"
				yAxisLabel="Amount Raised"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class FundSizes2016DetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/fund-sizes-ascending.png"
				statName="2016 Fund Sizes"
			/>
		);
	}
}
