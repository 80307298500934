import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import smoothscrollPolyfill from 'smoothscroll-polyfill';

import './index.scss';
import ErrorFallback from './components/error-fallback';
import { releaseVersion } from './config';
import store from './store';
import { queryClient, ResponseError } from './utils/api';
import { routes } from './views/App';

smoothscrollPolyfill.polyfill();

const IGNORED_ERROR_NAMES = new Set(['AbortError']);

if (process.env.ENABLE_SENTRY) {
	Sentry.init({
		release: releaseVersion,
		dsn: 'https://2930bd27a4884255b3ef94b330cb8379@sentry.io/192501',
		beforeSend(event, hint) {
			const processedEvent = { ...event };
			const exception = hint?.originalException;

			if (
				exception instanceof Error
				&& IGNORED_ERROR_NAMES.has(exception.name)
			) {
				console.error('Ignoring error:', exception);
				return null;
			}

			if (exception instanceof ResponseError) {
				const response = exception.response;
				processedEvent.extra = {
					...processedEvent.extra,
					statusText: response.statusText,
					status: response.status,
					url: response.url,
				};
			}

			return processedEvent;
		},
	});
}

const rootElement = document.getElementById('app');

if (!rootElement) {
	throw new Error('Missing root element <div id="app"></div>!');
}

const root = createRoot(rootElement);
const router = createBrowserRouter(routes);

root.render(
	<Sentry.ErrorBoundary
		fallback={<ErrorFallback />}
		onError={(error, componentStack) => {
			console.error(error);
			console.error(componentStack);
		}}
	>
		<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<RouterProvider router={router} />
			</QueryClientProvider>
		</Provider>
	</Sentry.ErrorBoundary>,
);
