import { ActionTypes } from '../constants';
import createReducer from '../utils/createReducer';

export default createReducer([], {
	[ActionTypes.MARK_ROBOT_REQUEST]: (state, { payload: { ids, robot } }) => {
		return robot
			? state.concat(ids.filter((id) => state.indexOf(id) < 0))
			: state.filter((id) => ids.indexOf(id) < 0);
	},
});
