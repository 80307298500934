export { login, logout } from './actions';
export type { Action } from './actions';
export { useUser } from './hooks';
export { default as authenticationReducer } from './reducer';
export type { State } from './reducer';
export { default as authenticationMiddleware } from './middleware';
export { deleteToken, getUser, subscribe } from './sync';
export type { User } from './types';
export { default as GoogleSignIn } from './google-sign-in';
export { default as TwoFactorForm } from './two-factor-form';

export const googleAuthEnabled = typeof window.google !== 'undefined';
