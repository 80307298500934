import d3 from 'd3';
import moment from 'moment';
import React from 'react';

import './funding-round-by-size.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import MultiBarChart from './charts/multi-bar-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funding/round_size_range/';
let data = null;

const xAxisTickFormat = (timestamp) =>
	moment(timestamp).utc().format("MMM 'YY");
const yAxisTickFormat = () => d3.format(',');

const formatData = (stats) => {
	// These are the keys coming from the api
	return [
		'< $1M',
		'$1M - $3M',
		'$3M - $8M',
		'$8M - $20M',
		'$20M - $50M',
		'$50M+',
	].map((key) => {
		return {
			key,
			values: stats[key],
		};
	});
};

export default class FundingRoundBySize extends React.Component {
	static getDetailCard() {
		return FundingRoundBySizeDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			reduceXTicks: true,
			showControls: true,
			staggerLabels: true,
			stacked: true,
		};

		// I have no idea why yAxisTickFormat needs to be called, but the y axis shows source code otherwise...
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				options={options}
				xAxisTickFormat={xAxisTickFormat}
				yAxisLabel="Number of Rounds"
				yAxisTickFormat={yAxisTickFormat()}
			>
				{MultiBarChart}
			</GenericChart>
		);
	}
}

class FundingRoundBySizeDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/funding_round_size_range.png"
				statName="Month-by-month Funding by Round Size"
			/>
		);
	}
}
