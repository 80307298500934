import React from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';
import ControlButton from '../../theme/components/control-button';
import IconButton from '../../theme/components/icon-button';
import { ChevronDownIcon, ChevronUpIcon } from '../../theme/icons/arrows';

const Header = styled.div`
	align-items: center;
	display: grid;
	gap: 8px;
	grid-template:
		'title clear open-toggle' auto
		/ 1fr auto auto;
`;

const OpenToggleButton = styled(IconButton)`
	border: none;
	grid-area: open-toggle;

	&:hover {
		box-shadow: none;
	}

	&:focus-visible {
		outline: 2px solid ${colors.semantic.focus};
	}
`;

const HeaderTitle = styled.span`
	${fonts.paragraph.strong}
	color: ${colors.text.primary};
	cursor: pointer;
	grid-area: title;

	&:hover ~ ${OpenToggleButton} {
		color: ${colors.icon.primary};
	}
`;
const ClearButton = styled(ControlButton)`
	border: none;
	grid-area: clear;
`;

interface Props {
	hasValue: boolean;
	isOpen: boolean;
	onClear: () => void;
	onOpenToggle: () => void;
	title: React.ReactNode;
}

export default function FilterControlHeader({
	hasValue,
	isOpen,
	onClear,
	onOpenToggle,
	title,
}: Props) {
	return (
		<Header>
			<HeaderTitle onClick={onOpenToggle}>{title}</HeaderTitle>
			{hasValue && (
				<ClearButton active={false} onClick={onClear} variant="warning">
					Clear
				</ClearButton>
			)}
			<OpenToggleButton onClick={onOpenToggle} size="small">
				{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
			</OpenToggleButton>
		</Header>
	);
}
