import { Temporal } from '@js-temporal/polyfill';

import type { NoteData } from './types';

export const formatAddedAt = ({ added_at }: NoteData) =>
	Temporal.Instant.from(added_at).toLocaleString('en-US', {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	});

export const formatReminderDate = ({ reminders }: NoteData) =>
	reminders.length > 0
		? Temporal.PlainDateTime.from(
				reminders[0].scheduled_for,
		  ).toLocaleString('en-US', {
				month: '2-digit',
				day: '2-digit',
				year: '2-digit',
		  })
		: '';

export const sortByDate = (notes: ReadonlyArray<NoteData>) =>
	notes.toSorted(({ added_at: a }, { added_at: b }) =>
		Temporal.Instant.compare(
			Temporal.Instant.from(b),
			Temporal.Instant.from(a),
		),
	);

export const reminderInFuture = ({ reminders }: NoteData): boolean => {
	if (reminders.length === 0) {
		return false;
	}

	return (
		Temporal.PlainDateTime.compare(
			Temporal.PlainDateTime.from(reminders[0].scheduled_for),
			Temporal.Now.plainDateTimeISO(),
		) >= 0
	);
};
