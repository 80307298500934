import React from 'react';
import loadable from 'react-loadable';

import { isDriveTenant, isTalentPartner, or } from '../components/Authorized';
import authorizedRoute from '../components/authorized-route';
import HerbieLoader from '../components/herbie-loader';

export { stageChipColors } from './job-order-detail';

const View = loadable({
	loader: () => import('./view'),
	loading: HerbieLoader,
});

export const routes = authorizedRoute(
	{
		path: 'talent',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	or(isDriveTenant, isTalentPartner),
);
