/**
 * @file
 *
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2122-18863&mode=dev
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors, effects } from '../';

import UnstyledChip from './chip';

interface Colors {
	background: string;
	border: string;
	color: string;
}

interface ColorConfig {
	disabled: {
		active: Colors;
		inactive: Colors;
	};
	enabled: {
		active: Colors;
		inactive: Colors;
	};
	hover: {
		active: Colors;
		inactive: Colors;
	};
}

const defaultDisabledColors: ColorConfig['disabled'] = {
	active: {
		background: colors.layer.disabled,
		border: colors.border.disabled,
		color: colors.text.disabled,
	},
	inactive: {
		background: colors.layer.disabled,
		border: colors.border.disabled,
		color: colors.text.disabled,
	},
} as const;

const defaultEnabledColors: ColorConfig['enabled'] = {
	active: {
		background: colors.layer.selected,
		border: colors.border.interactive,
		color: colors.text.interactive,
	},
	inactive: {
		background: colors.button.secondary,
		border: colors.button.secondaryBorder,
		color: colors.text.secondary,
	},
} as const;

const defaultHoverColors: ColorConfig['hover'] = {
	active: {
		background: colors.layer.selectedHover,
		border: colors.border.interactive,
		color: colors.text.interactive,
	},
	inactive: {
		background: colors.button.secondary,
		border: colors.button.secondaryBorderHover,
		color: colors.text.secondaryHover,
	},
};

const Chip = styled(UnstyledChip)``;
const Container = styled.label<{ $colorConfig: ColorConfig }>`
	cursor: pointer;

	input {
		/* hide the input while keeping it focusable */
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		width: 0;

		&:disabled + ${Chip} {
			cursor: not-allowed;
		}

		&:not(:disabled):checked:hover + ${Chip} {
			background: ${({ $colorConfig }) =>
				$colorConfig.hover.active.background};
			border-color: ${({ $colorConfig }) =>
				$colorConfig.hover.active.border};
			color: ${({ $colorConfig }) => $colorConfig.hover.active.color};
		}

		&:not(:disabled):not(:checked):hover + ${Chip} {
			background: ${({ $colorConfig }) =>
				$colorConfig.hover.inactive.background};
			border-color: ${({ $colorConfig }) =>
				$colorConfig.hover.inactive.border};
			color: ${({ $colorConfig }) => $colorConfig.hover.inactive.color};
		}

		&:focus-visible + ${Chip} {
			${effects.shadow.focus}
		}
	}
`;

interface Props {
	className?: string;
	disabledColors?: ColorConfig['disabled'];
	enabledColors?: ColorConfig['enabled'];
	hoverColors?: ColorConfig['hover'];
	isActive: boolean;
	isDisabled?: boolean;
	label: React.ReactNode;
	name?: string;
	onAction: (checked: boolean) => void;
	onMouseEnter?: () => void;
	type?: 'checkbox' | 'radio';
}

export function FilterLabel({
	className,
	disabledColors = defaultDisabledColors,
	enabledColors = defaultEnabledColors,
	hoverColors = defaultHoverColors,
	isActive,
	isDisabled = false,
	label,
	name,
	onAction,
	onMouseEnter,
	type = 'checkbox',
}: Props) {
	const colorConfig = {
		disabled: disabledColors,
		enabled: enabledColors,
		hover: hoverColors,
	};
	const chipColors = isDisabled ? colorConfig.disabled : colorConfig.enabled;
	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			onAction(event.target.checked);
		},
		[onAction],
	);

	return (
		<Container
			$colorConfig={colorConfig}
			className={className}
			onMouseEnter={onMouseEnter}
		>
			<input
				checked={isActive}
				disabled={isDisabled}
				name={name}
				onChange={handleChange}
				type={type}
			/>
			<Chip
				label={label}
				{...chipColors[isActive ? 'active' : 'inactive']}
			/>
		</Container>
	);
}
