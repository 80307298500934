import React from 'react';

import useAbortSignal from '../hooks/use-abort-signal';

import type { IState } from './state';

export type Transition<S extends IState> = (nextState: S | Promise<S>) => void;

// We dont necessarily want S (that is a State<T>)...we want the generic T used
// for State..that is the actual value of our field (number/string, etc... used in IState)
export default function useStateMachine<S extends IState>(
	initialState: S,
): [S, Transition<S>] {
	const [error, setError] = React.useState<Error | null>(null);
	const [currentState, setState] = React.useState<S>(initialState);
	const signal = useAbortSignal();

	const transition = React.useCallback(
		(nextState: S | Promise<S>): void => {
			if (signal.aborted) return;
			if (nextState instanceof Promise) {
				nextState.then(
					(resolvedNextState: S) => {
						if (signal.aborted) return;
						setState(resolvedNextState);
					},
					(asyncError: Error) => {
						if (asyncError.name !== 'AbortError') {
							if (signal.aborted) return;
							setError(asyncError);
						}
					},
				);
			} else {
				setState(nextState);
			}
		},
		[signal],
	);

	React.useEffect(() => {
		if (currentState.onEnter) {
			const nextState = currentState.onEnter();
			if (nextState) {
				transition(nextState as Promise<S>);
			}
		}

		// React guarantees that the previous effect will be cleaned up (by
		// calling the previous state's onExit(), if any) before executing the
		// next effect (by calling the next state's onEnter(), if any).
		return () => {
			if (currentState.onExit) {
				currentState.onExit();
			}
		};
	}, [currentState, transition]);

	if (error) {
		throw error;
	}

	return [currentState, transition];
}
