// @flow

import omit from 'lodash.omit';
import Slider from 'rc-slider';
import React from 'react';
import type { ComponentType as ReactComponentType } from 'react';

import { charcoalGray } from '../../colors';
import type { Filters } from '../behavior';

import './minimum-filter.scss';
import type { DisplayProps } from './description';
import SearchFilter from './filter';
import type { FilterComponentProps } from './filters';

type Position = number;
type Text = string;
type Value = number | null;

export type MinimumDisplayProps = {
	onRemove: () => void,
	punctuator: string,
	resultCount: ?number,
	value: Value,
};

const defaultConvertPositionToValue = (position: Position): Value =>
	position === 0 ? null : position;

const defaultConvertValueToPosition = (value: Value): Position =>
	value == null ? 0 : value;

const defaultConvertValueToText = (value: Value): Text =>
	String(value == null ? 0 : value);

type Options = {
	convertPositionToValue?: (position: Position) => Value,
	convertValueToPosition?: (value: Value) => Position,
	convertValueToText?: (value: Value) => Text,
	max: number,
	min?: number,
};

type State = {
	position: Position,
};

export default (
	label: string,
	field: string,
	Display: ReactComponentType<MinimumDisplayProps>,
	options: Options,
	componentIdentifier: string,
): ReactComponentType<FilterComponentProps> => {
	const convertPositionToValue =
		typeof options.convertPositionToValue === 'function'
			? options.convertPositionToValue
			: defaultConvertPositionToValue;
	const convertValueToPosition =
		typeof options.convertValueToPosition === 'function'
			? options.convertValueToPosition
			: defaultConvertValueToPosition;
	const convertValueToText =
		typeof options.convertValueToText === 'function'
			? options.convertValueToText
			: defaultConvertValueToText;

	const convertPositionToText = (position: Position): Text =>
		convertValueToText(convertPositionToValue(position));

	if (typeof options.max !== 'number') {
		throw new Error('`options.max` must be a number.');
	}

	const maxPosition =
		typeof options.max === 'number'
			? convertValueToPosition(options.max)
			: 0;

	if (typeof options.min !== 'undefined' && typeof options.min !== 'number') {
		throw new Error('`options.min` must be a number.');
	}

	const minPosition =
		typeof options.min === 'number'
			? convertValueToPosition(options.min)
			: 0;

	const selectFilter = (filters: Filters): Value =>
		filters[field] == null ? null : filters[field][0];

	const updateFilters = (filters: Filters, value: Value): Filters =>
		value === null
			? omit(filters, field)
			: {
					...filters,
					[field]: [value, null],
			  };

	class MinimumSearchFilter extends React.Component<
		FilterComponentProps,
		State,
	> {
		static get displayComponent(): ReactComponentType<DisplayProps> {
			return MinimumSearchFilterDisplay;
		}

		static get field(): string {
			return field;
		}

		state: State = {
			position: convertValueToPosition(selectFilter(this.props.filters)),
		};

		UNSAFE_componentWillReceiveProps(newProps: FilterComponentProps): void {
			this.setState({
				position: convertValueToPosition(
					selectFilter(newProps.filters),
				),
			});
		}

		handleAfterChange = (position: Position): void => {
			const oldValue = selectFilter(this.props.filters);
			const newValue = convertPositionToValue(position);

			if (oldValue !== newValue) {
				this.props.onChange(
					updateFilters(this.props.filters, newValue),
					componentIdentifier,
				);
			}
		};

		handleChange = (position: Position): void => {
			this.setState({
				position,
			});
		};

		render() {
			return (
				<SearchFilter label={label}>
					<div className="MinimumSearchFilter-content">
						<div className="MinimumSearchFilter-label">
							{convertPositionToText(this.state.position)}
						</div>
						<Slider
							handleStyle={{
								borderColor: charcoalGray.string(),
							}}
							max={maxPosition}
							min={minPosition}
							onAfterChange={this.handleAfterChange}
							onChange={this.handleChange}
							railStyle={{
								height: '2px',
								marginTop: '1px',
							}}
							trackStyle={{
								backgroundColor: charcoalGray.string(),
								height: '2px',
								marginTop: '1px',
							}}
							value={this.state.position}
						/>
					</div>
				</SearchFilter>
			);
		}
	}

	class MinimumSearchFilterDisplay extends React.Component<DisplayProps> {
		static defaultProps = {
			punctuator: '',
		};

		static get key(): string {
			return field;
		}

		static countValues(filters: Filters): number {
			return selectFilter(filters) !== null ? 1 : 0;
		}

		handleRemove = (): void => {
			this.props.onChange(
				updateFilters(this.props.filters, null),
				componentIdentifier,
			);
		};

		render() {
			return (
				<Display
					onRemove={this.handleRemove}
					punctuator={this.props.punctuator}
					resultCount={this.props.resultCount}
					value={selectFilter(this.props.filters)}
				/>
			);
		}
	}

	return MinimumSearchFilter;
};
