import type { ColumnDescriptor } from './column';

export const appDefinedColumns: Array<ColumnDescriptor> = [
	{
		name: 'Last Contact',
		type: 'date_since',
		field: 'last_contact_with_drive',
	},
	{
		name: 'Location',
		type: 'locations',
		field: 'main_locations_list',
	},
	{
		name: 'Employees',
		type: 'employees',
		field: 'employees',
	},
	{
		name: 'Employee Growth',
		type: 'employee_growth',
		field: 'employee_counts',
	},
	{
		name: 'Funding',
		type: 'funding',
		field: 'funding',
	},
	{
		name: 'Notes',
		type: 'notes',
		field: 'notes',
	},
	{
		name: 'Email Count',
		type: 'email_count',
		field: 'num_total_emails',
	},
	{
		name: 'Email',
		type: 'email_address',
		field: 'emails',
	},
	{
		name: 'Job',
		type: 'current_job',
		field: 'current_job',
	},
	{
		name: 'Roles',
		type: 'roles',
		field: 'roles',
	},
];
