import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DocumentTitle from 'react-document-title';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import styled from 'styled-components';

import * as deprecatedColors from '../colors';
import Body from '../components/body';
import { BackIcon } from '../components/icons';
import Redirect from '../components/redirect';
import Resizer from '../components/resizer';
import { Navigation, NavigationTab, Sidebar } from '../components/sidebar';
import PipelineComponent from '../pipelines';
import { colors } from '../theme';
import useGet from '../utils/hooks/use-get';
import useLocalStorage from '../utils/hooks/use-local-storage';

import CandidatePool from './candidate-pool';
import { MAGIC_CANDIDATE_STATUSES } from './fact-table';
import Details from './job-order-details';
import PipelineProfileCardExpanded from './pipeline-profile-card-expanded';
import type { JobOrder } from './types';

export function stageChipColors(latestStage: string) {
	const arr = Object.entries(MAGIC_CANDIDATE_STATUSES).find(
		([, { stageTitles }]) =>
			(stageTitles as ReadonlyArray<string>).includes(latestStage),
	);

	if (!arr) {
		return {
			backgroundColor: colors.data.dust.layerSubtle,
			textColor: colors.data.dust.textSecondary,
		};
	}

	return {
		PRESCREEN: {
			backgroundColor: colors.data.dust.layerSubtle,
			textColor: colors.data.dust.textSecondary,
		},
		SUBMISSION: {
			backgroundColor: colors.data.green.layerSubtle,
			textColor: colors.data.green.textSecondary,
		},
		HIRING_MANAGER_SCREEN: {
			backgroundColor: colors.data.orange.layerSubtle,
			textColor: colors.data.orange.textSecondary,
		},
		SECOND_ROUND: {
			backgroundColor: colors.data.green.layerSubtle,
			textColor: colors.data.green.textSecondary,
		},
		TAKE_HOME: {
			backgroundColor: colors.data.pink.layerSubtle,
			textColor: colors.data.pink.textSecondary,
		},
		TECHNICAL: {
			backgroundColor: colors.data.purple.layerSubtle,
			textColor: colors.data.purple.textSecondary,
		},
		ONSITE: {
			backgroundColor: colors.data.indigo.layerSubtle,
			textColor: colors.data.indigo.textSecondary,
		},
		OFFER: {
			backgroundColor: colors.data.blue.layerSubtle,
			textColor: colors.data.blue.textSecondary,
		},
		START: {
			backgroundColor: colors.data.green.layerSubtle,
			textColor: colors.data.green.textSecondary,
		},
		ARCHIVE: {
			backgroundColor: colors.data.red.layerSubtle,
			textColor: colors.data.red.textSecondary,
		},
	}[arr[0] as keyof typeof MAGIC_CANDIDATE_STATUSES];
}

const StyledSidebar = styled(Sidebar)`
	flex: 0 0 auto;
`;

const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledLink = styled(Link)`
	display: flex;
	align-items: center;
	margin-left: 3px;

	& > svg {
		color: ${deprecatedColors.mediumGray.toString()};

		&:hover {
			color: ${deprecatedColors.mediumGray.darken(0.8).toString()};
		}
	}
`;

const StyledTitleContainer = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 5px 10px;
`;

const StyledTopTitle = styled.span`
	grid-area: title;
	color: #333;
	font-size: 1.5em;
	font-weight: normal;
	white-space: wrap;
`;

const StyledTopSubtitle = styled.span`
	grid-area: name;
	font-weight: 400;
	font-size: 1em;
	color: #333;
`;

export default function JobOrderDetail(): JSX.Element {
	const params = useParams<'id'>();
	const jobOrderId = parseInt(params.id || '-1', 10);

	const [jobOrder, Loader] = useGet<JobOrder>(`/job-orders/${jobOrderId}`);

	const [sideBarWidth, setSideBarWidth] = useLocalStorage<number>(
		'JobOrdersSideBarWidth',
		250,
	);

	if (!jobOrder) return <Loader />;
	return (
		<DocumentTitle
			title={`${jobOrder.title} @ ${jobOrder.fused_company.name}`}
		>
			<Body>
				<DndProvider backend={HTML5Backend}>
					<Resizer
						initialWidth={sideBarWidth}
						maxWidth={300}
						minWidth={250}
						onDragEnd={setSideBarWidth}
						resizeDirection="right"
						slideBarColor="#eee"
					>
						<StyledSidebar>
							<StyledHeader>
								<StyledLink to="../.." relative="path">
									<BackIcon width="24" height="24" />
								</StyledLink>
								<StyledTitleContainer>
									<StyledTopTitle>
										{jobOrder.title}
									</StyledTopTitle>
									<StyledTopSubtitle>
										{jobOrder.fused_company.name}
									</StyledTopSubtitle>
								</StyledTitleContainer>
							</StyledHeader>
							<Navigation>
								<NavigationTab to="details">
									Details
								</NavigationTab>
								<NavigationTab to="candidates">
									Candidates
								</NavigationTab>
								<NavigationTab to="pipeline">
									Interview Pipeline
								</NavigationTab>
							</Navigation>
						</StyledSidebar>
					</Resizer>
				</DndProvider>
				<Routes>
					<Route
						path="candidates"
						element={
							<CandidatePool
								id={jobOrder.candidate_pool_id}
								jobOrderId={jobOrder.id}
								jobOrderPipelineId={jobOrder.pipeline_id}
							/>
						}
					/>
					<Route
						path="details"
						element={<Details jobOrder={jobOrder} />}
					/>
					<Route
						path="pipeline"
						element={
							<PipelineComponent
								id={jobOrder.pipeline_id}
								ExpandedCard={PipelineProfileCardExpanded}
								mapStageTitleToColors={stageChipColors}
							/>
						}
					/>
					<Route
						path="*"
						element={
							<Redirect
								to="pipeline"
								preserveHash
								preserveSearch
								replace
							/>
						}
					/>
				</Routes>
			</Body>
		</DocumentTitle>
	);
}
