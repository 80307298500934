import moment from 'moment';
import React from 'react';
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import * as colors from '../colors';
import { mixpanelBookmarkIdSessionReport } from '../config';

import { ResponsiveChart } from './components';
import useMixpanelInsights from './use-mixpanel-insights';

interface SessionDurationResponse {
	series: {
		'Session End [Average Session Duration (Seconds)]': Record<
			string,
			number
		>;
	};
}

export default function HerbieSessionDuration(): JSX.Element {
	const [mixpanelData, Loader] = useMixpanelInsights<SessionDurationResponse>(
		mixpanelBookmarkIdSessionReport,
	);

	if (!mixpanelData) return <Loader />;

	const data = Object.entries(
		mixpanelData.series['Session End [Average Session Duration (Seconds)]'],
	).map(([date, averageDuration]) => ({
		date: moment(date).format('YYYY-MM-DD'),
		averageDuration: Math.round(averageDuration / 60),
	}));

	return (
		<ResponsiveChart>
			<LineChart
				data={data}
				margin={{ top: -15, right: 5, left: -20, bottom: 0 }}
			>
				<Tooltip />
				<CartesianGrid vertical={false} strokeDasharray="4" />
				<XAxis dataKey="date" />
				<YAxis />
				<Line
					dataKey="averageDuration"
					name="Session Duration (min)"
					stroke={colors.primaryDriveBlue.string()}
					strokeWidth={1.5}
					dot={false}
				/>
				<Legend verticalAlign="top" height={28} align="right" />
			</LineChart>
		</ResponsiveChart>
	);
}
