// @flow

import React from 'react';

import DescriptionValue from './description-value';

type Props = {
	onClear: () => void,
};

export default class ClearAllFiltersButton extends React.Component<Props> {
	handleRemove = () => {
		this.props.onClear();
	};

	render() {
		return (
			<DescriptionValue onRemove={this.handleRemove}>
				Clear all
			</DescriptionValue>
		);
	}
}
