import numeral from 'numeral';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import DiscreteBarChart from '../charts/discrete-bar-chart';
import GenericChart from '../charts/generic-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/portfolio_gm_percentage_by_company?fund=1';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'GM %',
		values: dataArr.map((company) => ({
			label: company.semi_annuals__fused_company__name,
			value: company.gm_percentage,
		})),
	},
];

export default class SemiAnnualFund1PortfolioGMPercentageByCompany extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund1PortfolioGMPercentageByCompanyDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 75 }}
				onDataFetch={this.handleDataFetch}
				title="2021 Portfolio GM % (Projected)"
				xAxisLabel="Company"
				yAxisLabel="GM %"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund1PortfolioGMPercentageByCompanyDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/portfolio_gm_by_company.png"
				statName="Fund I Portfolio GM % by Company"
			/>
		);
	}
}
