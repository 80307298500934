export interface ColumnDescriptor {
	editable?: boolean;
	field: string;
	fixed?: boolean;
	key?: string;
	name: string;
	type: string;
}

export function getColumnDescriptorKey(descriptor: ColumnDescriptor): string {
	return typeof descriptor.key === 'string'
		? descriptor.key
		: descriptor.field;
}
