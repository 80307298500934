import { type Sorting } from '../types';

const DISPLAY_NAMES: Record<Sorting['fieldName'], string> = {
	description: 'Description',
	employeeGrowth: 'Employee Growth',
	employeeCount: 'Employee Headcount',
	foundedYear: 'Founded Year',
	lastContact: 'Last Contacted',
	lastRaised: 'Last Raised',
	name: 'Name',
	searchQueryStrength: 'Search Query Strength',
	totalFunding: 'Total Funding',
};

export default DISPLAY_NAMES;
