import assertExhaustive from '../utils/assert-exhaustive';

import {
	AccessGrantLevel,
	PersonProfileTransitionNote,
	Pipeline,
	PipelineStageItem,
	PipelineStageTransition,
	PipelineStageTransitionDatum,
	StageTransitionDataRule,
} from './types';

export interface API_Pipeline {
	access_grant_level: AccessGrantLevel;
	id: number;
	items?: Array<API_PipelineStageItem>;
	stages: Array<API_PipelineStage>;
}

interface API_PipelineStage {
	id: number;
	rules: Array<
		| {
				id: number;
				optional: boolean;
				sort_key: number;
				title: string;
				type: 'DATE';
				value: string;
		  }
		| {
				id: number;
				optional: boolean;
				sort_key: number;
				title: string;
				type: 'NUMERIC';
				value: number;
		  }
		| {
				extra: string | null;
				id: number;
				optional: boolean;
				sort_key: number;
				options: Array<{
					label: string;
					value: string;
					require_extra: boolean;
				}>;
				title: string;
				type: 'MULTI_VALUE';
				value: string;
		  }
		| {
				id: number;
				optional: boolean;
				sort_key: number;
				title: string;
				type: 'TEXT';
				value: string;
		  }
	>;
	sort_key: number;
	title: string;
}

export interface API_PipelineStageItem {
	current_location: string | null;
	currently_moving_stages?: boolean;
	id: number;
	most_recent_stage_id: number;
	photo_url: string;
	priority: '' | 'LOW' | 'MEDIUM' | 'HIGH';
	profile_id: number;
	stage_transitions: Array<API_PipelineStageTransition>;
	sub_title: string;
	title: string;
}

export interface API_PipelineStageTransition {
	created_at: string;
	created_by: {
		first_name: string;
		last_name: string;
		photo_url: string;
		username: string;
	};
	destination_stage: {
		id: number;
		title: string;
	};
	id: number;
	notes: Array<PersonProfileTransitionNote>;
	profile: number;
	source_stage: {
		title: string;
	} | null;
	transition_data: Array<API_PipelineStageTransitionDatum>;
}

export type API_PipelineStageTransitionDatum =
	| {
			id: number;
			person_profile_transition: number;
			requirement: number;
			title: string;
			type: 'DATE';
			value: string;
	  }
	| {
			id: number;
			person_profile_transition: number;
			requirement: number;
			title: string;
			type: 'NUMERIC';
			value: number;
	  }
	| {
			extra: string | null;
			label: string;
			id: number;
			person_profile_transition: number;
			requirement: number;
			title: string;
			type: 'MULTI_VALUE';
			value: string;
	  }
	| {
			id: number;
			person_profile_transition: number;
			requirement: number;
			title: string;
			type: 'TEXT';
			value: string;
	  };

// eslint-disable-next-line consistent-return
export function transformPipelineTransitionDatumFromApi(
	datum: API_PipelineStageTransitionDatum,
): PipelineStageTransitionDatum {
	switch (datum.type) {
		case 'DATE': {
			return {
				id: datum.id,
				ruleId: datum.requirement,
				title: datum.title,
				type: datum.type,
				value: datum.value,
			};
		}
		case 'NUMERIC': {
			return {
				id: datum.id,
				ruleId: datum.requirement,
				title: datum.title,
				type: datum.type,
				value: datum.value,
			};
		}
		case 'TEXT': {
			return {
				id: datum.id,
				ruleId: datum.requirement,
				title: datum.title,
				type: datum.type,
				value: datum.value,
			};
		}
		case 'MULTI_VALUE': {
			return {
				extra: datum.extra,
				label: datum.label,
				id: datum.id,
				ruleId: datum.requirement,
				title: datum.title,
				type: datum.type,
				value: datum.value,
			};
		}
		default:
			assertExhaustive(datum);
	}
}

export function transformPipelineStageTransitionFromApi({
	created_at,
	created_by,
	destination_stage,
	id,
	notes,
	profile,
	source_stage,
	transition_data,
}: API_PipelineStageTransition): PipelineStageTransition {
	return {
		createdAt: created_at,
		createdBy: {
			firstName: created_by.first_name,
			lastName: created_by.last_name,
			photoUrl: created_by.photo_url,
			username: created_by.username,
		},
		destinationStage: {
			id: destination_stage.id,
			title: destination_stage.title,
		},
		id,
		itemId: profile,
		notes,
		sourceStageTitle: source_stage?.title || null,
		transitionData: transition_data.map(
			transformPipelineTransitionDatumFromApi,
		),
	};
}

export function transformPipelineItemFromApi({
	current_location,
	currently_moving_stages,
	most_recent_stage_id,
	photo_url,
	profile_id,
	stage_transitions,
	sub_title,
	...item
}: API_PipelineStageItem): PipelineStageItem {
	return {
		...item,
		currentStageId: most_recent_stage_id,
		currentlyMovingStages: currently_moving_stages,
		location: current_location,
		photoUrl: photo_url,
		priority: item.priority,
		profileId: profile_id,
		stageTransitions: stage_transitions.map(
			transformPipelineStageTransitionFromApi,
		),
		subTitle: sub_title,
	};
}

export default function transformPipelineFromApi(
	apiPipeline: API_Pipeline,
): Pipeline {
	return {
		accessGrantLevel: apiPipeline.access_grant_level,
		editable:
			apiPipeline.access_grant_level === 'OWNER'
			|| apiPipeline.access_grant_level === 'EDITOR',
		id: apiPipeline.id,
		items: (apiPipeline.items || []).map(transformPipelineItemFromApi),
		stages: apiPipeline.stages.map(({ sort_key, rules, ...stage }) => ({
			...stage,
			sortKey: sort_key,
			rules: rules.map(
				// eslint-disable-next-line consistent-return, array-callback-return
				(rule): StageTransitionDataRule => {
					switch (rule.type) {
						case 'DATE': {
							return {
								id: rule.id,
								optional: rule.optional,
								sortKey: rule.sort_key,
								title: rule.title,
								type: rule.type,
							};
						}
						case 'NUMERIC': {
							return {
								id: rule.id,
								optional: rule.optional,
								sortKey: rule.sort_key,
								title: rule.title,
								type: rule.type,
							};
						}
						case 'TEXT': {
							return {
								id: rule.id,
								optional: rule.optional,
								sortKey: rule.sort_key,
								title: rule.title,
								type: rule.type,
							};
						}
						case 'MULTI_VALUE': {
							return {
								id: rule.id,
								optional: rule.optional,
								options: rule.options.map(
									({ label, value, require_extra }) => ({
										label,
										value,
										requireExtra: require_extra,
									}),
								),
								sortKey: rule.sort_key,
								title: rule.title,
								type: rule.type,
							};
						}
						default:
							assertExhaustive(rule);
					}
				},
			),
		})),
	};
}
