import { ActionTypes } from '../constants';
import { ONEPAGER_LOAD_SUCCESS } from '../one-pagers';
import createReducer from '../utils/createReducer';
import dateFieldParser from '../utils/date-field-parser';

const parseLastContact = dateFieldParser('last_contact_with_drive');

const addFromWatchListCompanies = (state, entities) => {
	if (entities.length === 0) return state;
	const nextState = { ...state };

	for (const entity of entities) {
		if (entity.type === 'companies') {
			nextState[entity.id] = {
				...nextState[entity.id],
				...parseLastContact(entity),
			};
			if (Array.isArray(entity.notes)) {
				nextState[entity.id].notes = entity.notes.map(({ id }) => id);
			}
		}
	}

	return nextState;
};

export default createReducer(
	{},
	{
		[ActionTypes.ADD_COMPANY_FUNNEL_ACTION]: (state, action) => ({
			...state,
			[action.payload.company_id]: {
				...state[action.payload.company_id],
				funnel_actions: (
					state[action.payload.company_id].funnel_actions || []
				).concat(action.payload),
			},
		}),
		[ActionTypes.COMPANYPROFILE_SUCCESS]: (state, action) => {
			const nextState = {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					...parseLastContact(action.payload),
				},
			};

			return nextState;
		},
		[ActionTypes.DELETE_LP_DATA]: (state, { payload: { companyId } }) => {
			const nextState = { ...state };
			nextState[companyId] = {
				...nextState[companyId],
				lp_fundraising_data: null,
			};
			return nextState;
		},
		[ActionTypes.EDIT_LP_DATA]: (
			state,
			{ payload: { companyId, lpData } },
		) => {
			const nextState = { ...state };
			nextState[companyId] = {
				...(nextState[companyId] || {}),
				...lpData.lp,
				lp_fundraising_data: lpData,
			};
			return nextState;
		},
		[ActionTypes.LOAD]: (state, { payload }) => {
			if (payload.type === 'companies') {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						...parseLastContact(payload),
					},
				};
			}

			return state;
		},
		[ActionTypes.LOAD_COMPANIES]: (state, { payload: companies }) => {
			const nextState = { ...state };

			companies.forEach((company) => {
				nextState[company.id] = {
					...nextState[company.id],
					...parseLastContact(company),
					notes: company.notes && company.notes.map(({ id }) => id),
				};
			});

			return nextState;
		},
		[ActionTypes.MERGE_SUCCESS]: (state, action) => {
			if (action.payload.merged.type === 'companies') {
				return {
					...state,
					[action.payload.merged.id]: {
						...state[action.payload.merged.id],
						...action.payload.merged.data,
					},
				};
			}

			return state;
		},
		[ActionTypes.NOTES_ADD_SUCCESS]: (state, { payload }) => {
			if (payload.type === 'companies') {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						notes: payload.notes.map(({ id }) => id),
					},
				};
			}
			return state;
		},
		[ActionTypes.NOTES_DELETE_SUCCESS]: (state, { payload }) => {
			for (const id of Object.keys(state)) {
				if (
					state[id].notes
					&& state[id].notes.some((noteId) => noteId === payload.id)
				) {
					return {
						...state,
						[id]: {
							...state[id],
							notes: state[id].notes.filter(
								(noteId) => noteId !== payload.id,
							),
						},
					};
				}
			}
			return state;
		},
		[ActionTypes.NOTES_LOAD_SUCCESS]: (state, { payload }) => {
			if (payload.type === 'companies' && !!payload.id) {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						notes: payload.notes.map(({ id }) => id),
					},
				};
			} else {
				const nextState = { ...state };

				payload.notes
					.filter(
						({ target }) => target && target.type === 'companies',
					)
					.forEach((note) => {
						const entityId = note.target.id;

						nextState[entityId] = {
							...state[entityId],
							...note.target,
							notes: [
								...((nextState[entityId]
									&& nextState[entityId].notes)
									|| []),
								note.id,
							],
						};
					});

				return nextState;
			}
		},
		[ONEPAGER_LOAD_SUCCESS]: (state, { payload }) => {
			const newState = { ...state };
			const companyId = payload.fused_company.id;
			newState[companyId] = {
				...(newState[companyId] || {}),
				...payload.fused_company,
			};
			return newState;
		},
		[ActionTypes.PARCHMENT_DELETE]: (state, { payload }) => {
			for (const id of Object.keys(state)) {
				if (
					state[id].parchments
					&& state[id].parchments.some(
						(parchmentId) => parchmentId === payload.id,
					)
				) {
					return {
						...state,
						[id]: {
							...state[id],
							parchments: state[id].parchments.filter(
								(parchmentId) => parchmentId !== payload.id,
							),
						},
					};
				}
			}
			return state;
		},
		[ActionTypes.SEARCH_SUCCESS]: (state, action) => {
			const newState = { ...state };

			action.payload.hits
				.map((hit) => hit.data)
				.filter((hit) => hit.type === 'companies')
				.forEach((hit) => {
					newState[hit.id] = {
						...state[hit.id],
						...parseLastContact(hit),
					};
				});

			return newState;
		},
		[ActionTypes.WATCHLIST_ADD_ITEM_SUCCESS]: (
			state,
			{ payload: { item } },
		) => addFromWatchListCompanies(state, [item.entity]),
		[ActionTypes.WATCHLIST_LOAD_SUCCESS]: (state, { payload: { items } }) =>
			addFromWatchListCompanies(
				state,
				items
					.filter(({ entity }) => entity.type === 'companies')
					.map(({ entity }) => entity),
			),
		[ActionTypes.FUNDTRACKER_LOAD_SUCCESS]: (
			state,
			{ payload: { items } },
		) => {
			const nextState = { ...state };

			items
				.filter(({ entity }) => entity.type === 'companies')
				.forEach(({ entity }) => {
					nextState[entity.id] = {
						...nextState[entity.id],
						...parseLastContact(entity),
					};
				});

			return nextState;
		},
	},
);
