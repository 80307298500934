import d3 from 'd3';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import GenericChart from '../charts/generic-chart';
import MultiBarChart from '../charts/multi-bar-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/total_portfolio_headcount?fund=1';
let data = null;

const yAxisTickFormat = () => d3.format(',');

export default class SemiAnnualFund1PortfolioHeadcount extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund1PortfolioHeadcountDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showControls: false,
			stacked: false,
			stackOffset: 'expand',
			staggerLabels: true,
			reduceXTicks: true,
		};

		// I have no idea why yAxisTickFormat needs to be called, but the y axis shows source code otherwise...
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="Semi Annuals Portfolio Headcount Analysis"
				xAxisLabel="Year"
				yAxisLabel="Total Portfolio Headcount"
				yAxisTickFormat={yAxisTickFormat()}
			>
				{MultiBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund1PortfolioHeadcountDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/semi_annual_headcount.png"
				statName="Fund I Yearly Portfolio Headcount Analysis"
			/>
		);
	}
}
