import { Temporal } from '@js-temporal/polyfill';

function parsePlainDate(dateLike: string): Temporal.PlainDate {
	return Temporal.PlainDate.from(dateLike);
}

function parseOptionalPlainDate(
	dateLike: string | null,
): Temporal.PlainDate | null {
	if (dateLike === null) return null;
	return parsePlainDate(dateLike);
}

export type FactTableTuple = [
	company: number,
	name: string,
	surfaced: string,
	score: number,
	assigned: string | null,
	reachedOut: string | null,
	via: string | null,
	replied: string | null,
	firstMeeting: string | null,
	secondMeeting: string | null,
	onePager: string | null,
	ignored: string | null,
	snoozed: string | null,
	passed: string | null,
	nextSurfaced: string | null,
	source: string | null,
	experimentName: string | null,
	experimentLabel: string | null,
];

export default class FactTableRow {
	public readonly company: number;
	public readonly name: string;
	public readonly surfaced: Temporal.PlainDate;
	public readonly score: number;
	public readonly assigned: Temporal.PlainDate | null;
	public readonly reachedOut: Temporal.PlainDate | null;
	public readonly via: string | null;
	public readonly replied: Temporal.PlainDate | null;
	public readonly firstMeeting: Temporal.PlainDate | null;
	public readonly secondMeeting: Temporal.PlainDate | null;
	public readonly onePager: Temporal.PlainDate | null;
	public readonly ignored: Temporal.PlainDate | null;
	public readonly snoozed: Temporal.PlainDate | null;
	public readonly passed: Temporal.PlainDate | null;
	public readonly nextSurfaced: Temporal.PlainDate | null;
	public readonly source: string | null;
	public readonly experimentName: string | null;
	public readonly experimentLabel: string | null;

	public constructor([
		company,
		name,
		surfaced,
		score,
		assigned,
		reachedOut,
		via,
		replied,
		firstMeeting,
		secondMeeting,
		onePager,
		ignored,
		snoozed,
		passed,
		nextSurfaced,
		source,
		experimentName,
		experimentLabel,
	]: FactTableTuple) {
		this.company = company;
		this.name = name;
		this.surfaced = parsePlainDate(surfaced);
		this.score = score;
		this.assigned = parseOptionalPlainDate(assigned);
		this.reachedOut = parseOptionalPlainDate(reachedOut);
		this.via = via;
		this.replied = parseOptionalPlainDate(replied);
		this.firstMeeting = parseOptionalPlainDate(firstMeeting);
		this.secondMeeting = parseOptionalPlainDate(secondMeeting);
		this.onePager = parseOptionalPlainDate(onePager);
		this.ignored = parseOptionalPlainDate(ignored);
		this.snoozed = parseOptionalPlainDate(snoozed);
		this.passed = parseOptionalPlainDate(passed);
		this.nextSurfaced = parseOptionalPlainDate(nextSurfaced);
		this.source = source;
		this.experimentName = experimentName;
		this.experimentLabel = experimentLabel;
	}
}

export type Stage = Exclude<
	{
		[K in keyof FactTableRow]: FactTableRow[K] extends Temporal.PlainDate | null
			? K
			: never;
	}[keyof FactTableRow],
	'surfaced'
>;
