import { Temporal } from '@js-temporal/polyfill';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import type {
	PipelineStageItem,
	PipelineStageTransitionDatum,
	StageTransitionDataRule,
} from '../pipelines';
import { ReadOnlyPriorityChip } from '../pipelines';
import { EditablePriorityChip } from '../profiles/sections/fast-track/priority-chip';
import { colors, effects, fonts } from '../theme';
import IconButton from '../theme/components/icon-button';
import { XIcon } from '../theme/icons/system';
import { MapPinIcon } from '../theme/icons/travel';
import useAnalytics from '../utils/hooks/use-analytics';

import { MAGIC_CANDIDATE_STATUSES } from './fact-table';
import PipelineProfileCardTransition from './pipeline-profile-card-transition';

const Container = styled.div`
	${fonts.paragraph.paragraph};
	background-color: ${colors.background.wrapper};
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;
`;

const Header = styled.div`
	${effects.shadow.hover};
	${fonts.label.label};
	background-color: ${colors.layer.layer};
	border-bottom: 1px solid ${colors.border.subtle};
	color: ${colors.text.primary};
	display: flex;
	gap: 2px;
	justify-content: space-between;
	padding: 16px;
`;
const HeaderLeft = styled.div`
	display: flex;
	flex: 1;
`;
const Candidate = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;
const Logo = styled.a`
	img {
		border-radius: 50%;
		border: 1px solid ${colors.border.subtle};
		float: left;
		grid-area: logo;
		margin-right: 16px;
		height: 60px;
		object-fit: contain;
		object-position: 50% 50%;
		width: 60px;

		&:hover {
			cursor: pointer;
		}
	}
`;
const NameAndPriority = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
`;
const Title = styled.div`
	${fonts.h5.productive};
	color: ${colors.text.primary};
`;
const SubTitle = styled.div`
	align-items: bottom;
	display: flex;
`;
const Location = styled.div`
	align-items: flex-start;
	color: ${colors.text.secondary};
	display: flex;
	gap: 4px;
`;
const LocationIcon = styled(MapPinIcon)`
	height: 16px;
	width: 16px;
`;

const Feed = styled.div`
	align-content: start;
	display: grid;
	gap: 20px;
	padding: 20px;
	overflow-y: scroll;
`;

export default function PipelineProfileCardExpanded({
	editable,
	item,
	onClose,
	pipelineId,
	stageRules,
}: {
	editable: boolean;
	item: PipelineStageItem;
	onClose: () => void;
	pipelineId: number;
	stageRules: Record<number, Array<StageTransitionDataRule>>;
}): JSX.Element {
	const transitionData = useMemo(
		() =>
			Object.fromEntries(
				item.stageTransitions.map((transition) => [
					transition.id,
					stageRules[transition.destinationStage.id].map((rule) => ({
						rule,
						value:
							transition.transitionData.find(
								({ ruleId }) => ruleId === rule.id,
							)
							// If prescreen transition data hasn't been set,
							// default to the date the transition was created
							|| ((
								MAGIC_CANDIDATE_STATUSES.PRESCREEN
									.stageTitles as ReadonlyArray<string>
							).includes(transition.destinationStage.title)
							&& (
								MAGIC_CANDIDATE_STATUSES.PRESCREEN
									.dateDataNames as ReadonlyArray<string>
							).includes(rule.title)
								? ({
										id: -1,
										ruleId: rule.id,
										title: rule.title,
										type: 'DATE',
										value: Temporal.Instant.from(
											transition.createdAt,
										)
											.toZonedDateTimeISO(
												Temporal.Now.timeZone(),
											)
											.toPlainDate()
											.toString(),
								  } as PipelineStageTransitionDatum)
								: null),
					})),
				]),
			),
		[item, stageRules],
	);

	useAnalytics(
		{
			eventName: 'Expand Pipeline Card',
			componentIdentifier: 'talent-pipeline',
			viewType: 'job-order-detail',
			properties: useMemo(
				() => ({
					pipelinePersonProfileId: item.id,
					pipelineId,
				}),
				[item.id, pipelineId],
			),
		},
		[item.id],
	);

	return (
		<Container>
			<Header>
				<HeaderLeft>
					<Logo
						href={`/people/${item.profileId}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={item.photoUrl}
							alt={`${item.title} profile picture`}
						/>
					</Logo>
					<Candidate>
						<NameAndPriority>
							<Title>{item.title}</Title>
							{editable ? (
								<EditablePriorityChip
									pipelineId={pipelineId}
									pipelineItem={item}
								/>
							) : (
								<ReadOnlyPriorityChip
									priority={item.priority || ''}
								/>
							)}
						</NameAndPriority>
						<SubTitle>{item.subTitle}</SubTitle>
						{item.location && (
							<Location>
								<LocationIcon />
								{item.location}
							</Location>
						)}
					</Candidate>
				</HeaderLeft>
				<IconButton onClick={onClose} size="small" label="Close">
					<XIcon />
				</IconButton>
			</Header>
			<Feed>
				{[...item.stageTransitions]
					.sort((a, b) =>
						Temporal.Instant.compare(b.createdAt, a.createdAt),
					)
					.map((transition) => (
						<PipelineProfileCardTransition
							key={transition.id}
							editable={editable}
							item={item}
							pipelineId={pipelineId}
							transition={transition}
							transitionData={transitionData[transition.id]}
						/>
					))}
			</Feed>
		</Container>
	);
}
