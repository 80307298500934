import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { primaryDriveBlue } from '../colors';

/*
 * On sufficiently wide pages, we want to show the navigation sidebar and, based
 * on the route, the main content. On narrow screens, we want the sidebar
 * content to function as a navigation page, with the main content appearing as
 * a distinct page.
 */
export const Sidebar = styled.aside`
	background-color: #fff;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
	overflow-y: scroll;
	width: 300px;
	/* z-index allows shadow to be cast over right side content */
	z-index: 24;

	@media (max-width: 768px) {
		width: 100%;

		&:not(:last-child) {
			display: none;
		}
	}
`;

export const Navigation = styled.nav``;

export const NavigationTab = styled(NavLink)`
	background-color: #fff;
	border-bottom: 1px solid #eee;
	display: block;
	padding: 16px 24px;
	transition: background-color 0.1s ease;

	&:hover {
		background-color: #f8f8f8;
	}

	&.active {
		background-color: #eee;
		color: ${primaryDriveBlue.string()};
	}
`;

export const Title = styled.h1`
	font-weight: normal;
	padding: 30px 12px 0;
	margin: 0;
`;

export const TopTitle = styled(Title)`
	padding: 0;
	margin-top: 18px;
`;

export const TitleContainer = styled.div`
	align-items: flex-end;
	border-bottom: 1px solid #eee;
	display: flex;
	height: 74px;
	justify-content: space-between;
	padding: 16px 10px;
`;
