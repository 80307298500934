import {
	queryOptions,
	useMutation,
	useSuspenseQuery,
} from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { deleteLPData, editLPData } from '../actions';
import {
	get,
	patch,
	post,
	remove,
	ResponseError,
	updateQueryData,
} from '../utils/api';
import useDelayedMutation from '../utils/hooks/use-delayed-mutation';

import {
	type DrivePointOfContactOption,
	type LPData,
	type LPEmployeeUser,
	type LPUserAccessLevel,
	lpUserAccessLevels,
	type UserConflictErrorData,
} from './types';

const lpFundraisingDataQueryKeys = {
	all: () => ['lp-fundraising'] as const,
	drivePointOfContactOptions: () =>
		[
			...lpFundraisingDataQueryKeys.all(),
			'drivePointOfContactOptions',
		] as const,
	detail: (lpId: number) =>
		[...lpFundraisingDataQueryKeys.all(), lpId] as const,
	employeeUsers: (lpId: number) =>
		[...lpFundraisingDataQueryKeys.detail(lpId), 'employeeUsers'] as const,
	list: () => [...lpFundraisingDataQueryKeys.all(), 'list'] as const,
};

function drivePointOfContactOptionsQueryOptions() {
	return queryOptions({
		queryKey: lpFundraisingDataQueryKeys.drivePointOfContactOptions(),
		queryFn: ({ signal }) =>
			get<ReadonlyArray<DrivePointOfContactOption>>(
				'/lpfundraising/drive-point-of-contact-options',
				{
					signal,
				},
			),
		// The options change roughly once per fundraise, and the table needs
		// its columns to be stable, so cache this for the lifetime of the page.
		staleTime: Infinity,
	});
}

export function useDrivePointOfContactOptionsSuspense() {
	return useSuspenseQuery(drivePointOfContactOptionsQueryOptions());
}

function lpFundraisingDataQueryOptions() {
	return queryOptions({
		queryKey: lpFundraisingDataQueryKeys.list(),
		queryFn: ({ signal }) =>
			get<Array<LPData>>('/lpfundraising', { signal }),
	});
}

export function useLPFundraisingDataSuspense() {
	return useSuspenseQuery(lpFundraisingDataQueryOptions());
}

export function useUpdateLPFundraisingData() {
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: ({ id, ...body }: Partial<LPData> & Pick<LPData, 'id'>) =>
			patch<LPData>(`/lpfundraising/${id}`, { body }),
		onSuccess: (data) => {
			dispatch(editLPData(data.lp.id, data));
			updateQueryData(lpFundraisingDataQueryOptions(), (list) =>
				list.map((item) => (item.id === data.id ? data : item)),
			);
		},
	});
}

export function useDeleteLPFundraisingData() {
	const dispatch = useDispatch();

	return useMutation({
		mutationFn: ({ lpId }: { lpId: number }) =>
			remove(`/lpfundraising/${lpId}`),
		onSuccess: (
			_,
			{ companyId, lpId }: { companyId: number; lpId: number },
		) => {
			dispatch(deleteLPData(companyId));
			updateQueryData(lpFundraisingDataQueryOptions(), (list) =>
				list.filter((item) => item.id !== lpId),
			);
		},
	});
}

export function lpEmployeeUsersQueryOptions(lpId: number) {
	return queryOptions({
		queryKey: lpFundraisingDataQueryKeys.employeeUsers(lpId),
		queryFn: ({ signal }) =>
			get<ReadonlyArray<LPEmployeeUser>>(
				`/lpfundraising/${lpId}/employee-users`,
				{
					signal,
				},
			),
	});
}

export function useLPEmployeeUsersSuspense(lpId: number) {
	return useSuspenseQuery(lpEmployeeUsersQueryOptions(lpId));
}

function updateEmployeeUser(
	list: ReadonlyArray<LPEmployeeUser>,
	employeeUser: LPEmployeeUser,
): ReadonlyArray<LPEmployeeUser> {
	const listWithoutEmployeeUser = list.filter((item) => {
		const matches =
			(item.employee != null
				&& item.employee.id === employeeUser.employee?.id)
			|| (item.user != null && item.user.id === employeeUser.user?.id);
		return !matches;
	});
	return [...listWithoutEmployeeUser, employeeUser];
}

export function useInviteLPEmployeeUser() {
	return useMutation({
		mutationFn: ({
			accessLevel,
			emailAddress,
			firstName,
			fusedPersonId,
			lastName,
			lpId,
		}: {
			accessLevel: Exclude<
				LPUserAccessLevel,
				(typeof lpUserAccessLevels)['none']
			>;
			emailAddress: string;
			firstName: string;
			fusedPersonId?: number;
			lastName: string;
			lpId: number;
		}) =>
			post<LPEmployeeUser>(`/lpfundraising/${lpId}/employee-users/`, {
				body: {
					access_level: accessLevel,
					email_address: emailAddress,
					first_name: firstName,
					fused_person_id: fusedPersonId,
					last_name: lastName,
				},
			}),
		onError: (error: ResponseError<UserConflictErrorData>) => error,
		onSuccess: (employeeUser, { lpId }) => {
			updateQueryData(lpEmployeeUsersQueryOptions(lpId), (list) =>
				updateEmployeeUser(list, employeeUser),
			);
		},
	});
}

export function useUpdateLPEmployeeUser() {
	return useDelayedMutation({
		minimumMilliseconds: 500,
		mutationFn: ({
			accessLevel,
			emailAddress,
			lpId,
			userId,
		}: {
			accessLevel?: LPUserAccessLevel;
			emailAddress?: string;
			lpId: number;
			userId: number;
		}) =>
			patch<LPEmployeeUser>(
				`/lpfundraising/${lpId}/employee-users/${userId}/`,
				{
					body: {
						access_level: accessLevel,
						email_address: emailAddress,
					},
				},
			),
		onError: (error: ResponseError<UserConflictErrorData>) => error,
		onSuccess: (employeeUser, { lpId }) => {
			updateQueryData(lpEmployeeUsersQueryOptions(lpId), (list) =>
				updateEmployeeUser(list, employeeUser),
			);
		},
	});
}

export function useSendLPEmployeeUserInvitation() {
	return useDelayedMutation({
		minimumMilliseconds: 1000,
		mutationFn: ({ lpId, userId }: { lpId: number; userId: number }) =>
			post<LPEmployeeUser>(
				`/lpfundraising/${lpId}/employee-users/${userId}/send-invite/`,
			),
		onSuccess: (employeeUser, { lpId }) => {
			updateQueryData(lpEmployeeUsersQueryOptions(lpId), (list) =>
				updateEmployeeUser(list, employeeUser),
			);
		},
	});
}
