import React from 'react';
import styled from 'styled-components';

import Button from '../../../components/button';
import SnackBar from '../../snack-bar';

import type { ButtonStatus } from './index';

const FailedWrapper = styled.div`
	color: red;
`;

const SuccessWrapper = styled.div`
	color: green;
`;

interface Props {
	disabled: boolean;
	onClick: () => void;
	status: ButtonStatus;
	message: null | string;
}

function Save({ disabled, message, onClick, status }: Props): JSX.Element {
	if (status === 'inProgress') {
		return (
			<Button disabled type="submit" primary onClick={onClick}>
				Saving...
			</Button>
		);
	} else if (status === 'failed') {
		return (
			<FailedWrapper>
				{message && (
					<SnackBar topPosition={100} type="alert" text={message} />
				)}
				<Button
					disabled={disabled}
					type="submit"
					primary
					onClick={onClick}
				>
					Save
				</Button>
			</FailedWrapper>
		);
	} else if (status === 'success') {
		return (
			<SuccessWrapper>
				{message && (
					<SnackBar clearTime={1500} type="success" text={message} />
				)}
				<Button
					disabled={disabled}
					type="submit"
					primary
					onClick={onClick}
				>
					Save
				</Button>
			</SuccessWrapper>
		);
	}
	return (
		<Button disabled={disabled} type="submit" primary onClick={onClick}>
			Save
		</Button>
	);
}

export default Save;
