import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors } from '../../theme';
import { FilterLabel as UnstyledFilterLabel } from '../../theme/components/filters';

import { type Pagination } from './types';

const enabledColors = {
	active: {
		background: colors.layer.selected,
		border: colors.border.interactive,
		color: colors.text.interactive,
	},
	inactive: {
		background: 'transparent',
		border: 'transparent',
		color: colors.text.secondary,
	},
};
const hoverColors = {
	active: {
		background: colors.layer.selectedHover,
		border: colors.border.interactive,
		color: colors.text.interactive,
	},
	inactive: {
		background: colors.layer.hover,
		border: 'transparent',
		color: colors.text.primary,
	},
};

const FilterLabel = styled(UnstyledFilterLabel)`
	align-items: center;
	display: flex;

	& > span {
		flex-direction: column;
		height: 26px;
		justify-content: center;
		min-width: 26px;
		padding-left: 2px;
		padding-right: 2px;

		span {
			text-align: center;
		}
	}
`;

interface Props {
	isActive: boolean;
	isDisabled?: boolean;
	onClick: (pageNumber: number) => void;
	onPrefetch?: (pageNumber: number) => void;
	page: Pagination['page'];
}

export function PaginationControl({
	isActive,
	isDisabled,
	onClick,
	onPrefetch,
	page,
}: Props) {
	const handleAction = useCallback(() => {
		onClick(page);
	}, [onClick, page]);
	const handlePrefetch = useCallback(() => {
		if (isDisabled) return;
		if (onPrefetch) onPrefetch(page);
	}, [isDisabled, onPrefetch, page]);

	return (
		<FilterLabel
			enabledColors={enabledColors}
			hoverColors={hoverColors}
			isActive={isActive}
			isDisabled={isDisabled}
			label={page}
			name="pagination-control"
			onAction={handleAction}
			onMouseEnter={handlePrefetch}
			type="radio"
		/>
	);
}
