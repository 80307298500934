import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

import { ChevronDownIcon, ChevronUpIcon } from '../theme/icons/arrows';

import type { HeaderProps } from './column';
import { sortDirections, toggle as toggleSortDirection } from './sort';
import type { SortDirection } from './sort';

const sortableClassName = 'HerbieTable__header--sortable';
const sortedClassName = 'HerbieTable__header--sorted';
const arrowClassName = 'HerbieTable__header__sort-arrow';
const sortIndexClassName = 'HerbieTable__header--sort-index';

const Arrow = styled.div<{ disabled?: boolean }>`
	align-items: center;
	display: flex;
	opacity: ${({ disabled }) => (disabled ? 0 : 1)};

	> svg {
		height: 14px;
		width: 14px;
	}
`;

const Children = styled.div``;

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`;

export interface Props extends HeaderProps {
	children?: ReactNode;
	className?: string;
	initialSortDirection?: SortDirection;
	onSort: (direction: SortDirection) => void;
	sort: SortDirection | null;
	sortIndex: number | null;
}

export default class SortableHeader extends React.Component<Props> {
	handleSort = (): void => {
		const { initialSortDirection = sortDirections.ascending } = this.props;
		this.props.onSort(
			this.props.sort
				? toggleSortDirection(this.props.sort)
				: initialSortDirection,
		);
	};

	render(): JSX.Element {
		/* eslint-disable @typescript-eslint/no-unused-vars */
		const {
			children,
			className,
			initialSortDirection = sortDirections.ascending,
			onSort,
			sort,
			sortIndex,
			...props
		} = this.props;
		return (
			<th
				{...props}
				onClick={this.handleSort}
				className={classNames(className, sortableClassName, {
					[sortedClassName]: sort,
				})}
			>
				<Container>
					{children != null && <Children>{children}</Children>}
					<Arrow disabled={sort == null} className={arrowClassName}>
						{sort === sortDirections.ascending ? (
							<ChevronUpIcon />
						) : (
							<ChevronDownIcon />
						)}
					</Arrow>
					{sortIndex != null && (
						<span className={sortIndexClassName}>{sortIndex}</span>
					)}
				</Container>
			</th>
		);
	}
}
