import React from 'react';
import type { ReactElement } from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import assertExhaustive from '../../utils/assert-exhaustive';
import useSettableField, {
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from '../../utils/state-machine/settable-field';

const cellClassName = 'HerbieTable__select_cell';

const Cell = styled.td`
	height: 100%;
	min-width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const SavingCell = styled(Cell)`
	background-color: #eee;
`;

const SuccessCell = styled(Cell)`
	background-color: #dfd;
`;

const ErrorCell = styled(Cell)`
	background-color: #a00;
`;

const Label = styled.label`
	display: block;
	height: 40px;
	line-height: 30px;
	min-width: 100%;
	position: relative;
	width: 12ch;
`;

const Select = styled.select`
	appearance: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	color: transparent;
	font-size: inherit;
	height: 40px;
	line-height: inherit;
	padding: 5px 10px;
	width: 100%;

	option {
		color: black;
	}
`;

const Value = styled.span`
	bottom: 0;
	left: 0;
	overflow: hidden;
	padding: 5px 10px;
	pointer-events: none;
	position: absolute;
	right: 0;
	text-overflow: ellipsis;
	top: 0;
	white-space: nowrap;
`;

// We have a custom LabeledSelect component to work around limitations styling
// native `<select>` elements. Specifically, if an `<option>`'s label overflows
// its container, the browser truncates the text. For consistency with other
// column types, we want to use an ellipsis. This component allows us greater
// styling control over the select input.

type LabeledSelectProps = {
	children: ReactElement<'option'>[] | ReactElement<'option'>;
	disabled?: boolean;
	label: string;
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	value: string;
};

const LabeledSelect = ({
	children,
	disabled,
	label,
	onChange,
	value,
	...props
}: LabeledSelectProps) => (
	<Label {...props} title={label}>
		<Value>{label}</Value>
		<Select disabled={disabled} onChange={onChange} value={value}>
			{children}
		</Select>
	</Label>
);

type Options<Data extends string> = Record<Data, string> | Map<Data, string>;

export function getOptionLabel<Data extends string>(
	options: Options<Data>,
	key: Data,
): string {
	if (options instanceof Map) {
		const label = options.get(key);
		if (label == null) {
			return key;
		}
		return label;
	}

	return options[key] ?? key;
}

export interface SelectCellProps<Data extends string> {
	className: string;
	disabledOptions: ReadonlyArray<Data>;
	initialValue: Data;
	onChange: (value: Data, signal: AbortSignal) => Promise<Data>;
	options: Options<Data>;
}

export function SelectCell<Data extends string>({
	disabledOptions,
	initialValue,
	onChange,
	options,
	...props
}: SelectCellProps<Data>) {
	const [currentState, transition] = useSettableField<Data>();

	if (currentState instanceof NormalState) {
		return (
			<Cell {...props}>
				<LabeledSelect
					label={getOptionLabel(options, initialValue)}
					onChange={(event) => {
						transition(
							currentState.onChange(
								event.target.value as Data,
								onChange,
							),
						);
					}}
					value={initialValue}
				>
					{(options instanceof Map
						? [...options.entries()]
						: Object.entries(options)
					).map(([key, label]) => (
						<option
							key={key}
							value={key}
							disabled={disabledOptions.includes(key as Data)}
						>
							{label as string}
						</option>
					))}
				</LabeledSelect>
			</Cell>
		);
	} else if (currentState instanceof SavingState) {
		return (
			<SavingCell {...props}>
				<LabeledSelect
					disabled
					label={getOptionLabel(options, currentState.value)}
					value={currentState.value}
				>
					<option value={currentState.value}>
						{getOptionLabel(options, currentState.value)}
					</option>
				</LabeledSelect>
			</SavingCell>
		);
	} else if (currentState instanceof SuccessState) {
		return (
			<SuccessCell {...props}>
				<LabeledSelect
					disabled
					label={getOptionLabel(options, currentState.value)}
					value={currentState.value}
				>
					<option value={currentState.value}>
						{getOptionLabel(options, currentState.value)}
					</option>
				</LabeledSelect>
			</SuccessCell>
		);
	} else if (currentState instanceof ErrorState) {
		return <ErrorCell {...props}>{currentState.error.message}</ErrorCell>;
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class SelectColumn<T extends object, Data extends string>
	implements IColumn<T>
{
	name: string;
	protected readonly options: Options<Data> | ((row: T) => Options<Data>);
	protected select: (row: T) => Data;
	protected update: null | ((val: Data) => Partial<T>);
	protected cellCanUpdate: null | ((row: T) => boolean);
	private disabledOptions: ReadonlyArray<Data>;
	private _sort: null | ((direction: SortDirection, a: T, b: T) => number);

	constructor({
		name,
		options,
		select,
		update = null,
		cellCanUpdate = null,
		disabledOptions = [],
		sort = null,
	}: {
		name: string;
		options: Options<Data> | ((row: T) => Options<Data>);
		select: (row: T) => Data;
		update?: null | ((val: Data) => Partial<T>);
		cellCanUpdate?: null | ((row: T) => boolean);
		disabledOptions?: ReadonlyArray<Data>;
		sort?: null | ((direction: SortDirection, a: T, b: T) => number);
	}) {
		this.name = name;
		this.options = options;
		this.select = select;
		this.update = update;
		this.cellCanUpdate = cellCanUpdate;
		this.disabledOptions = disabledOptions;
		this._sort = sort;

		this.cell.displayName = 'SelectColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const value = this.select(row);

		const update = this.update;
		const canUpdate =
			update != null
			&& (this.cellCanUpdate == null || this.cellCanUpdate(row));
		const options =
			typeof this.options === 'function'
				? this.options(row)
				: this.options;
		const className = `${props.className || ''} ${cellClassName}`;
		if (deferred || !canUpdate || !onChange) {
			const label = getOptionLabel(options, value);

			return (
				<Cell {...props} className={className}>
					<Label title={label}>
						<Value>{label}</Value>
					</Label>
				</Cell>
			);
		}

		return (
			<SelectCell
				{...props}
				className={className}
				disabledOptions={this.disabledOptions}
				initialValue={this.select(row)}
				onChange={async (newValue: Data, signal: AbortSignal) => {
					const patch = update(newValue);
					const response: T = await onChange(row, patch, signal);
					const updatedValue: Data = this.select(response);
					return updatedValue;
				}}
				options={options}
			/>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				{this.name}
			</SortableHeader>
		);
	}

	search(query: string, row: T): boolean {
		const options =
			typeof this.options === 'function'
				? this.options(row)
				: this.options;
		const value = this.select(row);
		const label = getOptionLabel(options, value);
		return !!label && label.toLowerCase().includes(query);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		if (this._sort) return this._sort(direction, a, b);

		const optionsA =
			typeof this.options === 'function' ? this.options(a) : this.options;
		const optionsB =
			typeof this.options === 'function' ? this.options(b) : this.options;

		const aVal = getOptionLabel(optionsA, this.select(a));
		const bVal = getOptionLabel(optionsB, this.select(b));

		if (aVal === bVal) return 0;
		if (aVal == null || aVal === '') return 1;
		if (bVal == null || bVal === '') return -1;
		return direction === sortDirections.ascending
			? aVal.localeCompare(bVal)
			: bVal.localeCompare(aVal);
	}

	toCSV(row: T): string {
		const options =
			typeof this.options === 'function'
				? this.options(row)
				: this.options;
		const value = getOptionLabel(options, this.select(row));
		return value ? value : '';
	}
}
