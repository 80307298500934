export interface PromiseReturn {
	then: Promise<any>['then'];
	catch: Promise<any>['catch'];
	cancel: () => void;
}

// Copied resolve/reject types, so 'any' is correct
type Resolve = (value?: any) => void;
type Reject = (reason?: any) => void;

export default (promise: Promise<any>): PromiseReturn => {
	let canceled = false;
	let resolve: Resolve;
	let reject: Reject;
	const cancelablePromise = new Promise((res, rej) => {
		resolve = res;
		reject = rej;
	});

	promise.then(
		(value) => {
			if (!canceled) {
				resolve(value);
			}
		},
		(error) => {
			if (!canceled) {
				reject(error);
			}
		},
	);

	return {
		then: cancelablePromise.then.bind(cancelablePromise),
		catch: cancelablePromise.catch.bind(cancelablePromise),
		cancel: () => {
			canceled = true;
		},
	};
};
