import React, { lazy } from 'react';

import { type User } from '../authentication';
import { isDriveTenant } from '../components/Authorized';
import authorizedRoute from '../components/authorized-route';

const loadComponent = () => import('./view');

const View = lazy(loadComponent);

function loader() {
	return loadComponent();
}

const BETA_USER_EMAILS: string[] = [];

export function showNewSearch(user: User): boolean {
	return isDriveTenant(user) || BETA_USER_EMAILS.includes(user.email);
}

export const path = 'new-search';

export const routes = [
	authorizedRoute(
		{
			path,
			element: <View />,
			loader,
		},
		showNewSearch,
	),
];
