// @flow

import omit from 'lodash.omit';
import pluralize from 'pluralize';
import React from 'react';

import './description.scss';
import Authorized, { or } from '../../components/Authorized';
import SentenceList from '../../components/sentence-list';
import { intersection } from '../../utils/sets';
import type { Filters } from '../behavior';

import ClearAllFiltersButton from './clear-all-filters-button';
import DescriptionValue from './description-value';
import { components } from './filters';
import SaveSearchForm from './save-search-form';

export type DisplayProps = {
	filters: Filters,
	onChange: (filters: Filters, componentIdentifier: string) => void,
	punctuator: string,
	resultCount: ?number,
};

type Props = {
	filters: Filters,
	loading: boolean,
	onChange: (filters: Filters, componentIdentifier: string) => void,
	resultCount: ?number,
};

export default class Description extends React.Component<Props> {
	getDescription = () => {
		const description = this.refs.description.innerText;
		return description[0].toUpperCase() + description.slice(1);
	};

	handleClearAll = () => {
		this.props.onChange({}, 'clear-all-filter');
	};

	handleQueryRemove = () => {
		this.props.onChange(
			omit(this.props.filters, ['query']),
			'clear-query-filter',
		);
	};

	handleTagRemove = () => {
		const nextFilters = omit(this.props.filters, ['tags']);
		const typeSpecificFields = new Set(
			components
				.filter(({ types }) => types.size < 3)
				.map(({ Component }) => Component.field),
		);
		const filterFields = new Set(
			Object.keys(nextFilters).filter((field) => field !== 'query'),
		);
		const toRemove = intersection(typeSpecificFields, filterFields);

		// If removing the tag would invalidate any filters, confirm beforehand.
		if (toRemove.size > 0) {
			const message =
				'Removing the search type will clear some filters. Clear them and continue?';

			// eslint-disable-next-line no-alert
			if (!confirm(message)) {
				return;
			}
		}

		this.props.onChange(
			omit(nextFilters, Array.from(toRemove)),
			'clear-tag-filter',
		);
	};

	render() {
		const { filters, loading, resultCount } = this.props;

		if (resultCount === null) return null;

		const { query, tags } = filters;
		const tag = Array.isArray(tags) && tags.length === 1 ? tags[0] : null;
		const tagDisplayCount = tag ? 1 : 0;
		const queryDisplayCount = query && query.length > 0 ? 1 : 0;
		const displayComponents = components
			.map(({ Component }) => Component.displayComponent)
			.filter((Display) => Display.countValues(this.props.filters) > 0);
		const valueCounts = displayComponents.map((Display) =>
			Display.countValues(filters),
		);
		const separator = valueCounts.some((count) => count > 2) ? ';' : ',';
		const showClearAll =
			tagDisplayCount
				+ queryDisplayCount
				+ valueCounts.reduce((a, b) => a + b, 0)
			> 1;

		return (
			<div className="SearchDescription">
				<p>
					{loading ? 'Searching ' : `Found ${resultCount || 0} `}
					<span ref="description">
						{tag === null ? (
							pluralize('result', resultCount)
						) : (
							<DescriptionValue
								onRemove={this.handleTagRemove}
								value={tag}
							>
								{pluralize(tag, resultCount)}
							</DescriptionValue>
						)}
						{query && query.length > 0 && (
							<span>
								{' matching '}
								<DescriptionValue
									onRemove={this.handleQueryRemove}
									value={query}
								>
									"{query}"
								</DescriptionValue>
							</span>
						)}
						{displayComponents.length >= 1 ? (
							<span>
								{' that '}
								<SentenceList
									conjunction="and"
									punctuator="."
									separator={separator}
								>
									{displayComponents.map((Display) => (
										<Display
											filters={filters}
											key={Display.key}
											onChange={this.props.onChange}
											resultCount={resultCount}
										/>
									))}
								</SentenceList>
							</span>
						) : (
							'.'
						)}
					</span>
					{showClearAll && (
						<span>
							{' '}
							<ClearAllFiltersButton
								onClear={this.handleClearAll}
							/>
						</span>
					)}
					<Authorized
						auth={or(
							'contacts.watchlists',
							'contacts.limited_watchlists',
						)}
					>
						<span>
							{' '}
							<SaveSearchForm
								getDescription={this.getDescription}
								filters={filters}
							/>
						</span>
					</Authorized>
				</p>
			</div>
		);
	}
}
