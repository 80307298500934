import d3 from 'd3';
import moment from 'moment';
import React from 'react';

import './industries-founded-yearly-change.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import MultiBarChart from './charts/multi-bar-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/industries/founded/';
let data = null;

const yAxisTickFormat = () => d3.format('%');
const formatData = (responses) => {
	const periods = [5, 4, 3, 2, 1];
	const dates = periods.map((s) =>
		moment().month(0).date(1).subtract(s, 'years').format('YYYY'),
	);

	const periodTotals = periods.map(() => 0),
		industries = {};

	responses.forEach((response, index) => {
		response.forEach((industry) => {
			const name = industry.industries__name;
			const count = industry.count;

			if (!industries[name]) {
				industries[name] = {
					total: 0,
					periods: Array(periods.length).fill(0),
				};
			}

			industries[name].total += count;
			industries[name].periods[index] += count;
			periodTotals[index] += count;
		});
	});

	const topIndustries = Object.keys(industries)
		.sort((a, b) => industries[b].total - industries[a].total)
		.slice(0, 20);

	return dates.slice(1).map((date, period) => ({
		key: date,
		values: topIndustries.map((name) => {
			const lastPeriod = period;
			const thisPeriod = period + 1;

			const industry = industries[name];

			const lastPeriodTotal = periodTotals[lastPeriod];
			const thisPeriodTotal = periodTotals[thisPeriod];

			const lastPeriodCount = industry.periods[lastPeriod];
			const thisPeriodCount = industry.periods[thisPeriod];
			const lastPeriodRatio = lastPeriodTotal
				? lastPeriodCount / lastPeriodTotal
				: 1 / topIndustries.length;
			const thisPeriodRatio = thisPeriodTotal
				? thisPeriodCount / thisPeriodTotal
				: 1 / topIndustries.length;

			const ratio = lastPeriodRatio
				? (thisPeriodRatio - lastPeriodRatio) / lastPeriodRatio
				: 0;

			return {
				label: name,
				value: ratio,
			};
		}),
	}));
};

export default class IndustriesFoundedYearlyChange extends React.Component {
	static getDetailCard() {
		return IndustriesFoundedYearlyChangeDetailCard;
	}

	static getKey() {
		return `${apiUrl}-yearlyChange`;
	}

	fetchData = () => {
		return Promise.all(
			[5, 4, 3, 2, 1].map((str) => api.get(`${apiUrl}?period=${str}`)),
		).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showControls: true,
			staggerLabels: true,
			reduceXTicks: true,
		};

		// I have no idea why yAxisTickFormat needs to be called, but the y axis shows source code otherwise...
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="Industry Founded Yearly Change"
				xAxisLabel="Industry"
				yAxisLabel="Companies Founded"
				yAxisTickFormat={yAxisTickFormat()}
			>
				{MultiBarChart}
			</GenericChart>
		);
	}
}

class IndustriesFoundedYearlyChangeDetailCard extends React.Component {
	static getKey() {
		return `${apiUrl}-yearlyChange`;
	}

	render() {
		return (
			<StatsDetailListItem
				id={`${apiUrl}-yearlyChange`}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/industries_founded_change.png"
				statName="Industry Founded Yearly Change"
			/>
		);
	}
}
