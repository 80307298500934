export const sortDirections = {
	ascending: 'ascending',
	descending: 'descending',
} as const;

export type SortDirection = keyof typeof sortDirections;

export interface Sort {
	readonly direction: SortDirection;
	readonly index: number;
}

export function toggle(direction: SortDirection): SortDirection {
	return direction === sortDirections.ascending
		? sortDirections.descending
		: sortDirections.ascending;
}
