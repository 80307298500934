import type { Middleware } from 'redux';

import type { Action } from './actions';
import { login, logout } from './actions';
import { LOGIN, LOGOUT } from './constants';
import { deleteToken, getUser, setToken } from './sync';

const authenticationMiddleware: Middleware<unknown, Action> =
	() => (next) => (action: Action) => {
		switch (action.type) {
			case LOGIN:
				/*
				 * External callers will pass a JWT string to the login action. This
				 * middleware listens for that token, synchronizes it to
				 * localStorage, extracts the user payload from the token, and swaps
				 * the login action's payload with the user object. This should
				 * probably be separated into two actions, one with the token and
				 * one with the user.
				 */
				if (typeof action.payload === 'string') {
					setToken(action.payload);
					const user = getUser();
					// Business logic says the user _should_ always exist at this
					// point, but let's be sure.
					if (user) {
						return next(login(user));
					}
				}
				// If we already have a user object in the payload, pass the action
				// through unmodified.
				return next(action);
			case LOGOUT:
				deleteToken();
				return next(logout());
			default:
				return next(action);
		}
	};

export default authenticationMiddleware;
