type JSONValue =
	| null
	| boolean
	| number
	| string
	| Array<JSONValue>
	| { [key: string]: JSONValue };

export default function safeJSONParse(json: string): JSONValue | undefined {
	try {
		return JSON.parse(json) as JSONValue;
	} catch (error) {
		return void 0;
	}
}
