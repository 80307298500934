/**
 * @file
 * Time icons from [Figma][1].
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5577&mode=dev
 */
import React from 'react';

export function CalendarIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM11 13V17H6V13H11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ClockArrowLoopRightIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17 8.5V5.88468C19.1128 7.32466 20.5 9.75033 20.5 12.5C20.5 16.9183 16.9183 20.5 12.5 20.5C8.08172 20.5 4.5 16.9183 4.5 12.5C4.5 8.08172 8.08172 4.5 12.5 4.5V2.5C6.97715 2.5 2.5 6.97715 2.5 12.5C2.5 18.0228 6.97715 22.5 12.5 22.5C18.0228 22.5 22.5 18.0228 22.5 12.5C22.5 9.2288 20.9293 6.32446 18.501 4.5H21V2.5L15 2.5V8.5H17Z"
				fill="currentColor"
			/>
			<path d="M17 12H13V7H11V14H17V12Z" fill="currentColor" />
		</svg>
	);
}

export function ClockSnoozeIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g fill="currentColor">
				<path d="M17 12H13V7H11V14H17V12Z" />
				<path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12H20C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12Z" />
				<path d="M16 9H22V7H18.7624L22 4.30198V2H16.0003V4H19.2383L16 6.69857V9Z" />
			</g>
		</svg>
	);
}
