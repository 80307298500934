import { Temporal } from '@js-temporal/polyfill';
import React, { useCallback, useMemo, useState } from 'react';
import { Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import type { TooltipFormatter } from 'recharts';
import styled from 'styled-components';

import useGet from '../../utils/hooks/use-get';
import { ChartContainer, ChartTitle, ResponsiveChart } from '../components';

import DateCohort from './cohorts';
import getColors from './colors';
import FactTableRow from './fact-table-row';
import type { FactTableTuple, Stage } from './fact-table-row';

const CohortChartContainer = styled(ChartContainer)`
	grid-template-columns: 1fr min-content;
	grid-template-areas:
		'title controls'
		'chart chart';
`;

const CohortChartTitle = styled(ChartTitle)`
	grid-area: title;
`;

const CohortResponsiveChart = styled(ResponsiveChart)`
	grid-area: chart;
`;

const durations = [
	['1 week', Temporal.Duration.from({ weeks: 1 })],
	['2 weeks', Temporal.Duration.from({ weeks: 2 })],
	['3 weeks', Temporal.Duration.from({ weeks: 3 })],
	['1 month', Temporal.Duration.from({ months: 1 })],
	['2 months', Temporal.Duration.from({ months: 2 })],
	['3 months', Temporal.Duration.from({ months: 3 })],
	['4 months', Temporal.Duration.from({ months: 4 })],
	['5 months', Temporal.Duration.from({ months: 5 })],
	['6 months', Temporal.Duration.from({ months: 6 })],
] as const;

type DataPoint = {
	// `label` will only be a string, and each cohort will only be a number, but
	// all keys must match the index signature. We have to use the index
	// signature because we can't statically know the cohorts. The Recharts API
	// forces us into this data structure.
	[cohort: string]: string | number;
	label: string | number;
};

// Right now, we're surfacing ~240 companies each quarter, so this represents
// ~1/3 of that. This is a tradeoff between a smaller sample size giving us
// performance data on a cohort sooner versus a larger sample being less subject
// to noise. Empirically, going below 80 started to show noise on the right side
// of incomplete cohorts, but going above 80 didn't shift the curve much.
const MINIMUM_STATISTICALLY_SIGNIFICANT_SAMPLE_SIZE = 80;

// We may eventually put `startDate` in a `useState()` so it's user-
// configurable. For now, keep it as a constant.
const startDate = new Temporal.PlainDate(2021, 10, 1);

let surfaceDateMemo = startDate;
let surfaceDurationMemo = durations[0][1];
let surfaceThresholdMemo = surfaceDateMemo.add(surfaceDurationMemo);
let assignDateMemo = surfaceDateMemo;
let assignDurationMemo = surfaceDurationMemo;
let assignThresholdMemo = surfaceThresholdMemo;
function getMemoizedEventThreshold(
	surfaced: Temporal.PlainDate,
	assigned: Temporal.PlainDate | null,
	duration: Temporal.Duration,
): Temporal.PlainDate {
	if (assigned !== null) {
		if (
			assignDurationMemo !== duration
			|| Temporal.PlainDate.compare(assignDateMemo, assigned) !== 0
		) {
			assignDateMemo = assigned;
			assignDurationMemo = duration;
			assignThresholdMemo = assigned.add(duration);
		}
		return assignThresholdMemo;
	}
	if (
		surfaceDurationMemo !== duration
		|| Temporal.PlainDate.compare(surfaceDateMemo, surfaced) !== 0
	) {
		surfaceDateMemo = surfaced;
		surfaceDurationMemo = duration;
		surfaceThresholdMemo = surfaced.add(duration);
	}
	return surfaceThresholdMemo;
}

export default function HighBeamCohorts(): JSX.Element {
	const [stage, setStage] = useState<Stage>('reachedOut');
	const handleStageChange = useCallback(
		(event: React.ChangeEvent<HTMLSelectElement>) => {
			setStage(event.target.value as Stage);
		},
		[],
	);

	const [response, Loader] = useGet<Array<FactTableTuple>>(
		'/internal_dashboards/high_beam_cohort',
		{
			start_date: startDate.toString(),
		},
	);

	const factTable = useMemo(
		() => response && response.map((row) => new FactTableRow(row)),
		[response],
	);
	const cohorts = useMemo(() => DateCohort.series(startDate), []);
	const data = useMemo(() => {
		if (!factTable) return [];

		const now = Temporal.Now.plainDateISO();
		const groups = DateCohort.group(cohorts, factTable);
		return durations.map(([label, duration]) => {
			const dataPoint: DataPoint = { label };
			for (const [cohort, rows] of groups.entries()) {
				let cohortMatureSampleSize = 0,
					matureSamplesReachedStateWithinDuration = 0;
				for (const row of rows) {
					const threshold = getMemoizedEventThreshold(
						row.surfaced,
						row.assigned,
						duration,
					);
					const rowIsAtLeastDurationAge =
						Temporal.PlainDate.compare(threshold, now) <= 0;
					if (rowIsAtLeastDurationAge) {
						cohortMatureSampleSize++;
						const event = row[stage];
						const eventHappenedBeforeThreshold =
							event !== null
							&& Temporal.PlainDate.compare(event, threshold)
								<= 0;
						if (eventHappenedBeforeThreshold)
							matureSamplesReachedStateWithinDuration++;
					}
				}
				if (
					cohortMatureSampleSize
					>= MINIMUM_STATISTICALLY_SIGNIFICANT_SAMPLE_SIZE
				) {
					dataPoint[cohort.label] =
						matureSamplesReachedStateWithinDuration
						/ cohortMatureSampleSize;
				}
			}
			return dataPoint;
		});
	}, [cohorts, factTable, stage]);

	if (!factTable) {
		return (
			<CohortChartContainer>
				<CohortChartTitle>High Beam Cohorts</CohortChartTitle>
				<Loader style={{ gridArea: 'chart' }} />
			</CohortChartContainer>
		);
	}

	const colors = getColors(cohorts);

	return (
		<CohortChartContainer>
			<CohortChartTitle>High Beam Cohorts</CohortChartTitle>
			<select value={stage} onChange={handleStageChange}>
				<option value="passed">Passed</option>
				<option value="snoozed">Snoozed</option>
				<option value="ignored">Ignored</option>
				<option value="assigned">Assigned</option>
				<option value="reachedOut">Reached Out</option>
				<option value="replied">Replied</option>
				<option value="firstMeeting">First Meeting</option>
				<option value="secondMeeting">Second Meeting</option>
				<option value="onePager">One Pager</option>
			</select>
			<CohortResponsiveChart>
				<LineChart
					data={data}
					margin={{ top: 15, right: 5, left: -20, bottom: 0 }}
				>
					<XAxis dataKey="label" type="category" name="label" />
					<YAxis tickFormatter={formatPercent} />
					<Tooltip
						cursor={{ strokeDasharray: '3 3' }}
						itemSorter={(item) => -item.value}
						formatter={tooltipFormatter}
					/>
					<Legend />
					{cohorts.map((cohort, i) => (
						<Line
							dataKey={cohort.label}
							key={cohort.label}
							stroke={colors[i].toString()}
							strokeWidth={2}
							type="linear"
						/>
					))}
				</LineChart>
			</CohortResponsiveChart>
		</CohortChartContainer>
	);
}

const percentFormatter = new Intl.NumberFormat('en-US', {
	style: 'percent',
	maximumFractionDigits: 0,
});

function formatPercent(value: number): string {
	return percentFormatter.format(value);
}

const tooltipFormatter: TooltipFormatter = (value) => {
	if (typeof value === 'number') {
		return formatPercent(value);
	}
	return value;
};
