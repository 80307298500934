import { useEffect } from 'react';

/**
 * Automatically provides an AbortController for async effects like `fetch`.
 *
 * Follows the semantics of `useEffect` in that the effect may return a cleanup
 * function. The effect callback function should always be wrapped in
 * `useCallback` so that the effect will only re-run when its inputs change.
 */
export default function useAbortableEffect(
	effect: (signal: AbortSignal) => void | (() => void) | Promise<void>,
): void {
	useEffect(() => {
		const abortController = new AbortController();
		const cleanup = effect(abortController.signal);

		return () => {
			// Call `abort` first. That way, the optional cleanup function can
			// assume that the asynchronous effect has already been aborted by
			// the time it's invoked.
			abortController.abort();
			if (typeof cleanup === 'function') {
				cleanup();
			}
		};
	}, [effect]);
}
