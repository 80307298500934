// @flow

import React, { type Node as ReactNode } from 'react';

import './filter.scss';
import { trackEvent } from '../../utils/analytics';

type Props = {
	children?: ReactNode,
	label?: ReactNode,
};

const SearchFilter = ({ children, label }: Props) => (
	<div
		className="SearchFilter"
		onScroll={() =>
			trackEvent(
				'Scroll Search Filters',
				'advanced-search-sidebar',
				'advanced-search',
			)
		}
	>
		{label && <h4 className="SearchFilter-label">{label}</h4>}
		{children}
	</div>
);

export default SearchFilter;
