import { $convertToMarkdownString } from '@lexical/markdown';
import { $getRoot, createEditor, EditorState } from 'lexical';

import TextEditor, {
	editorNodes,
	MARKDOWN_TRANSFORMERS,
	ToolbarToggle,
} from './editor';
import { nodeClassMapping } from './theme';
import useEditorClear from './use-editor-clear';

export { default as TemplatePlugin } from './template-plugin';
export type { EditorState };
export { nodeClassMapping, ToolbarToggle, useEditorClear };
export default TextEditor;

export function getTextContent(editorState: EditorState) {
	return editorState.read(() =>
		$convertToMarkdownString(MARKDOWN_TRANSFORMERS, $getRoot()),
	);
}

export function deserializeEditorState(serializedEditorState: string): string {
	try {
		return getTextContent(
			createEditor({ nodes: editorNodes }).parseEditorState(
				serializedEditorState,
			),
		);
	} catch (e) {
		return '';
	}
}

export function serializeEditorState(editorState: EditorState): string {
	return JSON.stringify(editorState);
}

export function isEditorEmpty(editorState: EditorState) {
	return editorState.read(() => {
		const root = $getRoot();
		const isEmpty =
			(root.getFirstChild()?.getTextContent() || '') === ''
			&& root.getChildrenSize() === 1;

		return isEmpty;
	});
}
