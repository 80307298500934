import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import reportError from '../../utils/sentry';

import {
	type FilterControlHeaderProps,
	type FilterControlProps,
	type IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import UnstyledIntegerCombobox from './integer-combo-box';
import { type EmployeeCountFilter } from './types';

const Container = styled.div`
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;
const IntegerComboBox = styled(UnstyledIntegerCombobox)`
	max-width: calc(50% - 8px);
	width: calc(50% - 8px);
`;

const DEFAULT_OPTIONS = [10, 100, 1000, 10000, 100000, 1000000];
const ALL_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9].flatMap((multiplier) =>
	DEFAULT_OPTIONS.map((value) => value * multiplier),
);

const FIELD_NAME: EmployeeCountFilter['fieldName'] = 'employeeCount';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<EmployeeCountFilter>) {
	const currentMinValue = filter?.value?.lowerBound ?? null;
	const currentMaxValue = filter?.value?.upperBound ?? null;

	const submit = useCallback(
		(lowerBound: number | null, upperBound: number | null) => {
			if (!lowerBound && !upperBound) {
				onClear(FIELD_NAME);
				return;
			}

			onChange({
				fieldName: FIELD_NAME,
				value: {
					lowerBound,
					upperBound,
				},
			});
		},
		[onChange, onClear],
	);

	const handleMinSubmit = useCallback(
		(newMin: number | null) => {
			submit(newMin, currentMaxValue);
		},
		[currentMaxValue, submit],
	);
	const handleMaxSubmit = useCallback(
		(newMax: number | null) => {
			submit(currentMinValue, newMax);
		},
		[currentMinValue, submit],
	);

	return (
		<Container>
			<IntegerComboBox
				allOptions={ALL_OPTIONS}
				defaultOptions={DEFAULT_OPTIONS}
				defaultValue={currentMinValue}
				id="employee-count-filter-min"
				label="Minimum"
				onSubmit={handleMinSubmit}
				placeholder="No Min"
			/>
			<IntegerComboBox
				allOptions={ALL_OPTIONS}
				defaultOptions={DEFAULT_OPTIONS}
				defaultValue={currentMaxValue}
				id="employee-count-filter-max"
				label="Maximum"
				onSubmit={handleMaxSubmit}
				placeholder="No Max"
			/>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<EmployeeCountFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);

	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={filter != null}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Employee Headcount"
		/>
	);
}

const EmployeeCountFilterControl: IFilterControl<EmployeeCountFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (
			filter.value.lowerBound != null
			&& filter.value.upperBound != null
		) {
			return [
				`have between ${filter.value.lowerBound} and ${filter.value.upperBound} employees`,
			];
		} else if (filter.value.lowerBound != null) {
			return [
				`have at least ${filter.value.lowerBound} ${pluralize(
					'employee',
					filter.value.lowerBound,
				)}`,
			];
		} else if (filter.value.upperBound != null) {
			return [
				`have fewer than ${filter.value.upperBound} ${pluralize(
					'employee',
					filter.value.upperBound,
				)}`,
			];
		} else {
			reportError(
				new Error(
					`Cannot build description for EmployeeCountFilter: ${JSON.stringify(
						filter,
					)}`,
				),
			);
			return [];
		}
	},
	fieldName: FIELD_NAME,
	header: Header,
};

export default EmployeeCountFilterControl;
