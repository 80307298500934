// @flow

import React from 'react';
import './checkbox-filter-option.scss';

type Props = {
	checked: boolean,
	label: string,
	onChange: (option: string, checked: boolean) => void,
	value: string,
};

export default class CheckboxSearchFilterOption extends React.Component<Props> {
	handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
		this.props.onChange(this.props.value, event.target.checked);
	};

	render() {
		const { checked, label } = this.props;

		return (
			<label className="CheckboxSearchFilterOption">
				<input
					checked={checked}
					className="CheckboxSearchFilterOption-input"
					onChange={this.handleChange}
					type="checkbox"
				/>
				<span className="CheckboxSearchFilterOption-label">
					{label}
				</span>
			</label>
		);
	}
}
