import numeral from 'numeral';
import React from 'react';

import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import DiscreteBarChart from './charts/discrete-bar-chart';
import GenericChart from './charts/generic-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funds/buckets/';
let data = null;

const formatData = (dataArr) => [
	{
		key: 'Bucket',
		values: dataArr.map((bucket) => ({
			label: `${numeral(bucket.start)
				.format('$0.0a')
				.toUpperCase()} - ${numeral(bucket.end)
				.format('$0.0a')
				.toUpperCase()}`,
			value: bucket.num_funds,
		})),
	},
];

export default class YearlyFundNumbersByBucket extends React.Component {
	static getDetailCard() {
		return YearlyFundNumbersByBucketDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 90 }}
				onDataFetch={this.handleDataFetch}
				title="2016 Funds Raised by Bucket"
				xAxisLabel="Fund"
				yAxisLabel="Funds Raised"
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class YearlyFundNumbersByBucketDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/funds-by-bucket.png"
				statName="2016 Funds By Bucket"
			/>
		);
	}
}
