import React from 'react';
import loadable from 'react-loadable';

import { or } from '../components/Authorized';
import authorizedRoute from '../components/authorized-route';
import HerbieLoader from '../components/herbie-loader';

const View = loadable({
	loader: () => import('./view'),
	loading: HerbieLoader,
});

export const routes = authorizedRoute(
	{
		path: 'watchlists',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	or('contacts.watchlists', 'contacts.limited_watchlists'),
);
