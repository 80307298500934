/**
 * @file
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2036%3A24529&mode=dev
 */
import React from 'react';
import styled, { css } from 'styled-components';

import { colors, effects, fonts } from '..';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary' | 'ghost' | 'danger';
}

export const border = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorder}`,
	ghost: 'none',
	danger: 'none',
} as const;

export const borderHover = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorderHover}`,
	ghost: 'none',
	danger: 'none',
} as const;

export const borderActive = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorderActive}`,
	ghost: 'none',
	danger: 'none',
} as const;

export const backgroundColors = {
	primary: colors.button.primary,
	secondary: colors.button.secondary,
	ghost: colors.button.ghost,
	danger: colors.button.danger,
} as const;

export const backgroundHoverColors = {
	primary: colors.button.primaryHover,
	secondary: colors.button.secondary,
	ghost: colors.button.ghostHover,
	danger: colors.button.dangerHover,
} as const;

export const backgroundActiveColors = {
	primary: colors.button.primaryActive,
	secondary: colors.button.secondary,
	ghost: colors.button.ghostActive,
	danger: colors.button.dangerActive,
} as const;

export const textColors = {
	primary: colors.text.onColor,
	secondary: colors.text.primary,
	ghost: colors.text.interactive,
	danger: colors.text.onColor,
} as const;

export const textHoverColors = {
	primary: colors.text.onColor,
	secondary: colors.text.primary,
	ghost: colors.text.interactiveHover,
	danger: colors.text.onColor,
} as const;

export const buttonStyle = css<Props>`
	${fonts.paragraph.paragraph};
	align-items: center;
	background: ${({ variant = 'primary' }) => backgroundColors[variant]};
	border: ${({ variant = 'primary' }) => border[variant]};
	border-radius: 8px;
	color: ${({ variant = 'primary' }) => textColors[variant]};
	cursor: pointer;
	display: flex;
	gap: 4px;
	justify-content: center;
	min-width: 80px;
	padding: ${({ variant = 'primary' }) =>
		variant === 'secondary' ? '6px 12px 6px 8px' : '7px 13px 7px 9px'};
	transition: background 0.1s ease-in-out, border-color 0.1s ease-in-out;
	white-space: nowrap;

	&:active {
		${effects.shadow.hover}
		background: ${({ variant = 'primary' }) =>
			backgroundActiveColors[variant]};
		border: ${({ variant = 'primary' }) => borderActive[variant]};
	}

	&:disabled {
		background: ${({ variant = 'primary' }) =>
			variant === 'ghost' ? 'transparent' : colors.button.disabled};
		border-color: ${({ variant = 'primary' }) =>
			variant === 'ghost' ? 'transparent' : colors.button.disabled};
		color: ${colors.text.disabled};
		cursor: not-allowed;
	}

	&:hover:not(:disabled) {
		${effects.shadow.hover}
		background: ${({ variant = 'primary' }) =>
			backgroundHoverColors[variant]};
		border: ${({ variant = 'primary' }) => borderHover[variant]};
		color: ${({ variant = 'primary' }) => textHoverColors[variant]};
	}

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Button = styled.button<Props>`
	${buttonStyle}
`;

export default Button;
