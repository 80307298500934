import React from 'react';
import type { ListBoxItemRenderProps } from 'react-aria-components';
import styled from 'styled-components';

import { colors, fonts } from '../../theme';
import { EnvelopeIcon } from '../../theme/icons/document';
import { BriefcaseIcon } from '../../theme/icons/objects';
import { BuildingIcon } from '../../theme/icons/travel';
import type {
	AlgoliaCompanyHit,
	AlgoliaHit,
	AlgoliaPersonHit,
} from '../behavior';

import Highlight from './highlight';

export interface AlgoliaNoHit {
	id: 'no-results';
	type: 'no-results';
}
export const NoHit: AlgoliaNoHit = {
	id: 'no-results',
	type: 'no-results',
};

const Container = styled.div`
	display: grid;
	gap: 4px;
	padding: 6px 8px;
`;

const NameAndImage = styled.div`
	${fonts.paragraph.paragraph}
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
`;

const Image = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	flex: 0 0 12px;
	height: 20px;
	margin-right: 4px;
	max-width: 20px;
	min-width: 20px;
	object-fit: contain;
	object-position: center;
`;

const CompanyHomepageUrl = styled.span`
	color: ${colors.text.interactive};
`;

const CompanySubHeader = styled.div`
	${fonts.label.label}
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
	overflow: hidden;

	${CompanyHomepageUrl} {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	svg {
		flex-shrink: 0;
		height: 12px;
		width: 12px;
	}
`;

const CompanyDescription = styled.div`
	${fonts.label.label}
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	color: ${colors.text.secondary};
	display: -webkit-box;
	line-clamp: 2;
	overflow: hidden;
`;

const PersonSubHeader = styled.div`
	${fonts.label.label}
	align-items: center;
	color: ${colors.text.primary};
	display: grid;
	gap: 4px;
	grid-auto-flow: column;
	justify-content: start;
	overflow: hidden;

	span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	svg {
		height: 12px;
		width: 12px;
	}
`;

function CompanyResult({
	isFocused,
	result,
}: {
	isFocused: boolean;
	result: AlgoliaCompanyHit;
}): JSX.Element {
	const style = isFocused ? { backgroundColor: colors.layer.hover } : {};
	let parsedUrl = '';
	if (result.data.homepage_url) {
		const url = new URL(result.data.homepage_url);
		parsedUrl = url.hostname + (url.pathname === '/' ? '' : url.pathname);
	}
	const nameHighlight = result._highlightResult?.data?.name;

	return (
		<Container style={style}>
			<NameAndImage>
				<Image alt={result.data.name} src={result.data.logo_url} />
				{nameHighlight ? (
					<div>
						<Highlight string={nameHighlight.value} />
					</div>
				) : (
					result.data.name
				)}
			</NameAndImage>
			<CompanySubHeader>
				<BuildingIcon />
				{parsedUrl && (
					<CompanyHomepageUrl>{parsedUrl}</CompanyHomepageUrl>
				)}
			</CompanySubHeader>
			<CompanyDescription>{result.data.description}</CompanyDescription>
		</Container>
	);
}

function PersonResult({
	isFocused,
	result,
}: {
	isFocused: boolean;
	result: AlgoliaPersonHit;
}): JSX.Element {
	const style = isFocused ? { backgroundColor: colors.layer.hover } : {};
	const currentJob = result.jobs[0];
	const emailMatch = result._highlightResult?.terms
		?.slice(1) // First term is always name
		?.filter(
			({ matchLevel }) =>
				matchLevel === 'full' || matchLevel === 'partial',
		)?.[0]?.value;

	const email = emailMatch
		? emailMatch.replace(/<\/?em>/gu, '')
		: result.data.primary_email?.email || '';

	const nameMatch = result._highlightResult?.terms?.[0];

	return (
		<Container style={style}>
			<NameAndImage>
				<Image
					alt={`${result.data.first_name} ${result.data.last_name}`}
					src={result.data.photo_url}
				/>
				{nameMatch ? (
					<div>
						<Highlight string={nameMatch.value} />
					</div>
				) : (
					`${result.data.first_name} ${result.data.last_name}`
				)}
			</NameAndImage>
			{email && (
				<PersonSubHeader>
					<EnvelopeIcon />
					{emailMatch ? (
						<div>
							<Highlight string={emailMatch} />
						</div>
					) : (
						<span>{email}</span>
					)}
				</PersonSubHeader>
			)}
			{currentJob && (
				<PersonSubHeader>
					<BriefcaseIcon />
					<span>{currentJob}</span>
				</PersonSubHeader>
			)}
		</Container>
	);
}

type Props = { result: AlgoliaHit | AlgoliaNoHit } & ListBoxItemRenderProps;

export default function QuickSearchResult({
	result,
	isFocused,
}: Props): JSX.Element | null {
	if (result.type === 'companies') {
		return <CompanyResult isFocused={isFocused} result={result} />;
	} else if (result.type === 'people') {
		return <PersonResult isFocused={isFocused} result={result} />;
	} else {
		return null;
	}
}
