// @flow

import React from 'react';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import rangeSearchFilter from './range-filter';
import type { RangeDisplayProps } from './range-filter';

class FoundedSearchFilterDisplay extends React.Component<RangeDisplayProps> {
	static defaultProps = {
		punctuator: '',
	};

	render() {
		const [min, max] = this.props.value;

		if (min != null && max != null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'was' : 'were'
					} founded between `}
					<DescriptionValue onRemove={this.props.onRemoveMin}>
						{min}
					</DescriptionValue>
					{' and '}
					<DescriptionValue
						onRemove={this.props.onRemoveMax}
						punctuator={this.props.punctuator}
					>
						{max}
					</DescriptionValue>
				</FilterDescription>
			);
		} else if (min == null && max != null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'was' : 'were'
					} founded before `}
					<DescriptionValue
						onRemove={this.props.onRemoveMax}
						punctuator={this.props.punctuator}
					>
						{max}
					</DescriptionValue>
				</FilterDescription>
			);
		} else if (min != null && max == null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'was' : 'were'
					} founded since `}
					<DescriptionValue
						onRemove={this.props.onRemoveMin}
						punctuator={this.props.punctuator}
					>
						{min}
					</DescriptionValue>
				</FilterDescription>
			);
		}

		return null;
	}
}

export default rangeSearchFilter(
	'Founded',
	'data.founding',
	FoundedSearchFilterDisplay,
	{
		max: new Date().getFullYear(),
		min: 1900,
	},
	'founded-filter',
);
