import { useSelector } from 'react-redux';

import { selectUser } from '../selectors';

import type { User } from './types';

export function useUser(): User {
	const user = useSelector(selectUser);
	if (user === null) {
		throw new Error('useUser only available when logged in.');
	}
	return user;
}
