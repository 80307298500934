import { useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getAlgoliaKey } from '../../selectors';
import { FilterLabel } from '../../theme/components/filters';
import { XIcon } from '../../theme/icons/system';
import algoliaSearch from '../../utils/algolia';
import toSentence from '../../utils/to-sentence';

import {
	type FilterControlHeaderProps,
	type FilterControlProps,
	type IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import TextComboBox from './text-combo-box';
import { type IndustryFilter } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: space-between;
`;
const Industries = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const IndustryLabel = styled.div`
	display: flex;
	gap: 4px;

	& > svg {
		height: 16px;
		width: 16px;
	}
`;

function useAlgoliaIndustries() {
	const algoliaKey = useSelector(getAlgoliaKey);

	return useQuery({
		queryKey: [
			'search',
			'industry-filter-control',
			'algolia-search',
			algoliaKey,
		],
		queryFn: async () =>
			algoliaSearch(algoliaKey, {
				facets: ['data.industry'],
			}),
		select: (response) => Object.entries(response.facets['data.industry']),
		staleTime: Infinity,
	});
}

const FIELD_NAME: IndustryFilter['fieldName'] = 'industry';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<IndustryFilter>) {
	const { data: industryNames } = useAlgoliaIndustries();
	const comboboxItems = useMemo(
		() =>
			(industryNames || [])
				.toSorted(
					([, aIndustryCount], [, bIndustryCount]) =>
						bIndustryCount - aIndustryCount,
				)
				.map(([name]) => ({
					id: name,
					text: name,
				})),
		[industryNames],
	);
	const selectableComboItems = useMemo(
		() =>
			comboboxItems.filter(
				({ id }) => !filter?.value.some((industry) => industry === id),
			),
		[comboboxItems, filter?.value],
	);

	const handleIndustryDeselect = useCallback(
		(industryName: string) => {
			if (!filter) return;

			const newValue = filter.value.filter(
				(name) => name !== industryName,
			);

			if (newValue.length === 0) {
				onClear(FIELD_NAME);
			} else {
				onChange({
					...filter,
					value: newValue,
				});
			}
		},
		[filter, onChange, onClear],
	);
	const handleIndustrySelect = useCallback(
		(industryName: number | string | null) => {
			const parsed =
				typeof industryName === 'number'
					? industryName.toString()
					: industryName ?? '';

			if (parsed == null) return;

			if (filter && filter.value.some((name) => name === industryName)) {
				return;
			} else {
				const industry = comboboxItems.find(
					({ id }) => id === industryName,
				);
				if (industry) {
					onChange({
						fieldName: FIELD_NAME,
						value: [...(filter?.value || []), parsed],
					});
				}
			}
		},
		[comboboxItems, filter, onChange],
	);

	return (
		<Container>
			<TextComboBox
				allOptions={selectableComboItems}
				defaultOptions={selectableComboItems.slice(0, 5)}
				defaultValue=""
				disabled={!industryNames}
				id="industries"
				label="Operating in..."
				onSubmit={handleIndustrySelect}
				placeholder="SaaS, Cloud Computing, etc"
			/>
			<Industries>
				{(filter?.value || []).map((name) => (
					<FilterLabel
						isActive
						key={name}
						label={
							<IndustryLabel>
								<span>{name}</span>
								<XIcon />
							</IndustryLabel>
						}
						onAction={() => {
							handleIndustryDeselect(name);
						}}
					/>
				))}
			</Industries>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<IndustryFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Industries"
		/>
	);
}

const IndustryFilterControl: IFilterControl<IndustryFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (filter.value.length === 0) {
			return [];
		}
		return [
			`operate in the ${toSentence(filter.value, 'or')} ${pluralize(
				'industry',
				filter.value.length,
			)}`,
		];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default IndustryFilterControl;
