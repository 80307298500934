import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Tooltip, { UnstyledTooltipContent } from '../components/tooltip';
import {
	Logo,
	SubTitle,
	Title,
	TitleAndLogoContainer,
} from '../profiles/summaries/profile-tooltip-card';
import { colors, effects, fonts } from '../theme';
import { buttonStyle } from '../theme/components/button';
import QuantityIndicator from '../theme/components/quantity-indicator';
import { trackEvent } from '../utils/analytics';
import reportError from '../utils/sentry';

import UnstyledOpenEmailLink from './open-email-link';
import { type CompanyOutreach } from './types';

const SingleContactCellBody = styled.div`
	${fonts.paragraph.paragraph}
	color: ${colors.text.primary};
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	justify-content: space-between;
`;

const MultiContactCell = styled.div`
	${fonts.paragraph.paragraph}
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	justify-content: space-between;
`;
const MultiContactEmailAddresses = styled.ul`
	align-items: center;
	display: flex;
	gap: 8px;
`;
const MultiContactCellEmailAddress = styled(Link)`
	color: ${colors.text.interactive};

	&:hover {
		${fonts.paragraph.underline}
		color: ${colors.text.interactiveHover};
		cursor: pointer;
	}
`;
const AdditionalEmailsTooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.shadow};
	${fonts.paragraph.paragraph};
	background: ${colors.layer.layer};
	border-radius: 4px;
	border: 1px solid ${colors.border.subtle};
	color: ${colors.text.secondary};
	display: flex;
	flex-direction: column;
	gap: 4px;
	min-width: 180px;
	padding: 8px;
`;
const ProfileLinkTooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.shadow};
	${fonts.paragraph.paragraph};
	background: ${colors.layer.layer};
	border-radius: 4px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 250px;
	min-width: 180px;
	padding: 8px 12px;
`;
const Emphasized = styled.span`
	color: ${colors.text.primary};
`;

const NameAndLogo = styled(Link)`
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	flex: 1;
	gap: 4px;
`;

const EmailLink = styled(UnstyledOpenEmailLink)`
	${buttonStyle}
	align-items: center;
	min-width: 105px;
	align-self: flex-start;
`;

function trackProfileClickEvent() {
	trackEvent('Open Profile', 'outreach-table', 'home-page', {
		target_profile_type: 'people',
	});
}

function SingleContactCell({
	contact,
	outreach,
}: {
	contact: CompanyOutreach['contacts'][0];
	outreach: CompanyOutreach;
}) {
	return (
		<SingleContactCellBody>
			<Tooltip
				content={
					<ProfileLinkTooltipContent>
						<TitleAndLogoContainer>
							<Logo src={contact.photoUrl} alt="" />
							<Title>{contact.name}</Title>
						</TitleAndLogoContainer>
						{contact.currentJob && (
							<TitleAndLogoContainer>
								<Logo
									src={contact.currentJob.company.logoUrl}
									alt=""
								/>
								<span>
									<Title>
										{contact.currentJob.company.name}
									</Title>{' '}
									<SubTitle>
										{contact.currentJob.title}
									</SubTitle>
								</span>
							</TitleAndLogoContainer>
						)}
					</ProfileLinkTooltipContent>
				}
			>
				<NameAndLogo
					to={`/people/${contact.id}`}
					onClick={trackProfileClickEvent}
				>
					<Logo src={contact.photoUrl} alt="" />
					{contact.name !== 'N/A' ? contact.name : contact.email}
				</NameAndLogo>
			</Tooltip>
			<EmailLink
				analyticsComponentName="contacts-cell"
				companyOutreach={outreach}
				variant="secondary"
			/>
		</SingleContactCellBody>
	);
}

function MultiContactsCell({
	contacts,
	outreach,
}: {
	contacts: CompanyOutreach['contacts'];
	outreach: CompanyOutreach;
}) {
	const sortedContacts = contacts.toSorted(
		({ email: emailA }, { email: emailB }) => {
			const aIsDrive = emailA.endsWith('drivecapital.com');
			const bIsDrive = emailB.endsWith('drivecapital.com');
			if (aIsDrive && !bIsDrive) {
				return 1;
			} else if (!aIsDrive && bIsDrive) {
				return -1;
			} else {
				return 0;
			}
		},
	);
	const firstContact = sortedContacts[0];

	return (
		<MultiContactCell>
			<MultiContactEmailAddresses>
				<Tooltip
					content={
						<ProfileLinkTooltipContent>
							<NameAndLogo
								to={`/people/${firstContact.id}`}
								onClick={trackProfileClickEvent}
							>
								<Logo src={firstContact.photoUrl} alt="" />
								{firstContact.name !== 'N/A'
									? firstContact.name
									: firstContact.email}
							</NameAndLogo>

							{firstContact.currentJob && (
								<TitleAndLogoContainer>
									<Logo
										src={
											firstContact.currentJob.company
												.logoUrl
										}
										alt=""
									/>
									<span>
										<Title>
											{
												firstContact.currentJob.company
													.name
											}
										</Title>{' '}
										<SubTitle>
											{firstContact.currentJob.title}
										</SubTitle>
									</span>
								</TitleAndLogoContainer>
							)}
						</ProfileLinkTooltipContent>
					}
				>
					<MultiContactCellEmailAddress
						key={firstContact.id}
						to={`/people/${firstContact.id}`}
						onClick={trackProfileClickEvent}
					>
						{firstContact.email}
					</MultiContactCellEmailAddress>
				</Tooltip>

				<Tooltip
					content={
						<AdditionalEmailsTooltipContent>
							<span>And:</span>
							{sortedContacts.slice(1).map(({ id, email }) => (
								<Link
									key={id}
									to={`/people/${id}`}
									onClick={trackProfileClickEvent}
								>
									<Emphasized>{email}</Emphasized>
								</Link>
							))}
						</AdditionalEmailsTooltipContent>
					}
				>
					<QuantityIndicator
						menu={false}
						open={false}
						onClick={() => {
							// no-op
						}}
						quantity={sortedContacts.length - 1}
					/>
				</Tooltip>
			</MultiContactEmailAddresses>
			<EmailLink
				analyticsComponentName="contacts-cell"
				companyOutreach={outreach}
				variant="secondary"
			/>
		</MultiContactCell>
	);
}

interface Props {
	companyOutreach: CompanyOutreach;
}

export default function ContactsCell({ companyOutreach }: Props) {
	// Guard against merges after the initial outreach (probably should happen in backend :grimacing:)
	const contactByProfileId = companyOutreach.contacts.reduce(
		(acc, contact) => {
			acc[contact.id] = contact;
			return acc;
		},
		{} as Record<number, (typeof companyOutreach.contacts)[0]>,
	);
	const uniqueContacts = Object.values(contactByProfileId);

	if (uniqueContacts.length === 0) {
		reportError(
			new Error(
				`No contacts found for company outreach ${companyOutreach.id}`,
			),
		);
		return null;
	} else if (uniqueContacts.length > 1) {
		return (
			<MultiContactsCell
				contacts={uniqueContacts}
				outreach={companyOutreach}
			/>
		);
	} else {
		return (
			<SingleContactCell
				contact={uniqueContacts[0]}
				outreach={companyOutreach}
			/>
		);
	}
}
