import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { trackEvent } from '../utils/analytics';
import type { ViewType } from '../utils/analytics';
import useDebouncedCallback from '../utils/hooks/use-debounced-callback';

const defaultHistoryKey =
	'This is the default scroll history key shared by all currently-mounted instances.';

interface ScrollState {
	x: number;
	y: number;
}

type LocationState<K extends string> = {
	[k in K]?: ScrollState;
};

export function useScrollHistory<K extends string = typeof defaultHistoryKey>({
	debounceMs = 250,
	// This component needs to store its position under a unique key in
	// `history.state`. If there's only one instance of this component mounted
	// at a time, then using the default is safe. If there's more than one, then
	// this key needs to be specified.
	historyKey = defaultHistoryKey as K,
}: { debounceMs?: number; historyKey?: K } = {}): [
	number | null | undefined,
	number | null | undefined,
	(event: React.UIEvent<HTMLElement>) => void,
] {
	const location = useLocation();
	const state = location.state as LocationState<K> | undefined;
	const { x, y } = state?.[historyKey] ?? {};

	const navigate = useNavigate();
	const [onDebouncedScroll] = useDebouncedCallback(
		useCallback(
			(
				newX: number,
				newY: number,
				componentIdentifier?: string,
				viewType?: ViewType,
			) => {
				if (componentIdentifier && viewType) {
					trackEvent('Scroll Table', componentIdentifier, viewType);
				}

				navigate(
					{
						...location,
					},
					{
						state: {
							...(typeof state === 'object' ? state : {}),
							[historyKey]: { x: newX, y: newY },
						},
						replace: true,
					},
				);
			},
			[location, navigate, state, historyKey],
		),
		debounceMs,
	);
	// Because React reuses event instances, we need to split scroll handling
	// into two separate callbacks. If we were to use a single debounced
	// handler, by the time it ran, the original event instance would be long
	// gone. Instead, an outer callback that isn't debounced runs immediately
	// and calls the inner debounced callback with the `x` and `y` scroll
	// values, which will still be available when the debounced callback runs.
	const onScroll = useCallback(
		(
			event: React.UIEvent<HTMLElement>,
			componentIdentifier?: string,
			viewType?: ViewType,
		) => {
			onDebouncedScroll(
				event.currentTarget.scrollLeft,
				event.currentTarget.scrollTop,
				componentIdentifier,
				viewType,
			);
		},
		[onDebouncedScroll],
	);

	return [x, y, onScroll];
}
