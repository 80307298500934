// This file would take a massive effort to fully type all at once, so instead
// we'll add types gradually.
/* eslint-disable flowtype/no-types-missing-file-annotation */

import type { WatchListData } from '../components/watchlists/watch-list';
import type { State as StoreState } from '../reducers';
import { selectCurrentCandidatePoolsByTypeAndId } from '../talent/selectors';

export const isRobot = (state, type, id) =>
	type === 'people' && state.robots.get(`${id}`);

export const getAlgoliaKey = (state) => state.user.algolia_key;

export const getCompanyById = (state, id) => state.companiesById[id];

export const getCompanyLPDataById = (state, id) => {
	const company = getCompanyById(state, id);
	if (!company) {
		return null;
	}
	return company.lp_fundraising_data;
};

export const getPersonById = (state, id) => state.peopleById[id];

export const getEntityByTypeAndId = (state, type, id) => {
	switch (type) {
		case null:
			return null;
		case 'companies':
			return getCompanyById(state, id);
		case 'people':
			return getPersonById(state, id);
		default:
			throw new Error(`Unknown type ${type}.`);
	}
};

export const selectAreSemiAnnualsLoaded = (state) => state.semiAnnuals.loaded;

export type MarketMapRegionOrWatchList =
	| {
			id: number,
			map: number,
			name: string,
			type: 'market-maps',
	  }
	| {
			id: number,
			name: string,
			type: 'watchlists',
	  };

export type MarketMapRegionOrWatchListOrCandidatePool =
	| MarketMapRegionOrWatchList
	| {|
			id: number,
			jobOrderId: number,
			name: string,
			type: 'candidatePools',
	  |};

export const selectCurrentListsByTypeAndId = (state, type, id) => {
	const watchLists = selectCurrentWatchListsByTypeAndId(state, type, id).map(
		(list) => ({
			type: 'watchlists',
			id: list.id,
			name: list.name,
		}),
	);
	const currentCandidatePools = selectCurrentCandidatePoolsByTypeAndId(
		state,
		type,
		id,
	).map((pool) => ({
		id: pool.id,
		jobOrderId: pool.job_order_id,
		name: pool.name,
		type: 'candidatePools',
	}));

	return watchLists.concat(currentCandidatePools);
};

export const selectNoteById = (state, id) => selectNotesById(state)[id];

export const selectNotesById = (state) => state.notes.byId;

export const selectSemiAnnualById = (state, id) =>
	selectSemiAnnualsById(state)[id];

export const selectSemiAnnualIds = (state) =>
	Object.keys(selectSemiAnnualsById(state)).map((id) => parseInt(id, 10));

export const selectSemiAnnualLoadedById = (state, id) =>
	(selectSemiAnnualById(state, id) || {}).loaded || false;

export const selectSemiAnnuals = (state) =>
	Object.values(selectSemiAnnualsById(state));

export const selectSemiAnnualsById = (state) => state.semiAnnuals.byId;

export const selectUser = (state) => state.user;

export const selectAreWatchListsLoaded = (state) => state.watchLists.loaded;

// This was a fun one. Sentry was reporting errors every few days saying
// "Could not read property `added_at` of `undefined`." It took forever to
// reproduce, but I finally figured out what was happening:
//
// When we reference `entity.notes` below, that's an array of integers at this
// point. The notes column is a connected component that then pulls each note's
// data from the redux store. As a performance optimization for large lists, we
// were caching the generated `list` in a `WeakMap` based on referential
// equality with the `watchList` from the redux store. When initially written,
// this was a valid assumption because all of the watch list's data was stored
// either in the `watchListsById` reducer or directly within the entity
// reducers, whose data was then pulled into the cache.
//
// Adding the notes column that pulls data from outside the cache, but based on
// cached data, invalidated that assumption. If a user starts on a watch lists,
// clicks through to a profile page, deletes a note with ID 123 from that
// profile, and then goes back to the watch list, the cached data will cause
// the notes column to attempt to load from the redux store the note with ID
// 123, which no longer exists.
//
// We now cache based on the watch list ID and referential equality of the
// entire redux store. Looking only at notes, entities, and watch lists would be
// slightly more precise, but the occasions where something else that doesn't
// affect the watch list would change while viewing the watch list are rare.
let watchListCache: ?[string, StoreState, WatchListData] = null;

export const selectWatchListById = (state, watchListId) => {
	let list = null;

	if (
		watchListCache != null
		&& watchListCache[0] === watchListId
		&& watchListCache[1] === state
	) {
		list = watchListCache[2];
	} else {
		const watchList = state.watchLists.byId[watchListId];

		if (!watchList || !watchList.loaded) {
			return null;
		}

		list = {
			...watchList,
			items: watchList.items.map(({ type, id, extra }) => {
				const entity = getEntityByTypeAndId(state, type, id);
				return {
					...entity,
					// All watch lists use `logo_url` for the image column's
					// descriptor because watch lists originally only
					// supported companies. We translate people to look like
					// companies by copying `photo_url` to `logo_url` to
					// match the descriptor's field.
					logo_url: entity.logo_url || entity.photo_url,
					notes: (entity.notes || []).map((noteId) =>
						selectNoteById(state, noteId),
					),
					...extra,
				};
			}),
		};
		watchListCache = [watchListId, state, list];
	}

	return list;
};

export const selectWatchListColumnsById = (state, watchListId) => {
	const watchList = selectWatchListById(state, watchListId);

	if (watchList) return watchList.columns;
	return null;
};

export const selectWatchListLoadedById = (state, watchListId) => {
	const watchList = selectWatchListById(state, watchListId);
	return Boolean(watchList && watchList.loaded);
};

export const selectWatchLists = (state) => Object.values(state.watchLists.byId);

export const selectCurrentWatchListsByTypeAndId = (state, type, id) => {
	if (type === 'companies' || type === 'people') {
		return selectWatchLists(state).filter((watchList) =>
			watchList.items.some(
				(item) => item.id === id && item.type === type,
			),
		);
	}
	return [];
};
