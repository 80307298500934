import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import useDebouncedCallback from '../utils/hooks/use-debounced-callback';
import ImmutableURLSearchParams from '../utils/immutable-url-search-params';

const defaultParameterName = 'search';

export function useSearchHistory(
	parameterName: string = defaultParameterName,
): [string, (search: string) => void] {
	const [searchParams, setSearchParams] = useSearchParams();
	const query = searchParams.get(parameterName) || '';

	const [onSearch] = useDebouncedCallback(
		useCallback(
			(nextQuery: string) => {
				setSearchParams(
					(currentParams) => {
						if (nextQuery) {
							return new URLSearchParams(
								new ImmutableURLSearchParams(currentParams)
									.set(parameterName, nextQuery)
									.toString(),
							);
						} else {
							return new URLSearchParams(
								new ImmutableURLSearchParams(currentParams)
									.delete(parameterName)
									.toString(),
							);
						}
					},
					{ replace: true },
				);
			},
			[parameterName, setSearchParams],
		),
		250,
	);

	return [query, onSearch];
}
