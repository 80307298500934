import styled from 'styled-components';

import { colors } from '../../../theme';

export const Chevron = styled.svg`
	color: ${colors.icon.onColor};
	height: 32px;
	padding: 8px;
	width: 32px;
`;
