import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { Dispatch } from 'redux';
import styled from 'styled-components';

import { login as loginAction } from '../';
import { backgroundGray, primaryDriveBlue } from '../../colors';
import HerbieLoader from '../../components/herbie-loader';
import ConfirmForm from '../../password/confirmForm';
import { trackEvent } from '../../utils/analytics';
import { get } from '../../utils/api';
import type { ResponseError } from '../../utils/api';
import useAbortSignal from '../../utils/hooks/use-abort-signal';
import type { Action } from '../actions';
import GoogleOAuthConsent, {
	useGoogleOAuthConsent,
} from '../google-oauth-consent';
import GoogleSignIn, { useGoogleSignIn } from '../google-sign-in';
import type { User } from '../types';

const PageContainer = styled.div`
	background-color: ${backgroundGray.toString()};
	background-image: url('https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/herbie-transparent-large.png');
	background-position: 40% 40%;
	background-repeat: no-repeat;
	background-size: cover;
	display: grid;
	grid-template-columns: 1fr 375px 1fr;
	grid-template-rows: 15vh auto 1fr;
	font-size: 16px;
`;

const ViewContainer = styled.div`
	width: 315px;
`;

const CenterContainer = styled.div`
	align-items: stretch;
	grid-column: 2;
	grid-row: 2;
	display: flex;
	flex-direction: column;
	max-width: 375px;
	width: 100%;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 6px;
	padding: 20px;
	box-shadow: 0px 0px 30px 3px rgba(172, 171, 171, 0.23);
	h1 {
		margin-top: 5px;
		text-align: center;
	}
	p {
		margin: 5px 0px 20px 0px;
		text-align: center;
		font-size: 13px;
	}
	.Form-container {
		background-color: transparent;
		box-shadow: none;
		margin: 0;
		padding: 0;
	}
	.Login-form {
		margin: 0;
	}

	/* all of these or-divider styles contribute to the little
		divider lines */
	.or-divider {
		width: 100%;
		margin-top: 5px;
		margin-bottom: 15px;
		text-align: center;
		font-weight: bold;
	}

	.or-divider > span {
		position: relative;
		display: inline-block;
	}

	.or-divider > span:before,
	.or-divider > span:after {
		content: '';
		position: absolute;
		top: 50%;
		width: 200%;
		height: 1px;
		background: lightgray;
	}

	.or-divider > span:before {
		right: 100%;
		margin-right: 15px;
	}

	.or-divider > span:after {
		left: 100%;
		margin-left: 15px;
	}
`;

const Link = styled.a`
	color: ${primaryDriveBlue.toString()};
`;

const HerbieImg = styled.img`
	width: 50%;
	margin-left: 9%;
	margin-top: -17.7%;
`;

interface RouterProps {
	id: string;
	token: string;
}
interface MappedProps {
	id: number;
	token: string;
}

interface DispatchProps {
	login(token: string): void;
}

interface Props extends DispatchProps, MappedProps {}

interface EmptyState {
	status: 'loading' | 'failure';
}

interface UserState {
	status: 'success';
	user: User;
}

function FinishRegistration({ id, login, token }: Props) {
	const signal = useAbortSignal();
	const [state, setState] = useState<EmptyState | UserState>({
		status: 'loading',
	});

	const googleSignIn = useGoogleSignIn({
		analyticsEventName: 'Finish Registration',
		analyticsViewType: 'finish-registration',
		onSuccess: login,
	});
	const scopes = Object.keys(
		state.status === 'success' ? state.user.oauth_scopes : [],
	);
	const googleOAuthConsent = useGoogleOAuthConsent({
		analyticsEventName: 'Finish Registration',
		analyticsViewType: 'finish-registration',
		onSuccess: login,
		scopes,
	});
	const googleAuthEnabled =
		googleSignIn.enabled || googleOAuthConsent.enabled;
	const shouldAskForOAuth = scopes.length > 0;

	useEffect(() => {
		get<User>(`/auth/invited-user-permissions/${id}`, {
			signal,
			query: {
				token,
			},
		})
			.then((user) => {
				trackEvent(
					'Visit New Page',
					'finish-registration-view',
					'finish-registration',
					{
						username: user.username,
						tenantName:
							user.tenant != null ? user.tenant.name : null,
						userGroups: user.groups,
					},
				);
				setState({ user, status: 'success' });
			})
			.catch((error: ResponseError) => {
				if (error.name !== 'AbortError') {
					trackEvent(
						'Visit New Page',
						'finish-registration-view',
						'finish-registration',
						{
							username: null,
							tenantName: null,
							userGroups: null,
						},
					);
					setState({ status: 'failure' });
				}
			});
	}, [googleAuthEnabled, id, token, signal]);

	if (state.status === 'failure') {
		return (
			<PageContainer>
				<CenterContainer>
					<p>This token is invalid.</p>
					<p>
						Please contact{' '}
						<Link href="mailto:herbie-support@drivecapital.com">
							Herbie Support
						</Link>{' '}
						for assistance.
					</p>
					<p>herbie-support@drivecapital.com</p>
				</CenterContainer>
			</PageContainer>
		);
	} else if (state.status === 'success') {
		return (
			<PageContainer>
				<CenterContainer>
					<HerbieImg src="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/HerbsterFullyReloaded.png" />

					<h1>Welcome, {state.user.first_name}</h1>
					<p>
						You're almost there! <br />
						Finish account registration below
					</p>
					<ViewContainer>
						<ConfirmForm
							buttonText="Set Password"
							eventName="Finish Registration"
							username={state.user.username}
							token={token}
							viewType="finish-registration"
						/>
						{googleAuthEnabled && (
							<div className="or-divider">
								<span>OR</span>
							</div>
						)}
						{googleSignIn.enabled && !shouldAskForOAuth && (
							<GoogleSignIn
								errorMessage={googleSignIn.signInError}
								renderButton={googleSignIn.renderButton}
							/>
						)}
						{googleOAuthConsent.enabled && shouldAskForOAuth && (
							<GoogleOAuthConsent
								errorMessage={googleOAuthConsent.consentError}
								triggerConsentFlow={
									googleOAuthConsent.triggerConsentFlow
								}
							/>
						)}
					</ViewContainer>
				</CenterContainer>
			</PageContainer>
		);
	}

	return (
		<PageContainer>
			<CenterContainer>
				<HerbieLoader />
			</CenterContainer>
		</PageContainer>
	);
}

const ConnectedRegistration = connect<RouterProps, DispatchProps, object>(
	null,
	(dispatch: Dispatch<Action>): DispatchProps => ({
		login: (...args) => dispatch(loginAction(...args)),
	}),
)(FinishRegistration);

export default function View() {
	const params = useParams<'id' | 'token'>();
	const id = parseInt(params.id || '-1', 10);
	const token = params.token || '';

	return <ConnectedRegistration id={id} token={token} />;
}
