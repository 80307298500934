/**
 * @file [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=149%3A118292&mode=dev
 */
import {
	ListboxButton,
	ListboxInput,
	ListboxOption,
	ListboxPopover,
	useListboxContext,
} from '@reach/listbox';
import '@reach/listbox/styles.css';
import { animated, useTransition } from '@react-spring/web';
import React, { type ReactNode } from 'react';
import styled from 'styled-components';

import { colors, effects, fonts } from '..';
import { ChevronDownIcon, ChevronUpIcon } from '../icons/arrows';
import {
	CheckCircleIcon,
	ExclamationMarkCircleIcon,
	WarningIcon,
} from '../icons/system';

import Chip from './chip';

export const Option = styled(ListboxOption)`
	&[data-reach-listbox-option] {
		${fonts.paragraph.paragraph}
		align-items: center;
		background: ${colors.layer.layer};
		color: ${colors.text.secondary};
		display: flex;
		flex-direction: row;
		gap: 4px;
		padding: 6px 8px;
		transition: all 0.1s ease-out;

		&[data-current-selected] {
			${fonts.paragraph.strong}
			background: ${colors.layer.active};
			color: ${colors.text.secondaryActive};
		}

		&[data-current-nav],
		&:hover:not(:disabled) {
			cursor: pointer;
			background: ${colors.layer.hover};
			color: ${colors.text.secondaryHover};
		}
	}
`;

type Status = 'default' | 'success' | 'warning' | 'danger';

const statusBorderColors = {
	default: colors.border.subtle,
	success: colors.semantic.success,
	warning: colors.border.strong,
	danger: colors.semantic.danger,
} as const;

const statusIcons = {
	default: null,
	success: CheckCircleIcon,
	warning: WarningIcon,
	danger: ExclamationMarkCircleIcon,
} as const;

const statusTextColors = {
	warning: colors.text.warning,
	danger: colors.text.danger,
} as const;

const Label = styled.span<{ $disabled: boolean }>`
	${fonts.label.label}
	color: ${({ $disabled }) =>
		$disabled ? colors.text.disabled : colors.text.secondary};
`;

const Contents = styled.div`
	align-items: flex-start;
	display: flex;
	flex: 1;
	gap: 4px;
	margin-right: 8px;
`;

export const Avatar = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	height: 20px;
	object-fit: cover;
	width: 20px;
`;

const Chevron = styled.svg`
	color: ${colors.icon.secondary};
	height: 16px;
	width: 16px;
`;

const Icon = styled.svg`
	height: 16px;
	margin-right: 2px;
	width: 16px;
`;

const Placeholder = styled.span`
	${fonts.paragraph.paragraph}
	color: ${colors.text.secondary};
`;

const ListButton = styled(ListboxButton)<{ $status: Status }>`
	&[data-reach-listbox-button] {
		${fonts.paragraph.paragraph}
		align-items: center;
		background: ${colors.field.field};
		border-radius: 8px;
		border: 1px solid ${({ $status }) => statusBorderColors[$status]};
		color: ${colors.text.primary};
		display: flex;
		flex-direction: row;
		padding: 6px 12px;
		transition: all 0.1s ease-in-out;

		&:hover {
			background: ${colors.field.hover};
		}

		&:focus-visible {
			${effects.shadow.focus}
			background: ${colors.field.field};
			border-color: ${colors.semantic.focus};
			outline: none;
		}

		&[aria-disabled='true'] {
			background: ${colors.field.disabled};
			border-color: ${colors.border.disabled};
			color: ${colors.text.disabled};
			cursor: not-allowed;
			opacity: 1;

			${Avatar} {
				opacity: 0.5;
			}

			${Chevron} {
				color: ${colors.icon.disabled};
			}

			${Placeholder} {
				color: ${colors.text.disabled};
			}
		}
	}
`;

const Popover = animated(styled(ListboxPopover)`
	${effects.shadow.shadow}
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 8px;
	display: block;
	margin-top: 4px;
	overflow: hidden;
	padding: 6px 0;
	transform-origin: top left;

	&[hidden] {
		/* Override hiding via CSS so react-spring can animate exit. */
		display: block;
	}
`);

const StatusText = styled.span<{
	$disabled: boolean;
	$status: 'warning' | 'danger';
}>`
	${fonts.label.label}
	color: ${({ $disabled, $status }) =>
		$disabled ? colors.text.disabled : statusTextColors[$status]};
`;

const Container = styled.label`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

const Quantity = styled(Chip)`
	padding: 0px 8px;
	margin-right: 8px;
`;

interface BodyProps {
	buttonChildren: ReactNode;
	children: ReactNode;
	disabled: boolean;
	placeholder: string;
	quantity?: number;
	status: Status;
}

function Body({
	buttonChildren,
	children,
	disabled,
	placeholder,
	quantity,
	status,
}: BodyProps) {
	const { isExpanded } = useListboxContext();

	const transitions = useTransition(isExpanded, {
		from: { opacity: 0, scale: 0.9 },
		enter: { opacity: 1, scale: 1 },
		leave: { opacity: 0, scale: 0.9 },
		config: { mass: 1, tension: 2000, friction: 75 },
	});

	const StatusIcon = statusIcons[status];

	return (
		<>
			<ListButton $status={status}>
				<Contents>
					{buttonChildren ?? <Placeholder>{placeholder}</Placeholder>}
				</Contents>
				{quantity != null && (
					<Quantity
						color={
							disabled
								? colors.text.disabled
								: colors.text.secondary
						}
						background={
							disabled
								? colors.layer.disabled
								: colors.data.dust.layer
						}
						label={`+${quantity}`}
					/>
				)}
				{StatusIcon && <Icon as={StatusIcon} />}
				<Chevron as={isExpanded ? ChevronUpIcon : ChevronDownIcon} />
			</ListButton>
			{transitions(
				(styles, item) =>
					item && <Popover style={styles}>{children}</Popover>,
			)}
		</>
	);
}

interface Props {
	buttonChildren: ReactNode;
	className?: string;
	children: ReactNode;
	disabled?: boolean;
	label?: ReactNode;
	id?: string;
	name?: string;
	onChange: (key: string) => void;
	placeholder: string;
	quantity?: number;
	required?: boolean;
	status?: Status;
	statusText?: string;
	value: string | null;
}

/**
 * @deprecated Use `src/components/dropdown` instead.
 */
export default function Dropdown({
	buttonChildren,
	className,
	children,
	disabled = false,
	label,
	id,
	name,
	onChange,
	placeholder,
	quantity,
	required = false,
	status = 'default',
	statusText,
	value,
}: Props) {
	return (
		<Container className={className}>
			{label && <Label $disabled={disabled}>{label}</Label>}
			<ListboxInput
				className={className}
				disabled={disabled}
				id={id}
				name={name}
				onChange={onChange}
				required={required}
				value={value ?? ''}
			>
				<Body
					buttonChildren={buttonChildren}
					disabled={disabled}
					placeholder={placeholder}
					quantity={quantity}
					status={status}
				>
					{children}
				</Body>
			</ListboxInput>
			{(status === 'warning' || status === 'danger') && statusText && (
				<StatusText $disabled={disabled} $status={status}>
					{statusText}
				</StatusText>
			)}
		</Container>
	);
}
