import React from 'react';
import loadable from 'react-loadable';

import authorizedRoute from '../components/authorized-route';
import HerbieLoader from '../components/herbie-loader';

export { loadOnePagerSuccess } from './redux/actions';
export type { Action } from './redux/actions';
export { ONEPAGER_DELETE, ONEPAGER_LOAD_SUCCESS } from './redux/constants';
export { default as reducer } from './redux/reducers';
export type { OnePagersState } from './redux/reducers';
export type { OnePager, PartialOnePager } from './types';

export { useCompanyOnePagers } from './redux/queries';

const View = loadable({
	loader: () => import('./list-view'),
	loading: HerbieLoader,
});

export const routes = authorizedRoute(
	{
		path: 'onepagers',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.onepagers',
);
