import d3 from 'd3';
import moment from 'moment';
import React from 'react';

import './companies-in-herbie.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import LineChart from './charts/line-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/companies/';
let data = null;

const xAxisTickFormat = (timestamp) =>
	d3.time.format('%x')(new Date(timestamp * 1000));

const formatData = (response) => {
	let total = 0;

	return [
		{
			key: 'Companies in Herbie',
			values: response.map((point) => {
				total += point.count;

				return {
					x: moment(point.date, 'YYYY-MM-DD').unix(),
					y: total,
				};
			}),
		},
	];
};

export default class CompaniesInHerbie extends React.Component {
	static getDetailCard() {
		return CompaniesInHerbieDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showXAxis: true,
			showYAxis: true,
			useInteractiveGuideline: true,
			showLegend: true,
		};

		return (
			<GenericChart
				data={data}
				loadingComponent={HerbieLoader}
				fetchData={this.fetchData}
				forceY={0}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="Companies in Herbie"
				xAxisLabel="Date"
				xAxisTickFormat={xAxisTickFormat}
				yAxisLabel="Companies"
			>
				{LineChart}
			</GenericChart>
		);
	}
}

class CompaniesInHerbieDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/companies.png"
				statName="Companies in Herbie"
			/>
		);
	}
}
