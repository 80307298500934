/**
 * We define five different responsive breakpoints, from xs to xl.
 *
 * We write styles for `xs` by default, so we don't need to define a constant
 * for it. We then override styles for any larger breakpoints in `@media`
 * queries.
 *
 * For example, this section will have `16px` padding on small screens, growing
 * to `32px` on medium screens and above:
 *
 * ```js
 * const Section = styled.section`
 *     padding: 16px;
 *     ＠media (${breakpoints.md}) {
 *        padding: 32px;
 *     }
 * }
 * ```
 *
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=5%3A2&mode=dev
 */
const breakpoints = {
	// xs is anything smaller than 576px.
	sm: 'min-width: 576px',
	md: 'min-width: 768px',
	lg: 'min-width: 992px',
	xl: 'min-width: 1440px',
} as const;

export default breakpoints;
