import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IconMenu } from '../components/menus/menu';
import { colors, effects, fonts } from '../theme';
import Chip from '../theme/components/chip';
import IconButton from '../theme/components/icon-button';
import { EnvelopeIcon } from '../theme/icons/document';
import { SpeechBubbleIcon } from '../theme/icons/human';
import { XIcon } from '../theme/icons/system';
import { ClockArrowLoopRightIcon, ClockSnoozeIcon } from '../theme/icons/time';

import { StateChip, TooltipContents } from './state-cell';
import { type CompanyOutreach, type EventHistoryItem } from './types';

const Body = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 20px;
`;
const Header = styled.div`
	${fonts.h5.productive};
	color: ${colors.text.secondary};
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;
const StateContainer = styled.div`
	max-width: 250px;
`;
const StateChipContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
`;
const HistoryList = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-height: 300px;
	overflow-y: scroll;
`;
const HistoryListItem = styled.li`
	${effects.shadow.elevated};
	${fonts.label.label};
	border-radius: 12px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 12px;
	min-width: 250px;
	padding: 12px;
`;
const HistoryListItemTitle = styled.span`
	${fonts.label.label};
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 4px;
	justify-content: space-between;
`;
const HistoryListItemMessage = styled.span`
	color: ${colors.text.primary};
`;
const HistoryListItemNote = styled.span`
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 4px;

	svg {
		height: 12px;
		width: 12px;
	}
`;

const HISTORY_TYPES_TO_RENDER: ReadonlyArray<EventHistoryItem['type']> = [
	'initial_event',
	'snoozed_event',
	'monitoring_event',
];

interface Props {
	companyOutreach: CompanyOutreach;
}

export default function HistoryPopover({ companyOutreach }: Props) {
	const [open, setOpen] = useState(false);
	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);
	const handleOpenChange = useCallback((isOpen: boolean) => {
		setOpen(isOpen);
	}, []);
	const buttonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (open) {
			buttonRef.current?.focus();
		}
	}, [open]);

	return (
		<IconMenu
			Icon={ClockArrowLoopRightIcon}
			buttonSize="small"
			isOpen={open}
			onOpenChange={handleOpenChange}
			placement="bottom end"
		>
			<Body>
				<Header>
					<StateContainer>
						<StateChipContainer>
							<StateChip companyOutreach={companyOutreach} />
						</StateChipContainer>
						<TooltipContents companyOutreach={companyOutreach} />
					</StateContainer>
					<IconButton
						aria-label="Close"
						label=""
						onClick={handleClose}
						size="small"
						ref={buttonRef}
					>
						<XIcon />
					</IconButton>
				</Header>
				<HistoryList>
					{companyOutreach.history
						.filter(({ type }) =>
							HISTORY_TYPES_TO_RENDER.includes(type),
						)
						.map((item) => (
							<HistoryListItem
								key={`${item.timestamp.toLocaleString()}-${
									item.message
								}`}
							>
								<HistoryListItemTitle>
									{item.type !== 'snoozed_event' && (
										<Chip
											background={colors.data.dust.layer}
											color={
												colors.data.dust.textSecondary
											}
											icon={<EnvelopeIcon />}
											label="Email"
										/>
									)}
									{item.type === 'snoozed_event' && (
										<Chip
											background={colors.data.dust.layer}
											color={
												colors.data.dust.textSecondary
											}
											icon={<ClockSnoozeIcon />}
											label="Snooze"
										/>
									)}
									{item.timestamp.toPlainDate().toString()}{' '}
								</HistoryListItemTitle>
								<HistoryListItemMessage>
									{item.type === 'initial_event'
										? 'First contacted'
										: item.message}
									{item.type === 'snoozed_event'
										&& item.note && (
											<HistoryListItemNote>
												<SpeechBubbleIcon />{' '}
												<span>{item.note}</span>
											</HistoryListItemNote>
										)}
								</HistoryListItemMessage>
							</HistoryListItem>
						))}
				</HistoryList>
			</Body>
		</IconMenu>
	);
}
