import { DialogContent, DialogOverlay } from '@reach/dialog';
import '@reach/dialog/styles.css';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import styled from 'styled-components';

import { driveGreen, primaryDriveBlue } from '../colors';
import { XIcon } from '../components/icons';
import { TopLevelStat } from '../components/top-level-detail';
import { get } from '../utils/api';
import useAbortableEffect from '../utils/hooks/use-abortable-effect';
import reportError from '../utils/sentry';

import {
	probabilities,
	type Probability,
	probabilityOptions,
	weight,
} from './probability';
import { formatMillions } from './table';

const StyledDialogOverlay = styled(DialogOverlay)`
	z-index: 100;
`;

const StyledDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: solid 5px hsla(0, 0%, 0%, 0.5);
	border-radius: 10px;
	height: min-content;
	width: 100vw;
	position: relative;

	@media (min-width: 1000px) {
		width: 60vw;
	}
	.recharts-default-legend {
		display: flex;
		flex-direction: column-reverse;
	}
`;

const ChartButton = styled.button`
	appearance: none;
	background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
`;

const CloseButton = styled.button`
	appearance: none;
	background: none;
	border: none;
	padding: 3px;
	color: #aaa;
	position: absolute;
	top: 0;
	right: 0;
	transition: color 0.1s ease-in-out;
	cursor: pointer;

	&:hover {
		color: #888;
	}
`;

const StyledCloseIcon = styled(XIcon)`
	height: 30px;
	width: 30px;

	path {
		fill: currentColor;
	}
`;

const StyledTopLevelStat = styled(TopLevelStat)`
	padding: 0px;
	position: relative;
`;

const StyledTitle = styled.span`
	font-size: 24px;
	font-weight: 500;
	flex: 1;
	text-align: center;
`;

function convertMonth(datetime: string) {
	const parsed = moment(datetime);

	if (parsed.isValid()) {
		return parsed.format('MMMM');
	}
	throw Error(`Unexpected financial commitment date ${datetime}`);
}

const probabilityScale = new Map<Probability, string>([
	[probabilities.ZERO_POINT_FIVE, driveGreen.string()],
	[probabilities.ONE, primaryDriveBlue.string()],
	[probabilities.TWO, primaryDriveBlue.lighten(0.3).string()],
	[probabilities.THREE, primaryDriveBlue.lighten(0.8).string()],
	[probabilities.FOUR, primaryDriveBlue.lighten(1.2).string()],
]);

export default function FinancialCommitmentGraph(): JSX.Element {
	const [state, setState] = useState<
		Array<
			{ month: string } & {
				[probability in Probability]: number;
			}
		>
	>([]);

	useAbortableEffect(
		useCallback((signal) => {
			get<
				Array<
					{ month: string } & {
						[probability in Probability]?: number;
					}
				>
			>('/lpfundraising/pipeline', {
				signal,
			})
				.then((data) => {
					setState(
						data.map((object) => ({
							month: convertMonth(object.month),
							...(Object.fromEntries(
								Object.values(probabilities).map(
									(probability: Probability) => [
										probability,
										weight(
											object[probability] ?? 0,
											probability,
										),
									],
								),
							) as { [probability in Probability]: number }),
						})),
					);
				})
				.catch(reportError);
		}, []),
	);

	const [showDialog, setShowDialog] = React.useState(false);
	const open = () => setShowDialog(true);
	const close = () => setShowDialog(false);

	return (
		<StyledTopLevelStat>
			<BarChart
				width={224}
				height={68}
				data={state}
				margin={{ top: 9, right: 0, bottom: 9, left: 0 }}
			>
				<YAxis domain={[0, 'dataMax']} hide />
				<XAxis tick={false} height={1} />

				{[...probabilityScale.entries()].map(([key, color]) => (
					<Bar key={key} dataKey={key} stackId="a" fill={color} />
				))}
			</BarChart>
			<ChartButton onClick={open} />

			<StyledDialogOverlay isOpen={showDialog} onDismiss={close}>
				<StyledDialogContent aria-label="Weighted Financial Commitment By Month">
					<StyledTitle>
						Weighted Financial Commitment by Month
					</StyledTitle>
					<CloseButton onClick={close}>
						<StyledCloseIcon />
					</CloseButton>
					<ResponsiveContainer width="100%" height={500}>
						<BarChart
							data={state}
							margin={{
								top: 20,
								right: 30,
								left: 40,
								bottom: 20,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis
								dataKey="month"
								label={{
									value: 'Month Committed',
									position: 'bottom',
									offset: 5,
									style: {
										fontSize: 18,
									},
								}}
							/>
							<YAxis
								label={{
									value: 'Total Weighted Commitment',
									angle: -90,
									position: 'left',
									offset: -25,
									viewBox: {
										x: -15,
										y: 112,
										height: 10,
										width: 10,
									},
									style: {
										fontSize: 18,
									},
								}}
								tickFormatter={(amount) =>
									formatMillions(Number(amount))
								}
							/>
							<Tooltip
								formatter={(amount, probability) => [
									formatMillions(Number(amount)),
									probabilityOptions.get(
										parseInt(
											probability,
											10,
										) as Probability,
									),
								]}
								itemSorter={(item: { name: string }) =>
									parseFloat(
										probabilityOptions.get(
											parseInt(
												item.name,
												10,
											) as Probability,
										) ?? '',
									) * -1
								}
							/>
							<Legend
								verticalAlign="middle"
								layout="vertical"
								align="right"
								iconSize={18}
								payload={[...probabilityScale.entries()].map(
									([key, color]) => ({
										dataKey: key,
										color,
										id: key,
										type: 'rect',
										value: probabilityOptions.get(key),
									}),
								)}
							/>
							{[...probabilityScale.entries()].map(
								([key, color]) => (
									<Bar
										key={probabilityOptions.get(key)}
										dataKey={key}
										stackId="a"
										fill={color}
									/>
								),
							)}
						</BarChart>
					</ResponsiveContainer>
				</StyledDialogContent>
			</StyledDialogOverlay>
		</StyledTopLevelStat>
	);
}
