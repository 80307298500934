import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FourOhFourPage from '../components/four-oh-four-page';
import HerbieLoader from '../components/herbie-loader';
import { get, ResponseError } from '../utils/api';
import useAbortableEffect from '../utils/hooks/use-abortable-effect';

type State = { name: 'loading' } | { error: ResponseError; name: 'error' };

const initialState: State = { name: 'loading' };

export default function ReminderRedirect() {
	const params = useParams<'id'>();
	const [state, setState] = useState<State>(initialState);
	const navigate = useNavigate();

	const id = params.id;

	useAbortableEffect(
		useCallback(
			async (signal) => {
				try {
					if (id != null) {
						const destination = await get<{
							type: string;
							id: number;
							hash?: string;
						}>(`/reminders/${id}`, {
							signal,
						});
						navigate(
							`/${destination.type}/${destination.id}${
								destination.hash || ''
							}`,
							{ replace: true },
						);
					}
				} catch (error) {
					if (
						error instanceof ResponseError
						&& error.name !== 'AbortError'
					) {
						setState({ error, name: 'error' });
					}
				}
			},
			[id, navigate],
		),
	);

	if (state.name === 'loading') {
		return <HerbieLoader />;
	} else {
		return <FourOhFourPage />;
	}
}
