import { Temporal } from '@js-temporal/polyfill';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { EditableNote, ReadOnlyNote, SaveIndicator } from '../../notes';
import { selectUser } from '../../selectors';
import { colors, fonts } from '../../theme';
import formatTimeSince from '../../utils/format-time-since';
import {
	useDeletePipelineItemTransitionNote,
	useUpdatePipelineItemTransitionNote,
} from '../api';
import { PersonProfileTransitionNote } from '../types';

const Commenter = styled.div`
	${fonts.label.strong};
	align-items: center;
	color: ${colors.text.primary};
	display: flex;
	gap: 4px;
	overflow: hidden;
	white-space: nowrap;
`;

const Date = styled.span`
	${fonts.label.label};
	color: ${colors.text.secondary};
`;

const HeaderContainer = styled.div`
	${fonts.label.label};
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 8px;
	justify-content: flex-start;
	padding-left: 4px;
`;

const Logo = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	flex: 0 0 12px;
	height: 20px;
	max-width: 20px;
	min-width: 20px;
	object-fit: contain;
	object-position: center;
`;

const formatAddedAt = ({ added_at }: PersonProfileTransitionNote) => {
	const today = Temporal.Now.instant();
	const addedAt = Temporal.Instant.from(added_at);
	const diff = today
		.since(addedAt, {
			smallestUnit: 'second',
		})
		.round({ smallestUnit: 'day' });

	if (diff.days < 1) {
		return formatTimeSince(added_at);
	}

	return addedAt.toLocaleString('en-US', {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});
};

function EditableHeader({
	isSaving,
	note,
}: {
	isSaving: boolean;
	note: PersonProfileTransitionNote;
}) {
	return (
		<HeaderContainer>
			<Commenter>
				<Logo src={note.commenter.photo_url} />
				<p>
					{note.commenter.first_name} {note.commenter.last_name}
				</p>
			</Commenter>
			<Date>{formatAddedAt(note)}</Date>
			<SaveIndicator isSaving={isSaving} />
		</HeaderContainer>
	);
}

function ReadOnlyHeader({ note }: { note: PersonProfileTransitionNote }) {
	return (
		<HeaderContainer>
			<Commenter>
				<Logo src={note.commenter.photo_url} />
				<p>
					{note.commenter.first_name} {note.commenter.last_name}
				</p>
			</Commenter>
			<Date>{formatAddedAt(note)}</Date>
		</HeaderContainer>
	);
}

interface Props extends PersonProfileTransitionNote {
	itemId: number;
	mixpanelIdentifier: string;
	namespace: string;
	onCollapse: (ref: HTMLDivElement | null) => void;
	pipelineId: number;
	profileId: number;
	transitionId: number;
}

export default function TransitionNote({
	itemId,
	mixpanelIdentifier,
	namespace,
	onCollapse,
	pipelineId,
	profileId,
	transitionId,
	...note
}: Props) {
	const { mutate: updateNote, isPending: isSaving } =
		useUpdatePipelineItemTransitionNote(
			itemId,
			pipelineId,
			profileId,
			transitionId,
		);
	const { mutate: deleteNote } = useDeletePipelineItemTransitionNote(
		itemId,
		pipelineId,
		profileId,
		transitionId,
	);
	const user = useSelector(selectUser);
	const isEditable = user?.id === note.commenter.id;
	const noteRef = useRef<HTMLDivElement | null>(null);

	const noteProps = {
		key: note.id,
		namespace: `${namespace}-${note.id}`,
		note,
		onCollapse: () => {
			onCollapse(noteRef.current);
		},
		ref: noteRef,
	};

	return isEditable ? (
		<EditableNote
			buttonSize="small"
			footerVisible={false}
			HeaderComponent={EditableHeader}
			isSaving={isSaving}
			onDelete={deleteNote}
			onUpdate={updateNote}
			mixpanelIdentifier={mixpanelIdentifier}
			placeholder="Add a reply here..."
			profileType="pipelines"
			toolbarOpen={false}
			{...noteProps}
		/>
	) : (
		<ReadOnlyNote
			analyticsComponentIdentifier={mixpanelIdentifier}
			analyticsViewType="pipelines"
			HeaderComponent={ReadOnlyHeader}
			{...noteProps}
		/>
	);
}
