// @flow

import classNames from 'classnames';
import { Set as ImmutableSet } from 'immutable';
import React, {
	type Key as ReactKey,
	type Node as ReactNode,
	useCallback,
	useMemo,
	useState,
} from 'react';
import styled from 'styled-components';

import { primaryDriveBlue } from '../colors';

import { IColumn } from './column';

const TableRow = styled.tr`
	&:hover {
		td {
			box-shadow: inset 0px 5px 0px -4px ${primaryDriveBlue
						.fade(0.85)
						.toString()} /* top */,
				inset 0px -5px 0px -4px
					${primaryDriveBlue.fade(0.85).toString()} /* bottom */;
		}
	}
`;

// Extracting these constants reduces string allocations
const bodyClassName = 'HerbieTable__body';
const cellClassName = 'HerbieTable__cell';
const rowClassName = 'HerbieTable__row';
const focusedRowName = 'HerbieTable__row--focused';
const selectedRowName = 'HerbieTable__row-selected';

type RowProps<T> = {|
	columns: Array<IColumn<T>>,
	focused: boolean,
	getStickyProps: (
		column: IColumn<T>,
		columnIndex: number,
		className: string,
	) => {},
	index: number,
	observeVisibility: (target: HTMLElement, callback: () => void) => void,
	+onBlur?: (event: SyntheticFocusEvent<HTMLInputElement>) => void,
	onChange?: (row: T, patch: $Shape<T>, signal: AbortSignal) => Promise<T>,
	onFocus?: (index: number | null) => void,
	onRowKeyDown?: (event: KeyboardEvent, row: T) => void,
	onSelect?: (selection: { rowKey: ReactKey, selected: boolean }) => void,
	row: T,
	rowKey: string,
	selected: boolean,
|};

function Row<T: {}>({
	columns,
	getStickyProps,
	focused,
	index,
	observeVisibility,
	onBlur,
	onChange,
	onFocus,
	onRowKeyDown,
	onSelect,
	row,
	rowKey,
	selected,
}: RowProps<T>): ReactNode {
	const [deferred, setDeferred] = useState(true);
	const ref = useCallback(
		(target: HTMLTableSectionElement | null): void => {
			if (target) {
				observeVisibility(target, () => {
					setDeferred(false);
				});
			}
		},
		[observeVisibility],
	);
	const className = useMemo(
		() =>
			classNames(rowClassName, {
				[focusedRowName]: focused,
				[selectedRowName]: selected,
			}),
		[focused, selected],
	);

	return (
		<tbody className={bodyClassName} ref={ref}>
			<TableRow className={className}>
				{columns.map((column, columnIndex) =>
					React.createElement(column.cell, {
						deferred,
						focused,
						index,
						key: (columnIndex: ReactKey),
						onBlur,
						onChange,
						onFocus,
						onRowKeyDown,
						onSelect,
						props: getStickyProps(
							column,
							columnIndex,
							cellClassName,
						),
						row,
						rowKey,
						selected,
					}),
				)}
			</TableRow>
		</tbody>
	);
}

const MemoizedRow = React.memo(Row);

type RowsProps<T> = {|
	canChangeRow?: (row: T) => boolean,
	columns: Array<IColumn<T>>,
	focusedIndex?: number | null,
	getRowKey: (row: T) => ReactKey,
	getStickyProps: (
		column: IColumn<T>,
		columnIndex: number,
		className: string,
	) => {},
	observeVisibility: (target: HTMLElement, callback: () => void) => void,
	+onBlur?: (event: SyntheticFocusEvent<HTMLInputElement>) => void,
	onChange?: (row: T, patch: $Shape<T>, signal: AbortSignal) => Promise<T>,
	onFocus?: (index: number | null) => void,
	onRowKeyDown?: (event: KeyboardEvent, row: T) => void,
	onSelect?: (selection: { rowKey: string, selected: boolean }) => void,
	rows: Array<T>,
	// This type differs from the Table's selectedRowKeys.
	// According to https://flow.org/en/docs/react/types/#toc-react-key, a ReactKey is a `string | number`,
	// but if we specify selectedRowKeys: ImmutableSet<string>, flow complains about the following snippet:
	// `selectedRowKeys.has(getRowKey(row))`. This isn't _technically_ lying to the type-checker so let's
	// just set this and move on
	selectedRowKeys: ImmutableSet<ReactKey>,
|};

function Rows<T: {}>({
	canChangeRow,
	columns,
	focusedIndex,
	getRowKey,
	getStickyProps,
	observeVisibility,
	onBlur,
	onChange,
	onFocus,
	onRowKeyDown,
	onSelect,
	rows,
	selectedRowKeys,
}: RowsProps<T>): ReactNode {
	return rows.map((row, index) => {
		// If `canChangeRow` is passed and returns `false` for this row, we
		// don't want to pass `onChange` anymore. `onChange` is optional but not
		// nullable, so if it is passed, it must be a function. That means we
		// can't set `onChange={null}`. Making it nullable would cascade to all
		// of our column implementations that currently trust that `onChange`
		// either doesn't exist or is a function. Instead, we create a temporary
		// object that conditionally contains the `onChange` key and spread it
		// into the component's props.
		const onChangeIfCanChangeRow =
			onChange != null && (canChangeRow == null || canChangeRow(row))
				? { onChange }
				: {};

		const key = getRowKey(row);
		return (
			// $FlowFixMe: https://github.com/facebook/flow/issues/2165
			<MemoizedRow
				columns={columns}
				focused={focusedIndex === index}
				getStickyProps={getStickyProps}
				index={index}
				key={key}
				observeVisibility={observeVisibility}
				{...onChangeIfCanChangeRow}
				onBlur={onBlur}
				onFocus={onFocus}
				onRowKeyDown={onRowKeyDown}
				onSelect={onSelect}
				row={row}
				rowKey={key}
				selected={selectedRowKeys.has(key)}
			/>
		);
	});
}

// Flow currently can't handle parametric constants. This makes it impossible
// to fully type a memoized component when its props type is parametric. That
// issue is mitigated by the fact that this component is not exposed as part of
// the table's public API. The importance of the performance optimization here
// outweighs the inconvenience during development.
// $FlowFixMe: https://github.com/facebook/flow/issues/2165
export default React.memo(Rows);
