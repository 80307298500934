/**
 * @file [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?type=design&node-id=9-11599&mode=design&t=tQaqkCcIAiVoaofs-0
 */
import React from 'react';

import { SearchIcon } from '../icons/system';

import TextInput from './text-input';

type Props = React.ComponentPropsWithoutRef<typeof TextInput>;

export default React.forwardRef(function Search(
	props: Props,
	ref: React.Ref<HTMLInputElement>,
) {
	return (
		<TextInput {...props} icon={<SearchIcon />} ref={ref} type="search" />
	);
});
