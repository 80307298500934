import React, { useCallback } from 'react';
import styled from 'styled-components';

import Modal from '../components/modal';
import { colors, fonts } from '../theme';
import Button from '../theme/components/button';
import IconButton from '../theme/components/icon-button';
import TextInput from '../theme/components/text-input';
import { XIcon } from '../theme/icons/system';

import { useSnoozeOutreachCompanies } from './api';
import { type CompanyOutreach } from './types';
import useSnoozeForm from './use-snooze-form';

const StyledModal = styled(Modal)`
	min-width: min(80vw, 420px);
	padding: 20px;

	.HerbieModal__container {
		display: flex;
		flex-direction: column;
		gap: 20px;
		outline: none;
	}
`;

const Titles = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Title = styled.div`
	${fonts.h4.productive}
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	gap: 8px;
	justify-content: space-between;
`;
const Subtitle = styled.div`
	${fonts.paragraph.paragraph}
	color: ${colors.text.primary};
`;
const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Buttons = styled.div`
	display: flex;
	gap: 4px;
`;
const LabelAsterisk = styled.span`
	color: ${colors.text.danger};
`;
const SnoozeDateInput = styled(TextInput)`
	> span {
		min-height: 16px;
	}
`;

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	companyOutreaches: CompanyOutreach[];
}

export default function SnoozeModal({
	isOpen,
	onClose,
	onSubmit,
	companyOutreaches,
}: Props) {
	const { mutateAsync: snoozeOutreachCompanies, isPending: isSnoozing } =
		useSnoozeOutreachCompanies();

	const {
		handleSnoozeNotesChange,
		handleSnoozeUntilChange,
		handleSnoozeUntilInputBlur,
		snoozeNotes,
		snoozeUntil,
		snoozeUntilError,
	} = useSnoozeForm(isOpen, companyOutreaches);

	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (snoozeUntilError.status === 'danger') {
				return;
			} else {
				await snoozeOutreachCompanies({
					notes: snoozeNotes,
					outreachIds: companyOutreaches.map((company) => company.id),
					snoozeUntil: snoozeUntil ?? '',
				});
				onSubmit();
			}
		},
		[
			companyOutreaches,
			onSubmit,
			snoozeNotes,
			snoozeOutreachCompanies,
			snoozeUntil,
			snoozeUntilError,
		],
	);

	return (
		<StyledModal onClose={onClose} isOpen={isOpen} title="Snooze">
			<Titles>
				<Title>
					Follow Up Later{' '}
					<IconButton label="Close" onClick={onClose} size="small">
						<XIcon />
					</IconButton>
				</Title>
				<Subtitle>
					{companyOutreaches.length > 1
						? `These ${companyOutreaches.length} outreaches`
						: `${companyOutreaches[0]?.company?.name}`}{' '}
					will show back up in your queue on the selected date.
				</Subtitle>
			</Titles>
			<Form onSubmit={handleSubmit}>
				<SnoozeDateInput
					autoFocus
					id="snooze-date"
					label={
						<div>
							Follow up again on<LabelAsterisk>*</LabelAsterisk>
						</div>
					}
					name="snooze-date"
					onBlur={handleSnoozeUntilInputBlur}
					onChange={handleSnoozeUntilChange}
					required
					status={snoozeUntilError.status}
					statusText={snoozeUntilError.message}
					type="date"
					value={snoozeUntil || ''}
				/>
				<TextInput
					id="snooze-notes"
					label="Comment"
					name="snooze-notes"
					onChange={handleSnoozeNotesChange}
					placeholder="Add some context (optional)"
					value={snoozeNotes || ''}
				/>
				<Buttons>
					<Button
						disabled={
							snoozeUntilError.status === 'danger' || isSnoozing
						}
						type="submit"
					>
						Follow Up Later
					</Button>
					<Button onClick={onClose} type="button" variant="secondary">
						Cancel
					</Button>
				</Buttons>
			</Form>
		</StyledModal>
	);
}
