import Color from 'color';
import tailwindColors from 'tailwindcss/colors';

import type DateCohort from './cohorts';

const swatch = tailwindColors.sky;
const palette = Object.keys(swatch)
	.map((key) => parseInt(key, 10))
	.filter((key) => key >= 200 && key <= 900)
	.sort()
	.map((key) => key.toString() as keyof typeof swatch)
	.map((key) => new Color(swatch[key]));

export default function getColors(cohorts: Array<DateCohort>): Array<Color> {
	return cohorts.map(
		(_, idx, idxs) =>
			palette[
				Math.round((idx / (idxs.length - 1)) * (palette.length - 1))
			],
	);
}
