import pluralize from 'pluralize';
import React, { useState } from 'react';
import styled from 'styled-components';

import ControlButton from '../../theme/components/control-button';
import { ChevronUpIcon } from '../../theme/icons/arrows';
import { PersonProfileTransitionNote } from '../types';

const ReplyControlsContainer = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: row;
`;

const ToggleButton = styled(ControlButton)`
	& > svg {
		height: 12px;
		width: 12px;
	}
`;

export function ReplyButton({
	disabled,
	onClick,
}: {
	disabled?: boolean;
	onClick(event: React.MouseEvent): void;
}) {
	return (
		<ToggleButton
			active={false}
			disabled={disabled}
			onClick={onClick}
			variant="secondary"
			type="button"
		>
			Reply
		</ToggleButton>
	);
}

export function ToggleRepliesButton({
	count,
	expanded,
	onClick,
}: {
	count: number;
	expanded: boolean;
	onClick(event: React.MouseEvent): void;
}) {
	return (
		<ToggleButton
			active={expanded}
			onClick={onClick}
			variant="secondary"
			type="button"
		>
			{count} {pluralize('Reply', count)}
			{expanded ? <ChevronUpIcon /> : null}
		</ToggleButton>
	);
}

export function useReplyControls(
	notes: ReadonlyArray<PersonProfileTransitionNote>,
	editable: boolean,
) {
	const [renderNotes, setRenderNotes] = useState(false);
	const [autoFocusNewNote, setAutoFocusNewNote] = useState(false);

	function handleToggleNotes() {
		setRenderNotes((prev) => !prev);
		setAutoFocusNewNote(false);
	}

	function handleReplyClick() {
		setRenderNotes(true);
		setAutoFocusNewNote(true);
	}

	const ReplyControls = () => (
		<ReplyControlsContainer>
			{notes.length > 0 && (
				<ToggleRepliesButton
					count={notes.length}
					expanded={renderNotes}
					onClick={handleToggleNotes}
				/>
			)}
			{editable && <ReplyButton onClick={handleReplyClick} />}
		</ReplyControlsContainer>
	);

	return { renderNotes, autoFocusNewNote, ReplyControls };
}
