import React from 'react';
import loadable from 'react-loadable';

import authorizedRoute from '../components/authorized-route';
import HerbieLoader from '../components/herbie-loader';

const View = loadable({
	loader: () => import('./SemiAnnuals'),
	loading: HerbieLoader,
});

export const routes = authorizedRoute(
	{
		path: 'semiannuals',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.semiannuals',
);
