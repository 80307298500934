import { Temporal } from '@js-temporal/polyfill';

export function getStartOfQuarter(
	date: Temporal.PlainDate,
): Temporal.PlainDate {
	return new Temporal.PlainDate(
		date.year,
		date.month - ((date.month - 1) % 3),
		1,
	);
}

export function* generateQuarters(
	start: Temporal.PlainDate,
	end: Temporal.PlainDate = Temporal.Now.plainDateISO(),
): Generator<Temporal.PlainDate> {
	let quarter = getStartOfQuarter(start);
	while (Temporal.PlainDate.compare(quarter, end) < 0) {
		yield quarter;
		quarter = quarter.add({ months: 3 });
	}
}
