import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { colors, effects, fonts } from '../../theme';
import ControlButton from '../../theme/components/control-button';
import IconButton from '../../theme/components/icon-button';
import {
	ChevronUpDownContractIcon,
	ChevronUpDownExpandIcon,
} from '../../theme/icons/arrows';

import { type IFilterControl } from './control';
import { type IFilter } from './types';

const View = styled.div`
	${effects.shadow.shadow}
	background-color: ${colors.layer.layer};
	border-radius: 8px;
	border: 1px solid ${colors.border.subtle};
`;
const FilterContainer = styled.div`
	padding: 12px;

	&:first-child {
		background-color: ${colors.layer.layer};
		margin-bottom: 10px;
		padding-bottom: 20px;
		padding-top: 20px;
		position: sticky;
		top: 0;
	}

	&:not(:last-child) {
		border-bottom: 1px solid ${colors.border.subtle};
	}
`;
const ExpandCollapseButton = styled(IconButton)`
	border: none;

	&:hover {
		box-shadow: none;
	}

	&:focus-visible {
		outline: 2px solid ${colors.semantic.focus};
	}
`;
const Header = styled.div`
	${fonts.h5.productive}
	align-items: center;
	display: flex;
	justify-content: space-between;
`;
const HeaderLeft = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;

	&:hover ~ ${ExpandCollapseButton} {
		color: ${colors.icon.primary};
	}
`;

interface Props<T extends IFilter<string, unknown>> {
	className?: string;
	controls: Array<IFilterControl<T>>;
	filters: Array<T>;
	onFilterChange: (filter: T) => void;
	onFilterClear: (fieldName: T['fieldName'] | 'ALL') => void;
}

export default function FilterControls<T extends IFilter<string, unknown>>({
	controls,
	className,
	filters,
	onFilterChange,
	onFilterClear,
}: Props<T>) {
	const fieldNames = useMemo(
		() => controls.map(({ fieldName }) => fieldName),
		[controls],
	);

	const [openFilters, setOpenFilters] = useState<
		Record<T['fieldName'], boolean>
	>(
		fieldNames.reduce((acc, fieldName) => {
			acc[fieldName] = true;
			return acc;
		}, {} as Record<T['fieldName'], boolean>),
	);
	const allFiltersAreOpen = Object.values(openFilters).every(
		(isOpen) => isOpen,
	);

	const handleResetFilters = useCallback(
		() => onFilterClear('ALL'),
		[onFilterClear],
	);
	const handleExpandCollapse = useCallback(() => {
		setOpenFilters(
			(currentOpenFilters) =>
				Object.fromEntries(
					Object.entries(currentOpenFilters).map(([fieldName]) => [
						fieldName,
						!allFiltersAreOpen,
					]),
				) as Record<T['fieldName'], boolean>,
		);
	}, [allFiltersAreOpen]);
	const handleOpenToggle = useCallback((fieldName: T['fieldName']) => {
		setOpenFilters((currentOpenFilters) => ({
			...currentOpenFilters,
			[fieldName]: !currentOpenFilters[fieldName],
		}));
	}, []);

	useEffect(() => {
		setOpenFilters(
			fieldNames.reduce((acc, fieldName) => {
				acc[fieldName] = true;
				return acc;
			}, {} as Record<T['fieldName'], boolean>),
		);
	}, [fieldNames]);

	return (
		<View className={className}>
			<FilterContainer>
				<Header>
					<HeaderLeft onClick={handleExpandCollapse}>
						Filters
						<ExpandCollapseButton
							onClick={handleExpandCollapse}
							size="small"
						>
							{allFiltersAreOpen ? (
								<ChevronUpDownContractIcon />
							) : (
								<ChevronUpDownExpandIcon />
							)}
						</ExpandCollapseButton>
					</HeaderLeft>
					{filters.length > 0 && (
						<ControlButton
							active={false}
							onClick={handleResetFilters}
							variant="warning"
						>
							Reset
						</ControlButton>
					)}
				</Header>
			</FilterContainer>
			{controls.map((control) => {
				const filter =
					filters.find((fil) => fil.fieldName === control.fieldName)
					|| null;
				const isOpen = openFilters[control.fieldName] ?? true;

				return (
					<FilterContainer key={control.fieldName}>
						{React.createElement(control.header, {
							filter,
							isOpen,
							onClear: onFilterClear,
							onOpenToggle: handleOpenToggle,
						})}
						{isOpen
							&& React.createElement(control.control, {
								filter,
								onChange: onFilterChange,
								onClear: onFilterClear,
							})}
					</FilterContainer>
				);
			})}
		</View>
	);
}
