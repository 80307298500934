import Color from 'color';

export const activeGreen = new Color('#66bb6a');
export const alabasterWhite = new Color('#fafafa');
export const assignTeal = new Color('#329fb3');
export const backgroundGray = new Color('#f8f8f8');
export const black = new Color('#000000');
export const charcoalGray = new Color('#4a4a4a');
export const disabledGray = new Color('#aaa');
export const driveGreen = new Color('#009b7b');
export const lightGray = new Color('#C7C7C7');
export const green = new Color('#008000');
export const herbieBlack = new Color('#333');
export const herbieGray = new Color('#c4c4c4');
export const herbieDarkerGray = new Color('#bcbcbc');
export const herbieDarkestGray = new Color('#ba0000');
export const herbieMediumGray = new Color('#3a393f');
export const herbieSmartTeal = new Color('#5CBDCE');
export const herbieWindows = new Color('#5d5e5e');
export const mediumGray = new Color('#777');
export const moneyGreen = new Color('#009b7b');
export const orange = new Color('#d98635');
export const primaryDriveBlue = new Color('#007dc2');
export const purple = new Color('#4a4b8e');
export const red = new Color('#d64e74');
export const secondaryDarkDriveBlue = new Color('#0d4b6e');
export const secondaryGray = new Color('#e0e0e0');
export const sectionBorder = new Color('#d4d4d4');
export const sectionHeaderBackground = new Color('#eaecef');
export const tableRowBlue = new Color('#f8fafc');
export const yellow = new Color('#ffde56');
export const white = new Color('#ffffff');
