import {
	type DefaultError,
	type QueryClient,
	useMutation,
	type UseMutationOptions,
	type UseMutationResult,
} from '@tanstack/react-query';

import delay from '../delay';

interface UseDelayedMutationOptions<
	TData = unknown,
	TError = DefaultError,
	TVariables = void,
	TContext = unknown,
> extends UseMutationOptions<TData, TError, TVariables, TContext> {
	/**
	 * The minimum time that this mutation will remain in the pending state.
	 *
	 * If the mutation completes before this time, it will remain in the pending
	 * state until this time has elapsed. This can prevent flickering of UI
	 * pending states.
	 */
	minimumMilliseconds: number;
	// React Query allows a default mutation function, so `mutationFn` is
	// technically optional. We require it here so that we have something to
	// delay.
	mutationFn: Exclude<
		UseMutationOptions<TData, TError, TVariables, TContext>['mutationFn'],
		void
	>;
}

export default function useDelayedMutation<
	TData = unknown,
	TError = DefaultError,
	TVariables = void,
	TContext = unknown,
>(
	options: UseDelayedMutationOptions<TData, TError, TVariables, TContext>,
	queryClient?: QueryClient,
): UseMutationResult<TData, TError, TVariables, TContext> {
	const { minimumMilliseconds, mutationFn, ...rest } = options;

	return useMutation(
		{
			...rest,
			mutationFn: async (variables) => {
				const startTime = Date.now();
				const result = await mutationFn(variables);
				const elapsedTime = Date.now() - startTime;

				if (elapsedTime < minimumMilliseconds) {
					await delay(minimumMilliseconds - elapsedTime);
				}

				return result;
			},
		},
		queryClient,
	);
}
