import pluralize from 'pluralize';
import React, { useState } from 'react';
import { Button as AriaButton, DialogTrigger } from 'react-aria-components';
import styled from 'styled-components';

import { buttonStyles } from '../../../theme/components/control-button';
import {
	ArrowSortAscending,
	ChevronDownIcon,
} from '../../../theme/icons/arrows';
import { type EntityType } from '../../use-entity-type';
import { type Sorting } from '../types';
import { calculateValidFieldNames } from '../utils';

import CurrentSorting from './current-sorting';
import DISPLAY_NAMES from './display-names';
import { Dialog, Heading, Popover } from './shared-components';
import SortingActions from './sorting-actions';

const Button = styled(AriaButton)`
	${buttonStyles}
	align-items: center;
	display: flex;
	gap: 2px;
	svg {
		height: 12px;
		width: 12px;
	}
`;

interface Props {
	availableFieldNames?: Sorting['fieldName'][];
	entityType: EntityType;
	fieldNameDisplayNames?: Partial<{
		[K in keyof typeof DISPLAY_NAMES]: string;
	}>;
	isDisabled?: boolean;
	onSortAdd: (
		fieldName: Sorting['fieldName'],
		direction?: Sorting['direction'],
	) => void;
	onSortClear: () => void;
	onSortRemove: (fieldName: Sorting['fieldName']) => void;
	onSortReorder: (fieldName: Sorting['fieldName'], newIndex: number) => void;
	onSortToggle: (fieldName: Sorting['fieldName']) => void;
	sorting: Sorting[];
}

export default function ControlButton({
	availableFieldNames,
	entityType,
	fieldNameDisplayNames,
	isDisabled = false,
	onSortAdd,
	onSortClear,
	onSortRemove,
	onSortReorder,
	onSortToggle,
	sorting,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const validFieldNames = calculateValidFieldNames(entityType).filter(
		(name) =>
			availableFieldNames == null || availableFieldNames.includes(name),
	);
	const unusedFieldNames = validFieldNames.filter(
		(fieldName) =>
			!sorting.map((sort) => sort.fieldName).includes(fieldName),
	);
	const buttonText = sorting.length
		? `Sorting by ${sorting.length} ${pluralize('field', sorting.length)}`
		: 'Sort by...';

	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
			<Button active={false} isDisabled={isDisabled} variant="secondary">
				<ArrowSortAscending />
				<span>{buttonText}</span>
				<ChevronDownIcon />
			</Button>
			<Popover placement="bottom left">
				<Dialog>
					<Heading>
						<span>Sort by</span>
					</Heading>
					<CurrentSorting
						fieldNameDisplayNames={fieldNameDisplayNames}
						onSortRemove={onSortRemove}
						onSortReorder={onSortReorder}
						onSortToggle={onSortToggle}
						sorting={sorting}
					/>
					<SortingActions
						fieldNameDisplayNames={fieldNameDisplayNames}
						isClearDisabled={sorting.length === 0}
						isOpen={isOpen}
						onSortAdd={onSortAdd}
						onSortClear={onSortClear}
						unusedFieldNames={unusedFieldNames}
					/>
				</Dialog>
			</Popover>
		</DialogTrigger>
	);
}
