import { useEffect } from 'react';

import { EventName, trackEvent, ViewType } from '../analytics';

type Options = {
	eventName: EventName;
	componentIdentifier: string;
	viewType: ViewType;
	properties?: Record<string, unknown>;
};

export default function useAnalytics(
	{ eventName, componentIdentifier, viewType, properties }: Options,
	dependencies: Array<unknown> = [],
): void {
	useEffect(() => {
		trackEvent(eventName, componentIdentifier, viewType, properties);
	}, [
		eventName,
		componentIdentifier,
		viewType,
		properties,
		// This rule reports an error when it is not able to statically analyze
		// spread dependencies. However, it continues to verify we have at least
		// the minimum dependencies even if we disable this particular line
		// (try commenting out one of those above). We intentionally use this as
		// a way to specify _extra_ dependencies like IDs so that we report
		// distinct views for consecutive navigation events among the same
		// renderer and type.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		...dependencies,
	]);
}
