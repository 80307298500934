// @flow

import { combineReducers } from 'redux';

import { type Action, type RemoveItemsFromWatchListAction } from '../actions';
import { ActionTypes } from '../constants';
import createReducer from '../utils/createReducer';
import KeyedMap from '../utils/keyed-map';

type Entity = {
	id: number,
	type: string,
};

function hash(entity: Entity): string {
	return `/${entity.type}/${entity.id}`;
}

const byId = createReducer(
	{},
	{
		[ActionTypes.FUNDTRACKER_LOAD_SUCCESS]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...state[payload.id],
				...payload,
				items: payload.items.map((item) => ({
					type: item.entity.type,
					id: item.entity.id,
					extra: item.extra,
				})),
				loaded: true,
			},
		}),
		[ActionTypes.WATCHLIST_ADD_ITEM_SUCCESS]: (
			state,
			{ payload: { watchListId, item } },
		) => ({
			...state,
			[watchListId]: {
				...state[watchListId],
				items: [
					...state[watchListId].items.filter(
						(row) =>
							!(
								row.type === item.entity.type
								&& row.id === item.entity.id
							),
					),
					{
						type: item.entity.type,
						id: item.entity.id,
						extra: item.extra,
					},
				],
			},
		}),
		[ActionTypes.WATCHLIST_CREATE]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...payload,
				loaded: false,
			},
		}),
		[ActionTypes.WATCHLIST_DELETE]: (state, { payload }) => {
			const nextState = { ...state };

			delete nextState[payload.id];

			return nextState;
		},
		[ActionTypes.WATCHLIST_EDIT]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...state[payload.id],
				auto_update: payload.auto_update,
				columns: payload.columns,
				include_in_travel_plans: payload.include_in_travel_plans,
				name: payload.name,
				public: payload.public,
				send_email_update: payload.send_email_update,
			},
		}),
		[ActionTypes.WATCHLISTS_INVALIDATE]: () => ({}),
		[ActionTypes.WATCHLIST_ITEM_EDIT_SUCCESS]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...state[payload.id],
				items: state[payload.id].items.map((item) => {
					const { type, id } = payload.item.entity;

					if (item.type === type && item.id === id) {
						return {
							type,
							id,
							extra: payload.item.extra,
						};
					}

					return item;
				}),
			},
		}),
		[ActionTypes.WATCHLIST_LOAD_SUCCESS]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...state[payload.id],
				...payload,
				items: payload.items.map((item) => ({
					type: item.entity.type,
					id: item.entity.id,
					extra: item.extra,
				})),
				loaded: true,
			},
		}),
		[ActionTypes.WATCHLIST_REMOVE_ITEMS_SUCCESS]: (
			state,
			{ payload }: RemoveItemsFromWatchListAction,
		) => {
			const nextState = { ...state };
			const deletedCompanyIds = payload.items.companies || [];
			const deletedPeopleIds = payload.items.people || [];

			const newItems = nextState[payload.watchListId].items.filter(
				(item) => {
					if (item.type === 'companies') {
						return !deletedCompanyIds.includes(item.id);
					} else if (item.type === 'people') {
						return !deletedPeopleIds.includes(item.id);
					} else {
						return true;
					}
				},
			);

			nextState[payload.watchListId].items = newItems;
			return nextState;
		},
		[ActionTypes.WATCHLISTS_LOAD_SUCCESS]: (state, { payload }) => {
			const nextState = { ...state };

			payload.forEach((watchList) => {
				const existingItems: KeyedMap<Entity, Entity> = new KeyedMap(
					hash,
				);

				if (state[watchList.id]) {
					state[watchList.id].items.forEach((item) => {
						existingItems.set(item, item);
					});
				}

				nextState[watchList.id] = {
					...watchList,
					items: watchList.items.map(({ type, id }) => ({
						...(existingItems.get({ type, id }) || {}),
						type,
						id,
					})),
					loaded:
						(state[watchList.id] && state[watchList.id].loaded)
						|| false,
				};
			});

			return nextState;
		},
	},
);

const loaded = createReducer(false, {
	[ActionTypes.WATCHLISTS_LOAD_SUCCESS]: () => true,
	[ActionTypes.WATCHLISTS_INVALIDATE]: () => false,
});

export default combineReducers<_, Action>({
	byId,
	loaded,
});
