import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { type MarketMapTree, useMarketMaps } from '../../market-maps';
import { FilterLabel } from '../../theme/components/filters';
import { XIcon } from '../../theme/icons/system';

import {
	type FilterControlHeaderProps,
	type FilterControlProps,
	type IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import TextComboBox from './text-combo-box';
import { type MarketMapsFilter } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: space-between;
`;
const MarketMaps = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const MarketMapLabels = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const MarketMapLabel = styled.div`
	display: flex;
	gap: 4px;

	& > svg {
		height: 16px;
		width: 16px;
	}
`;

function safeParseInt(value: string) {
	const parsed = parseInt(value, 10);
	return Number.isNaN(parsed) ? null : parsed;
}

type Option = { id: number; text: string; subtext: string };
function buildOption({ id, name, owner }: MarketMapTree): Option {
	return {
		id,
		text: name,
		subtext: `(${owner.first_name})`,
	};
}

interface MarketMapsComboBoxProps {
	id: string;
	label: string;
	onDeselect: (mapId: number) => void;
	onSelect: (mapId: number) => void;
	selectedMarketMaps: number[];
}

function MarketMapsComboBox({
	id,
	label,
	onDeselect,
	onSelect,
	selectedMarketMaps,
}: MarketMapsComboBoxProps) {
	const { data: allMarketMaps, isSuccess: marketMapsLoaded } =
		useMarketMaps();

	const options = useMemo<Option[]>(
		() => (marketMapsLoaded && allMarketMaps.map(buildOption)) || [],
		[allMarketMaps, marketMapsLoaded],
	);
	const selectableOptions = useMemo(
		() =>
			options.filter((option) => !selectedMarketMaps.includes(option.id)),
		[options, selectedMarketMaps],
	);
	const recentOptions = useMemo<Option[]>(
		() =>
			(marketMapsLoaded
				&& allMarketMaps
					.filter((option) => !selectedMarketMaps.includes(option.id))
					.toSorted((a, b) =>
						(b.last_viewed_at ?? '').localeCompare(
							a.last_viewed_at ?? '',
						),
					)
					.map(buildOption)
					.slice(0, 5))
			|| [],
		[allMarketMaps, selectedMarketMaps, marketMapsLoaded],
	);

	const handleSelect = useCallback(
		(mapId: number | string | null) => {
			const parsed =
				typeof mapId === 'number' ? mapId : safeParseInt(mapId ?? '');
			if (parsed !== null) {
				onSelect(parsed);
			}
		},
		[onSelect],
	);

	return (
		<MarketMaps>
			<TextComboBox
				allOptions={selectableOptions}
				defaultOptions={recentOptions}
				defaultValue=""
				id={id}
				label={label}
				onSubmit={handleSelect}
				placeholder="Find a Market Map"
			/>
			<MarketMapLabels>
				{marketMapsLoaded
					&& selectedMarketMaps.map((mapId) => (
						<FilterLabel
							isActive
							key={mapId}
							label={
								<MarketMapLabel>
									{
										options.find(
											(option) => option.id === mapId,
										)?.text
									}
									<XIcon />
								</MarketMapLabel>
							}
							onAction={() => {
								onDeselect(mapId);
							}}
						/>
					))}
			</MarketMapLabels>
		</MarketMaps>
	);
}
type ValueType = keyof MarketMapsFilter['value'];
const FIELD_NAME: MarketMapsFilter['fieldName'] = 'marketMaps';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<MarketMapsFilter>) {
	let marketMaps: {
		include: number[];
		exclude: number[];
	};

	if (filter) {
		marketMaps = filter.value;
	} else {
		marketMaps = {
			include: [],
			exclude: [],
		};
	}

	const handleSelect = useCallback(
		(type: ValueType, mapId: number) => {
			onChange({
				fieldName: FIELD_NAME,
				value: {
					...marketMaps,
					[type]: [...marketMaps[type], mapId],
				},
			});
		},
		[marketMaps, onChange],
	);

	const handleRemove = useCallback(
		(type: ValueType, mapId: number) => {
			const updatedMaps = marketMaps[type].filter((id) => id !== mapId);
			const otherType = type === 'include' ? 'exclude' : 'include';
			const otherTypeHasMaps = marketMaps[otherType].length > 0;

			if (updatedMaps.length === 0 && !otherTypeHasMaps) {
				onClear(FIELD_NAME);
				return;
			}
			onChange({
				fieldName: FIELD_NAME,
				value: {
					...marketMaps,
					[type]: updatedMaps,
				},
			});
		},
		[marketMaps, onChange, onClear],
	);

	return (
		<Container>
			<MarketMapsComboBox
				id="include-market-maps-filter"
				label="Included in..."
				onDeselect={(id) => handleRemove('include', id)}
				onSelect={(id) => handleSelect('include', id)}
				selectedMarketMaps={marketMaps.include}
			/>
			<MarketMapsComboBox
				id="exclude-market-maps-filter"
				label="Excluded from..."
				onDeselect={(id) => handleRemove('exclude', id)}
				onSelect={(id) => handleSelect('exclude', id)}
				selectedMarketMaps={marketMaps.exclude}
			/>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<MarketMapsFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Market Maps"
		/>
	);
}

const Control: IFilterControl<MarketMapsFilter> = {
	control: FilterControl,
	description: () => {
		return [];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default Control;
