import { Temporal } from '@js-temporal/polyfill';
import pluralize from 'pluralize';
import React from 'react';
import styled from 'styled-components';

import Tooltip, { UnstyledTooltipContent } from '../components/tooltip';
import { colors, effects, fonts } from '../theme';
import Chip from '../theme/components/chip';
import { ArrowLoopRightIcon } from '../theme/icons/arrows';
import { CalendarIcon, ClockSnoozeIcon } from '../theme/icons/time';
import assertExhaustive from '../utils/assert-exhaustive';
import nonNullable from '../utils/non-nullable';

import { calculateDaysAgo, useAcknowledgedOutreachFollowUps } from './api';
import { type CompanyOutreach, type OutreachState } from './types';

const chipColors: Record<
	OutreachState,
	{
		default: { background: string; color: string };
	}
> = {
	actionable: {
		default: {
			background: colors.data.orange.layerSubtle,
			color: colors.data.orange.iconSecondary,
		},
	},
	expired: {
		default: {
			background: colors.data.red.layerSubtle,
			color: colors.data.red.iconSecondary,
		},
	},
	expiring_soon: {
		default: {
			background: colors.data.red.layerSubtle,
			color: colors.data.red.iconSecondary,
		},
	},
	monitoring: {
		default: {
			background: colors.data.blue.layer,
			color: colors.data.blue.iconSecondary,
		},
	},
	snoozed: {
		default: {
			background: colors.data.dust.layer,
			color: colors.data.dust.iconSecondary,
		},
	},
};
const followUpAcknowledgedColors = {
	default: {
		background: colors.data.dust.layer,
		color: colors.data.dust.iconSecondary,
	},
};

const StyledTooltip = styled(Tooltip)`
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	min-width: 80px;
	height: 100%;

	&:hover {
		cursor: default;
	}
`;

const TooltipContent = styled(UnstyledTooltipContent)`
	${effects.shadow.shadow};
	background: ${colors.layer.layer};
	border: 1px solid ${colors.border.subtle};
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 8px;
	width: 180px;
`;

const TooltipHeader = styled.div`
	${fonts.paragraph.strong};
	color: ${colors.text.primary};
`;

const TooltipExplanation = styled.div`
	${fonts.label.label};
	color: ${colors.text.primary};
`;

function ActionableTip() {
	return (
		<>
			<TooltipHeader>Actionable</TooltipHeader>
			<TooltipExplanation>
				It's been at least 3 days since you reached out. Now is a good
				time to follow up.
			</TooltipExplanation>
		</>
	);
}

function ExpiredTip() {
	return (
		<>
			<TooltipHeader>Expiring Today</TooltipHeader>
			<TooltipExplanation>
				This message is at least 14 days old and will be been
				automatically dismissed tonight without further action
			</TooltipExplanation>
		</>
	);
}

function ExpiringSoonTip() {
	return (
		<>
			<TooltipHeader>Expiring soon</TooltipHeader>
			<TooltipExplanation>
				This message is over 10 days old and will be automatically
				dismissed soon without further action
			</TooltipExplanation>
		</>
	);
}

function MonitoringTip() {
	return (
		<>
			<TooltipHeader>Monitoring</TooltipHeader>
			<TooltipExplanation>
				This message was sent within the past 1-3 days and does not
				require your immediate attention.
			</TooltipExplanation>
		</>
	);
}

function FollowUpAcknowledgedTip() {
	return (
		<>
			<TooltipHeader>Follow up detected</TooltipHeader>
			<TooltipExplanation>
				We haven't yet synced the email you just sent. This message will
				move to monitoring when we do.
			</TooltipExplanation>
		</>
	);
}

function SnoozedTip() {
	return (
		<>
			<TooltipHeader>Snoozed</TooltipHeader>
			<TooltipExplanation>
				This message is snoozed. We won't bother you about it until the
				selected date.
			</TooltipExplanation>
		</>
	);
}

function StateTip({ outreach }: { outreach: CompanyOutreach }) {
	const state = outreach.state;

	switch (state) {
		case 'actionable':
			return <ActionableTip />;
		case 'expired':
			return <ExpiredTip />;
		case 'expiring_soon':
			return <ExpiringSoonTip />;
		case 'monitoring':
			return <MonitoringTip />;
		case 'snoozed':
			return <SnoozedTip />;
		default:
			assertExhaustive(state);
	}
}

function useTooltipConfig(outreach: CompanyOutreach) {
	const { data: acknowledgedFollowUps } = useAcknowledgedOutreachFollowUps();
	const acknowledgedFollowUpAt = acknowledgedFollowUps[outreach.id] ?? null;

	const maxTimestamp = [outreach.mostRecentOutreachAt, outreach.snoozedUntil]
		.filter(nonNullable)
		.toSorted((a, b) => Temporal.ZonedDateTime.compare(b, a))[0];

	const showAcknowledgedFollowUpTip =
		acknowledgedFollowUpAt
		&& Temporal.ZonedDateTime.compare(maxTimestamp, acknowledgedFollowUpAt)
			< 0;

	const chipColor = showAcknowledgedFollowUpTip
		? followUpAcknowledgedColors
		: chipColors[outreach.state];

	let icon = <CalendarIcon />;
	if (showAcknowledgedFollowUpTip) {
		icon = <ArrowLoopRightIcon />;
	} else if (outreach.state === 'snoozed') {
		icon = <ClockSnoozeIcon />;
	}

	return {
		chipColor,
		icon,
		showAcknowledgedFollowUpTip,
	};
}

export function TooltipContents({
	companyOutreach,
}: {
	companyOutreach: CompanyOutreach;
}) {
	const { showAcknowledgedFollowUpTip } = useTooltipConfig(companyOutreach);

	return showAcknowledgedFollowUpTip ? (
		<FollowUpAcknowledgedTip />
	) : (
		<StateTip outreach={companyOutreach} />
	);
}

export function StateChip({
	companyOutreach,
}: {
	companyOutreach: CompanyOutreach;
}) {
	const { chipColor, icon, showAcknowledgedFollowUpTip } =
		useTooltipConfig(companyOutreach);

	return (
		<>
			<Chip
				background={chipColor.default.background}
				color={chipColor.default.color}
				icon={icon}
			/>
			{showAcknowledgedFollowUpTip
				? 'Syncing...'
				: stateTimeString(companyOutreach)}
		</>
	);
}

function stateTimeString(outreach: CompanyOutreach): string {
	if (outreach.state === 'snoozed' && outreach.snoozedUntil) {
		const snoozedUntilDate = outreach.snoozedUntil.toPlainDate();
		const currentYear = Temporal.Now.zonedDateTimeISO('UTC').year;
		if (snoozedUntilDate.year === currentYear) {
			return `until ${snoozedUntilDate.month}/${snoozedUntilDate.day}`;
		} else {
			return `until ${snoozedUntilDate.month}/${snoozedUntilDate.day}/${snoozedUntilDate.year}`;
		}
	} else {
		const daysAgo = calculateDaysAgo(outreach);
		return `${daysAgo} ${pluralize('day', daysAgo)} ago`;
	}
}

interface Props {
	companyOutreach: CompanyOutreach;
}

export default function StateCell({ companyOutreach }: Props) {
	return (
		<StyledTooltip
			content={
				<TooltipContent crossOffset={100} placement="top">
					<TooltipContents companyOutreach={companyOutreach} />
				</TooltipContent>
			}
			delay={100}
		>
			<StateChip companyOutreach={companyOutreach} />
		</StyledTooltip>
	);
}
