import d3 from 'd3';
import identity from 'lodash.identity';
import nv from 'nvd3';
import type { Margin, Nvd3Element } from 'nvd3';
import React, { Component } from 'react';

type Props = {
	barsForceY?: number | Array<number>;
	data: Array<{
		key: string;
		values: Array<ValueData>;
	}>;
	linesForceY?: number | Array<number>;
	margin?: Margin;
	options?: Record<string, unknown>;
	title?: string;
	xAxisLabel?: string;
	xAxisTickFormat?: (axis: string | number) => string;
	y1AxisLabel?: string;
	y1AxisTickFormat?: (axis: string | number) => string;
	y2AxisLabel?: string;
	y2AxisTickFormat?: (axis: string | number) => string;
} & typeof defaultProps;

const defaultProps = {
	title: '',
	xAxisLabel: '',
	xAxisTickFormat: identity,
	y1AxisLabel: '',
	y1AxisTickFormat: identity,
	y2AxisLabel: '',
	y2AxisTickFormat: identity,
};

interface ValueData {
	label: string;
	value: number;
}

export default class LinePlusBarChart extends Component<Props> {
	static defaultProps = defaultProps;

	buildMultiBarChart = (ref: SVGSVGElement | null): void => {
		if (!ref) return;

		nv.addGraph((): Nvd3Element => {
			const chart = nv.models
				.linePlusBarChart()
				.x((d: ValueData) => d.label)
				.y((d: ValueData) => d.value);

			if (this.props.margin) {
				chart.margin(this.props.margin);
			}
			if (this.props.options) {
				chart.options(this.props.options);
			}

			chart.xAxis
				.axisLabel(this.props.xAxisLabel)
				.tickFormat(this.props.xAxisTickFormat);
			chart.y1Axis
				.axisLabel(this.props.y1AxisLabel)
				.tickFormat(this.props.y1AxisTickFormat);
			chart.y2Axis
				.axisLabel(this.props.y2AxisLabel)
				.tickFormat(this.props.y2AxisTickFormat);

			if (typeof this.props.barsForceY !== 'undefined') {
				chart.bars.forceY(
					Array.isArray(this.props.barsForceY)
						? this.props.barsForceY
						: [this.props.barsForceY],
				);
			}

			if (typeof this.props.linesForceY !== 'undefined') {
				chart.bars.forceY(
					Array.isArray(this.props.linesForceY)
						? this.props.linesForceY
						: [this.props.linesForceY],
				);
			}

			const selectedChart = d3
				.select(ref)
				.datum(this.props.data)
				.call(chart);

			// Get Chart width the top spacing for title alignment
			const rect = ref.getBoundingClientRect();
			selectedChart
				.append('text')
				.attr('x', rect.width / 2)
				.attr('y', rect.top / 5)
				.attr('font-size', 16)
				.attr('text-anchor', 'middle')
				.text(this.props.title);

			nv.utils.windowResize(() => chart.update());
			return chart;
		});
	};

	render(): JSX.Element {
		return (
			<svg className="LinePlusBarChart" ref={this.buildMultiBarChart} />
		);
	}
}
