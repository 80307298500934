// @flow

import omit from 'lodash.omit';
import React from 'react';
import type { ComponentType as ReactComponentType } from 'react';

import CheckboxSearchFilterOption from './checkbox-filter-option';
import type { DisplayProps } from './description';
import SearchFilter from './filter';
import type { FilterComponentProps } from './filters';

export type CheckboxDisplayProps = {
	onRemove: () => void,
	punctuator: string,
	resultCount: ?number,
	value: Array<string>,
};

type Props = {|
	...FilterComponentProps,
	options: Array<string>,
|};

export default (
	label: string,
	field: string,
	Display: ReactComponentType<CheckboxDisplayProps>,
	componentIdentifier: string,
): ReactComponentType<Props> => {
	const selectFilter = (filters) =>
		typeof filters[field] === 'undefined' ? [] : filters[field];

	const updateFilters = (filters, value) =>
		value.length === 0
			? omit(filters, field)
			: { ...filters, [field]: value };

	class CheckboxSearchFilter extends React.Component<Props> {
		static get displayComponent() {
			return CheckboxSearchFilterDisplay;
		}

		static get field() {
			return field;
		}

		handleChange = (option: string, checked: boolean) => {
			const { filters } = this.props;
			const value = selectFilter(filters);

			if (checked) {
				if (!value.includes(option)) {
					this.props.onChange(
						updateFilters(filters, [...value, option]),
						componentIdentifier,
					);
				}
			} else {
				if (value.includes(option)) {
					this.props.onChange(
						updateFilters(
							filters,
							value.filter((val) => val !== option),
						),
						componentIdentifier,
					);
				}
			}
		};

		render() {
			const value = selectFilter(this.props.filters);

			return (
				<SearchFilter label={label}>
					<ul>
						{this.props.options.map((option) => (
							<li key={option}>
								<CheckboxSearchFilterOption
									checked={value.includes(option)}
									label={option}
									onChange={this.handleChange}
									value={option}
								/>
							</li>
						))}
					</ul>
				</SearchFilter>
			);
		}
	}

	class CheckboxSearchFilterDisplay extends React.Component<DisplayProps> {
		static defaultProps = {
			punctuator: '',
		};

		static get key() {
			return field;
		}

		static countValues(filters) {
			return selectFilter(filters).length;
		}

		handleRemove = (removed) => {
			this.props.onChange(
				updateFilters(
					this.props.filters,
					selectFilter(this.props.filters).filter(
						(option) => option !== removed,
					),
				),
				`clear-${componentIdentifier}`,
			);
		};

		render() {
			const sortedSelections = selectFilter(this.props.filters)
				.slice()
				.sort((a, b) => a.localeCompare(b));

			return (
				<Display
					onRemove={this.handleRemove}
					punctuator={this.props.punctuator}
					resultCount={this.props.resultCount}
					value={sortedSelections}
				/>
			);
		}
	}

	return CheckboxSearchFilter;
};
