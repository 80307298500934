import numeral from 'numeral';
import React from 'react';

import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import DiscreteBarChart from './charts/discrete-bar-chart';
import GenericChart from './charts/generic-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funds/buckets/?year=2016';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('$0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'Bucket',
		values: dataArr.map((bucket) => ({
			label: `${numeral(bucket.start)
				.format('$0.0a')
				.toUpperCase()} - ${numeral(bucket.end)
				.format('$0.0a')
				.toUpperCase()}`,
			value: bucket.total_dollars,
		})),
	},
];

export default class YearlyFundDollarsByBucket extends React.Component {
	static getDetailCard() {
		return YearlyFundDollarsByBucketDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 90 }}
				onDataFetch={this.handleDataFetch}
				title="2016 Dollars Raised by Bucket"
				xAxisLabel="Fund Bucket"
				yAxisLabel="Dollars Raised"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class YearlyFundDollarsByBucketDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/dollars-by-bucket.png"
				statName="2016 Fund Dollars Raised By Bucket"
			/>
		);
	}
}
