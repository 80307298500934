import d3 from 'd3';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import GenericChart from '../charts/generic-chart';
import MultiBarChart from '../charts/multi-bar-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/yearly_portfolio_revenue?fund=1';
let data = null;

const xAxisTickFormat = (date) => date;
const yAxisTickFormat = () => d3.format('.3s');

const formatData = (stats) => {
	// These are the keys coming from the api
	return Object.keys(stats).map((key) => {
		return {
			key,
			values: stats[key],
		};
	});
};

export default class SemiAnnualFund1YearlyPortfolioRevenue extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund1YearlyPortfolioRevenueDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showControls: true,
			stacked: true,
		};

		// I have no idea why yAxisTickFormat needs to be called, but the y axis shows source code otherwise...
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				options={options}
				xAxisTickFormat={xAxisTickFormat}
				yAxisLabel="Revenue"
				yAxisTickFormat={yAxisTickFormat()}
			>
				{MultiBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund1YearlyPortfolioRevenueDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/yearly_portfolio_breakdown.png"
				statName="Fund I Yearly Breakdown of Portfolio Revenue"
			/>
		);
	}
}
