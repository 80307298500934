import d3 from 'd3';
import React from 'react';

import './industries-contacted.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import DiscreteBarChart from './charts/discrete-bar-chart';
import GenericChart from './charts/generic-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/industries/contacted/';
let data = null;

const yAxisTickFormat = () => d3.format(',.0d');

const formatData = (dataArr) => [
	{
		key: 'Contacted',
		values: dataArr.map((industry) => ({
			label: industry.name,
			value: industry.companies,
		})),
	},
];

export default class IndustriesContacted extends React.Component {
	static getDetailCard() {
		return IndustriesContactedDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 85 }}
				onDataFetch={this.handleDataFetch}
				title="Top Industries Contacted"
				xAxisLabel="Industry"
				yAxisLabel="Companies Contacted"
				yAxisTickFormat={yAxisTickFormat()}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class IndustriesContactedDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/industries_contacted.png"
				statName="Industries Contacted"
			/>
		);
	}
}
