import React, { useCallback } from 'react';
import {
	Button as AriaButton,
	GridList as AriaGridList,
	GridListItem as AriaGridListItem,
	type Key as AriaSelectionKey,
	type DroppableCollectionReorderEvent,
	useDragAndDrop,
} from 'react-aria-components';
import styled from 'styled-components';

import { colors, fonts } from '../../../theme';
import { ArrowDown, ArrowUp } from '../../../theme/icons/arrows';
import { DraggableIcon } from '../../../theme/icons/document';
import { XIcon } from '../../../theme/icons/system';
import { type Sorting } from '../types';

import DISPLAY_NAMES from './display-names';

const GridList = styled(AriaGridList)`
	display: flex;
	flex-direction: column;

	.react-aria-DropIndicator {
		&[data-drop-target] {
			outline: 1px solid ${colors.semantic.focus};
		}

		@supports not selector(:has(.foo)) {
			/* Undo gap in browsers that don't support :has */
			margin-bottom: -2px;
		}
	}
`;
const GridListItem = styled(AriaGridListItem)`
	cursor: pointer;
	outline: none;

	&[data-focus-visible] {
		& > div > div {
			outline: 2px solid ${colors.semantic.focus};
		}
	}

	& > div {
		align-items: center;
		/* react-aria-components sets display: content directly on the html element */
		/* without !important, we cannot make the list item a grid container */
		display: grid !important;
		gap: 0px;
		/* draggable-icon sort-toggle remove-button */
		grid-template-columns: min-content 1fr min-content;
		outline: none;
		padding: 6px 8px;

		& > div {
			align-items: center;
			display: flex;
			gap: 4px;
			padding: 0 4px;
		}

		& > div > span {
			&:first-of-type {
				${fonts.paragraph.paragraph}
				color: ${colors.text.primary};
				padding-right: 6px;
				white-space: nowrap;
			}

			&:not(:first-of-type) {
				${fonts.label.label}
				color: ${colors.text.secondary};
				white-space: nowrap;
			}
		}

		& > div > svg {
			color: ${colors.icon.secondary};

			&:first-of-type {
				height: 16px;
				width: 16px;
			}

			&:not(:first-of-type) {
				height: 12px;
				width: 12px;
			}
		}
	}
`;
const SortRemoveButton = styled(AriaButton)`
	apparance: none;
	background: none;
	border: none;
	cursor: pointer;
	padding-right: 0;

	&[data-focus-visible] {
		outline: 2px solid ${colors.semantic.focus};
	}

	& > svg {
		height: 12px;
		width: 12px;
	}
`;
const SortDragButton = styled(AriaButton)`
	align-items: center;
	apparance: none;
	background: none;
	border: none;
	color: ${colors.icon.secondary};
	cursor: pointer;
	display: flex;
	padding-left: 0;

	&[data-focus-visible] {
		outline: 2px solid ${colors.semantic.focus};
	}

	& > svg {
		height: 16px;
		width: 16px;
	}
`;

interface Props {
	fieldNameDisplayNames?: Partial<{
		[K in keyof typeof DISPLAY_NAMES]: string;
	}>;
	onSortRemove: (fieldName: Sorting['fieldName']) => void;
	onSortReorder: (fieldName: Sorting['fieldName'], newIndex: number) => void;
	onSortToggle: (fieldName: Sorting['fieldName']) => void;
	sorting: Sorting[];
}

export default function CurrentSorting({
	fieldNameDisplayNames,
	sorting,
	onSortRemove,
	onSortReorder,
	onSortToggle,
}: Props) {
	const handleSortToggle = useCallback(
		(fieldName: AriaSelectionKey) => {
			onSortToggle(fieldName as Sorting['fieldName']);
		},
		[onSortToggle],
	);

	const onReorder = useCallback(
		({ keys, target }: DroppableCollectionReorderEvent) => {
			const fieldName = Array.from(keys)[0];
			if (fieldName === target.key) return;

			const targetIndex = sorting.findIndex(
				(sort) => sort.fieldName === target.key,
			);
			const newIndex =
				target.dropPosition === 'before'
					? targetIndex
					: targetIndex + 1;

			onSortReorder(fieldName as Sorting['fieldName'], newIndex);
		},
		[onSortReorder, sorting],
	);

	const { dragAndDropHooks } = useDragAndDrop({
		acceptedDragTypes: ['sort'],
		getItems: () => sorting.map((sort) => ({ sort: JSON.stringify(sort) })),
		onReorder,
	});

	return (
		<GridList
			aria-label="Current Sorting"
			dragAndDropHooks={dragAndDropHooks}
			onAction={handleSortToggle}
		>
			{sorting.map(({ fieldName, direction }) => (
				<GridListItem
					key={fieldName}
					id={fieldName}
					textValue={fieldName}
				>
					<SortDragButton slot="drag">
						<DraggableIcon />
					</SortDragButton>
					<div>
						<span>
							{fieldNameDisplayNames?.[fieldName]
								|| DISPLAY_NAMES[fieldName]}
						</span>
						{direction === 'ASCENDING' ? (
							<ArrowUp />
						) : (
							<ArrowDown />
						)}
						<span>
							{direction === 'ASCENDING'
								? 'Ascending'
								: 'Descending'}
						</span>
					</div>
					<SortRemoveButton onPress={() => onSortRemove(fieldName)}>
						<XIcon />
					</SortRemoveButton>
				</GridListItem>
			))}
		</GridList>
	);
}
