/**
 * @file
 * Arrow icons from [Figma][1].
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5579&mode=dev
 */
import React from 'react';

export function Arrow2Collapse(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M14.021 8.58576L14.021 4H12.021L12.021 12L20.021 12V9.99996L15.4352 9.99996L19.4709 5.96436L18.0567 4.55016L14.021 8.58576Z"
				fill="currentColor"
			/>
			<path
				d="M9 16.4351L5.96444 19.4706L4.55024 18.0564L7.5858 15.0209L3 15.0209V13.0209L11 13.0209L11 21.0208H9L9 16.4351Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function Arrow2Expand(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" {...props}>
			<path
				d="M9.00525 5.99217L9.00525 3.69929L6.98741 5.71709L6.28031 5.00999L8.29815 2.99219L6.00525 2.99219V1.99219H10.0052L10.0052 5.99217H9.00525Z"
				fill="currentColor"
			/>
			<path
				d="M4.51253 6.77774L2.99475 8.29549V6.00261H1.99475V10.0026H5.99475V9.00259H3.70185L5.21963 7.48484L4.51253 6.77774Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowDown(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.9999 16.1716L18.3638 10.8076L19.778 12.2218L11.9999 20L4.22168 12.2218L5.63589 10.8076L10.9999 16.1716V4H12.9999V16.1716Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowDownLineBottomIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M0 17H18V19H0V17ZM10 11.1716L16.0711 5.1005L17.4853 6.51472L9 15L0.51472 6.51472L1.92893 5.1005L8 11.1716V0H10V11.1716Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowDownLineIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M1.5 9.5H10.5V10.5H1.5V9.5ZM6.5 6.5858L9.53555 3.55025L10.2427 4.25736L6 8.5L1.75736 4.25736L2.46446 3.55025L5.5 6.5858V1H6.5V6.5858Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowLeftLineRight(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 16 16"
			fill="none"
			{...props}
		>
			<path
				d="M6.71407 3.28613L7.65687 4.22894L4.55227 7.33353L10.6669 7.3336V8.66693L4.5523 8.66687L7.65687 11.7714L6.71407 12.7142L2 8.0002L6.71407 3.28613ZM12.0001 12.6668V3.33347H13.3335V12.6668H12.0001Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowLoopRightIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M15 3.88468V6.5H13V0.5L19 0.5V2.5L16.501 2.5C18.9293 4.32446 20.5 7.2288 20.5 10.5C20.5 16.0228 16.0228 20.5 10.5 20.5C4.97715 20.5 0.5 16.0228 0.5 10.5C0.5 4.97715 4.97715 0.5 10.5 0.5V2.5C6.08172 2.5 2.5 6.08172 2.5 10.5C2.5 14.9183 6.08172 18.5 10.5 18.5C14.9183 18.5 18.5 14.9183 18.5 10.5C18.5 7.75033 17.1128 5.32466 15 3.88468Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowUp(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.9999 7.82843V20H10.9999V7.82843L5.63589 13.1924L4.22168 11.7782L11.9999 4L19.778 11.7782L18.3638 13.1924L12.9999 7.82843Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowUpLineBottomIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3 19H21V21H3V19ZM13 5.82843V17H11V5.82843L4.92893 11.8995L3.51472 10.4853L12 2L20.4853 10.4853L19.0711 11.8995L13 5.82843Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowRightIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M8.0858 5.50044L5.4038 2.81843L6.1109 2.11133L10 6.00044L6.1109 9.88949L5.4038 9.18239L8.0858 6.50044H2V5.50044H8.0858Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowRightUp(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowSortAscending(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path
				d="M19 3L23 8H20V20H18V8H15L19 3ZM14 18V20H3V18H14ZM14 11V13H3V11H14ZM12 4V6H3V4H12Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ArrowSortDescending(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path
				d="M20 4V16H23L19 21L15 16H18V4H20ZM12 18V20H3V18H12ZM14 11V13H3V11H14ZM14 4V6H3V4H14Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChevronDownIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M11.9997 13.1714L16.9495 8.22168L18.3637 9.63589L11.9997 15.9999L5.63574 9.63589L7.04996 8.22168L11.9997 13.1714Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChevronLeftIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChevronRightIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M13.1714 12.0007L8.22168 7.05093L9.63589 5.63672L15.9999 12.0007L9.63589 18.3646L8.22168 16.9504L13.1714 12.0007Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChevronUpDownContractIcon(
	props: React.SVGProps<SVGSVGElement>,
) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M18.2074 18.7932L12.0003 12.5861L5.79319 18.7932L7.20739 20.2074L12.0003 15.4145L16.7932 20.2074L18.2074 18.7932ZM5.79309 5.20706L12.0002 11.4142L18.2073 5.20706L16.7931 3.79285L12.0002 8.58574L7.20729 3.79285L5.79309 5.20706Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChevronUpDownExpandIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M12.1385 6.02869L8.00044 1.89062L3.86237 6.02869L4.80517 6.97153L8.00044 3.77624L11.1957 6.97153L12.1385 6.02869ZM3.8623 9.97133L8.00037 14.1094L12.1384 9.97133L11.1956 9.02846L8.00037 12.2237L4.8051 9.02846L3.8623 9.97133Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function ChevronUpIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M11.9997 10.8284L7.04996 15.7782L5.63574 14.364L11.9997 8L18.3637 14.364L16.9495 15.7782L11.9997 10.8284Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function PaperAirplane1Outline(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M3.5 1.3457C3.58425 1.3457 3.66714 1.36699 3.74096 1.4076L22.2034 11.562C22.4454 11.695 22.5337 11.9991 22.4006 12.241C22.3549 12.3241 22.2865 12.3925 22.2034 12.4382L3.74096 22.5925C3.499 22.7256 3.19497 22.6374 3.06189 22.3954C3.02129 22.3216 3 22.2387 3 22.1544V1.8457C3 1.56956 3.22386 1.3457 3.5 1.3457ZM5 4.38261V11.0001H10V13.0001H5V19.6175L18.8499 12.0001L5 4.38261Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function RightUpSquareIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10 6V8H5V19H16V14H18V20C18 20.5523 17.5523 21 17 21H4C3.44772 21 3 20.5523 3 20V7C3 6.44772 3.44772 6 4 6H10ZM21 3V11H19L18.9999 6.413L11.2071 14.2071L9.79289 12.7929L17.5849 5H13V3H21Z"
				fill="currentColor"
			/>
		</svg>
	);
}
