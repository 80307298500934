// @flow

import React from 'react';

import Header from '../components/header';
import { Banner } from '../new-search';
import { trackEvent } from '../utils/analytics';

import './view.scss';
import searchBehavior from './behavior';
import type { Filters as FiltersType, SearchBehaviorProps } from './behavior';
import AdminToolbar from './components/admin-toolbar';
import Description from './components/description';
import Filters from './components/filters';
import Results from './components/results';
import SearchBox from './components/search-box';

export type SelectedSet = {|
	companies: Array<number>,
	people: Array<number>,
|};

type Props = {|
	...SearchBehaviorProps,
|};

type State = {
	selected: SelectedSet,
};

class SearchView extends React.Component<Props, State> {
	state: State = {
		selected: {
			companies: [],
			people: [],
		},
	};

	componentDidMount() {
		// This tracks: anytime a user visits the Advanced Search page
		trackEvent('Visit New Page', 'advanced-search-view', 'advanced-search');
	}

	UNSAFE_componentWillReceiveProps(newProps: Props): void {
		// If results are merged or marked as robots, the search behavior decorator
		// will pass new results, but `handleChange` won't have been called with new
		// filters first, so here we just remove any results from the selection that
		// are no longer available.
		if (Array.isArray(newProps.results)) {
			this.setState((state) => ({
				selected: {
					companies: state.selected.companies.filter(
						(cid: number) =>
							newProps.results != null
							&& newProps.results.some(
								({ type, id }) =>
									type === 'companies' && id === cid,
							),
					),
					people: state.selected.people.filter(
						(pid: number) =>
							newProps.results != null
							&& newProps.results.some(
								({ type, id }) =>
									type === 'people' && id === pid,
							),
					),
				},
			}));
		} else {
			this.setState({
				selected: {
					companies: [],
					people: [],
				},
			});
		}
	}

	handleChange = (filters: FiltersType): void => {
		this.setState({
			selected: {
				companies: [],
				people: [],
			},
		});
		this.props.onChange(filters);
	};

	handleClear = (filters: FiltersType, componentIdentifier: string): void => {
		trackEvent(
			'Clear Search Filter',
			componentIdentifier,
			'advanced-search',
			{
				...filters,
			},
		);
		this.handleChange(filters);
	};

	handleQueryChange = (
		event: SyntheticInputEvent<HTMLInputElement>,
	): void => {
		this.handleChange({
			...this.props.filters,
			query: event.target.value,
		});
	};

	handleSelect = (type: 'companies' | 'people', id: number): void => {
		this.setState((state) => {
			// Flow cannot handle union types as computed keys in object
			// spreads, so we need to copy the object then update and return the
			// copy.
			// https://github.com/facebook/flow/issues/8299
			const next = { ...state.selected };
			if (next[type].includes(id)) {
				next[type] = next[type].filter((x) => x !== id);
			} else {
				next[type] = [...next[type], id];
			}
			return { selected: next };
		});
	};

	render() {
		return (
			<div className="Search">
				<Header search={false} />
				<Banner />
				<div className="Search-body">
					<Filters
						filters={this.props.filters}
						onChange={this.handleChange}
					/>
					<main className="Search-main">
						<div>
							<SearchBox
								autoFocus
								onChange={this.handleQueryChange}
								placeholder="Search Herbie"
								value={this.props.filters.query || ''}
							/>
						</div>
						<Description
							filters={this.props.filters}
							loading={this.props.loading}
							onChange={this.handleClear}
							resultCount={this.props.resultCount}
						/>
						<Results
							complete={this.props.complete}
							filters={this.props.filters}
							loading={this.props.loading}
							onScroll={this.props.onScroll}
							onSelect={this.handleSelect}
							paging={this.props.paging}
							resultCount={this.props.resultCount}
							results={this.props.results}
							selected={this.state.selected}
						/>
						<AdminToolbar selected={this.state.selected} />
					</main>
				</div>
			</div>
		);
	}
}

export default searchBehavior({ history: true })(SearchView);
