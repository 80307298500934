import React from 'react';
import {
	Navigate,
	type NavigateProps,
	type Path,
	useLocation,
} from 'react-router-dom';

type Props = NavigateProps & {
	preserveHash?: boolean;
	preserveSearch?: boolean;
};

/**
 * A react-router-v6 compatible way to render a client side Redirect.
 * Useful when you want to preserve any query string url fragment present
 * whenever a redirect is triggered. For more information visit the
 * documentation for [Navigate][1].
 *
 * [1]: https://reactrouter.com/en/6.14.2/components/navigate
 */
function Redirect({ preserveHash, preserveSearch, to, ...props }: Props) {
	const location = useLocation();
	const path: Path =
		typeof to === 'string'
			? { pathname: to, search: '', hash: '' }
			: {
					pathname: to.pathname || '',
					search: to.search || '',
					hash: to.hash || '',
			  };

	if (preserveHash) {
		path.hash = location.hash;
	}
	if (preserveSearch) {
		path.search = location.search;
	}

	return <Navigate to={path} {...props} />;
}

Redirect.defaultProps = {
	preserveHash: false,
	preserveSearch: false,
};

export default Redirect;
