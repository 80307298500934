type Key = string;
export type Subscriber<T> = (value: T) => void;
export type Unsubscribe = () => void;

export default class Subject<T> {
	private subscribers = new Map<Key, Subscriber<T>>();

	private getNewKey(): Key {
		const A = 0x41;
		const Z = 0x5a;

		let key = '';
		do {
			key += String.fromCharCode(Math.floor(Math.random() * (Z - A)) + A);
		} while (this.subscribers.has(key));
		return key;
	}

	next(value: T): void {
		for (const subscriber of this.subscribers.values()) {
			subscriber(value);
		}
	}

	subscribe(fn: Subscriber<T>): Unsubscribe {
		const key = this.getNewKey();
		this.subscribers.set(key, fn);

		return () => {
			this.subscribers.delete(key);
		};
	}
}
