import React from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import * as colors from '../colors';
import useGet from '../utils/hooks/use-get';

import { ResponsiveChart } from './components';
import { formatPercentage, formatQuarter } from './utils';

interface OnePagerDatum {
	quarter: string;
	herbie_total: number;
	relative: number;
}

interface Props {
	endDate: string;
	startDate: string;
}

const tooltipLabelFormatter = (label: string | number): React.ReactNode => {
	if (typeof label === 'string') return formatQuarter(label);
	return label;
};
const tooltipValueFormatter = (
	value: string | number | Array<string | number>,
	name: string,
): React.ReactNode => {
	if (name === 'Relative' && typeof value === 'number')
		return formatPercentage(value);
	return value;
};

export default function HerbieAttributableOnePagers({
	startDate,
	endDate,
}: Props): JSX.Element {
	const [data, Loader] = useGet<Array<OnePagerDatum>>(
		'/internal_dashboards/herbie_one_pagers',
		{
			start_date: startDate,
			end_date: endDate,
		},
	);

	if (!data) return <Loader />;

	return (
		<ResponsiveChart>
			<BarChart
				data={data}
				margin={{ top: -15, right: 0, left: -20, bottom: 0 }}
			>
				<Tooltip
					cursor={{ fill: colors.lightGray.lighten(0.15).string() }}
					formatter={tooltipValueFormatter}
					labelFormatter={tooltipLabelFormatter}
				/>
				<CartesianGrid vertical={false} strokeDasharray="4" />
				<XAxis dataKey="quarter" tickFormatter={formatQuarter} />
				<YAxis yAxisId="total" orientation="left" />
				<YAxis
					yAxisId="relative"
					orientation="right"
					tickFormatter={formatPercentage}
				/>
				<Bar
					dataKey="herbie_total"
					name="Total"
					yAxisId="total"
					fill={colors.primaryDriveBlue.string()}
				/>
				<Bar
					dataKey="relative"
					name="Relative"
					yAxisId="relative"
					fill={colors.driveGreen.string()}
				/>
				<Legend verticalAlign="top" height={28} align="right" />
			</BarChart>
		</ResponsiveChart>
	);
}
