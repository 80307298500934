import React from 'react';
import styled from 'styled-components';

import {
	type CellArgs,
	type FooterArgs,
	type HeaderArgs,
	type IColumn,
	SortableHeader,
	type SortDirection,
	sortDirections,
} from '../table';
import assertExhaustive from '../utils/assert-exhaustive';
import noop from '../utils/noop';
import useEditableField, {
	EditingState,
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from '../utils/state-machine/editable-field';

import { type Probability } from './probability';

type Value = number | null;

type Data = {
	amount: Value;
	probability: Probability;
};

function format(value: number): string {
	return `$${(value / 1e6).toFixed(1)}M`;
}

const HeaderText = styled.div`
	text-align: right;
`;

const Cell = styled.td`
	overflow: hidden;
`;

const ReadOnlyCell = styled(Cell)`
	font-variant-numeric: tabular-nums;
	overflow: hidden;
	padding: 10px;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Input = styled.input`
	appearance: none;
	background-color: transparent;
	border: none;
	font-size: inherit;
	font-variant-numeric: tabular-nums;
	line-height: 20px;
	margin: 0;
	min-width: 100%;
	overflow: hidden;
	padding: 10px;
	text-align: right;
	text-overflow: ellipsis;
	width: 5ch;
	white-space: nowrap;
`;

const SavingInput = styled(Input)`
	background-color: #eee;
`;

const SuccessInput = styled(Input)`
	background-color: #dfd;
`;

const ErrorText = styled.span`
	color: #800;
	overflow: hidden;
	padding: 5px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Footer = styled.th`
	font-variant-numeric: tabular-nums;
	text-align: right;
	white-space: nowrap;
`;

type WeightedCurrencyCellProps = {
	initialValue: Value;
	onChange: (value: Value, signal: AbortSignal) => Promise<Value>;
};

function EditableWeightedCurrencyCell({
	initialValue,
	onChange,
	...props
}: WeightedCurrencyCellProps) {
	const [currentState, transition] = useEditableField<Value>();

	if (currentState instanceof NormalState) {
		return (
			<Cell {...props}>
				<Input
					// We know that a change won't happen here, but React
					// throws a warning if we have a controlled component
					// without a change handler.
					onChange={noop}
					onFocus={() => {
						transition(currentState.onEdit(initialValue));
					}}
					type="text"
					value={initialValue == null ? '' : format(initialValue)}
				/>
			</Cell>
		);
	} else if (currentState instanceof EditingState) {
		const editingState: EditingState<Value> = currentState;
		return (
			<Cell {...props}>
				<Input
					onBlur={() => {
						transition(
							editingState.value === initialValue
								? editingState.onCancel()
								: editingState.onSubmit(onChange),
						);
					}}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						transition(
							editingState.onChange(
								event.target.value.length === 0
									? null
									: Math.round(
											parseFloat(event.target.value)
												* 1e6,
									  ),
							),
						);
					}}
					onKeyDown={(
						event: React.KeyboardEvent<HTMLInputElement>,
					) => {
						switch (event.key) {
							case 'Enter':
								transition(editingState.onSubmit(onChange));
								return;
							case 'Escape':
								transition(editingState.onCancel());
								return;
							default: // Do nothing
						}
					}}
					type="number"
					value={
						editingState.value == null
							? ''
							: editingState.value / 1e6
					}
				/>
			</Cell>
		);
	} else if (currentState instanceof SavingState) {
		const savingState: SavingState<Value> = currentState;
		return (
			<Cell {...props}>
				<SavingInput
					disabled
					readOnly
					type="text"
					value={
						savingState.value == null
							? ''
							: format(savingState.value)
					}
				/>
			</Cell>
		);
	} else if (currentState instanceof SuccessState) {
		const successState: SuccessState<Value> = currentState;
		return (
			<Cell {...props}>
				<SuccessInput
					disabled
					readOnly
					type="text"
					value={
						successState.value == null
							? ''
							: format(successState.value)
					}
				/>
			</Cell>
		);
	} else if (currentState instanceof ErrorState) {
		return (
			<Cell {...props}>
				<ErrorText>{currentState.error.message}</ErrorText>
			</Cell>
		);
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class WeightedCurrencyColumn<T extends object>
	implements IColumn<T>
{
	name: string;
	_select: (row: T) => Data;
	_update: null | ((value: Value) => Partial<T>);
	_weight: (value: number, probability: Probability) => number;

	constructor({
		name,
		select,
		update = null,
		weight,
	}: {
		name: string;
		select: (row: T) => Data;
		update?: null | ((value: Value) => Partial<T>);
		weight: (amount: number, probability: Probability) => number;
	}) {
		this.name = name;
		this._select = select;
		this._update = update;
		this._weight = weight;

		this.cell.displayName = 'WeightedCurrencyColumn';
	}

	// Declare this as an arrow function to preserve the value of `this`. The
	// display name is set in the constructor.
	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const value = this._select(row).amount;

		const update = this._update;
		if (deferred || !update || !onChange) {
			return (
				<ReadOnlyCell {...props}>
					{value == null || value === 0 ? null : format(value)}
				</ReadOnlyCell>
			);
		}

		return (
			<EditableWeightedCurrencyCell
				initialValue={value}
				onChange={async (newValue, signal) => {
					const patch = update(newValue);
					const response: T = await onChange(row, patch, signal);
					const updatedValue = this._select(response).amount;
					return updatedValue;
				}}
				{...props}
			/>
		);
	});

	footer({ props, rows }: FooterArgs<T>) {
		let total = 0;
		let weighted = 0;

		for (let i = 0; i < rows.length; i++) {
			const data: Data = this._select(rows[i]);
			if (data.amount) {
				total += data.amount;
				weighted += this._weight(data.amount, data.probability);
			}
		}

		return (
			<Footer {...props}>
				{format(weighted)}
				{' / '}
				{format(total)}
			</Footer>
		);
	}

	header({ onSort, props, sort, sortIndex }: HeaderArgs) {
		return (
			<SortableHeader
				initialSortDirection={sortDirections.descending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				<HeaderText>{this.name}</HeaderText>
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T) {
		const aVal = this._select(a).amount;
		const bVal = this._select(b).amount;

		if (aVal === bVal) return 0;
		if (aVal === null) return 1;
		if (bVal === null) return -1;
		return direction === sortDirections.ascending
			? aVal - bVal
			: bVal - aVal;
	}

	toCSV(row: T): string {
		const value = this._select(row);
		const amount = value.amount;
		return amount ? format(amount) : '';
	}
}
