/*
 * Desktop Safari is the only supported browser that doesn't implement the HTML5
 * `date` input type.
 *
 * https://bugs.webkit.org/show_bug.cgi?id=119175
 *
 * Creating and exercising the `<input>` element is a relatively expensive test,
 * but the value will never change, so calculate it once and remember the
 * result.
 *
 * One possible implementation would run the test at startup and export a
 * constant value, but this would add to the page initialization time. Instead,
 * we export a function and run the test on demand the first time it's needed.
 */

let cachedValue: boolean | null = null;

export default function isDateInputSupported(): boolean {
	if (cachedValue === null) {
		const element = document.createElement('input');
		element.setAttribute('type', 'date');
		const invalidValue = 'not a date';
		element.setAttribute('value', invalidValue);
		cachedValue = element.value !== invalidValue;
	}

	return cachedValue;
}
