import React from 'react';
import styled from 'styled-components';

import useGet from '../utils/hooks/use-get';

import {
	CurrentFundStats,
	PreviousFundStats,
} from './investor-investment-stats';
import InvestorMissedDeals from './investor-missed-deals';
import type { MissedDealApiResponse } from './investor-missed-deals';
import type { InvestorDashboardData } from './types';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;
const SingleChartContainer = styled.div`
	width: 500px;
	height: 525px;
	margin: 20px 10px;
`;
const MultiChartContainer = styled.div`
	width: 500px;
	height: 525px;
	margin: 20px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const SmallerChartContainer = styled.div`
	height: 48%;
	width: 100%;
`;

export const PATH = 'investors';

export default function InvestorsView(): JSX.Element {
	const [investmentStats, StatsLoader] = useGet<InvestorDashboardData>(
		'/internal_dashboards/investor',
	);

	const [missedDeals, DealsLoader] = useGet<MissedDealApiResponse>(
		'/internal_dashboards/investor/missed_deals',
		{ most_recent_count: 10 },
	);

	return (
		<Container>
			<MultiChartContainer>
				<SmallerChartContainer>
					{investmentStats ? (
						<CurrentFundStats data={investmentStats} />
					) : (
						<StatsLoader />
					)}
				</SmallerChartContainer>
				<SmallerChartContainer>
					{missedDeals ? (
						<InvestorMissedDeals data={missedDeals} />
					) : (
						<DealsLoader />
					)}
				</SmallerChartContainer>
			</MultiChartContainer>
			<SingleChartContainer>
				{investmentStats ? (
					<PreviousFundStats data={investmentStats} />
				) : (
					<StatsLoader />
				)}
			</SingleChartContainer>
		</Container>
	);
}
