import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND } from 'lexical';
import { useEffect } from 'react';

import useEditorClear from './use-editor-clear';

interface Props {
	onEnter: (clearEditor: () => void) => void;
}

export default function CommandEnterPlugin({ onEnter }: Props) {
	const [editor] = useLexicalComposerContext();
	const clearEditor = useEditorClear();

	useEffect(() => {
		return editor.registerCommand(
			KEY_ENTER_COMMAND,
			(payload) => {
				if (payload?.metaKey) {
					onEnter(clearEditor);
					return true;
				}
				return false;
			},
			COMMAND_PRIORITY_HIGH,
		);
	}, [clearEditor, editor, onEnter]);

	return null;
}
