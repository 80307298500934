import { startCase } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import * as colors from '../colors';
import ExternalLink from '../components/external-link';
import StyledMarkdown from '../components/styled-markdown';
import listify from '../utils/listify';

import type { JobOrder } from './types';

const STATUS_COLORS = {
	CLOSED: colors.mediumGray,
	OPEN: colors.activeGreen,
};

const Container = styled.div`
	margin: 16px;
	overflow-y: scroll;
	width: 100%;
`;

const DetailsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(4, minmax(0, 1fr));
	grid-auto-rows: minmax(60px, auto);
	margin: 10px;
	align-items: center;
`;

const Detail = styled.div`
	display: flex;
	flex-direction: column;
`;

const DetailTitle = styled.span`
	color: ${colors.mediumGray.string()};
	font-size: 11px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
`;

const DetailValue = styled.span`
	color: ${colors.herbieBlack.string()};
	margin-top: 2px;
`;

const Header = styled.div`
	display: grid;
	grid-template:
		'logo name .' auto
		'logo title status' auto
		/ auto auto 1fr;
	margin-bottom: 16px;
	width: fit-content;
`;

const HiringManagerDetails = styled.div`
	display: grid;
	grid-template:
		'photo manager' auto
		'photo email' auto
		/ auto 1fr;
	align-items: center;
`;

const HiringManagerPhotosLink = styled(Link)`
	grid-area: photo;

	img {
		border-radius: 20px;
		height: 35px;
		width: 35px;
		min-width: 35px;
		margin-right: 8px;
		object-fit: contain;
		object-position: center;
		overflow: hidden;
		outline-offset: 1px;
		outline: 1px solid #eee;
	}
`;

const HiringManagerEmail = styled(ExternalLink)`
	grid-area: email;
`;

const HiringManagerName = styled(DetailValue)`
	grid-area: manager;
`;

const HorizontalRule = styled.hr`
	border: 0;
	height: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const JobTitle = styled.p`
	grid-area: title;
	color: #333;
	font-size: 2.5em;
	font-weight: normal;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const LogoLink = styled(Link)`
	grid-area: logo;

	img {
		background-color: ${colors.white.string()};
		border-radius: 20px;
		height: 70px;
		width: 70px;
		min-width: 70px;
		float: left;
		margin: 4px 8px;
		object-fit: contain;
		object-position: center;
		text-indent: 100%;
		overflow: hidden;
		outline-offset: 2px;
		outline: 1px solid ${colors.lightGray.string()};
	}
`;

export const Name = styled.p`
	grid-area: name;
	font-weight: 400;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	align-self: end;
	margin-left: 2px;
	color: ${colors.mediumGray.string()};
`;

const Status = styled('div')<{ status: keyof typeof STATUS_COLORS }>`
	${({ status }) => `
		border: 1px solid ${STATUS_COLORS[status].string()};
		background: ${STATUS_COLORS[status].lighten(0.6).string()};
	`}
	grid-area: status;
	align-self: center;
	margin-left: 10px;
	border-radius: 5px;
	padding: 3px 10px;
`;

const StyledLink = styled(Link)`
	&:hover {
		text-decoration: underline;
	}
`;

function renderDetail(title: string, value: string | ReactNode) {
	return (
		<Detail>
			<DetailTitle>{title}</DetailTitle>
			<DetailValue>{value}</DetailValue>
		</Detail>
	);
}

function formatCurrency(amount: number | null) {
	return amount === null ? '' : numeral(amount).format('$0a');
}

export default function JobOrderDetails({
	jobOrder,
}: {
	jobOrder: JobOrder;
}): JSX.Element {
	const {
		additional_information,
		closed_on,
		compensation_salary,
		description_url,
		environment_preference,
		fused_company,
		hiring_manager,
		job_type,
		locations,
		open_roles,
		opened_on,
		priority,
		status,
		talent_acquisition_managers,
		title,
	} = jobOrder;
	return (
		<Container>
			<Header>
				<LogoLink to={`/companies/${fused_company.id}`}>
					<img src={fused_company.logo_url} />
				</LogoLink>
				<Name>
					<StyledLink to={`/companies/${fused_company.id}`}>
						{fused_company.name}
					</StyledLink>{' '}
					•{' '}
					{listify(
						talent_acquisition_managers.map(
							(tam) => `${tam.first_name} ${tam.last_name}`,
						),
					)}
				</Name>
				<JobTitle>{title}</JobTitle>
				<Status status={status}>
					○ {startCase(status.toLowerCase())}
				</Status>
			</Header>
			<HorizontalRule />
			<DetailsContainer>
				{renderDetail(
					'Job Type',
					`${startCase(job_type.toLowerCase())}, ${startCase(
						environment_preference.toLowerCase(),
					)}`,
				)}
				{renderDetail('Priority', startCase(priority.toLowerCase()))}
				{renderDetail(
					'Location(s)',
					locations.length > 0 ? listify(locations) : '--',
				)}
				{renderDetail(
					'Description',
					description_url ? (
						<ExternalLink href={description_url}>
							{description_url}
						</ExternalLink>
					) : (
						'--'
					),
				)}
				{renderDetail('Open Roles', open_roles)}
				{compensation_salary
					&& renderDetail(
						'Salary Range',
						compensation_salary.min && compensation_salary.max
							? `${formatCurrency(
									compensation_salary?.min,
							  )} - ${formatCurrency(
									compensation_salary?.max,
							  )} ${compensation_salary?.currency_code}`
							: '--',
					)}
				{renderDetail(
					'Date Opened',
					`${moment(opened_on).format('MMM D, YYYY')} (${moment(
						opened_on,
					).fromNow()})`,
				)}
				{closed_on
					&& renderDetail(
						'Date Closed',
						`${moment(closed_on).format('MMM D, YYYY')} (${moment(
							closed_on,
						).diff(opened_on, 'days')} days)`,
					)}
				{renderDetail(
					'Hiring Manager',
					<HiringManagerDetails>
						<HiringManagerPhotosLink
							to={`/people/${hiring_manager.id}`}
						>
							<img src={hiring_manager.photo_url} />
						</HiringManagerPhotosLink>

						<HiringManagerName>
							<StyledLink to={`/people/${hiring_manager.id}`}>
								{hiring_manager.first_name}{' '}
								{hiring_manager.last_name}{' '}
							</StyledLink>
						</HiringManagerName>
						<HiringManagerEmail
							href={`mailto:${hiring_manager.emails[0]}`}
						>
							{hiring_manager.emails[0]}
						</HiringManagerEmail>
					</HiringManagerDetails>,
				)}
			</DetailsContainer>
			<HorizontalRule />
			<StyledMarkdown>{additional_information}</StyledMarkdown>
		</Container>
	);
}
