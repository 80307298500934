/**
 * @file
 * Object icons from [Figma][1].
 *
 * [1]: https://www.figma.com/file/5siS4zqTGDgKTXJFxcK1uD/Herbie-Icons?node-id=1%3A5113&mode=dev
 */
import React from 'react';

export function BriefcaseIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M7 5V2C7 1.44772 7.44772 1 8 1H16C16.5523 1 17 1.44772 17 2V5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7ZM9 13H4V19H20V13H15V16H9V13ZM20 7H4V11H9V9H15V11H20V7ZM11 11V14H13V11H11ZM9 3V5H15V3H9Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export function GraduationCapIcon(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M4 9.3333L0 7L12 0L24 7V15.5H22V8.1667L20 9.3333V16.0113L19.7774 16.2864C17.9457 18.5499 15.1418 20 12 20C8.85817 20 6.05429 18.5499 4.22263 16.2864L4 16.0113V9.3333ZM6 10.5V15.2917C7.46721 16.954 9.61112 18 12 18C14.3889 18 16.5328 16.954 18 15.2917V10.5L12 14L6 10.5ZM3.96927 7L12 11.6846L20.0307 7L12 2.31541L3.96927 7Z"
				fill="currentColor"
			/>
		</svg>
	);
}
