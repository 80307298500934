export default function toSentence(
	words: string[],
	coordinatingConjunction = 'and',
) {
	if (words.length === 0) {
		return '';
	}

	if (words.length === 1) {
		return words[0];
	}

	if (words.length === 2) {
		return `${words[0]} and ${words[1]}`;
	}

	const lastWord = words.pop();
	return `${words.join(', ')}, ${coordinatingConjunction} ${lastWord || ''}`;
}
