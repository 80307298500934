import numeral from 'numeral';
import React from 'react';

import { primaryDriveBlue } from '../../colors';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import DiscreteBarChart from './charts/discrete-bar-chart';
import GenericChart from './charts/generic-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/mw_growth_rounds';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('$0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'Amount Raised',
		values: dataArr.map((company) => ({
			color:
				company.is_drive === false
					? `${primaryDriveBlue.string()}`
					: 'green',
			label: `${company.name} - ${numeral(company.amount_raised).format(
				'0a',
			)}`,
			value: company.amount_raised,
		})),
	},
];

export default class MWGrowthRounds extends React.Component {
	static getDetailCard() {
		return MWGrowthRoundsDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 75 }}
				onDataFetch={this.handleDataFetch}
				title="Midwest Growth Rounds Last 12 Months"
				xAxisLabel="Company"
				yAxisLabel="Round Size"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class MWGrowthRoundsDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/industries_funded.png"
				statName="Midwest Growth Rounds Last 12 Months"
			/>
		);
	}
}
