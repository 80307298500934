import d3 from 'd3';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';

import './companies-in-herbie.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import LineChart from './charts/line-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/pme_data/?fund=1';
let data = null;

const xAxisTickFormat = (timestamp) =>
	d3.time.format('%x')(new Date(timestamp * 1000));
const yAxisTickFormat = (d) => numeral(d / 100).format('0.00%');

const formatData = (response) => {
	const tempData = {
		djia: [],
		drive_irr: [],
		russell: [],
		s_and_p: [],
	};

	const prettyKeys = {
		djia: 'DJIA',
		drive_irr: 'Drive Fund I IRR',
		russell: 'Russell',
		s_and_p: 'S&P 500',
	};

	response.forEach((row) => {
		Object.keys(row).forEach((k) => {
			if (k !== 'date') {
				tempData[k].push({
					x: moment(row.date, 'YYYY-MM-DD').unix(),
					y: row[k],
				});
			}
		});
	});

	return Object.keys(tempData).map((k) => {
		return {
			key: prettyKeys[k],
			values: tempData[k],
		};
	});
};

export default class PMEIRRFundIData extends React.Component {
	static getDetailCard() {
		return PMEIRRFundIDataDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showXAxis: true,
			showYAxis: true,
			useInteractiveGuideline: true,
			showLegend: true,
		};

		return (
			<GenericChart
				data={data}
				loadingComponent={HerbieLoader}
				fetchData={this.fetchData}
				forceY={0}
				margin={{ bottom: 75, right: 75 }}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="PME Benchmarking - Fund I"
				xAxisLabel="Date"
				xAxisTickFormat={xAxisTickFormat}
				yAxisLabel="IRR"
				yAxisTickFormat={yAxisTickFormat}
			>
				{LineChart}
			</GenericChart>
		);
	}
}

class PMEIRRFundIDataDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/pme.png"
				statName="Fund I PME Benchmarking"
			/>
		);
	}
}
