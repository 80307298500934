import d3 from 'd3';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import GenericChart from '../charts/generic-chart';
import MultiBarChart from '../charts/multi-bar-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/total_portfolio_revenue_all_funds';
let data = null;

export default class SemiAnnualAllFundsPortfolioRevenue extends React.Component {
	static getDetailCard() {
		return SemiAnnualAllFundsPortfolioRevenueDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	tickFormat(num) {
		// D3 using G (for Giga) instead of
		// B (for Billions) as the prefix
		// The Drive team does not like that
		// so we fix it here
		const siFormat = d3.format('.3s');
		return siFormat(num).replace('G', 'B');
	}

	render() {
		const options = {
			showControls: false,
			stackOffset: 'expand',
		};

		// I have no idea why yAxisTickFormat needs to be called, but the y axis shows source code otherwise...
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="Semi Annuals Portfolio Revenue Analysis"
				xAxisLabel="Year"
				yAxisLabel="Total Portfolio Revenue"
				yAxisTickFormat={this.tickFormat}
			>
				{MultiBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualAllFundsPortfolioRevenueDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/semi_annual_revenue.png"
				statName="All Funds Yearly Portfolio Revenue Analysis"
			/>
		);
	}
}
