import algoliasearch from 'algoliasearch/lite';

import { algoliaID, algoliaIndex } from '../config';

const algoliaClientMap = {};

const getAlgoliaClient = (key) => {
	if (algoliaClientMap[key]) {
		return algoliaClientMap[key];
	}

	const client = algoliasearch(algoliaID, key);
	algoliaClientMap[key] = client;

	return client;
};

const getIndex = function (key) {
	// TODO: This is an anonymous function instead of an arrow function to work
	// around a Babel bug.
	return getAlgoliaClient(key).initIndex(algoliaIndex);
};

const search = (key, query) =>
	getIndex(key)
		.search(query)
		.then((data) => {
			data.hits.forEach((hit) => {
				hit.data.type = hit.type;
			});

			return data;
		});

export default search;
