// @flow

import React from 'react';

import SentenceList from '../../components/sentence-list';

import checkboxSearchFilter from './checkbox-filter';
import type { CheckboxDisplayProps } from './checkbox-filter';
import DescriptionValue from './description-value';
import FilterDescription from './filter-description';

const RoleSearchFilterDisplay = ({
	onRemove,
	punctuator = '',
	resultCount,
	value,
}: CheckboxDisplayProps) => (
	<FilterDescription>
		{resultCount === 1 ? 'matches ' : 'match '}
		<SentenceList conjunction="or" punctuator={punctuator}>
			{value.map((role) => (
				<DescriptionValue key={role} onRemove={onRemove} value={role}>
					{role}
				</DescriptionValue>
			))}
		</SentenceList>
	</FilterDescription>
);

export default checkboxSearchFilter(
	'Role',
	'data.roles',
	RoleSearchFilterDisplay,
	'roles-filter',
);
