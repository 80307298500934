import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { selectUser } from '../../selectors';
import { breakpoints, colors, fonts } from '../../theme';
import {
	Menu,
	MenuButton,
	MenuItems,
	MenuLink,
} from '../../theme/components/menu';
import { MenuLineIcon } from '../../theme/icons/document';
import { authorize, isDriveTenant, isTalentPartner, or } from '../Authorized';
import type { Permission } from '../Authorized';

type AppLink = {
	auth?: Permission;
	name: string;
	to: string;
};

const StyledMenuButton = styled(MenuButton)`
	align-items: center;
	background: ${colors.fieldDark.field};
	border-radius: 50%;
	border: none;
	color: ${colors.icon.onColor};
	cursor: pointer;
	display: flex;
	height: 32px;
	justify-content: center;
	min-width: 32px;
	width: 32px;

	> svg {
		height: 16px;
		width: 16px;
	}
`;
const StyledMenuItems = styled(MenuItems)`
	${fonts.paragraph.paragraph};
`;

const ShortcutsWrapper = styled.div<{ $gridArea: string }>`
	${fonts.paragraph.wide};
	color: ${colors.text.onColor};
	font-size: 16px;
	grid-area: ${({ $gridArea }) => $gridArea};
	justify-self: end;
	max-height: 42px;
	overflow: hidden;

	display: none;
	@media (${breakpoints.sm}) {
		display: flex;
	}
`;

const ShortcutsList = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-end;
`;

const ShortcutListItem = styled.div`
	white-space: nowrap;
	padding: 10px 14px;
`;

const links: Array<AppLink> = [
	{
		auth: 'contacts.dataroom_for_lps',
		name: 'Data Room',
		to: '/dataroom',
	},
	{
		auth: 'contacts.market_maps',
		name: 'Atlas',
		to: '/market-maps',
	},
	{
		auth: 'contacts.funnel',
		name: 'High Beam',
		to: '/highbeam',
	},
	{
		auth: or('contacts.watchlists', 'contacts.limited_watchlists'),
		name: 'Watch Lists',
		to: '/watchlists',
	},
	{
		auth: 'contacts.lpfundraising',
		name: 'LP Dashboard',
		to: '/lps',
	},
	{
		auth: 'contacts.onepagers',
		name: 'One Pagers',
		to: '/onepagers',
	},
	{
		auth: or(isDriveTenant, isTalentPartner),
		name: 'FastTrack',
		to: '/talent/job-orders',
	},
	{
		auth: 'contacts.kpi',
		name: 'Internal Dashboards',
		to: '/internal-dashboards',
	},
	{
		auth: 'contacts.semiannuals',
		name: 'Semi Annuals',
		to: '/semiannuals',
	},
	{
		auth: 'contacts.kpi',
		name: 'Portfolio',
		to: '/portfolio',
	},
	{
		auth: 'contacts.kpi',
		name: 'Investor KPIs',
		to: '/kpi',
	},
	{
		auth: 'contacts.travel',
		name: 'Travel',
		to: '/travel',
	},
	{
		auth: 'contacts.internal_drive_stats',
		name: 'Analytics',
		to: 'https://analytics.herbie.drivecapital.com/',
	},

	{
		auth: 'contacts.stats',
		name: 'Stats',
		to: '/stats',
	},
	{
		auth: 'contacts.lpfundraising',
		name: 'Data Rooms',
		to: '/datarooms',
	},
];

function useAuthorizedLinks(includeHomeLink = false) {
	const user = useSelector(selectUser);
	const allLinks = useCallback(
		() => (includeHomeLink ? [{ name: 'Home', to: '/' }, ...links] : links),
		[includeHomeLink],
	)();

	return allLinks.filter(
		({ auth }) => !auth || (user != null && authorize(auth)(user)),
	);
}

export function DropdownMenu({
	includeHomeLink,
	onClick,
}: {
	includeHomeLink: boolean;
	onClick: () => void;
}) {
	const linkList = useAuthorizedLinks(includeHomeLink);

	return (
		<Menu
			menu={
				<StyledMenuButton onClick={onClick}>
					<MenuLineIcon />
				</StyledMenuButton>
			}
		>
			<StyledMenuItems>
				{linkList.map(({ name, to }) => {
					const item =
						name === 'Analytics' ? (
							<MenuLink
								key={to}
								href={to}
								rel="noopener noreferrer"
								target="_blank"
							>
								{name}
							</MenuLink>
						) : (
							<MenuLink forwardedAs={Link} key={to} to={to}>
								{name}
							</MenuLink>
						);
					return item;
				})}
			</StyledMenuItems>
		</Menu>
	);
}

export function ShortcutsMenu({
	gridArea,
	includeHomeLink,
}: {
	gridArea: string;
	includeHomeLink: boolean;
}) {
	const linkList = useAuthorizedLinks(includeHomeLink);

	return (
		<ShortcutsWrapper $gridArea={gridArea}>
			<ShortcutsList>
				{linkList.slice(0, 5).map(({ name, to }) => {
					const item = (
						<ShortcutListItem key={to}>
							<Link to={to}>{name}</Link>
						</ShortcutListItem>
					);
					return item;
				})}
			</ShortcutsList>
		</ShortcutsWrapper>
	);
}
