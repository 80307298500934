import { combineReducers } from 'redux';

import { ActionTypes } from '../constants';
import createReducer from '../utils/createReducer';

const mergedTypeReducer = (type) =>
	createReducer(
		{},
		{
			[ActionTypes.MERGE_SUCCESS]: (
				state,
				{ payload: { duplicates, merged } },
			) => {
				if (merged.type !== type) {
					return state;
				}

				const nextState = { ...state };

				duplicates.forEach((duplicate) => {
					nextState[duplicate.id] = merged;
				});

				return nextState;
			},
		},
	);

export default combineReducers({
	companies: mergedTypeReducer('companies'),
	people: mergedTypeReducer('people'),
});
