import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Authorized, {
	isInvestor,
	isSeedManager,
	or,
	useAuthorized,
} from '../components/Authorized';
import Header from '../components/header';
import View from '../components/view';
import { InvestorOutreachView } from '../investor-outreach';
import { BetaChip, showNewSearch } from '../new-search';
import { QuickSearch } from '../search';
import { breakpoints } from '../theme';

const Main = styled.div`
	background-color: #fdfdfd;
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: contain;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;

	@media (min-height: 416px) {
		background-image: url('https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/herbie-tire.jpg');
	}
`;

const SearchContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.SearchBox {
		background-color: white;
		border-style: solid;
		border-width: 1px;
		border-color: #aaa; /* $disabled-gray; */
		color: black;
	}
`;

const HelloContainer = styled.div`
	margin-top: 100px;
	text-align: center;

	@media (${breakpoints.sm}) {
		margin-top: 180px;
	}

	@media (max-height: 760px) {
		/* hide image on phone landscape modes */
		margin-top: 10vh;
	}

	@media (max-height: 415px) {
		/* hide image on phone landscape modes */
		margin-top: 40px;
	}

	h1 {
		font-weight: 200;
	}
`;

const Typeahead = styled.div`
	width: 80%;
	margin-top: 40px;

	@media (${breakpoints.sm}) {
		width: 50%;
		margin-top: 60px;
	}
`;

const Greeting = styled.span`
	color: #4a4a4a; /* $charcoal-gray; */
	display: none;
	font-size: 14px;
	padding: 16px;

	@media (${breakpoints.sm}) {
		display: inline;
		text-align: center;
		text-wrap: balance;
	}
`;
const AlignedBetaChip = styled(BetaChip)`
	align-items: baseline;

	svg {
		align-self: center;
	}
`;

const AdvancedSearchLink = styled(Link)`
	color: #007dc2; /* $primary-drive-blue; */
	display: inline-block;
	position: relative;
	transition: background-color 100ms ease-out;

	&:hover {
		color: #316f92; /* desaturate($primary-drive-blue, 50); */
	}
`;

const StyledQuickSearch = styled(QuickSearch)`
	max-width: initial;
`;

export default function Home() {
	const isInvestorUser = useAuthorized(or(isInvestor, isSeedManager));

	if (isInvestorUser) {
		return <InvestorOutreachView />;
	}

	return (
		<View>
			<Main>
				<Header search={false} />
				<SearchContainer>
					<HelloContainer>
						<h1>Hello, I'm Herbie.</h1>
						<h1>What can I help you find today?</h1>
					</HelloContainer>
					<Typeahead>
						<StyledQuickSearch
							autoFocus
							placeholder="Search Herbie"
						/>
					</Typeahead>
					<Greeting>
						To browse or filter companies, investors, and people,
						use
						<AdvancedSearchLink to="/search">
							&nbsp;advanced search
						</AdvancedSearchLink>
						<Authorized auth={showNewSearch}>
							<>
								{', or test the brand new'}&nbsp;
								<AlignedBetaChip />
								&nbsp;{'experience.'}
							</>
						</Authorized>
					</Greeting>
				</SearchContainer>
			</Main>
		</View>
	);
}
