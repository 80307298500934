import numeral from 'numeral';
import React from 'react';

import './companies-in-herbie.scss';
import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import LineChart from './charts/line-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funds/sizes_multiple?years=4';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('$0.0a').toUpperCase();

const formatData = (years) => {
	return Object.keys(years).map((year) => ({
		key: year,
		values: years[year]
			.slice()
			.sort((a, b) => a - b)
			.map((yearVal, index) => ({
				x: index,
				y: yearVal,
			})),
	}));
};

export default class FundSizeRawYears extends React.Component {
	static getDetailCard() {
		return FundSizeRawYearsDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showXAxis: false,
			showYAxis: true,
			useInteractiveGuideline: true,
			showLegend: true,
		};

		return (
			<GenericChart
				data={data}
				loadingComponent={HerbieLoader}
				fetchData={this.fetchData}
				forceY={0}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="Fund Sizes"
				yAxisLabel="Size of fund"
				yAxisTickFormat={yAxisTickFormat}
			>
				{LineChart}
			</GenericChart>
		);
	}
}

class FundSizeRawYearsDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/yearly-fund-sizes-raw.png"
				statName="Multiple years of fund sizes (raw)"
			/>
		);
	}
}
