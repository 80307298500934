// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import type { Action } from '../actions';
import { logout as logoutAction } from '../authentication';
import { logout } from '../utils/api';

type DispatchProps = {|
	logout: () => void,
|};
type Props = DispatchProps;

class Logout extends React.Component<Props> {
	componentDidMount() {
		logout();
		this.props.logout();
		location.reload(true);
	}

	render() {
		return null;
	}
}

export default connect<_, {||}, _, _, _, _>(
	null,
	(dispatch: Dispatch<Action>): DispatchProps => ({
		logout: () => dispatch(logoutAction()),
	}),
)(Logout);
