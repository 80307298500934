import React from 'react';
import styled from 'styled-components';

import { colors, effects, fonts } from '../../theme';

const CardBody = styled.div`
	${effects.shadow.hover}
	background-color: ${colors.card.card};
	border-radius: 8px;
	border: 1px solid ${colors.border.subtle};
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-width: 250px;
	padding: 8px 12px;
`;
const Logo = styled.img`
	border-radius: 50%;
	border: 1px solid ${colors.border.subtle};
	flex: 0 0 12px;
	height: 20px;
	object-fit: contain;
	object-position: center;
	width: 20px;
`;
const SubTitle = styled.span`
	${fonts.label.label};
	color: ${colors.text.secondary};
`;
const Title = styled.span`
	${fonts.paragraph.strong};
	color: ${colors.text.primary};
`;
const TitleAndLogoContainer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	flex: 1;
	gap: 4px;
	justify-content: flex-start;
`;

export { Logo, SubTitle, Title, TitleAndLogoContainer };
export default function ProfileTooltipCard({
	children,
	className,
	imageUrl,
	subTitle,
	title,
}: {
	children?: React.ReactNode;
	className?: string;
	imageUrl: string;
	subTitle?: string;
	title: string;
}) {
	return (
		<CardBody className={className}>
			<TitleAndLogoContainer>
				<Logo src={imageUrl} />
				<Title>{title}</Title>
			</TitleAndLogoContainer>
			{subTitle && <SubTitle>{subTitle}</SubTitle>}
			{children}
		</CardBody>
	);
}
