// @flow

import React, { type AbstractComponent as ReactAbstractComponent } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { compose } from 'recompose';

import type { State as StoreState } from '../reducers';
import type { AlgoliaHit, Filters, SearchBehaviorProps } from '../search';
import { searchBehavior } from '../search';

export type Option = {
	id: number,
	name: string,
};

function getOptionValue(option: Option): number {
	return option.id;
}

function getOptionLabel(option: Option): string {
	return option.name;
}

function noOptionsMessage({ inputValue }) {
	return inputValue ? 'No results' : 'Type to search';
}

export type Value<T: Option = Option> = T | Array<T>;

type OwnProps<T> = {|
	autoFocus?: boolean,
	className?: string,
	disabled?: boolean,
	multi: boolean,
	onBlur?: (evt: SyntheticEvent<HTMLFormElement>) => void,
	onValueChange: (value: ?Value<T>) => void,
	placeholder: string,
	value: ?Value<T>,
|};
type StateProps<T: Option = Option> = {|
	results: Array<T>,
|};

type Props<T> = {|
	...OwnProps<T>,
	...SearchBehaviorProps,
	...StateProps<T>,
|};

export default function algoliaPicker<T: Option = Option>(
	makeQuery: (query: string) => Filters,
	mapResults: (state: StoreState, results: Array<AlgoliaHit>) => Array<T>,
): ReactAbstractComponent<OwnProps<T>, mixed> {
	class AlgoliaPicker extends React.Component<Props<T>> {
		static defaultProps = {
			autoFocus: false,
			disabled: false,
			multi: false,
		};

		handleInputChange = (query: string): void => {
			this.props.onChange(makeQuery(query));
		};

		render() {
			return (
				<div
					className={`AlgoliaPicker ${
						this.props.className ? this.props.className : ''
					}`}
				>
					<Select
						autoFocus={this.props.autoFocus}
						getOptionLabel={getOptionLabel}
						getOptionValue={getOptionValue}
						hideSelectedOptions
						isDisabled={this.props.disabled}
						isMulti={this.props.multi}
						noOptionsMessage={noOptionsMessage}
						onBlur={this.props.onBlur}
						onChange={this.props.onValueChange}
						onInputChange={this.handleInputChange}
						options={this.props.results}
						placeholder={this.props.placeholder}
						value={this.props.value}
					/>
				</div>
			);
		}
	}

	return compose(
		searchBehavior(),
		connect(
			(
				state: StoreState,
				{ results }: OwnProps<T> & SearchBehaviorProps,
			): StateProps<T> => ({
				results: mapResults(state, results || []),
			}),
		),
	)(AlgoliaPicker);
}
