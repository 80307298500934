import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import assertExhaustive from '../../utils/assert-exhaustive';
import useSettableField, {
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from '../../utils/state-machine/settable-field';

type Data = boolean;

const HeaderText = styled.div`
	text-align: center;
`;

const Cell = styled.td`
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const ReadOnlyCell = styled(Cell)<{ checked: boolean }>`
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	white-space: nowrap;

	${({ checked }) =>
		checked
			? `
				color: green;
				background-color: rgba(0, 128, 0, 0.25);

				&:after {
					content: '✓';
				}
			`
			: `
				color: #888;

				&:before {
					content: '✗';
				}
			`}
`;

const SavingCell = styled(Cell)`
	background-color: #eee;
`;

const SuccessCell = styled(Cell)<{ checked: boolean }>`
	${({ checked }) =>
		checked
			? `
				color: green;
				background-color: #dfd;

			`
			: `
				background-color: #eaecef;
			`}
`;

const ErrorCell = styled(Cell)`
	background-color: #fdd;
	padding: 5px 10px;
`;

const Control = styled.span`
	&:before {
		background-color: transparent;
		content: '';
		display: block;
		padding: 10px;
		width: 100%;
	}
`;

const Label = styled.label`
	display: block;
	width: 100%;
`;

const Input = styled.input.attrs({
	type: 'checkbox',
})`
	height: 0.1px;
	left: 0;
	margin-left: -20px;
	opacity: 0.00000001;
	position: absolute;
	width: 0.1px;

	& + ${Control}:before {
		color: #888;
		content: '✗';
	}

	&:checked + ${Control}:before {
		color: green;
		content: '✓';
		background-color: rgba(0, 128, 0, 0.25);
	}

	&:focus + ${Control}:before {
		box-shadow: inset 0 0 4px 1px #4d90fe;
	}
`;

type CheckboxCellProps = {
	initialValue: Data;
	onChange: (value: Data, signal: AbortSignal) => Promise<Data>;
};

function CheckboxCell({ initialValue, onChange, ...props }: CheckboxCellProps) {
	const [currentState, transition] = useSettableField<Data>();

	if (currentState instanceof NormalState) {
		return (
			<Cell {...props}>
				<Label>
					<Input
						checked={initialValue}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
						) => {
							transition(
								currentState.onChange(
									event.target.checked,
									onChange,
								),
							);
						}}
					/>
					<Control />
				</Label>
			</Cell>
		);
	} else if (currentState instanceof SavingState) {
		return (
			<SavingCell {...props}>
				<Label>
					<Input checked={currentState.value} disabled />
					<Control />
				</Label>
			</SavingCell>
		);
	} else if (currentState instanceof SuccessState) {
		return (
			<SuccessCell {...props} checked={currentState.value}>
				<Label>
					<Input checked={currentState.value} disabled />
					<Control />
				</Label>
			</SuccessCell>
		);
	} else if (currentState instanceof ErrorState) {
		return <ErrorCell {...props}>{currentState.error.message}</ErrorCell>;
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class CheckboxColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	name: string;
	private select: (row: T) => Data;
	private update: null | ((val: Data) => Partial<T>);

	constructor({
		name,
		select,
		update = null,
	}: {
		name: string;
		select: (row: T) => Data;
		update?: null | ((val: Data) => Partial<T>);
	}) {
		this.name = name;
		this.select = select;
		this.update = update;

		this.cell.displayName = 'CheckboxColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const checked = this.select(row);

		const update = this.update;
		if (deferred || !update || !onChange) {
			return <ReadOnlyCell {...props} checked={checked} />;
		}

		return (
			<CheckboxCell
				{...props}
				initialValue={checked}
				onChange={async (newValue: Data, signal: AbortSignal) => {
					const patch = update(newValue);
					const response: T = await onChange(row, patch, signal);
					const updatedValue: Data = this.select(response);
					return updatedValue;
				}}
			/>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				{...props}
				initialSortDirection={sortDirections.descending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
			>
				<HeaderText>{this.name}</HeaderText>
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal: Data = this.select(a);
		const bVal: Data = this.select(b);

		return direction === sortDirections.ascending
			? +aVal - +bVal
			: +bVal - +aVal;
	}

	toCSV(row: T): string {
		const value = this.select(row);
		return value === true ? 'x' : '';
	}
}
