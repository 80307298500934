import flatten from 'lodash.flatten';
import { combineReducers } from 'redux';

import { ActionTypes } from '../constants';
import createReducer from '../utils/createReducer';

const addFromWatchListNotes = (state, notes) => {
	if (notes.length === 0) return state;
	const nextState = { ...state };

	for (const note of notes) {
		nextState[note.id] = note;
	}

	return nextState;
};

const byId = createReducer(
	{},
	{
		[ActionTypes.CANDIDATE_POOL_ITEM_ADD_SUCCESS]: (state, { payload }) =>
			addFromWatchListNotes(state, payload.item.entity.notes || []),
		[ActionTypes.CANDIDATE_POOL_LOAD_SUCCESS]: (state, { payload }) =>
			addFromWatchListNotes(
				state,
				flatten(payload.items.map((item) => item.entity.notes || [])),
			),
		[ActionTypes.LOAD_COMPANIES]: (state, { payload: companies }) => {
			const notes = flatten(
				companies.map((company) => company.notes || []),
			);
			if (notes.length === 0) return state;

			const nextState = { ...state };
			notes.forEach((note) => {
				nextState[note.id] = note;
			});
			return nextState;
		},
		[ActionTypes.NOTES_LOAD_SUCCESS]: (state, { payload }) => {
			const nextState = { ...state };

			payload.notes.forEach((note) => {
				if (note.target !== null) {
					nextState[note.id] = note;
				}
			});

			return nextState;
		},
		[ActionTypes.NOTES_ADD_SUCCESS]: (state, { payload }) => {
			const nextState = { ...state };

			payload.notes.forEach((note) => {
				nextState[note.id] = note;
			});

			return nextState;
		},
		[ActionTypes.NOTES_DELETE_SUCCESS]: (state, { payload }) => {
			const nextState = { ...state };

			delete nextState[payload.id];
			return nextState;
		},
		[ActionTypes.NOTES_EDIT]: (state, { payload }) => {
			const nextState = { ...state };

			nextState[payload.id] = payload;

			return nextState;
		},
		[ActionTypes.WATCHLIST_ADD_ITEM_SUCCESS]: (state, { payload }) =>
			addFromWatchListNotes(state, payload.item.entity.notes || []),
		[ActionTypes.WATCHLIST_LOAD_SUCCESS]: (state, { payload }) =>
			addFromWatchListNotes(
				state,
				flatten(payload.items.map((item) => item.entity.notes || [])),
			),
	},
);

const loaded = createReducer(false, {
	[ActionTypes.NOTES_LOAD_SUCCESS]: (state, { payload }) =>
		state || (!payload.type && !payload.id),
});

export default combineReducers({
	byId,
	loaded,
});
