export function deserialize(queryObject) {
	const filters = {};

	Object.keys(queryObject || {}).forEach((key) => {
		const value = queryObject[key];

		if (key === 'q') {
			filters.query = value;
		} else if (key.indexOf('.') > 0) {
			const path = key.split('.');
			const end = path[path.length - 1];

			if (end === 'max' || end === 'min') {
				const filter = path.slice(0, -1).join('.');

				if (!Array.isArray(filters[filter])) {
					filters[filter] = [null, null];
				}

				switch (end) {
					case 'min':
						filters[filter][0] = parseInt(value, 10);
						break;
					case 'max':
						filters[filter][1] = parseInt(value, 10);
						break;
					default:
					// This will never happen
				}
			} else if (Array.isArray(value)) {
				filters[key] = value;
			} else {
				filters[key] = [value];
			}
		} else if (Array.isArray(value)) {
			filters[key] = value;
		} else {
			filters[key] = [value];
		}
	});

	return filters;
}

export function serialize(filters) {
	const serialized = {};

	Object.keys(filters).forEach((key) => {
		const value = filters[key];

		if (key === 'query') {
			if (value.length >= 1) {
				serialized.q = value;
			}
		} else if (Array.isArray(value)) {
			if (value.every((el) => typeof el === 'string')) {
				if (value.length > 0) {
					serialized[key] = value;
				}
			} else {
				if (typeof value[0] === 'number') {
					serialized[`${key}.min`] = value[0];
				}

				if (typeof value[1] === 'number') {
					serialized[`${key}.max`] = value[1];
				}
			}
		}
	});

	return serialized;
}
