// @flow

import React from 'react';
import DocumentTitle from 'react-document-title';

import Authorized from '../Authorized';

import './stats-detail-list.scss';
import { statComponents } from './chart-utils';

const StatsDetailList = () => (
	<DocumentTitle title="Herbie Stats">
		<ul className="StatsDetailList">
			<li className="StatsDetailList-header">Stats</li>
			{statComponents.map(({ Component, auth }) => {
				const DetailCard = Component.getDetailCard();
				const key = Component.getKey();

				return (
					<Authorized auth={auth} key={key}>
						<DetailCard />
					</Authorized>
				);
			})}
		</ul>
	</DocumentTitle>
);

export default StatsDetailList;
