import { useCallback, useEffect, useRef, useState } from 'react';

export default function useDebouncedCallback<
	// `any` is used here in an `extends` constraint, not as a concrete type.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	T extends Array<any>,
>(callback: (...args: T) => void, ms: number): [(...args: T) => void, boolean] {
	const [isDebouncing, setIsDebouncing] = useState(false);
	const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
	const callbackRef = useRef<(...args: T) => void>(callback);
	const cancel = useCallback(() => {
		if (timeout.current) clearTimeout(timeout.current);
		timeout.current = null;
		setIsDebouncing(false);
	}, []);

	// Return `cancel` as the cleanup function from the `useEffect` handler so
	// that React automatically cancels any pending debounced operations when
	// the consuming component unmounts.
	useEffect(() => cancel, [cancel]);
	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const debouncedCallback = useCallback(
		(...args: T) => {
			if (timeout.current) clearTimeout(timeout.current);
			timeout.current = setTimeout(() => {
				cancel();
				callbackRef.current(...args);
			}, ms);
			setIsDebouncing(true);
		},
		[cancel, callbackRef, ms],
	);

	return [debouncedCallback, isDebouncing];
}
