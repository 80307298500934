import React from 'react';
import loadable from 'react-loadable';

import authorizedRoute from '../components/authorized-route';
import HerbieLoader from '../components/herbie-loader';

const View = loadable({
	loader: () => import('./view'),
	loading: HerbieLoader,
});

export const path = 'market-maps';
export const routes = authorizedRoute(
	{
		path,
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.market_maps',
);

export { useMarketMaps } from './api';
export type { MarketMapTree } from './region-tree';
