export const algoliaID = 'HHUJC9YMGG';
export const algoliaIndex = 'fused';
export const mixpanelBookmarkIdPageViewsReport = '29533493';
export const mixpanelBookmarkIdSessionReport = '29533397';
export const mixpanelProjectId = '578101';
export const mixpanelProxyHost = 'https://stats.herbie.drivecapital.com';
export const mixpanelToken =
	process.env.NODE_ENV === 'development'
		? 'b67330997bdfa59ddfeb345e81923247'
		: '19283653dfeb7fb820f06f295ccb8745';

export const googleOAuthClientId =
	process.env.NODE_ENV === 'development'
		? '768352000904-ugr78g07vrtik0ndepj21c846nr0pvu9.apps.googleusercontent.com'
		: '768352000904-lid7bpgu05gfp7theclg1ufi4b2ufbbd.apps.googleusercontent.com';
export const googleOAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
export const googleOAuthRedirect = 'static/html/google_oauth_callback.html';

// See src/index.html for available config options
declare global {
	interface Window {
		herbieConfig: { releaseVersion: string };
	}
}

export const releaseVersion =
	process.env.NODE_ENV === 'production'
		? window.herbieConfig.releaseVersion
		: 'development';
