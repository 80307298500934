import { Temporal } from '@js-temporal/polyfill';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { colors, effects, fonts } from '../theme';
import UnstyledDropdown, {
	Option as UnstyledOption,
} from '../theme/components/dropdown';
import { EnvelopeIcon as UnstyledEnvelopeIcon } from '../theme/icons/document';
import {
	BellOutlineIcon as UnstyledBellOutlineIcon,
	XIcon as UnstyledXIcon,
} from '../theme/icons/system';
import { CalendarIcon as UnstyledCalendarIcon } from '../theme/icons/time';

import { notificationMethods, type ReminderData } from './types';

const iconStyle = css`
	color: ${colors.icon.secondary};
	height: 16px;
	width: 16px;
`;

const AlertIcon = styled(UnstyledBellOutlineIcon)`
	${iconStyle}
`;

const ButtonChildren = styled.div`
	align-items: center;
	display: flex;
	gap: 4px;
	margin-right: -6px;
`;
const CalendarIcon = styled(UnstyledCalendarIcon)`
	${iconStyle}
`;
const DatePicker = styled.input`
	${fonts.label.label}
	appereance: none;
	background: transparent;
	border-radius: 4px;
	border: 1px solid transparent;
	color: ${colors.text.primary};
	outline: none;

	&:focus {
		${effects.shadow.focus}
		border-color: ${colors.semantic.focus};
	}

	&:disabled {
		box-shadow: none;
	}
`;
const Dropdown = styled(UnstyledDropdown)`
	&[data-reach-listbox-input] {
		> span {
			min-height: 34px;
		}
	}
`;
const EnvelopeIcon = styled(UnstyledEnvelopeIcon)`
	${iconStyle}
`;
const Header = styled.header`
	${fonts.paragraph.paragraph}
	border-bottom: 1px solid ${colors.border.subtle};
	color: ${colors.text.secondary};
	margin: 6px 8px;
	padding-bottom: 4px;
`;
const Option = styled(UnstyledOption)`
	&[data-reach-listbox-option] {
		${fonts.paragraph.paragraph}
		align-items: center;
	}
`;
const XIcon = styled(UnstyledXIcon)`
	${iconStyle}
`;

const CLEAR_NOTIFICATION = 'Clear';
export default function Reminder({
	disabled,
	onChange,
	reminder,
}: {
	disabled: boolean;
	onChange(reminder: ReminderData | null): void;
	reminder: ReminderData | null;
}) {
	const tomorrow = Temporal.Now.plainDateISO().add({ days: 1 }).toString();

	const handleNotificationMethodChange = useCallback(
		(notification_method: string) => {
			if (notification_method === CLEAR_NOTIFICATION) {
				onChange(null);
			} else if (
				notification_method !== notificationMethods.CALENDAR
				&& notification_method !== notificationMethods.EMAIL
			) {
				return;
			} else if (reminder) {
				onChange({
					...reminder,
					notification_method,
				});
			} else {
				onChange({
					notification_method,
					scheduled_for: tomorrow,
				});
			}
		},
		[onChange, reminder, tomorrow],
	);
	const handleScheduledForChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (reminder) {
				return onChange({
					...reminder,
					scheduled_for: event.target.value,
				});
			} else {
				return onChange({
					notification_method: notificationMethods.EMAIL,
					scheduled_for: event.target.value,
				});
			}
		},
		[onChange, reminder],
	);
	const buttonChildren = (
		<ButtonChildren>
			{reminder?.notification_method === 'Calendar' ? (
				<CalendarIcon />
			) : reminder?.notification_method === 'Email' ? (
				<EnvelopeIcon />
			) : (
				<AlertIcon />
			)}
			{reminder?.scheduled_for && (
				<DatePicker
					disabled={disabled}
					onChange={handleScheduledForChange}
					onKeyDown={(event) => {
						event.stopPropagation();
					}}
					onMouseDown={(event) => {
						event.stopPropagation();
					}}
					min={tomorrow}
					type="date"
					value={reminder.scheduled_for}
				/>
			)}
		</ButtonChildren>
	);

	return (
		<Dropdown
			buttonChildren={buttonChildren}
			disabled={disabled}
			onChange={handleNotificationMethodChange}
			placeholder="Set a reminder"
			value={reminder?.notification_method || null}
		>
			<Header>Remind me via</Header>
			<Option label="Email" value={notificationMethods.EMAIL}>
				<EnvelopeIcon /> Email
			</Option>
			<Option label="Calendar" value={notificationMethods.CALENDAR}>
				<CalendarIcon /> Calendar
			</Option>
			<Option label="Clear" value={CLEAR_NOTIFICATION}>
				<XIcon /> No Reminder
			</Option>
		</Dropdown>
	);
}
