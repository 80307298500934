// @flow

import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import AddCompanyForm from '../../components/AddCompanyForm';
import Authorized from '../../components/Authorized';
import SpinLoader from '../../components/spin-loader';
import type { AlgoliaHit, Filters } from '../behavior';
import type { SelectedSet } from '../view';

import Result from './result';

const AddButton = styled.button.attrs({
	className: 'Herbie-button--primary',
})`
	border-radius: 3px;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	margin-left: 6px;
	padding: 6px 12px;
`;

const Container = styled.div`
	flex: 1;
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
	overflow-y: scroll;
	margin-top: 12px;
`;

const LoadingArea = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-bottom: 12px;
	margin-top: 30px;
`;

const NoResultsArea = styled.div`
	max-width: 300px;
	margin: 0 auto 20px;
`;

// AddCompanyForm still uses SCSS, so we style it using the classes.
const StyledAddCompanyForm = styled(AddCompanyForm)`
	.AddCompanyForm-name,
	.AddCompanyForm-homepage {
		background-color: white;
		border-radius: 3px;
	}

	.AddCompanyForm-cancel {
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px;
	}

	.AddCompanyForm-submit {
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
	}
`;

type Props = {|
	complete: boolean,
	filters: Filters,
	loading: boolean,
	onScroll: () => void,
	onSelect: (type: 'companies' | 'people', id: number) => void,
	paging: boolean,
	resultCount: ?number,
	results: ?Array<AlgoliaHit>,
	selected: SelectedSet,
|};

export default function Results({
	complete,
	filters,
	loading,
	onScroll,
	onSelect,
	paging,
	results,
	selected,
}: Props) {
	const handleScroll = useCallback(
		(event: SyntheticWheelEvent<HTMLDivElement>) => {
			const list = event.currentTarget;

			if (
				!loading
				&& !paging
				&& list.scrollTop + list.clientHeight * 2 >= list.scrollHeight
			) {
				onScroll();
			}
		},
		[loading, paging, onScroll],
	);
	const showAddCompanyForm =
		!loading
		&& !paging
		&& Array.isArray(results)
		&& (!Array.isArray(filters.tags) || !filters.tags.includes('person'));

	return (
		<Container onScroll={complete ? void 0 : handleScroll}>
			<ul>
				{results
					&& results.map((result) => (
						<li key={result.type + result.id}>
							<Result
								onSelect={onSelect}
								result={result}
								selected={selected[result.type].includes(
									result.id,
								)}
							/>
						</li>
					))}
			</ul>
			{(loading || paging) && (
				<LoadingArea>
					<SpinLoader />
				</LoadingArea>
			)}
			{showAddCompanyForm && (
				<Authorized auth="contacts.watchlists">
					<NoResultsArea>
						<NoResults />
					</NoResultsArea>
				</Authorized>
			)}
		</Container>
	);
}

// We want the add button/form to reset back to the add button when the search
// changes. The easiest way to do that is put it in its own component. When the
// search changes, the parent component temporarily renders a loading spinner,
// then this component re-mounts with its initial state.
function NoResults() {
	const [adding, setAdding] = useState<boolean>(false);
	const handleAddClick = useCallback(() => {
		setAdding(true);
	}, []);
	const handleCancel = useCallback(() => {
		setAdding(false);
	}, []);

	if (adding) {
		return <StyledAddCompanyForm onCancel={handleCancel} />;
	} else {
		return (
			<p>
				{"Can't find a company? "}
				<AddButton onClick={handleAddClick} type="button">
					Add it!
				</AddButton>
			</p>
		);
	}
}
