// @flow

import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import './save-search-form.scss';
import { type Action, loadWatchListSuccess } from '../../actions';
import { trackEvent } from '../../utils/analytics';
import { post } from '../../utils/api';
import cancelablePromise from '../../utils/cancelable-promise';
import { hellip } from '../../utils/chars';
import reportError from '../../utils/sentry';
import { buildAlgoliaQuery } from '../behavior';
import type { Filters } from '../behavior';

type ExternalProps = {|
	filters: Filters,
	getDescription: () => string,
|};
type OwnProps = {|
	navigate: (to: string) => void,
	...ExternalProps,
|};
type DispatchProps = {|
	loadWatchListSuccess: (payload: any) => void,
|};
type Props = {|
	...OwnProps,
	...DispatchProps,
|};

type State = {
	saving: boolean,
};

class SaveSearchForm extends React.Component<Props, State> {
	request: ?any; // TODO: CancelablePromise type

	state: State = {
		saving: false,
	};

	componentWillUnmount() {
		if (this.request) {
			this.request.cancel();
		}
	}

	handleSave = () => {
		trackEvent('Save To WatchList', 'save-as-watchlist', 'advanced-search');
		this.setState({
			saving: true,
		});
		const query = buildAlgoliaQuery(this.props.filters);
		this.request = cancelablePromise(
			post('/watchlists', {
				body: {
					auto_update: true,
					description: this.props.getDescription(),
					include_in_travel_plans: false,
					name: 'Saved search',
					public: false,
					query,
				},
			}),
		);
		this.request
			.then((response) => {
				this.props.loadWatchListSuccess(response);
				this.props.navigate(`/watchlists/${response.id}`);
			})
			.catch((error) => {
				reportError(error);
			});
	};

	render() {
		return (
			<button
				className="SaveSearchForm"
				disabled={this.state.saving}
				onClick={this.handleSave}
				type="button"
			>
				{this.state.saving ? `Saving${hellip}` : 'Save as a watch list'}
			</button>
		);
	}
}

const ConnectedSaveSearchForm = connect<_, OwnProps, _, _, _, _>(
	null,
	(dispatch: Dispatch<Action>): DispatchProps =>
		bindActionCreators({ loadWatchListSuccess }, dispatch),
)(SaveSearchForm);

export default function SearchForm(props: ExternalProps) {
	const navigate = useNavigate();
	return <ConnectedSaveSearchForm navigate={navigate} {...props} />;
}
