import React from 'react';

import { colors } from '../theme';
import Chip from '../theme/components/chip';

import type { PipelineStageItem } from './types';

export const PRIORITY_COLORS = {
	'': {
		backgroundColor: colors.data.dust.layerHover,
		textColor: colors.data.dust.textPrimary,
	},
	LOW: {
		backgroundColor: colors.data.green.layerSubtle,
		textColor: colors.data.green.textSecondary,
	},
	MEDIUM: {
		backgroundColor: colors.data.yellow.layerSubtle,
		textColor: colors.data.yellow.textSecondary,
	},
	HIGH: {
		backgroundColor: colors.data.red.layerSubtle,
		textColor: colors.data.red.textSecondary,
	},
} as const;

export const priorityOptions = {
	'': 'Unset',
	LOW: 'Low',
	MEDIUM: 'Medium',
	HIGH: 'High',
} as const;

export function ReadOnlyPriorityChip({
	chipProps,
	className,
	priority = '',
}: {
	chipProps?: Partial<React.ComponentProps<typeof Chip>>;
	className?: string;
	priority?: PipelineStageItem['priority'];
}) {
	return (
		<Chip
			background={PRIORITY_COLORS[priority].backgroundColor}
			className={className}
			color={PRIORITY_COLORS[priority].textColor}
			label={priorityOptions[priority]}
			{...chipProps}
		/>
	);
}
