import type { Action } from './actions';
import { LOGIN, LOGOUT } from './constants';
import { getUser } from './sync';
import type { User } from './types';

export type State = User | null;

export default function authenticationReducer(
	// Redux reducers take an optional state on first call.
	// eslint-disable-next-line default-param-last
	state: State = getUser(),
	action: Action,
): State {
	switch (action.type) {
		case LOGIN:
			if (typeof action.payload !== 'string') {
				return action.payload;
			}

			return state;

		case LOGOUT:
			return null;

		default:
			return state;
	}
}
