import { useCallback } from 'react';

import useImmutableSearchParams from './use-immutable-search-params';

export default function useSearchParam(
	paramName: string,
	defaultValue: string | null = null,
): [string | null, (newValue: string) => void] {
	const [searchParams, setSearchParams] = useImmutableSearchParams();
	const setSearchParam = useCallback(
		(newValue: string) => {
			setSearchParams(
				(currentParams) => currentParams.set(paramName, newValue),
				{ replace: true },
			);
		},
		[paramName, setSearchParams],
	);

	return [searchParams.get(paramName) ?? defaultValue, setSearchParam];
}

function isOption<K extends string>(
	options: Record<K, unknown>,
	value: unknown,
): value is K {
	return typeof value === 'string' && Object.hasOwn(options, value);
}

export function useOptionSearchParam<Option extends string>(
	paramName: string,
	options: Record<Option, unknown>,
	defaultValue: Option,
): [Option, (newValue: Option) => void] {
	const [value, setValue] = useSearchParam(paramName);
	const option = isOption(options, value) ? value : defaultValue;
	return [option, setValue];
}
