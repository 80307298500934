import React from 'react';
import loadable from 'react-loadable';

import authorizedRoute from '../components/authorized-route';
import HerbieLoader from '../components/herbie-loader';

export { type LPData } from './types';
export { default as LPFundraisingView } from './view';

const View = loadable({
	loader: () => import('./view'),
	loading: HerbieLoader,
});

export const routes = authorizedRoute(
	{
		path: 'lps',
		element: <View />,
	},
	'contacts.lpfundraising',
);
