import React from 'react';
import type { ReactElement, ReactNode } from 'react';

export default (strings: Array<string>, separator = ','): string => {
	if (strings.length < 1) {
		return '';
	}

	if (strings.length === 1) {
		return strings[0];
	}

	if (strings.length === 2) {
		return strings.join(' and ');
	}

	return `${strings
		.slice(0, strings.length - 1)
		.join(`${separator} `)}${separator} and ${strings[strings.length - 1]}`;
};

interface Props {
	children: ReactNode;
}

export function Listify(props: Props): JSX.Element | null {
	const childArray = React.Children.toArray(props.children);

	if (childArray.length < 1) {
		return null;
	}
	if (childArray.length === 1) {
		return <React.Fragment>{childArray[0]}</React.Fragment>;
	}
	if (childArray.length === 2) {
		return (
			<React.Fragment>
				{childArray[0]} and {childArray[1]}
			</React.Fragment>
		);
	}
	return (
		<React.Fragment>
			{childArray.slice(0, childArray.length - 1).map((element) => (
				// https://reactjs.org/docs/react-api.html#reactchildrentoarray
				// Each element from Children.toArray contains a key,
				// but typescript doesn't know that. Casting to ReactElement
				// lets typescript know that key will for sure exist
				<React.Fragment key={(element as ReactElement).key}>
					{element},{' '}
				</React.Fragment>
			))}
			and {childArray[childArray.length - 1]}
		</React.Fragment>
	);
}
