import moment from 'moment';
import React from 'react';

import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericChart from './charts/generic-chart';
import LinePlusBarChart from './charts/line-plus-bar-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/people/contacted?filter=drive';
let data = null;

const xAxisTickFormat = (d) => moment(d * 1000).format('YYYY-MM-DD');

const formatData = (rawData) => {
	let accumulator = 0;

	return [
		{
			bar: true,
			color: '#c2d5ee',
			key: 'New',
			values: rawData.map(({ count, date }) => ({
				label: moment(date, 'YYYY-MM-DD').unix(),
				value: count,
			})),
		},
		{
			color: '#5799c7',
			key: 'Total',
			values: rawData.map(({ count, date }) => ({
				label: moment(date, 'YYYY-MM-DD').unix(),
				value: (accumulator += count),
			})),
		},
	];
};

export default class PeopleContactedDrive extends React.Component {
	static getDetailCard() {
		return PeopleContactedDriveDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => api.get(apiUrl).then(formatData);

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			focusEnable: false,
			showXAxis: true,
			showYAxis: true,
			useInteractiveGuideline: true,
		};

		return (
			<GenericChart
				barsForceY={0}
				data={data}
				fetchData={this.fetchData}
				linesForceY={0}
				margin={{ bottom: 45, right: 80 }}
				onDataFetch={this.handleDataFetch}
				options={options}
				title="People Contacted (Drive Only)"
				xAxisLabel="Week"
				xAxisTickFormat={xAxisTickFormat}
				y1AxisLabel="New contacts"
				y2AxisLabel="Total contacts"
				loadingComponent={HerbieLoader}
			>
				{LinePlusBarChart}
			</GenericChart>
		);
	}
}

class PeopleContactedDriveDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/companies_line_plus_bar.png"
				statName="People Contacted (Drive Only)"
			/>
		);
	}
}
