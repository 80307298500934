/**
 * [Figma reference][1].
 *
 * [1]: https://www.figma.com/file/9a4W4MPcOlwDXNUMhNcZaE/Herbie-Profiles?type=design&node-id=673-294440&mode=design&t=8oxrDX9MVMs3nFOG-0
 */
import React from 'react';
import styled from 'styled-components';

import colors from '../colors';
import fonts from '../fonts';

import Button from './button';

const Body = styled.div`
	${fonts.paragraph.paragraph};
	background: ${colors.card.card};
	border-radius: 8px;
	border: 1px dashed ${colors.border.subtle};
	color: ${colors.text.secondary};
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;
	justify-content: center;
	padding: 24px 12px;
	text-align: center;
	width: 100%;
`;
const Buttons = styled.div`
	display: flex;
	gap: 8px;
	justify-content: center;

	div {
		display: flex;
		width: 50%;

		&:first-child:not(:last-child) {
			justify-content: flex-end;
		}

		&:first-child:last-child {
			justify-content: center;
		}
	}
`;

interface Props extends React.ComponentPropsWithRef<'div'> {
	onPrimaryCallToAction?: () => void;
	onSecondaryCallToAction?: () => void;
	primaryCallToAction?: string;
	secondaryCallToAction?: string;
}

export default function EmptyStatePlaceholder({
	children,
	onPrimaryCallToAction,
	onSecondaryCallToAction,
	primaryCallToAction,
	secondaryCallToAction,
	...props
}: Props) {
	return (
		<Body {...props}>
			{children}
			{primaryCallToAction && (
				<Buttons>
					<div>
						<Button
							onClick={onPrimaryCallToAction}
							variant="secondary"
						>
							{primaryCallToAction}
						</Button>
					</div>
					{secondaryCallToAction && (
						<div>
							<Button
								onClick={onSecondaryCallToAction}
								variant="secondary"
							>
								{secondaryCallToAction}
							</Button>
						</div>
					)}
				</Buttons>
			)}
		</Body>
	);
}
