import moment from 'moment';
import React from 'react';
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import colors from 'tailwindcss/colors';

import { mixpanelBookmarkIdPageViewsReport } from '../config';

import { ResponsiveChart } from './components';
import useMixpanelInsights from './use-mixpanel-insights';

interface DriveTeamUsage {
	series: {
		'Visit New Page [Total Events]': {
			'Executive Assistants': Record<string, number>;
			'F4 General Managers': Record<string, number>;
			'Investor Relations': Record<string, number>;
			Investors: Record<string, number>;
			'Talent Partners': Record<string, number>;
		};
	};
}

export default function HerbieDriveTeamPageViews(): JSX.Element {
	const [data, Loader] = useMixpanelInsights<DriveTeamUsage>(
		mixpanelBookmarkIdPageViewsReport,
	);

	if (!data) return <Loader />;

	const pageViewData = data.series['Visit New Page [Total Events]'];
	const formatString = 'YYYY-MM-DD';
	const eaViews = new Map<string, number>();
	const f4Views = new Map<string, number>();
	const investorRelationsViews = new Map<string, number>();
	const investorViews = new Map<string, number>();
	const partnerViews = new Map<string, number>();

	for (const [date, views] of Object.entries(
		pageViewData['Executive Assistants'],
	)) {
		eaViews.set(moment(date).format(formatString), views);
	}
	for (const [date, views] of Object.entries(
		pageViewData['F4 General Managers'],
	)) {
		f4Views.set(moment(date).format(formatString), views);
	}
	for (const [date, views] of Object.entries(
		pageViewData['Investor Relations'],
	)) {
		investorRelationsViews.set(moment(date).format(formatString), views);
	}
	for (const [date, views] of Object.entries(pageViewData.Investors)) {
		investorViews.set(moment(date).format(formatString), views);
	}
	for (const [date, views] of Object.entries(
		pageViewData['Talent Partners'],
	)) {
		partnerViews.set(moment(date).format(formatString), views);
	}

	const allDates = new Set([
		...eaViews.keys(),
		...f4Views.keys(),
		...investorRelationsViews.keys(),
		...investorViews.keys(),
		...partnerViews.keys(),
	]);

	const formattedData = [...allDates].sort().map((date) => ({
		date,
		eas: Math.round(eaViews.get(date) || 0),
		f4s: Math.round(f4Views.get(date) || 0),
		investorRelations: Math.round(investorRelationsViews.get(date) || 0),
		investors: Math.round(investorViews.get(date) || 0),
		partners: Math.round(partnerViews.get(date) || 0),
	}));

	return (
		<ResponsiveChart>
			<LineChart
				data={formattedData}
				margin={{ top: 0, right: 5, left: -20, bottom: 0 }}
			>
				<Tooltip />
				<CartesianGrid vertical={false} strokeDasharray="4" />
				<XAxis dataKey="date" />
				<YAxis />
				<Line
					dataKey="investors"
					name="Investors"
					stroke={colors.purple['600']}
					strokeWidth={1.5}
					dot={false}
				/>
				<Line
					dataKey="partners"
					name="Talent"
					stroke={colors.blue['600']}
					strokeWidth={1.5}
					dot={false}
				/>
				<Line
					dataKey="investorRelations"
					name="IR"
					stroke={colors.green['600']}
					strokeWidth={1.5}
					dot={false}
				/>
				<Line
					dataKey="f4s"
					name="F4"
					stroke={colors.amber['600']}
					strokeWidth={1.5}
					dot={false}
				/>
				<Line
					dataKey="eas"
					name="EAs"
					stroke={colors.red['600']}
					strokeWidth={1.5}
					dot={false}
				/>
				<Legend verticalAlign="top" height={28} align="right" />
			</LineChart>
		</ResponsiveChart>
	);
}
