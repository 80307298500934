// @flow

import styled from 'styled-components';

import { charcoalGray, primaryDriveBlue, sectionBorder } from '../colors';

export const TopLevelDetail = styled.span`
	color: ${primaryDriveBlue.string()};
	font-size: 16px;
	font-weight: 500;
`;

export const TopLevelNumber = styled.span`
	color: ${charcoalGray.string()};
	font-size: 32px;
	font-weight: 300;
	margin-right: 12px;
`;

export const TopLevelStat = styled.div`
	align-items: center;
	background-color: #fff;
	border: 1px solid ${sectionBorder.string()};
	border-radius: 6px;
	display: flex;
	flex: 1 0 auto;
	margin: 0 10px;
	padding: 18px;
`;

export const TopLevelWrapper = styled.div`
	display: flex;
	flex-flow: row nowrap;
	overflow-x: scroll;
	justify-content: flex-start;
	padding: 12px;
	flex: none;
`;

export const TopLevelTable = styled.table`
	margin-right: 4px;
`;

export const TopLevelTableCell = styled.td`
	text-align: center;
	padding: 0px 4px;
`;

export const TopLevelTableHeader = styled.th`
	color: #888;
	font-size: 0.8em;
	font-weight: normal;
	padding: 0px 4px;
	text-align: center;
`;
