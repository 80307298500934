import Color from 'color';
import styled from 'styled-components';

import {
	charcoalGray,
	driveGreen,
	lightGray,
	primaryDriveBlue,
	red,
	secondaryGray,
} from '../colors';

interface Props {
	danger?: boolean;
	neutral?: boolean;
	primary?: boolean;
	small?: boolean;
	success?: boolean;
}

function getBackgroundColor({ danger, neutral, primary, success }: Props) {
	if (success) return driveGreen;
	if (primary) return primaryDriveBlue;
	if (danger) return red;
	if (neutral) return lightGray;
	return secondaryGray;
}

function getForegroundColor({ danger, primary, success }: Props) {
	if (primary || success || danger) return new Color('white');
	return charcoalGray;
}

const Button = styled.button<Props>`
	background-color: ${(props: Props) => getBackgroundColor(props).string()};
	border-radius: 3px;
	border: none;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	color: ${(props) => getForegroundColor(props).string()};
	font-family: inherit;
	font-size: inherit;
	margin: 12px 0;
	outline: none;
	padding: ${(props) => (props.small ? '6px 12px' : '12px 24px')};
	transition: background-color 0.1s linear;

	&:not(:disabled) {
		cursor: pointer;
	}

	&:hover,
	&:focus {
		background-color: ${(props) =>
			getBackgroundColor(props).lighten(0.07).string()};
	}

	&:active {
		background-color: ${(props) =>
			getBackgroundColor(props).darken(0.1).string()};
		transition-duration: 0s;
	}

	&:disabled {
		background-color: ${(props) =>
			getBackgroundColor(props).alpha(0.5).string()};
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	}
`;

export default Button;
