import { Set as ImmutableSet } from 'immutable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import * as colors from '../../colors';
import {
	Buttons,
	DeletingState,
	EditingState,
	NormalState,
	SavingState,
	useHeaderActions,
} from '../../components/buttons/header_actions';
import SnackBar from '../../components/snack-bar';
import { downloadTable } from '../../table';
import type { IColumn, Sort } from '../../table';
import { trackEvent } from '../../utils/analytics';
import EditColumnDescriptors from '../../watchlists/edit/column-descriptors';
import Authorized, {
	hasTenant,
	isStaff,
	isTalentPartner,
	or,
} from '../Authorized';
import { NewTrashIcon } from '../icons';

import type { ColumnDescriptor } from './column';
import CopyToCandidatePool from './copy-to-candidate-pool';
import Download from './download';
import type { EntityData, WatchListData } from './types';

const Container = styled.header`
	background-color: #eaecef;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 12px;
`;

const Left = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
`;
const Title = styled.h3``;
const SubTitle = styled.span`
	background-color: transparent;
	align-self: flex-end;
	font-size: 12px;
	margin-bottom: 6px;
	padding-left: 10px;
`;

const Icons = styled.span`
	align-items: center;
	display: flex;
	flex-direction: row;
	margin-left: 6px;
`;
const Icon = styled.span`
       display:flex
       flex-direction: row;
       svg{
               fill: #aaa;
       height: 15px;
       width: 15px;
       }
`;

// http://publicicons.org/search-icon/
const SearchIcon = (
	<svg viewBox="0 0 8 8">
		<path
			id="search"
			d="M7.99,6.928L5.847,4.784c0.289-0.476,0.458-1.033,0.458-1.63C6.305,1.412,4.895,0,3.152,0
                                               C1.411,0,0,1.412,0,3.154c0,1.741,1.412,3.151,3.153,3.151c0.597,0,1.154-0.169,1.63-0.458l2.145,2.146L7.99,6.928z M1.203,3.154
                                               c0.002-1.077,0.874-1.949,1.951-1.951c1.076,0.002,1.948,0.874,1.949,1.951C5.101,4.23,4.229,5.103,3.153,5.103
                                       C2.075,5.103,1.205,4.23,1.203,3.154z"
		/>
	</svg>
);
const AutoUpdateIcon = (
	<svg viewBox="0 0 120 120">
		<polygon points="106.739,41.135 47.711,119.699 61.321,64.439 29.656,64.973 42.59,0 80.997,0 65.58,41.17 " />
	</svg>
);

const EditButton = styled.button`
	background-color: transparent;
	color: #007dc2 !important;
	cursor: pointer;
	border: none;
	align-self: flex-end;
	font-size: 12px;
	margin-bottom: 5px;
	padding-left: 10px;
`;

const IconsContainer = styled.div`
	border-left: 1px solid ${colors.disabledGray.lighten(0.3).string()};
	margin-left: 12px;
	padding-left: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const TrashIcon = styled(NewTrashIcon)`
	color: ${colors.charcoalGray.string()};
	height: 20px;
	width: 20px;

	&:hover {
		transition: all 0.15s ease-in-out;
		cursor: pointer;
		color: ${colors.red.string()};
	}
`;

const Right = styled.div`
	align-items: center;
	display: flex;
	max-width: 300px;
	flex-direction: row;
	justify-content: flex-end;
	flex: 1;
`;

const SearchBox = styled.input`
	border: 0;
	border-radius: 3px;
	flex: 1;
	font-size: 14px;
	margin: -6px 6px -6px 0px;
	max-width: 300px;
	outline: 0;
	padding: 0 8px;
	height: 34px;
`;

const EditFormContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin: -6px 0 -6px -6px;
`;

const EditForm = styled.form`
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-between;
`;

const EditFormInputs = styled.div`
	display: flex;
	flex-direction: row;
`;

const EditFormLabel = styled.label`
	padding: 6px;
`;
const EditFormTextInput = styled.input`
	border: 0;
	border-radius: 3px;
	font-size: 14px;
	height: 34px;
	outline: 0;
	padding: 6px;
`;

const EditFormCheckboxInput = styled.input`
	margin-right: 6px;
`;

const DeleteConfirmation = styled.span`
	margin-left: 8px;
`;

interface Props {
	autoUpdate: boolean;
	canAutoUpdate: boolean;
	canEditCells: boolean;
	columnDescriptors: Array<ColumnDescriptor>;
	description: string | null;
	hideEdit: boolean;
	isOwner: boolean;
	isPublic: boolean;
	items: WatchListData['items'];
	name: string;
	onCopyToCandidatePool: (args: {
		candidatePoolId: number;
		jobOrderId: number;
	}) => Promise<void>;
	onDelete: () => Promise<void>;
	onEdit: (edits: {
		isAutoUpdate: boolean;
		isPublic: boolean;
		isSendUpdateEmail: boolean;
		newColumns?: Array<ColumnDescriptor>;
		newName: string;
	}) => Promise<void>;
	onItemsDelete: () => void;
	onSearch: (nextSearch: string) => void;
	owner: WatchListData['owner'];
	search: string;
	selectedItems: ImmutableSet<EntityData>;
	sendEmailUpdate: boolean;
	sort: Sort | null;
	tableColumns: Array<IColumn<EntityData>>;
}

function DeleteButton({
	onDelete,
	itemCount,
	selectedItemCount,
}: {
	itemCount: number;
	onDelete: Props['onItemsDelete'];
	selectedItemCount: number;
}): JSX.Element {
	const [confirming, setConfirming] = useState<boolean>(false);
	const shouldConfirm = itemCount > 0 && selectedItemCount === itemCount;
	const handleClick = useCallback(() => {
		if (!shouldConfirm) {
			onDelete();
		} else if (confirming) {
			onDelete();
		} else {
			setConfirming(true);
		}
	}, [confirming, onDelete, shouldConfirm]);

	useEffect(() => {
		if (!shouldConfirm && confirming) {
			setConfirming(false);
		}
	}, [confirming, shouldConfirm]);

	return (
		<>
			<TrashIcon onClick={handleClick} />
			{confirming && (
				<DeleteConfirmation>
					Delete all items in list?
				</DeleteConfirmation>
			)}
		</>
	);
}

export default function Header({
	autoUpdate,
	canAutoUpdate,
	canEditCells,
	columnDescriptors,
	description,
	hideEdit,
	isOwner,
	isPublic,
	items,
	name,
	onCopyToCandidatePool,
	onDelete,
	onEdit,
	onItemsDelete,
	onSearch,
	owner,
	search,
	sendEmailUpdate,
	selectedItems,
	sort,
	tableColumns,
}: Props): JSX.Element {
	const [actionState, transitionActionState] = useHeaderActions();
	const editedColumnDescriptors = useRef<EditColumnDescriptors | null>(null);
	const submitForm = useRef<HTMLFormElement>(null);

	const handleDownload = useCallback(() => {
		downloadTable(tableColumns, items, name, search, sort);
	}, [tableColumns, items, search, sort, name]);

	const handleSave = useCallback(() => {
		if (!submitForm.current || !editedColumnDescriptors.current) {
			return Promise.reject(
				new Error(
					'Missing refs for submitForm or editedColumnDescriptors',
				),
			);
		}

		const form = submitForm.current as HTMLFormElement & {
			isAutoUpdate?: HTMLInputElement;
			isPublic?: HTMLInputElement;
			isSendUpdateEmail: HTMLInputElement;
			name: HTMLInputElement;
		};

		return onEdit({
			isAutoUpdate: form.isAutoUpdate?.checked ?? autoUpdate,
			isPublic: form.isPublic?.checked ?? isPublic,
			isSendUpdateEmail:
				form.isSendUpdateEmail?.checked ?? sendEmailUpdate,
			newColumns: editedColumnDescriptors.current?.getColumnDescriptors(),
			newName: form.name.value,
		});
	}, [autoUpdate, isPublic, onEdit, sendEmailUpdate]);

	const selectedItemCount = selectedItems.size;
	const showItemActions = canEditCells && selectedItemCount > 0;
	const showEditButton = !showItemActions && isOwner;
	const showCopyToPool =
		showItemActions
		&& selectedItems.every((item) => item?.type === 'people');

	if (actionState instanceof NormalState) {
		return (
			<Container>
				<Left>
					<Title>{name}</Title>
					{description && (
						<Icons>
							<Icon title={description}>{SearchIcon}</Icon>
							{autoUpdate && (
								<Icon title="This watch list will be kept up to date automatically.">
									{AutoUpdateIcon}
								</Icon>
							)}
						</Icons>
					)}
					{hideEdit && <SubTitle>({items.length} Firms)</SubTitle>}
					{showEditButton && (
						<EditButton
							onClick={() => {
								transitionActionState(actionState.onEdit());
							}}
						>
							edit
						</EditButton>
					)}
					{!hideEdit && !isOwner && (
						<SubTitle className="WatchList-header-owner">
							({owner.first_name})
						</SubTitle>
					)}
					{(showItemActions || showCopyToPool) && (
						<IconsContainer>
							{showItemActions && (
								<DeleteButton
									onDelete={onItemsDelete}
									itemCount={items.length}
									selectedItemCount={selectedItemCount}
								/>
							)}
							{showCopyToPool && (
								<Authorized auth={or(isTalentPartner, isStaff)}>
									<CopyToCandidatePool
										onCopy={onCopyToCandidatePool}
									/>
								</Authorized>
							)}
						</IconsContainer>
					)}
				</Left>
				<Right>
					<SearchBox
						defaultValue={search}
						onChange={(event) => onSearch(event.target.value)}
						placeholder="Search names or locations"
					/>
					<Authorized auth="contacts.watchlists_export">
						<Download onDownload={handleDownload} />
					</Authorized>
				</Right>
			</Container>
		);
	}

	const formInputsDisabled =
		actionState instanceof DeletingState
		|| actionState instanceof SavingState;

	let snackBar = null;
	if (actionState instanceof EditingState && actionState.lastError) {
		if (actionState.lastError.action === 'DELETE') {
			snackBar = 'Failed to Delete';
		} else if (actionState.lastError.action === 'SAVE') {
			snackBar = 'Failed to Save';
		}
	}

	return (
		<Container>
			<EditFormContainer>
				{snackBar && <SnackBar type="alert" text={snackBar} />}
				<EditForm ref={submitForm} onSubmit={handleSave}>
					<EditFormInputs>
						<EditFormTextInput
							defaultValue={name}
							disabled={formInputsDisabled}
							name="name"
							placeholder="Name"
							type="text"
						/>
						<Authorized auth={hasTenant}>
							<EditFormLabel>
								<EditFormCheckboxInput
									defaultChecked={isPublic}
									disabled={formInputsDisabled}
									name="isPublic"
									type="checkbox"
								/>
								{'Public'}
							</EditFormLabel>
						</Authorized>
						<EditFormLabel>
							<EditFormCheckboxInput
								defaultChecked={sendEmailUpdate}
								disabled={formInputsDisabled}
								name="isSendUpdateEmail"
								type="checkbox"
							/>
							{'Include in update email'}
						</EditFormLabel>
						{canAutoUpdate && (
							<EditFormLabel>
								<EditFormCheckboxInput
									defaultChecked={autoUpdate}
									disabled={formInputsDisabled}
									name="isAutoUpdate"
									type="checkbox"
								/>
								{'Auto-update'}
							</EditFormLabel>
						)}
					</EditFormInputs>
					<Buttons
						onCancel={() => {
							trackEvent(
								'Editing Watch List',
								'watchlist-header',
								'watchlist-detail',
								{
									action: 'cancel-edits',
								},
							);
						}}
						deleteConfirmationMessage="Are you sure you want to delete this watch list?"
						onDelete={async () => {
							await onDelete();
							trackEvent(
								'Deleting Watch List',
								'watchlist-header',
								'watchlist-detail',
								{
									action: 'confirm-deletion',
								},
							);
						}}
						onSave={async () => {
							await handleSave();
							trackEvent(
								'Editing Watch List',
								'watchlist-header',
								'watchlist-detail',
								{
									action: 'save-edits',
								},
							);
						}}
						state={actionState}
						transition={transitionActionState}
					/>
				</EditForm>

				<EditColumnDescriptors
					columnDescriptors={columnDescriptors}
					protectedDescriptorKeys={[
						'logo_url',
						'name',
						'last_contact_with_drive',
					]}
					ref={editedColumnDescriptors}
				/>
			</EditFormContainer>
		</Container>
	);
}
