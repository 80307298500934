import { ErrorBoundary } from '@sentry/react';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { ChartContainer, ChartTitle } from './components';
import DataFixes from './data-fixes';
import HerbieAttributableInvestments from './herbie-attributable-investments';
import HerbieAttributableOnePagers from './herbie-attributable-one-pagers';
import HerbieDriveTeamPageViews from './herbie-drive-team-page-views';
import HerbieSessionDuration from './herbie-session-duration';
import HighBeamCohorts from './high-beam-cohorts';

const DashboardContainer = styled.div`
	width: 100%;
	flex: 1;
	padding: 24px;
	display: grid;
	grid-auto-rows: 450px;
	grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
	grid-gap: 24px;
`;

const DATA_FIX_START_DATE = '2021-04-01';
const QUARTERLY_DATA_START_DATE = '2020-01-01';
const QUARTERLY_DATA_END_DATE = moment()
	.subtract(1, 'month')
	.endOf('quarter')
	.format(moment.HTML5_FMT.DATE);

export const PATH = 'herbie';

export default function HerbieView(): JSX.Element {
	return (
		<DashboardContainer>
			<ChartContainer>
				<ChartTitle>New Investments</ChartTitle>
				<HerbieAttributableInvestments
					endDate={QUARTERLY_DATA_END_DATE}
					startDate={QUARTERLY_DATA_START_DATE}
				/>
			</ChartContainer>
			<ChartContainer>
				<ChartTitle>Herbie-Catalyzed Investor One Pagers</ChartTitle>
				<HerbieAttributableOnePagers
					endDate={QUARTERLY_DATA_END_DATE}
					startDate={QUARTERLY_DATA_START_DATE}
				/>
			</ChartContainer>
			<HighBeamCohorts />
			<ChartContainer>
				<ChartTitle>Data Fixes</ChartTitle>
				<DataFixes
					endDate={QUARTERLY_DATA_END_DATE}
					startDate={DATA_FIX_START_DATE}
				/>
			</ChartContainer>
			<ChartContainer>
				<ChartTitle>Average Session Duration</ChartTitle>
				<ErrorBoundary fallback={<p>Something went wrong</p>}>
					<HerbieSessionDuration />
				</ErrorBoundary>
			</ChartContainer>
			<ChartContainer>
				<ChartTitle>Drive Team Page Views</ChartTitle>
				<ErrorBoundary fallback={<p>Something went wrong</p>}>
					<HerbieDriveTeamPageViews />
				</ErrorBoundary>
			</ChartContainer>
		</DashboardContainer>
	);
}
