// @flow

import React, { type ChildrenArray as ReactChildrenArray } from 'react';
import styled from 'styled-components';

import { Pill } from '../../components/pills';

const Wrapper = styled.span`
	white-space: pre;
`;

type Props = {
	children: ReactChildrenArray<number | string>,
	onRemove: (value?: number | string) => void,
	punctuator: string,
	value?: number | string,
};

export default class DescriptionValue extends React.Component<Props> {
	static defaultProps = {
		punctuator: '',
	};

	handleRemove = () => {
		this.props.onRemove(this.props.value);
	};

	render() {
		return (
			<Wrapper>
				<Pill onRemove={this.handleRemove}>{this.props.children}</Pill>
				{this.props.punctuator}
			</Wrapper>
		);
	}
}
