import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import './Login.scss';

import {
	deleteToken,
	login as loginAction,
	TwoFactorForm,
} from '../authentication';
import GoogleSignIn, {
	useGoogleSignIn,
} from '../authentication/google-sign-in';
import { trackEvent } from '../utils/analytics';
import { getSecurityHeaders } from '../utils/api';
import useAbortSignal from '../utils/hooks/use-abort-signal';
import useAnalytics from '../utils/hooks/use-analytics';
import reportError from '../utils/sentry';

type Steps = {
	BASIC: 1;
	REQUESTING: 2;
	CODE: 3;
};
const steps: Steps = {
	BASIC: 1,
	REQUESTING: 2,
	CODE: 3,
};

export default function Login() {
	useAnalytics({
		componentIdentifier: 'login-view',
		eventName: 'Visit New Page',
		viewType: 'login',
	});
	useEffect(deleteToken, []);

	const dispatch = useDispatch();
	const signal = useAbortSignal();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [password, setPassword] = useState<string>('');
	const [step, setStep] = useState<keyof Steps>('BASIC');
	const [username, setUsername] = useState<string>('');
	const dispatchLogin = useCallback(
		(token: string) => {
			dispatch(loginAction(token));
		},
		[dispatch],
	);

	const handleUsernameChange = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			setUsername(event.currentTarget.value);
		},
		[],
	);
	const handlePasswordChange = useCallback(
		(event: React.SyntheticEvent<HTMLInputElement>) => {
			setPassword(event.currentTarget.value);
		},
		[],
	);
	const handleSubmit = useCallback(
		async (event: React.SyntheticEvent<HTMLFormElement>) => {
			event.preventDefault();

			if (!username) {
				setErrorMessage('Username is required');
				return;
			}
			if (!password) {
				setErrorMessage('Password is required');
				return;
			}

			setErrorMessage(null);
			setStep('REQUESTING');

			try {
				const response = await fetch('/api/v2/auth/token', {
					body: JSON.stringify({ username, password }),
					headers: {
						...getSecurityHeaders(),
						'Content-Type': 'application/json',
					},
					method: 'POST',
					signal,
				});
				switch (response.status) {
					case 200: {
						const { token } = (await response.json()) as {
							token: string;
						};
						trackEvent(
							'Attempt Login',
							'password-sign-in',
							'login',
							{
								success: true,
							},
						);
						dispatchLogin(token);
						break;
					}
					case 404: {
						trackEvent(
							'Attempt Login',
							'password-sign-in',
							'login',
							{
								success: false,
							},
						);
						setStep('BASIC');
						setErrorMessage('Username or Password is invalid.');
						break;
					}
					case 401: {
						setStep('CODE');
						break;
					}
					default:
						reportError(response);
						setStep('BASIC');
						setErrorMessage('An unknown error occurred.');
				}
			} catch (error) {
				reportError(error);
				setStep('BASIC');
				setErrorMessage('An unknown error occurred.');
			}
		},
		[dispatchLogin, password, signal, username],
	);

	const googleSignIn = useGoogleSignIn({
		analyticsEventName: 'Attempt Login',
		analyticsViewType: 'login',
		onSuccess: dispatchLogin,
	});

	return (
		<div className="Login">
			{steps[step] <= steps.REQUESTING && (
				<form className="Login-form" onSubmit={handleSubmit}>
					<div className="Form-container">
						<input
							autoCapitalize="off"
							autoComplete="username"
							autoCorrect="off"
							autoFocus
							className="Login-form-input"
							name="username"
							onChange={handleUsernameChange}
							placeholder="Username"
							spellCheck="false"
							type="text"
							value={username}
						/>
						<input
							autoComplete="current-password"
							className="Login-form-input"
							name="password"
							onChange={handlePasswordChange}
							placeholder="Password"
							type="password"
							value={password}
						/>
						<input
							className="Login-form-submit"
							disabled={steps[step] === steps.REQUESTING}
							type="submit"
							value="Sign In"
						/>
						{errorMessage && (
							<span className="Login-message Login-error">
								{errorMessage}
							</span>
						)}
						<p>
							<Link to="/password/reset">Forgot password?</Link>
						</p>
						{googleSignIn.enabled && (
							<>
								<div className="or-divider">
									<span>OR</span>
								</div>
								<GoogleSignIn
									errorMessage={googleSignIn.signInError}
									renderButton={googleSignIn.renderButton}
								/>
							</>
						)}
					</div>
				</form>
			)}
			{steps[step] >= steps.CODE && (
				<TwoFactorForm
					emailOrUsername={username}
					login={dispatchLogin}
					password={password}
				/>
			)}
		</div>
	);
}
