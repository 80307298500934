import { combineReducers } from 'redux';

import { ActionTypes } from '../constants';
import createReducer from '../utils/createReducer';

const byId = createReducer(
	{},
	{
		[ActionTypes.SEMIANNUAL_LOAD_SUCCESS]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...state[payload.id],
				...payload,
				loaded: true,
			},
		}),
		[ActionTypes.SEMIANNUAL_SAVE]: (state, { payload }) => ({
			...state,
			[payload.id]: {
				...payload,
				loaded: true,
			},
		}),
		[ActionTypes.SEMIANNUALS_LOAD_SUCCESS]: (state, { payload }) => {
			const nextState = { ...state };

			payload.forEach((semiAnnual) => {
				nextState[semiAnnual.id] = {
					...(state[semiAnnual.id] || []),
					...semiAnnual,
					loaded:
						(state[semiAnnual.id] && state[semiAnnual.id].loaded)
						|| false,
				};
			});

			return nextState;
		},
	},
);

const loaded = createReducer(false, {
	[ActionTypes.SEMIANNUALS_LOAD_SUCCESS]: () => true,
});

export default combineReducers({
	byId,
	loaded,
});
