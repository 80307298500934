export const notificationMethods = {
	CALENDAR: 'Calendar',
	EMAIL: 'Email',
} as const;

export type NotificationMethod =
	(typeof notificationMethods)[keyof typeof notificationMethods];

export interface ReminderData {
	notification_method: NotificationMethod;
	scheduled_for: string;
}

export interface NoteData {
	added_at: string;
	comment: string;
	commenter: {
		first_name: string;
		id: number;
		last_name: string;
		photo_url: string;
	};
	contacted_via: string;
	id: number;
	public: boolean;
	raw_comment: string;
	reminders: Array<ReminderData>;
}
export type ProfileType =
	| 'companies'
	| 'lpfundraising'
	| 'people'
	| 'pipelines';
