import numeral from 'numeral';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import DiscreteBarChart from '../charts/discrete-bar-chart';
import GenericChart from '../charts/generic-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/6_mo_revenue_change?fund=1';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'Revenue Added',
		values: dataArr.map((company) => ({
			label: company.company,
			value: company.revenue_change,
		})),
	},
];

export default class SemiAnnualFund1PortfolioRevenueChangeByCompany extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund1PortfolioRevenueChangeByCompanyDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 75 }}
				onDataFetch={this.handleDataFetch}
				title="Portfolio Revenue change in last 6 months"
				xAxisLabel="Company"
				yAxisLabel="Revenue Added"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund1PortfolioRevenueChangeByCompanyDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/portfolio_revenue_change.png"
				statName="Fund I Portfolio Revenue 6 month change by Company"
			/>
		);
	}
}
