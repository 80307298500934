import d3 from 'd3';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import GenericChart from '../charts/generic-chart';
import MultiBarChart from '../charts/multi-bar-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/yearly_portfolio_headcount?fund=2';
let data = null;

const yAxisTickFormat = () => d3.format(',');

const formatData = (stats) => {
	// These are the keys coming from the api
	return Object.keys(stats).map((key) => {
		return {
			key,
			values: stats[key],
		};
	});
};

export default class SemiAnnualFund2YearlyPortfolioHeadcount extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund2YearlyPortfolioHeadcountDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		const options = {
			showControls: true,
			stacked: true,
		};

		// I have no idea why yAxisTickFormat needs to be called, but the y axis shows source code otherwise...
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				onDataFetch={this.handleDataFetch}
				options={options}
				yAxisLabel="Number of FTEs"
				yAxisTickFormat={yAxisTickFormat()}
			>
				{MultiBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund2YearlyPortfolioHeadcountDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/yearly_portfolio_breakdown.png"
				statName="Fund II Yearly Breakdown of Portfolio Headcount"
			/>
		);
	}
}
