import React, { useEffect, useRef, useState } from 'react';
import type { ReactElement } from 'react';
import { DragLayerMonitor, useDrag, useDragLayer } from 'react-dnd';
import type { XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styled from 'styled-components';

import bound from '../utils/bound';
import getUniqueId from '../utils/get-unique-id';

interface DragBarProps {
	color: string;
}

// Setting a z-index of 24 here to allow this component
// to work alongside any use of the src/table component
// and it's styling
const DragBar = styled.div<DragBarProps>`
	width: 9px;
	height: 100%;
	z-index: 24;
	cursor: col-resize;
	margin: 0 -4px;
	background: linear-gradient(
		to right,
		transparent 0px,
		transparent 4px,
		${({ color }) => color} 4px,
		${({ color }) => color} 5px,
		transparent 5px,
		transparent 9px
	);
`;

interface DragItem {
	id: string;
}
type ResizeDirection = 'right';

interface Props {
	children: ReactElement;
	initialWidth: number;
	maxWidth: number;
	minWidth: number;
	onDragEnd?: (width: number) => void;
	resizeDirection: ResizeDirection;
	slideBarColor: string;
}

const calculateNewWidth = (
	childRect: DOMRect | undefined,
	cursorMousePosition: XYCoord | null,
): number | null => {
	if (!childRect || !cursorMousePosition) return null;

	return cursorMousePosition.x - childRect.left;
};
export default function Resizer({
	children,
	initialWidth,
	maxWidth,
	minWidth,
	onDragEnd,
	slideBarColor,
}: Props): ReactElement {
	const [width, setWidth] = useState(initialWidth);
	const childRef = useRef<HTMLElement>(null);

	// When using react-dnd's drag layer, each layer responds to any
	// active drag. Using a unique ref ensures that we will
	// update the specific instance of this component only when this instance
	// is being dragged. Without id ref and check, we would resize all instances
	// of this component any time react-dnd is used.
	const componentIdentifier = useRef<string>(getUniqueId('resizer'));
	const [, dragRef, dragPreview] = useDrag(
		() => ({
			end: (item: DragItem, monitor) => {
				if (item.id === componentIdentifier.current) {
					const calculatedNewWidth = calculateNewWidth(
						childRef.current?.getBoundingClientRect(),
						monitor.getClientOffset(),
					);
					const newWidth = bound(
						minWidth,
						calculatedNewWidth ?? initialWidth,
						maxWidth,
					);
					setWidth(newWidth);
					if (onDragEnd) onDragEnd(newWidth);
				}
			},
			item: (): DragItem => ({ id: componentIdentifier.current }),
			type: 'Resizer',
		}),
		[],
	);
	const draggingState = useDragLayer<
		{
			isDragging: ReturnType<DragLayerMonitor['isDragging']>;
			itemId: DragItem['id'] | null;
			mousePosition: ReturnType<DragLayerMonitor['getClientOffset']>;
		},
		DragItem
	>((monitor) => ({
		isDragging: monitor.isDragging(),
		itemId: monitor.getItem()?.id,
		mousePosition: monitor.getClientOffset(),
	}));

	useEffect(() => {
		dragPreview(getEmptyImage());
	}, [dragPreview]);

	const newWidth = calculateNewWidth(
		childRef.current?.getBoundingClientRect(),
		draggingState.mousePosition,
	);
	const currentWidth =
		(draggingState.itemId === componentIdentifier.current
			&& draggingState.isDragging
			&& newWidth)
		|| width;
	const calculatedWidth = bound(minWidth, currentWidth, maxWidth);

	const childProps = {
		ref: childRef,
		style: { width: `${calculatedWidth}px` },
	};

	return (
		<>
			{React.cloneElement(children, childProps)}
			<DragBar color={slideBarColor} ref={dragRef} />
		</>
	);
}
