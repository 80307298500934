import { css } from 'styled-components';

import { breakpoints } from '..';

/**
 * These reusable style constants are based on definitions in [Figma][1].
 *
 * Usage:
 *
 * ```tsx
 * const Component = styled.h1`
 *     ${fonts.h1.productive}
 * `;
 * ```
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?type=design&node-id=2196-21328&mode=design&t=mRtd2jt9tORFPntA-0
 */
const fonts = {
	caption: {
		caption: css`
			font-family: 'Staff';
			font-size: 9px;
			font-style: normal;
			font-weight: 400;
			letter-spacing: 0.09px;
			line-height: 12px; /* 133.333% */
			text-decoration: none;
			text-transform: none;
		`,
	},
	h1: {
		productive: css`
			font-family: 'Staff';
			font-size: 36px;
			font-style: normal;
			font-weight: 300; /* Light */
			letter-spacing: 0;
			line-height: 44px;
			text-decoration: none;
			text-transform: none;
		`,
	},
	h2: {
		productive: css`
			font-family: 'Staff';
			font-size: 28px;
			font-style: normal;
			font-weight: 500; /* Medium */
			letter-spacing: 0;
			line-height: 36px;
			text-decoration: none;
			text-transform: none;

			@media (${breakpoints.lg}) {
				font-size: 32px;
				line-height: 40px;
			}
		`,
		expressive: css`
			font-family: 'Staff Wide';
			font-size: 28px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 0;
			line-height: 36px;
			text-decoration: none;
			text-transform: none;

			@media (${breakpoints.lg}) {
				font-size: 32px;
				line-height: 40px;
			}
		`,
	},
	h3: {
		productive: css`
			font-family: 'Staff';
			font-size: 24px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 0;
			line-height: 32px;
			text-decoration: none;
			text-transform: none;

			@media (${breakpoints.lg}) {
				font-size: 28px;
				line-height: 36px;
			}
		`,
		expressive: css`
			font-family: 'Staff Condensed';
			font-size: 24px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 0;
			line-height: 32px;
			text-decoration: none;
			text-transform: uppercase;

			@media (${breakpoints.lg}) {
				font-size: 28px;
				line-height: 36px;
			}
		`,
	},
	h4: {
		productive: css`
			font-family: 'Staff';
			font-size: 18px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 0;
			line-height: 24px;
			text-decoration: none;
			text-transform: none;

			@media (${breakpoints.lg}) {
				font-size: 20px;
				line-height: 28px;
			}
		`,
		expressive: css`
			font-family: 'Staff Wide';
			font-size: 18px;
			font-style: normal;
			font-weight: 300; /* Light */
			letter-spacing: 0;
			line-height: 24px;
			text-decoration: none;
			text-transform: none;

			@media (${breakpoints.lg}) {
				font-size: 20px;
				line-height: 28px;
			}
		`,
		expressiveStrong: css`
			font-family: 'Staff Wide';
			font-size: 18px;
			font-style: normal;
			font-weight: 700; /* Bold */
			letter-spacing: 0;
			line-height: 24px;
			text-decoration: none;
			text-transform: uppercase;

			@media (${breakpoints.lg}) {
				font-size: 20px;
				line-height: 28px;
			}
		`,
	},
	h5: {
		productive: css`
			font-family: 'Staff';
			font-size: 16px;
			font-style: normal;
			font-weight: 600; /* SemiBold */
			letter-spacing: 0;
			line-height: 24px;
			text-decoration: none;
			text-transform: none;
		`,
	},
	h6: {
		productive: css`
			font-family: 'Staff';
			font-size: 14px;
			font-style: normal;
			font-weight: 600; /* SemiBold */
			letter-spacing: 0.14px;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
	},
	label: {
		label: css`
			font-family: 'Staff';
			font-size: 12px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 1%;
			line-height: 16px;
			text-decoration: none;
			text-transform: none;
		`,
		strong: css`
			font-family: 'Staff';
			font-size: 12px;
			font-style: normal;
			font-weight: 600; /* SemiBold */
			letter-spacing: 1%;
			line-height: 16px;
			text-decoration: none;
			text-transform: none;
		`,
		underline: css`
			font-family: 'Staff';
			font-size: 12px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 1%;
			line-height: 16px;
			text-decoration: underline;
			text-transform: none;
		`,
		italic: css`
			font-family: 'Staff';
			font-size: 12px;
			font-style: italic;
			font-weight: 400; /* Regular */
			letter-spacing: 1%;
			line-height: 16px;
			text-decoration: none;
			text-transform: none;
		`,
	},
	paragraph: {
		paragraph: css`
			font-family: 'Staff';
			font-size: 14px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 0;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
		strong: css`
			font-family: 'Staff';
			font-size: 14px;
			font-style: normal;
			font-weight: 600; /* SemiBold */
			letter-spacing: 1%;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
		underline: css`
			font-family: 'Staff';
			font-size: 14px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 1%;
			line-height: 20px;
			text-decoration: underline;
			text-transform: none;
		`,
		italic: css`
			font-family: 'Staff';
			font-size: 14px;
			font-style: italic;
			font-weight: 400; /* Regular */
			letter-spacing: 1%;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
		monospace: css`
			font-family: 'IBM Plex Mono';
			font-size: 14px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 1%;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
		monospaceStrong: css`
			font-family: 'IBM Plex Mono';
			font-size: 14px;
			font-style: normal;
			font-weight: 600; /* SemiBold */
			letter-spacing: 1%;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
		wide: css`
			font-family: 'Staff Wide';
			font-size: 14px;
			font-style: normal;
			font-weight: 400; /* Regular */
			letter-spacing: 0.16px;
			line-height: 20px;
			text-decoration: none;
			text-transform: none;
		`,
	},
} as const;

export default fonts;
