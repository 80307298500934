import React from 'react';
import { RadioGroup as AriaRadioGroup } from 'react-aria-components';
import styled from 'styled-components';

const StyledRadioGroup = styled(AriaRadioGroup)`
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

type RadioGroupProps = Omit<
	React.ComponentProps<typeof AriaRadioGroup>,
	'value'
> & {
	label: string;
	value: string | null;
};

export default function RadioGroup({ value, ...props }: RadioGroupProps) {
	// RadioGroupProps states that value is either a string or undefined. When using <RadioGroup /> as a controlled component,
	// use of undefined for some reason prevents the radio group from being focusable from the keyboard.
	// Passing null works :shrug:
	// Even their own docs use null: https://react-spectrum.adobe.com/react-aria/RadioGroup.html#controlled-value
	const typeSafeValue = value as string | undefined;
	return (
		<StyledRadioGroup
			{...props}
			aria-labelledby={props.label}
			value={typeSafeValue}
		/>
	);
}
