import React from 'react';
import styled from 'styled-components';

import Button from '../../../components/button';
import type { ResponseError } from '../../../utils/api';
import assertExhaustive from '../../../utils/assert-exhaustive';
import { useStateMachine } from '../../../utils/state-machine';
import type { IState } from '../../../utils/state-machine';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	button {
		margin: 5px;
	}
`;

const DeleteTextButton = styled(Button)`
	color: red;
	border: none;
	background-color: transparent;
	box-shadow: none;

	&:disabled {
		box-shadow: none;
		opacity: 0.5;
		background-color: transparent;
	}

	&:hover {
		box-shadow: none;
		background-color: transparent;
	}
`;

abstract class EmptyOnExit implements IState {
	onExit(): void {
		return;
	}
}

export class NormalState extends EmptyOnExit {
	onEdit(): EditingState {
		return new EditingState();
	}
}

export class EditingState extends EmptyOnExit {
	private readonly _deleteError: ResponseError | null;
	private readonly _saveError: ResponseError | null;

	constructor({
		deleteError,
		saveError,
	}: {
		deleteError?: ResponseError;
		saveError?: ResponseError;
	} = {}) {
		super();
		this._deleteError = deleteError ?? null;
		this._saveError = saveError ?? null;
	}

	get lastError(): {
		error: ResponseError;
		action: 'SAVE' | 'DELETE';
	} | null {
		if (!this._deleteError || !this._saveError) return null;

		return {
			action: this._deleteError ? 'DELETE' : 'SAVE',
			error: this._deleteError || this._saveError,
		};
	}

	onCancel(onCancel?: () => void): NormalState {
		if (onCancel) onCancel();
		return new NormalState();
	}

	onDelete(): DeleteConfirmationState {
		return new DeleteConfirmationState();
	}

	onSave(onSave: () => Promise<void>): SavingState {
		return new SavingState(onSave);
	}
}

export class DeleteConfirmationState extends EmptyOnExit {
	onCancel(): EditingState {
		return new EditingState();
	}

	onDelete(onDelete: () => Promise<void>): DeletingState {
		return new DeletingState(onDelete);
	}
}

export class DeletingState extends EmptyOnExit {
	private _onDelete: () => Promise<void>;

	constructor(onDelete: () => Promise<void>) {
		super();
		this._onDelete = onDelete;
	}

	async onEnter(): Promise<NormalState | EditingState> {
		try {
			await this._onDelete();
			return new NormalState();
		} catch (error) {
			return new EditingState({ deleteError: error as ResponseError });
		}
	}
}

export class SavingState extends EmptyOnExit {
	private _onSave: () => Promise<void>;

	constructor(onSave: () => Promise<void>) {
		super();
		this._onSave = onSave;
	}

	async onEnter(): Promise<NormalState | EditingState> {
		try {
			await this._onSave();
			return new NormalState();
		} catch (error) {
			return new EditingState({ saveError: error as ResponseError });
		}
	}
}

type State =
	| DeleteConfirmationState
	| DeletingState
	| EditingState
	| NormalState
	| SavingState;

export const useHeaderActions = (
	initialState: State = new NormalState(),
): [State, (state: State | Promise<State>) => void] =>
	useStateMachine<State>(initialState);

interface Props {
	cancelButtonLabel?: string;
	className?: string;
	deleteConfirmationMessage?: string;
	onCancel?: () => void;
	onDelete?: () => Promise<void>;
	onSave?: () => Promise<void>;
	state: State;
	transition: (newState: State | Promise<State>) => void;
	validForm?: boolean;
}

export function Buttons({
	className,
	deleteConfirmationMessage,
	onCancel,
	onDelete,
	onSave,
	state,
	transition,
	validForm = true,
	cancelButtonLabel = 'Cancel',
}: Props): JSX.Element | null {
	if (state instanceof DeletingState) {
		return (
			<Container className={className}>
				<Button danger type="button" disabled>
					Deleting...
				</Button>
				<Button neutral type="button" disabled>
					{cancelButtonLabel}
				</Button>
			</Container>
		);
	} else if (state instanceof DeleteConfirmationState) {
		return (
			<Container className={className}>
				{deleteConfirmationMessage}
				<Button
					danger
					type="button"
					onClick={(event) => {
						event.preventDefault();
						if (onDelete) {
							transition(state.onDelete(onDelete));
						}
					}}
				>
					Delete
				</Button>
				<Button
					neutral
					type="button"
					onClick={(event) => {
						event.preventDefault();
						transition(state.onCancel());
					}}
				>
					Cancel
				</Button>
			</Container>
		);
	} else if (state instanceof EditingState) {
		return (
			<Container className={className}>
				<Button
					neutral
					type="button"
					onClick={(event) => {
						event.preventDefault();
						transition(state.onCancel(onCancel));
					}}
				>
					{cancelButtonLabel}
				</Button>
				{onDelete && (
					<DeleteTextButton
						danger
						type="button"
						onClick={(event) => {
							event.preventDefault();
							transition(state.onDelete());
						}}
					>
						Delete
					</DeleteTextButton>
				)}
				{onSave && (
					<Button
						primary
						type="button"
						disabled={!validForm}
						onClick={(event) => {
							event.preventDefault();
							if (validForm) {
								transition(state.onSave(onSave));
							}
						}}
					>
						Save
					</Button>
				)}
			</Container>
		);
	} else if (state instanceof NormalState) {
		return null;
	} else if (state instanceof SavingState) {
		return (
			<Container className={className}>
				<Button neutral type="button" disabled>
					Cancel
				</Button>
				<Button primary type="button" disabled>
					Saving...
				</Button>
			</Container>
		);
	}

	assertExhaustive(state);
}
