import classnames from 'classnames';
import React, { useEffect, useState } from 'react';

import './generic-chart.scss';
import 'nvd3/build/nv.d3.min.css';
import SpinLoader from '../../../components/spin-loader';
import cancelablePromise from '../../../utils/cancelable-promise';
import noop from '../../../utils/noop';
import reportError from '../../../utils/sentry';

type Props<T> = {
	children: React.ElementType;
	className?: string;
	data?: Array<T> | null;
	fetchData: () => Promise<Array<T>>;
	loadingComponent?: React.ElementType;
	onDataFetch?: (data: Array<T>) => void;
};

export default function GenericChart<T>({
	children,
	className,
	fetchData,
	data = null,
	loadingComponent = SpinLoader,
	onDataFetch = noop,
	...props
}: Props<T>): JSX.Element {
	const [state, setState] = useState<{ data: Props<T>['data'] }>({ data });

	useEffect(() => {
		if (!state.data) {
			const promise = cancelablePromise(fetchData());

			promise
				.then((formattedData: Array<T>) => {
					setState({
						data: formattedData,
					});
					onDataFetch(formattedData);
				})
				.catch((error) => reportError(error));
			return () => {
				promise.cancel();
			};
		}
		return noop;
	}, [fetchData, state.data, onDataFetch]);

	const Chart = children;
	const Loader = loadingComponent;

	return (
		<div className={classnames('GenericChart', className)}>
			{!state.data ? <Loader /> : <Chart {...props} data={state.data} />}
		</div>
	);
}
