import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
	$createParagraphNode,
	$getRoot,
	CLEAR_EDITOR_COMMAND,
	COMMAND_PRIORITY_LOW,
} from 'lexical';
import { useCallback, useEffect } from 'react';

export default function useEditorClear() {
	const [editor] = useLexicalComposerContext();
	const clearEditor = useCallback(() => {
		editor.dispatchCommand(CLEAR_EDITOR_COMMAND, void 0);
	}, [editor]);

	useEffect(() => {
		return editor.registerCommand(
			CLEAR_EDITOR_COMMAND,
			() => {
				editor.update(() => {
					const root = $getRoot();
					const paragraph = $createParagraphNode();
					root.clear();
					root.append(paragraph);
					paragraph.select();
				});
				return true;
			},
			COMMAND_PRIORITY_LOW,
		);
	}, [editor]);

	return clearEditor;
}
