// @flow

import React from 'react';
import styled from 'styled-components';

import { backgroundGray, charcoalGray } from '../colors';
import Header from '../components/header';
import View from '../components/view';

const Container = styled.div`
	padding: 60px 20px;
	flex: 1;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: ${backgroundGray.string()};
`;

const RandomImage = styled.img`
	margin-top: 30px;
	border-radius: 12px;
	max-width: 100%;
	box-shadow: 0 2px 5px 0 ${charcoalGray.fade(0.65).string()};
`;

const images = [
	'https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/404/chris_sarah.jpg',
	'https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/404/nick_1.jpg',
	'https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/404/andy.jpg',
	'https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/404/robert.jpg',
	'https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/404/nick_2.jpg',
];
export const FourOhFourContent = () => (
	<Container>
		<h1>Feeling lost?</h1>
		<h3>Uh oh, the page could not be found.</h3>

		<RandomImage src={images[Math.floor(Math.random() * images.length)]} />
	</Container>
);

const FourOhFourPage = () => (
	<View>
		<Header />
		<FourOhFourContent />
	</View>
);
export default FourOhFourPage;
