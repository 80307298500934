import { LOGIN, LOGOUT } from './constants';
import type { User } from './types';

interface LoginAction {
	payload: string | User;
	type: typeof LOGIN;
}

export const login = (payload: string | User): LoginAction => ({
	type: LOGIN,
	payload,
});

interface LogoutAction {
	type: typeof LOGOUT;
}

export const logout = (): LogoutAction => ({
	type: LOGOUT,
});

export type Action = LoginAction | LogoutAction;
