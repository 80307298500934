import React from 'react';
import styled from 'styled-components';

import { PRIORITY_COLORS as DefaultColors } from './priority';
import { PipelineStageItem } from './types';

const PRIORITY_COLORS = {
	...DefaultColors,
	'': {
		backgroundColor: 'transparent',
		textColor: 'black',
	},
} as const;

type Data = PipelineStageItem['priority'];
interface PriorityCircleProps extends React.HTMLAttributes<HTMLDivElement> {
	readonly priority: Data;
}

const SizedCircle = styled.div`
	height: 12px;
	width: 12px;
	border-radius: 50%;
`;

const PriorityCircle: React.FC<PriorityCircleProps> = ({
	priority,
	...props
}) => {
	const circleStyle = {
		backgroundColor: PRIORITY_COLORS[priority].backgroundColor,
		border: `1.5px solid ${
			priority === ''
				? 'transparent'
				: PRIORITY_COLORS[priority].textColor
		}`,
	};

	return <SizedCircle {...props} style={circleStyle} />;
};

export default PriorityCircle;
