// @flow

import numeral from 'numeral';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import DiscreteBarChart from '../charts/discrete-bar-chart';
import GenericChart from '../charts/generic-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl =
	'/stats/semiannuals/portfolio_revenue_times_ownership_by_company?fund=2';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: '2021 Revenue (Projected) Multiplied by Drive Ownership',
		values: dataArr.map((company) => ({
			label: company.company,
			value: company.revenue_times_ownership,
		})),
	},
];

export default class SemiAnnualFund2PortfolioCurrentRevenueTimesOwnershipByCompany extends React.Component<{||}> {
	static getDetailCard() {
		return SemiAnnualFund2PortfolioRevenueTimesOwnershipByCompanyDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData: Object) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 75 }}
				onDataFetch={this.handleDataFetch}
				title="2021 Revenue (Projected) Multiplied by Total Current OS %"
				xAxisLabel="Company"
				yAxisLabel="Revenue times Ownership"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund2PortfolioRevenueTimesOwnershipByCompanyDetailCard extends React.Component<{||}> {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/portfolio_revenue_times_ownership.png"
				statName="Fund II 2021 Portfolio Revenue (Projected) Times Ownership by Company"
			/>
		);
	}
}
