// @flow

import { Set as ImmutableSet } from 'immutable';
import React, {
	type Key as ReactKey,
	type Node as ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import './table.scss';
import { trackEvent, type ViewType } from '../utils/analytics';
import { stringifyAsDataURI } from '../utils/csv';
import download from '../utils/download';
import useStableRef from '../utils/hooks/use-stable-ref';

import { type AddPickerResult, IColumn, isStickyLeft } from './column';
import SelectionColumn from './columns/multi-select';
import Footers from './footers';
import Headers from './headers';
import Rows from './rows';
import { type Sort, type SortDirection } from './sort';
import { stickiness } from './stickiness';
import useObserveVisibility from './use-observe-visibility';

type GenericRow = {
	+id: number,
};

export function getGenericRowKey(row: GenericRow) {
	return row.id;
}

export function searchData<T>(
	columns: Array<IColumn<T>>,
	search: string,
	rows: Array<T>,
): Array<T> {
	if (!search) return rows;

	return rows.filter((row) =>
		columns.some(
			(column) =>
				// $FlowFixMe: You're a troll if accessing `column.search` mutates.
				column.search && column.search(search.toLowerCase(), row),
		),
	);
}

function sortData<T>(
	columns: Array<IColumn<T>>,
	sort: Sort | null,
	rows: Array<T>,
): Array<T> {
	if (!sort) return rows;

	const { direction, index } = sort;
	const column = columns[index];
	if (!column) {
		// Index is out of range, do not try to sort
		return rows;
	}
	const sorter = column.sort;
	if (!sorter) return rows;
	return rows.slice().sort((a, b) => sorter.call(column, direction, a, b));
}

class StickyPropsCache {
	offsets: Array<number | null>;
	initialOffset: number;

	// column -> columnIndex -> className -> key -> props
	cache = new WeakMap<
		IColumn<any>,
		Map<number, Map<string, Map<ReactKey | null, {}>>>,
	>();

	constructor(columns: Array<IColumn<any>>, initialOffset: number = 0) {
		this.offsets = new Array(columns.length).fill(null);
		this.initialOffset = initialOffset;
	}

	getOffset(columns: Array<IColumn<any>>, columnIndex: number): number {
		if (columnIndex < 1) return this.initialOffset;

		let offset = this.offsets[columnIndex];
		if (offset == null) {
			offset = this.getOffset(columns, columnIndex - 1);
			const previousColumn = columns[columnIndex - 1];
			if (
				previousColumn.position != null
				&& previousColumn.position.sticky === stickiness.left
			) {
				// TODO: This calculation doesn't currently handle the border.
				// For a 40px-wide column with a 1px border-right, the sticky
				// columns to its right will slide over 1px when scrolling the
				// table horizontally. Unfortunately, in Firefox and Chrome,
				// only the column is sticky, not its border, so if we leave an
				// additional 1px for the border, when scrolling the table
				// horizontally, there'll be a 1px gap where the border was
				// through which the scrolled content appears from underneath.
				// Arguably this calculation shouldn't account for the border
				// itself as the border is a styling attribute on the column, so
				// it's the columns that are reporting a width 1px too narrow,
				// but this was the most natural place to leave this comment.
				// I'm leaving this as-is for now since it's already behaving
				// this way in production, but it would be nice to find a
				// solution for the scrolled content peeking through so that the
				// columns can report the correct width. Until then, shifting
				// over 1px when scrolling the rest of the table looks less bad.
				offset += previousColumn.position.width;
			}
			this.offsets[columnIndex] = offset;
		}
		return offset;
	}

	get(
		columns: Array<IColumn<any>>,
		columnIndex: number,
		className: string,
		key?: ReactKey | null = null,
	): {} {
		const column = columns[columnIndex];

		let cachedColumn = this.cache.get(column);
		if (!cachedColumn) {
			cachedColumn = new Map();
			this.cache.set(column, cachedColumn);
		}
		let cachedIndex = cachedColumn.get(columnIndex);
		if (!cachedIndex) {
			cachedIndex = new Map();
			cachedColumn.set(columnIndex, cachedIndex);
		}
		let cachedClassName = cachedIndex.get(className);
		if (!cachedClassName) {
			cachedClassName = new Map();
			cachedIndex.set(className, cachedClassName);
		}
		let cachedKey = cachedClassName.get(key);
		if (!cachedKey) {
			cachedKey = isStickyLeft(column)
				? {
						className: `${className} ${className}--sticky-left`,
						key,
						style: {
							left: this.getOffset(columns, columnIndex),
						},
				  }
				: {
						className,
						key,
				  };
			cachedClassName.set(key, cachedKey);
		}
		return cachedKey;
	}
}

type BaseProps<T: {}> = {
	canChangeRow?: (row: T) => boolean,
	className?: string,
	columns: Array<IColumn<T>>,
	data: Array<T>,
	focusedIndex?: number | null,
	footers?: Array<string>,
	getRowKey: (row: T) => ReactKey,
	initialScrollX?: ?number,
	initialScrollY?: ?number,
	mixpanelTracking?: {
		componentIdentifier: string,
		viewType: ViewType,
	},
	onAdd?: (value: AddPickerResult) => void,
	onChange?: (row: T, patch: $Shape<T>, signal: AbortSignal) => Promise<T>,
	onFocus?: (index: number | null) => void,
	onRowKeyDown?: (event: KeyboardEvent, row: T) => void,
	onScroll?: (
		event: SyntheticEvent<HTMLDivElement>,
		componentIdentifier?: string,
		viewType?: ViewType,
	) => void,
	onSelect?: (
		selection: { rowKey: ReactKey, selected: boolean } | 'ALL' | 'NONE',
	) => void,
	onSort?: (index: number, direction: SortDirection) => void,
	search?: string,
	selectedRowKeys?: ImmutableSet<ReactKey>,
};

type ControlledSortProps = {
	initialSort?: void,
	sort: Array<Sort>,
};
type UncontrolledSortProps = {
	initialSort?: Sort | null,
	sort?: void,
};

export type Props<T: {}> =
	| (BaseProps<T> & UncontrolledSortProps)
	| (BaseProps<T> & ControlledSortProps);

export function downloadTable<T: {}>(
	columns: Array<IColumn<T>>,
	data: Array<T>,
	name: string,
	search: string,
	sort: Sort | null,
	mixpanelTracking?: {
		componentIdentifier: string,
		viewType: ViewType,
	},
) {
	if (mixpanelTracking) {
		trackEvent(
			'Download Table',
			mixpanelTracking.componentIdentifier,
			mixpanelTracking.viewType,
		);
	}
	const searched = searchData(columns, search, data);
	const sorted = sortData(columns, sort, searched);

	const csvColumns = columns.filter(
		(column) => typeof column.toCSV === 'function',
	);
	const headers = csvColumns.map((column) =>
		typeof column.name === 'string' ? column.name : '',
	);
	const rows = sorted.map((row) =>
		csvColumns.map((column) => column.toCSV && column.toCSV(row)),
	);
	download(stringifyAsDataURI([headers, ...rows]), `${name}.csv`);
}

/**
 * Heuristically detect logical changes between column arrays.
 *
 * For now, we only detect added or removed columns. If we ever allow
 * re-ordering columns, we'd require each column to have a unique `key` like
 * React element arrays. We'd use that key here and apply it to each cell in the
 * `<Row>`.
 */
function isAcceptableColumnsChange<T>(
	previousColumns: Array<IColumn<T>>,
	currentColumns: Array<IColumn<T>>,
): boolean {
	return previousColumns.length !== currentColumns.length;
}

const intersectionObserverOptions = { rootMargin: '400px 0px 400px 0px' };

export default function Table<T: {}>({
	canChangeRow,
	className = '',
	columns,
	data,
	focusedIndex,
	footers,
	getRowKey,
	initialScrollX,
	initialScrollY,
	initialSort = null,
	mixpanelTracking,
	onAdd,
	onChange,
	onFocus,
	onRowKeyDown,
	onScroll: onScrollProp,
	onSelect,
	// We want to intercept sort handling, so rename this to avoid a name
	// collision.
	onSort: onSortProp,
	search = '',
	selectedRowKeys = new ImmutableSet(),
	sort: sortProp = void 0,
	...props
}: Props<T>): ReactNode {
	useStableRef(columns, 'columns', {
		isAcceptableChange: isAcceptableColumnsChange,
	});
	useStableRef(getRowKey, 'getRowKey()');
	useStableRef(mixpanelTracking, 'mixpanelTracking');

	const sortIsControlled = typeof sortProp !== 'undefined';

	const [internalSort, setInternalSort] = useState<Array<Sort>>(
		initialSort ? [initialSort] : [],
	);
	const sort = sortIsControlled ? sortProp : internalSort;

	const [observationRootRefCallback, observeVisibility] =
		useObserveVisibility(intersectionObserverOptions);
	// We want to keep our own ref to the scroll container so we can persist
	// scroll position to history.
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);
	const scrollContainerRefCallback = useCallback(
		(scrollContainer: HTMLDivElement | null): void => {
			scrollContainerRef.current = scrollContainer;
			observationRootRefCallback(scrollContainer);
		},
		[scrollContainerRef, observationRootRefCallback],
	);
	// Prevent updates to initial scroll values from re-triggering the scroll
	// effect by storing them in never-updated state.
	const [staticInitialScrollX] = useState(initialScrollX);
	const [staticInitialScrollY] = useState(initialScrollY);
	useEffect(() => {
		const scrollContainer = scrollContainerRef.current;
		if (scrollContainer) {
			if (staticInitialScrollX)
				scrollContainer.scrollLeft = staticInitialScrollX;
			if (staticInitialScrollY)
				scrollContainer.scrollTop = staticInitialScrollY;
		}
	}, [scrollContainerRef, staticInitialScrollX, staticInitialScrollY]);

	const onSort = useCallback(
		(index: number, direction: SortDirection): void => {
			setInternalSort([{ direction, index }]);
			if (mixpanelTracking) {
				trackEvent(
					'Sort Table',
					mixpanelTracking.componentIdentifier,
					mixpanelTracking.viewType,
					{
						index,
						direction,
					},
				);
			}

			// Storing the sort value in state and (optionally) passing it to
			// props means this component is a sort of hybrid between controlled
			// and uncontrolled sorting. As long as the implementations remain
			// consistent, that's fine.
			//
			// Doing it this way allows us to have some cases where we choose to
			// sync sorting to the URL and other instances where sorting is
			// ephemeral.
			if (onSortProp) onSortProp(index, direction);
		},
		[onSortProp, mixpanelTracking],
	);

	const onScroll = useCallback(
		(event: SyntheticEvent<HTMLDivElement>) => {
			if (onScrollProp) {
				if (mixpanelTracking) {
					onScrollProp(
						event,
						mixpanelTracking.componentIdentifier,
						mixpanelTracking.viewType,
					);
				} else {
					onScrollProp(event);
				}
			}
		},
		[onScrollProp, mixpanelTracking],
	);

	const rows = useMemo(() => {
		const searched = searchData(columns, search, data);
		if (!sortIsControlled) {
			const sorted = sortData(columns, sort[0] || null, searched);
			return sorted;
		} else {
			return searched;
		}
	}, [columns, sortIsControlled, search, sort, data]);

	const stickyPropsCache = useMemo(
		() => new StickyPropsCache(columns, 0),
		[columns],
	);
	const getStickyProps = useCallback(
		(
			column: IColumn<T>,
			columnIndex: number,
			extraClassName: string,
			key?: ReactKey,
		): {} =>
			stickyPropsCache.get(columns, columnIndex, extraClassName, key),
		[columns, stickyPropsCache],
	);
	const handleRowSelection = useCallback(
		(selection: { rowKey: ReactKey, selected: boolean }) => {
			if (onSelect) {
				onSelect(selection);
			}
		},
		[onSelect],
	);
	const handleHeaderSelection = useCallback(
		(selection: 'ALL' | 'NONE') => {
			if (onSelect) {
				onSelect(selection);
			}
		},
		[onSelect],
	);
	const handleRowBlur = useCallback(
		(event: SyntheticFocusEvent<HTMLInputElement>) => {
			if (!onFocus) return;
			if (!event.relatedTarget) return;
			onFocus(null);
		},
		[onFocus],
	);

	let itemsSelected = 'NONE';
	if (selectedRowKeys.size === 0) {
		itemsSelected = 'NONE';
	} else if (selectedRowKeys.size === data.length) {
		itemsSelected = 'ALL';
	} else if (selectedRowKeys.size > 0) {
		itemsSelected = 'SOME';
	}

	const hasSelectionColumn = columns.some(
		(column) => column instanceof SelectionColumn,
	);

	if (onFocus && !hasSelectionColumn) {
		throw new Error('SelectionColumn is required when using onFocus.');
	}

	return (
		<React.StrictMode>
			<div
				{...props}
				className={`HerbieTable__scroll-container ${className}`}
				onScroll={onScroll}
				ref={scrollContainerRefCallback}
			>
				<table cellPadding="0" className="HerbieTable__table">
					<Headers
						columns={columns}
						getStickyProps={getStickyProps}
						itemsSelected={itemsSelected}
						onAdd={onAdd}
						onSelect={onSelect && handleHeaderSelection}
						onSort={onSort}
						sort={sort}
					/>
					<Rows
						canChangeRow={canChangeRow}
						columns={columns}
						focusedIndex={focusedIndex}
						getRowKey={getRowKey}
						getStickyProps={getStickyProps}
						observeVisibility={observeVisibility}
						onBlur={handleRowBlur}
						onChange={onChange}
						onFocus={onFocus}
						onRowKeyDown={onRowKeyDown}
						onSelect={onSelect && handleRowSelection}
						rows={rows}
						selectedRowKeys={selectedRowKeys}
					/>
					<Footers
						columns={columns}
						footers={footers}
						getStickyProps={getStickyProps}
						rows={rows}
					/>
				</table>
			</div>
		</React.StrictMode>
	);
}
