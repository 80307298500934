import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import ImmutableURLSearchParams from '../utils/immutable-url-search-params';

import { sortDirections } from './sort';
import type { Sort, SortDirection } from './sort';

const defaultParameterName = 'sort';

function parseSortDirectionIndicator(
	indicator: string | null,
): SortDirection | null {
	switch (indicator) {
		case '+':
			return sortDirections.ascending;
		case '-':
			return sortDirections.descending;
		default:
			return null;
	}
}

function parseParameterValue(value: string | null): Sort | null {
	if (!value || value.length < 2) return null;
	const direction = parseSortDirectionIndicator(value[0]);
	const index = parseInt(value.slice(1), 10);

	if (direction != null && index >= 0) {
		return { direction, index };
	}

	return null;
}

export function useSortHistory(
	initialSort: Sort | null = null,
	parameterName: string = defaultParameterName,
): [Sort | null, (index: number, direction: SortDirection) => void] {
	const [params, setSearchParams] = useSearchParams();
	const queryParam = params.get(parameterName);

	// Prevent updates to the initial sort from triggering unnecessary re-
	// calculations by storing it in never-updated state.
	const [staticInitialSort] = useState(initialSort);
	const sort = useMemo(
		() => parseParameterValue(queryParam) || staticInitialSort,
		[queryParam, staticInitialSort],
	);

	const setSort = useCallback(
		(index: number, direction: SortDirection) => {
			const directionIndicator =
				direction === sortDirections.ascending ? '+' : '-';

			setSearchParams(
				(currentParams) =>
					new URLSearchParams(
						new ImmutableURLSearchParams(currentParams.toString())
							.set(parameterName, `${directionIndicator}${index}`)
							.toString(),
					),
				{ replace: true },
			);
		},
		[parameterName, setSearchParams],
	);

	return [sort, setSort];
}
