import React from 'react';
import {
	TooltipTrigger,
	Button as UnstyledButton,
	Tooltip as UnstyledTooltip,
} from 'react-aria-components';
import styled, { keyframes } from 'styled-components';

const Button = styled(UnstyledButton)`
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
`;

const slide = keyframes`
  from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const UnstyledTooltipContent = styled(UnstyledTooltip)`
	transform: translate3d(0, 0, 0);

	&[data-placement='top'] {
		margin-bottom: 8px;
		--origin: translateY(4px);
	}

	&[data-placement='bottom'] {
		margin-top: 8px;
		--origin: translateY(-4px);
		& .react-aria-OverlayArrow svg {
			transform: rotate(180deg);
		}
	}

	&[data-placement='right'] {
		margin-left: 8px;
		--origin: translateX(-4px);
		& .react-aria-OverlayArrow svg {
			transform: rotate(90deg);
		}
	}

	&[data-placement='left'] {
		margin-right: 8px;
		--origin: translateX(4px);
		& .react-aria-OverlayArrow svg {
			transform: rotate(-90deg);
		}
	}

	&[data-entering] {
		animation: ${slide} 200ms;
	}

	&[data-exiting] {
		animation: ${slide} 200ms reverse ease-in;
	}
`;

type Props = React.ComponentProps<typeof TooltipTrigger> & {
	children: React.ReactNode;
	className?: string;
	content: React.ReactNode;
};

export default function Tooltip({
	children,
	className,
	content,
	delay = 100,
	closeDelay = 100,
	...rest
}: Props) {
	return (
		<TooltipTrigger {...rest} delay={delay} closeDelay={closeDelay}>
			<Button className={className}>{children}</Button>
			{content}
		</TooltipTrigger>
	);
}
