import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';

const Text = styled.span`
	display: block;
	padding: 5px 0px;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: #aaa;
	text-align: right;
`;
const GreenText = styled(Text)`
	color: #5db6a3;
`;
const RedText = styled(Text)`
	color: red;
`;

const ChangeCell = styled.td`
	width: 10ch;
	padding: 5px 10px;
`;

type Data = number | null;

export default class ChangeColumn<T> implements IColumn<T> {
	private decimal: number;
	name: string;
	private select: (row: T) => Data;

	constructor({
		decimal,
		name,
		select,
	}: {
		decimal: number;
		name: string;
		select: (row: T) => Data;
	}) {
		this.decimal = decimal;
		this.name = name;
		this.select = select;

		this.cell.displayName = 'TextColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ props, row }: CellArgs<T>) => {
		const value = this.select(row);
		let display_text = '';
		let display: JSX.Element | string = display_text;
		if (value !== null) {
			if (value !== 0) {
				display_text = `${value.toFixed(this.decimal)}%`;
			}
			if (value > 0) {
				display = <GreenText>{`↑${display_text}`}</GreenText>;
			} else if (value < 0) {
				display = <RedText>{`↓${display_text}`}</RedText>;
			} else {
				display = <Text>0</Text>;
			}
		}
		return <ChangeCell {...props}>{display}</ChangeCell>;
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				initialSortDirection={sortDirections.ascending}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				{this.name}
			</SortableHeader>
		);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aVal: Data = this.select(a);
		const bVal: Data = this.select(b);

		if (aVal === bVal) return 0;
		if (aVal === null) return 1;
		if (bVal === null) return -1;
		return direction === sortDirections.ascending
			? aVal - bVal
			: bVal - aVal;
	}

	toCSV(row: T): string {
		const value = this.select(row);
		let display_text = '';
		if (value !== null) {
			if (value !== 0) {
				display_text = `${value.toFixed(this.decimal)}%`;
			}
			if (value > 0) {
				return `↑${display_text}`;
			} else if (value < 0) {
				return `↓${display_text}`;
			}
			return '0';
		} else {
			return '';
		}
	}
}
