export function difference<T>(a: Set<T>, b: Set<T>): Set<T> {
	return new Set([...a].filter((x) => !b.has(x)));
}

export function equals<T>(a: Set<T>, b: Set<T>): boolean {
	if (a.size !== b.size) {
		return false;
	}

	for (const value of a) {
		if (!b.has(value)) {
			return false;
		}
	}

	return true;
}

export function intersection<T>(first: Set<T>, ...rest: Array<Set<T>>): Set<T> {
	if (rest.length === 0) {
		return new Set(first);
	}

	const [second, ...remainder] = rest;
	return new Set(
		[...intersection(second, ...remainder)].filter((x) => first.has(x)),
	);
}

export function union<T>(...sets: Array<Set<T>>): Set<T> {
	return new Set(
		(function* () {
			for (let i = 0; i < sets.length; i++) {
				yield* sets[i];
			}
		})(),
	);
}
