// @flow

import React from 'react';

import { merge } from '../../actions';
import Authorized from '../../components/Authorized';
import type { SelectedSet } from '../view';

import AdminButton from './admin-button';

type Props = {
	selected: SelectedSet,
};

export default class MergeButton extends React.Component<Props> {
	handleMerge = () => {
		const { companies, people } = this.props.selected;
		const duplicates = companies
			.map((id) => ({
				type: 'companies',
				id,
			}))
			.concat(
				people.map((id) => ({
					type: 'people',
					id,
				})),
			);

		merge(duplicates, 'Advanced Search');
	};

	render() {
		const {
			selected: { companies, people },
		} = this.props;

		return (
			<Authorized auth="contacts.merge_profiles">
				<AdminButton
					enabled={
						(companies.length >= 2 && people.length === 0)
						|| (companies.length === 0 && people.length >= 2)
					}
					onClick={this.handleMerge}
				>
					Merge
				</AdminButton>
			</Authorized>
		);
	}
}
