import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SentenceList extends Component {
	static propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element),
		]),
		conjunction: PropTypes.string.isRequired,
		punctuator: PropTypes.string.isRequired,
		separator: PropTypes.string.isRequired,
	};

	static defaultProps = {
		punctuator: '',
		separator: ',',
	};

	render() {
		const children = React.Children.toArray(this.props.children);
		const { punctuator } = this.props;

		if (children.length === 1) {
			return (
				<span className="SentenceList">
					<span className="SentenceList-element">
						{React.cloneElement(children[0], { punctuator })}
					</span>
				</span>
			);
		} else if (children.length === 2) {
			return (
				<span className="SentenceList">
					<span className="SentenceList-element">{children[0]}</span>
					{` ${this.props.conjunction} `}
					<span className="SentenceList-element">
						{React.cloneElement(children[1], { punctuator })}
					</span>
				</span>
			);
		} else {
			const list = [];

			children.slice(0, -1).forEach((child, i) => {
				list.push(
					<span className="SentenceList-element" key={i}>
						{React.cloneElement(child, {
							punctuator: this.props.separator,
						})}
					</span>,
				);
				list.push(' ');
			});

			return (
				<span className="SentenceList">
					{list}
					{` ${this.props.conjunction} `}
					<span className="SentenceList-element">
						{React.cloneElement(children[children.length - 1], {
							punctuator,
						})}
					</span>
				</span>
			);
		}
	}
}
