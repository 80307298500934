import numeral from 'numeral';
import React from 'react';

import * as api from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import GenericMap from './charts/generic-map';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/funding/locations/';
let map = null;

export default class FundingMap extends React.Component {
	static getDetailCard() {
		return FundingMapDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(this.formatData);
	};

	formatData = ({ data }) => {
		// Fills is a mapping of color_code -> fillKey from the api to match the old stats page
		const fills = {
			high: '#D62728',
			medium_high: '#FF7F0E',
			medium: '#FFBB78',
			medium_low: '#51A644',
			low: '#2777B4',
		};

		return {
			scope: 'usa',
			geographyConfig: {
				popupOnHover: false,
				highlightOnHover: false,
			},
			fills: {
				...fills,
				defaultFill: '#d7d7d7',
			},
			bubbles: data.map((apiData) => ({
				...apiData,
				radius: apiData.bubble_size,
				fillKey: apiData.color_code,
				popup: `<div class="hoverinfo"><div>${apiData.name} - ${numeral(
					apiData.total_funding,
				)
					.format('$0,0 a')
					.toUpperCase()}</div></div>`,
			})),
			bubbleOptions: {
				popupTemplate: (geography, geoData) => geoData.popup,
			},
		};
	};

	handleDataFetch = (formattedData) => {
		map = formattedData;
	};

	render() {
		return (
			<GenericMap
				loadingComponent={HerbieLoader}
				map={map}
				fetchData={this.fetchData}
				onDataFetch={this.handleDataFetch}
			/>
		);
	}
}

class FundingMapDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/funded_heatmap.png"
				statName="Investment Heat Map"
			/>
		);
	}
}
