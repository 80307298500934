import delay from '../delay';

import type { IState } from './state';

import { useStateMachine } from '.';
import type { Transition } from '.';

export class NormalState<T> implements IState {
	onExit(): void {
		return;
	}
	onEdit(initialValue: T): EditingState<T> {
		return new EditingState<T>(initialValue);
	}
}

export class EditingState<T> implements IState {
	readonly value: T;

	constructor(value: T) {
		this.value = value;
	}

	onExit(): void {
		return;
	}

	onCancel(): NormalState<T> {
		return new NormalState<T>();
	}

	onChange(value: T): EditingState<T> {
		return new EditingState<T>(value);
	}

	onSubmit(
		submit: (value: T, signal: AbortSignal) => Promise<T>,
	): SavingState<T> {
		return new SavingState<T>(this.value, submit);
	}
}

export class SavingState<T> implements IState {
	private abortController: AbortController = new AbortController();
	private submit: (value: T, signal: AbortSignal) => Promise<T>;
	readonly value: T;

	constructor(
		value: T,
		submit: (value: T, signal: AbortSignal) => Promise<T>,
	) {
		this.value = value;
		this.submit = submit;
	}

	async onEnter(): Promise<SuccessState<T> | ErrorState> {
		try {
			const result = await this.submit(
				this.value,
				this.abortController.signal,
			);

			if (this.abortController.signal.aborted) {
				const abortError = new Error('The operation was aborted.');
				abortError.name = 'AbortError';
				throw abortError;
			}

			return new SuccessState<T>(result);
		} catch (e) {
			const error: Error = e as Error;
			if (error.name === 'AbortError') {
				throw error;
			} else {
				return new ErrorState(error);
			}
		}
	}

	onExit(): void {
		this.abortController.abort();
	}
}

export class SuccessState<T> implements IState {
	private abortController: AbortController = new AbortController();
	readonly value: T;

	constructor(value: T) {
		this.value = value;
	}

	async onEnter(): Promise<NormalState<T>> {
		await delay(1000, this.abortController.signal);
		return new NormalState<T>();
	}

	onExit(): void {
		this.abortController.abort();
	}
}

export class ErrorState implements IState {
	readonly error: Error;

	constructor(error: Error) {
		this.error = error;
	}

	onExit(): void {
		return;
	}
}

export type State<T> =
	| NormalState<T>
	| EditingState<T>
	| SavingState<T>
	| SuccessState<T>
	| ErrorState;

/**
 * This is a pre-built state machine for fields that have an intermediate
 * editing state. It respects external updates when being viewed while avoiding
 * changing the value out from under the user during interactions.
 */
export default function useEditableField<T>(): [
	State<T>,
	Transition<State<T>>,
] {
	return useStateMachine<State<T>>(new NormalState<T>());
}
