import React from 'react';
import { DragLayerMonitor, useDragLayer } from 'react-dnd';
import styled, { keyframes } from 'styled-components';
import colors from 'tailwindcss/colors';

import { ProfileCardDragItemType } from './constants';
import { CardBody } from './profile-card';
import type { PipelineStageItem } from './types';

const Container = styled.div`
	position: fixed;
	pointer-events: none;
	z-index: 100;
	left: 0;
	top: 0;
`;

const pickup = keyframes`
	from {
		transform: rotate(0) scale(1);
	}

	to {
		transform: rotate(2.5deg) scale(1.02);
	}
`;

const DraggingCardBody = styled(CardBody)`
	animation: ${pickup} 0.1s forwards linear;
	box-shadow: 1px 2px 8px 0px ${colors.slate['900']}30;
	transform-origin: center;
`;

export default function ProfileCardDragLayer() {
	const { currentOffset, isDragging, item } = useDragLayer<
		{
			currentOffset: ReturnType<
				DragLayerMonitor['getSourceClientOffset']
			>;
			isDragging: boolean;
			item: PipelineStageItem;
			itemType: ReturnType<DragLayerMonitor['getItemType']>;
		},
		PipelineStageItem
	>((monitor) => {
		const itemType = monitor.getItemType();
		return {
			currentOffset: monitor.getSourceClientOffset(),
			isDragging:
				monitor.isDragging() && itemType === ProfileCardDragItemType,
			item: monitor.getItem(),
			itemType,
		};
	});

	if (!isDragging || !currentOffset) return null;

	return (
		<Container>
			<div
				style={{
					transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
				}}
			>
				<DraggingCardBody
					photoUrl={item.photoUrl}
					profileId={item.profileId}
					subTitle={item.subTitle}
					title={item.title}
					priority={item.priority}
				/>
			</div>
		</Container>
	);
}
