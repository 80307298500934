/**
 * @file A horizontal binary toggle component.
 *
 * [Figma reference][1].
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2285%3A22181&mode=dev
 */
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors } from '..';

const Container = styled.label`
	align-items: center;
	cursor: pointer;
	display: inline-grid;
	gap: 4px;
	grid-template:
		'toggle label' auto
		/ auto auto;
	position: relative;
`;

const Input = styled.input`
	position: absolute;
	opacity: 0;
`;

const NUBBIN_DIAMETER_PX = 12;
const SLIDER_PADDING_PX = 2;

const Slider = styled.span`
	background: ${colors.toggle.inactive};
	border-radius: ${NUBBIN_DIAMETER_PX / 2 + SLIDER_PADDING_PX}px;
	display: inline-block;
	height: ${NUBBIN_DIAMETER_PX + SLIDER_PADDING_PX * 2}px;
	padding: ${SLIDER_PADDING_PX}px;
	position: relative;
	transition: background 0.1s ease-in-out;
	vertical-align: middle;
	width: ${(NUBBIN_DIAMETER_PX + SLIDER_PADDING_PX) * 2}px;

	&::before {
		background: ${colors.icon.onColor};
		border-radius: 50%;
		content: '';
		display: block;
		height: ${NUBBIN_DIAMETER_PX}px;
		transition: margin-left 0.1s ease-in-out;
		width: ${NUBBIN_DIAMETER_PX}px;
	}

	${Input}:checked + & {
		background: ${colors.toggle.active};

		&::before {
			margin-left: ${NUBBIN_DIAMETER_PX}px;
		}
	}
`;

const Label = styled.span`
	color: ${colors.text.primary};
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	grid-area: label;
	letter-spacing: 0.14px;
	line-height: 20px;
`;

/**
 * When the toggle is right-aligned, we need to ensure that we leave room for
 * the longest possible label so the toggle doesn't jump around when the label
 * changes. We do this by hiding both possible labels behind the label.
 */
const Spacer = styled(Label)`
	opacity: 0;
`;

interface Props {
	checked: boolean;
	checkedLabel: string;
	className?: string;
	onChange: (checked: boolean) => void;
	uncheckedLabel: string;
}

export default function Toggle({
	checked,
	className,
	checkedLabel,
	uncheckedLabel,
	onChange,
}: Props) {
	const onChangeCallback = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
		[onChange],
	);

	return (
		<Container className={className}>
			<Input
				checked={checked}
				onChange={onChangeCallback}
				type="checkbox"
			/>
			<Slider />
			<Spacer>{checkedLabel}</Spacer>
			<Spacer>{uncheckedLabel}</Spacer>
			<Label>{checked ? checkedLabel : uncheckedLabel}</Label>
		</Container>
	);
}
