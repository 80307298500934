import 'rxjs/add/observable/fromPromise';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';
import styled from 'styled-components';

import '../components/ChangePasswordForm.scss';
import SpinLoader from '../components/spin-loader';
import { trackEvent } from '../utils/analytics';
import { post } from '../utils/api';

import ConfirmForm from './confirmForm';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

interface Props {
	token?: string;
	username?: string;
}

interface State {
	loading: boolean;
	validToken: boolean;
}

class Confirm extends React.Component<Props, State> {
	private subscription: null | Subscription = null;

	state: State = {
		loading: true,
		validToken: false,
	};

	componentDidMount(): void {
		const { username, token } = this.props;
		// This tracks: anytime a user follows the password reset link in their
		// email & lands on the Set & Confirm New Password page
		trackEvent('Visit New Page', 'password-confirmation-view', 'accounts');

		this.subscription = Observable.fromPromise(
			post('/auth/password/validate', { body: { username, token } }),
		).subscribe(
			() => {
				this.setState({ validToken: true, loading: false });
			},
			() => {
				this.setState({ validToken: false, loading: false });
			},
		);
	}

	componentWillUnmount(): void {
		if (this.subscription) this.subscription.unsubscribe();
	}

	render(): JSX.Element {
		const { loading, validToken } = this.state;

		// This makes typescript happy knowing FOR SURE that username and
		// token exists when passing them to our form.
		// It also saves us a server request when we know this token/username
		// combo is invalid
		if (!this.props.username || !this.props.token) {
			return (
				<Container>
					<p>This token is invalid.</p>
				</Container>
			);
		}

		if (loading) {
			return (
				<Container>
					<SpinLoader />
				</Container>
			);
		}

		if (!validToken) {
			return (
				<Container>
					<p>This token is invalid.</p>
				</Container>
			);
		}

		return (
			<ConfirmForm
				buttonText="Change password"
				eventName="Update Account Information"
				username={this.props.username}
				token={this.props.token}
				viewType="accounts"
			/>
		);
	}
}

export default function HOCConfirm() {
	const params = useParams<'username' | 'token'>();

	return <Confirm token={params.token} username={params.username} />;
}
