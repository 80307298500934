import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import UnstyledEditor from '../components/text-editor';
import { type ViewType } from '../utils/analytics';

import {
	Container,
	EditorContainer,
	ExpandButton,
	Header,
	HeaderCommenterLogo,
	HeaderDate,
	HeaderLeft,
} from './shared-components';
import { NoteData } from './types';
import { formatAddedAt } from './utils';

const Body = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Editor = styled(UnstyledEditor)`
	max-height: none;
`;
function DefaultHeader({ note }: { note: NoteData }) {
	return (
		<Header>
			<HeaderLeft>
				<HeaderCommenterLogo src={note.commenter.photo_url} />
				{note.commenter.first_name} {note.commenter.last_name}
			</HeaderLeft>
			<HeaderDate>{formatAddedAt(note)}</HeaderDate>
		</Header>
	);
}

interface Props {
	analyticsComponentIdentifier: string;
	analyticsViewType: ViewType;
	contentHeight?: number;
	HeaderComponent?: React.ComponentType<{
		note: NoteData;
	}>;
	htmlId?: string;
	namespace: string;
	note: NoteData;
	onCollapse: (noteId: number) => void;
}

export default React.forwardRef(function ReadOnlyNote(
	{
		analyticsComponentIdentifier,
		analyticsViewType,
		contentHeight = 100,
		HeaderComponent = DefaultHeader,
		htmlId,
		namespace,
		note,
		onCollapse,
	}: Props,
	ref: React.Ref<HTMLDivElement>,
) {
	const [expandable, setExpandable] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const editorRef = useRef<HTMLDivElement>(null);

	const handleExpandClick = useCallback(
		(event: React.MouseEvent) => {
			event.stopPropagation();
			setExpanded((current) => !current);
			if (expanded) {
				onCollapse(note.id);
			}
		},
		[expanded, note.id, onCollapse],
	);

	useLayoutEffect(() => {
		if (editorRef.current) {
			const { offsetHeight } = editorRef.current;
			if (offsetHeight > contentHeight) {
				setExpandable(true);
			}
		}
	}, [contentHeight, editorRef.current?.offsetHeight]);

	return (
		<Container className="ReadOnlyNote" id={htmlId} ref={ref}>
			<HeaderComponent note={note} />
			<Body>
				<EditorContainer
					$contentHeight={contentHeight}
					$expandable={expandable}
					$expanded={expanded}
				>
					<Editor
						analyticsComponentIdentifier={
							analyticsComponentIdentifier
						}
						analyticsViewType={analyticsViewType}
						editable={false}
						initialContent={note.raw_comment || note.comment}
						namespace={namespace}
						ref={editorRef}
					/>
				</EditorContainer>
				{expandable && (
					<ExpandButton
						className="ReadOnlyNote__expand_button"
						onClick={handleExpandClick}
						expanded={expanded}
					/>
				)}
			</Body>
		</Container>
	);
});
