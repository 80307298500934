import { match, score } from 'fuzzaldrin-plus';

/**
 * Use fuzzaldrin-plus to filter complex data structures via a transformer
 */
export default function fuzzyFilter<T>(
	list: Array<T>,
	search: string,
	transform: (item: T) => string,
	sort = false,
): Array<T> {
	if (!search) return list;

	const matched: Array<[T, number, boolean]> = list.map((item) => [
		item,
		score(transform(item), search),
		match(transform(item), search).length > 0,
	]);

	const filtered = matched.filter((m) => m[2]);

	if (sort) {
		return filtered.sort((a, b) => b[1] - a[1]).map((m) => m[0]);
	} else {
		return filtered.map((m) => m[0]);
	}
}
