import React from 'react';
import { ResponsiveContainer } from 'recharts';
import type { ResponsiveContainerProps } from 'recharts';
import styled from 'styled-components';

export const ChartContainer = styled.div`
	border-radius: 4px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	background-color: white;
	display: grid;
	grid-template-rows: min-content 1fr;
	padding: 24px;
`;
export const ChartTitle = styled.h3`
	color: rgb(42, 42, 47);
	text-align: left;
`;

const RelativeContainer = styled.div`
	height: 100%;
	position: relative;
	width: 100%;
`;

const AbsoluteContainer = styled.div`
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
`;

interface ResponsiveChartProps extends ResponsiveContainerProps {
	className?: string;
	style?: React.CSSProperties;
}

// Without this fix, charts will either not shrink at all or shrink slowly over
// several seconds.
// https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
export function ResponsiveChart({
	className,
	style,
	...props
}: ResponsiveChartProps) {
	return (
		<RelativeContainer className={className} style={style}>
			<AbsoluteContainer>
				<ResponsiveContainer {...props} />
			</AbsoluteContainer>
		</RelativeContainer>
	);
}
