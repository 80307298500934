import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors, fonts } from '../theme';
import ThemeButton from '../theme/components/button';
import TextInput from '../theme/components/text-input';

import { useSnoozeOutreachCompanies } from './api';
import { type CompanyOutreach } from './types';
import useSnoozeForm from './use-snooze-form';

const Form = styled.form`
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 20px;
`;
const Header = styled.div`
	${fonts.h4.productive};
	color: ${colors.text.secondary};
	margin-bottom: 12px;
`;
const Buttons = styled.div`
	display: flex;
	gap: 4px;
`;
const LabelAsterisk = styled.span`
	color: ${colors.text.danger};
`;
const SnoozeDateInput = styled(TextInput)`
	> span {
		min-height: 16px;
	}
`;

interface Props {
	onClose: () => void;
	outreach: CompanyOutreach;
}

export default function SnoozeActionMenuForm({ onClose, outreach }: Props) {
	const { mutateAsync: snoozeOutreachCompanies, isPending: isSnoozing } =
		useSnoozeOutreachCompanies();

	const {
		handleSnoozeNotesChange,
		handleSnoozeUntilChange,
		handleSnoozeUntilInputBlur,
		snoozeNotes,
		snoozeUntil,
		snoozeUntilError,
	} = useSnoozeForm(true, [outreach]);

	const handleSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			if (snoozeUntilError.status === 'danger') {
				return;
			} else {
				await snoozeOutreachCompanies({
					outreachIds: [outreach.id],
					notes: snoozeNotes,
					snoozeUntil: snoozeUntil || '',
				});
				onClose();
			}
		},
		[
			onClose,
			outreach.id,
			snoozeNotes,
			snoozeOutreachCompanies,
			snoozeUntil,
			snoozeUntilError,
		],
	);

	return (
		<Form onSubmit={handleSubmit}>
			<Header>When?</Header>
			<SnoozeDateInput
				autoFocus
				id="snooze-date"
				label={
					<div>
						Follow up again on<LabelAsterisk>*</LabelAsterisk>
					</div>
				}
				name="snooze-date"
				onBlur={handleSnoozeUntilInputBlur}
				onChange={handleSnoozeUntilChange}
				required
				status={snoozeUntilError.status}
				statusText={snoozeUntilError.message}
				type="date"
				value={snoozeUntil || ''}
			/>
			<TextInput
				id="snooze-notes"
				label="Comment"
				name="snooze-notes"
				onChange={handleSnoozeNotesChange}
				placeholder="Add some context (optional)"
				value={snoozeNotes || ''}
			/>
			<Buttons>
				<ThemeButton
					disabled={
						snoozeUntilError.status === 'danger' || isSnoozing
					}
					type="submit"
				>
					Follow Up Later
				</ThemeButton>
				<ThemeButton
					onClick={onClose}
					type="button"
					variant="secondary"
				>
					Cancel
				</ThemeButton>
			</Buttons>
		</Form>
	);
}
