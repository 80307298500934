import { useCallback, useState } from 'react';

import useStableRef from './use-stable-ref';

// JSON.stringify can return undefined when passed undefined. The most thorough
// solution would involve constructing a complete type definition comprising all
// possible JSON-serializable values, but for now, I'm going to handle it as a
// run-time exception.
function safeJSONStringify(value: unknown): string {
	const result = JSON.stringify(value);
	if (result == null) {
		throw new TypeError(
			'Value passed to useLocalStorage() was not JSON-serializable.',
		);
	}
	return result;
}

function deserializeCurrentValue<T>(
	initialValue: T,
	key: string,
	deserialize: (serialized: string) => T,
): T {
	const currentSerializedValue = localStorage.getItem(key);
	return currentSerializedValue == null
		? initialValue
		: deserialize(currentSerializedValue);
}

export default function useLocalStorage<T>(
	key: string,
	initialValue: T,
	serialize: (value: T) => string = safeJSONStringify,
	deserialize: (serialized: string) => T = JSON.parse,
): [T, (value: T | ((currentValue: T) => T)) => void, () => void] {
	useStableRef(initialValue, 'useLocalStorage initialValue');

	const [currentValue, setCurrentValue] = useState(() =>
		deserializeCurrentValue(initialValue, key, deserialize),
	);

	const setter = useCallback(
		(newValue: T | ((currentValue: T) => T)): void => {
			if (newValue instanceof Function) {
				const previousValue = deserializeCurrentValue(
					initialValue,
					key,
					deserialize,
				);

				const nextValue = newValue(previousValue);
				localStorage.setItem(key, serialize(nextValue));
				setCurrentValue(nextValue);
			} else {
				localStorage.setItem(key, serialize(newValue));
				setCurrentValue(newValue);
			}
		},
		[deserialize, initialValue, key, setCurrentValue, serialize],
	);

	const clear = useCallback(() => {
		localStorage.removeItem(key);
		setCurrentValue(initialValue);
	}, [key, initialValue]);

	return [currentValue, setter, clear];
}
