/*
 * TypeScript does not have a built-in type declaration for
 * `navigator.standalone`, so we can add our own using declaration merging [1]
 * on the global `Navigator` interface.
 *
 * [1]: https://www.typescriptlang.org/docs/handbook/declaration-merging.html
 */
declare global {
	interface Navigator {
		/**
		 * A boolean available only on iOS Safari to determine whether the page
		 * is running as a standalone web app.
		 */
		standalone?: boolean;
	}
}

const isStandalone = !!navigator.standalone;

export default isStandalone;
