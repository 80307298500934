import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useUser } from '../authentication';
import Editor, {
	deserializeEditorState,
	EditorState,
	getTextContent,
	isEditorEmpty,
	serializeEditorState,
	TemplatePlugin,
	useEditorClear,
} from '../components/text-editor';
import { colors } from '../theme';
import UnstyledIconButton from '../theme/components/icon-button';
import { PaperAirplane1Outline as AirplaneIcon } from '../theme/icons/arrows';
import {
	LockClosedLineIcon as PrivateIcon,
	LockOpenIconIcon as PublicIcon,
} from '../theme/icons/system';
import { trackEvent } from '../utils/analytics';
import useLocalStorage from '../utils/hooks/use-local-storage';

import ContactedDropdown, { OTHER_OPTION } from './contacted-dropdown';
import Reminder from './reminder';
import { Container } from './shared-components';
import { type NoteData, type ProfileType, type ReminderData } from './types';

const USER_TEMPLATES = {
	'cameron-clarke': `INTRO

I’m Cameron, a Partner at Drive Capital focused on investments into AI infra and LLMops. By way of background, I did research and built software at Uber, and also have experience as a founder.

A bit about Drive:

Based in the US, out of Columbus, OH. Largest venture firm between the Coasts of the US

- 2014 - raised $250mm for the first venture fund
- 2017 - raised $300mm for the second venture fund
- 2019 - raised $650mm across our third set of funds, a third venture fund and a first time expansion stage fund
- 2022 - raised a little over $1bn across our fourth set of funds, a fourth venture fund and a second expansion stage fund

—

PAUSE FOR [FOUNDER] INTRO

—

[ ] What drove you to start the company?
[ ] Where are you with company building right now?
[ ] Where is the team based?
[ ] How big do you estimate the market that you’re going after to be?
[ ] Business model?
[ ] Product
[ ] Pricing
[ ] Revenue
[ ] Who are some of the other players in the space? How are you different?
[ ] What growth directions do you want to tackle next?
[ ] How are you thinking about margins and unit economics for your offering?
[ ] What are your fundraising goals at present?
[ ] Do you have a deck?`,
	'marcos-martinez-villalba': `- **Team**
    - CEO -
        - x
    - FTEs -
- **Description**
    - Company founded on X.
    - x
- **Market**
    - x
- **Customers / Traction**
    - x
- **Financials**
    - Revenue
        - FY 2022 -
            - Dec ARR -
        - FY 2023 -
            - Dec ARR -
        - FY 2024 -
    - Gross Margin -
    - Cash Position -
    - Burn Rate -
        - Runway of X months
- **Fundraising**
    - Series A round of $XM led by X on . $ post.
    - Total raised to date -
    - Currently raising a $ round. Approx terms of X.
- **Next steps**
    - x`,
} as const satisfies Record<string, string>;

const AddIconButton = styled(UnstyledIconButton)`
	align-items: center;
	background: ${colors.button.primary};
	border: 1px solid ${colors.button.primary};
	border-radius: 8px;
	color: ${colors.icon.onColor};
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
	}

	&:hover {
		color: ${({ disabled }) =>
			disabled ? 'inherit' : `${colors.icon.onColor};`};
	}
`;
const PrivacyIconButton = styled(UnstyledIconButton)`
	border-radius: 8px;

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Footer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`;

function AddButton({
	buttonSize,
	disabled,
	onClick,
}: {
	buttonSize: 'default' | 'small';
	disabled: boolean;
	onClick(): Promise<void>;
}) {
	const clearEditor = useEditorClear();
	const handleClick = useCallback(async () => {
		await onClick();
		clearEditor();
	}, [clearEditor, onClick]);

	return (
		<AddIconButton
			disabled={disabled}
			onClick={handleClick}
			size={buttonSize}
		>
			<AirplaneIcon />
		</AddIconButton>
	);
}

interface Props {
	autofocus?: boolean;
	buttonSize?: 'default' | 'small';
	footerVisible?: boolean;
	isCreating: boolean;
	mixpanelIdentifier: string;
	namespace: string;
	onCreate: (
		note: Pick<
			NoteData,
			'comment' | 'contacted_via' | 'public' | 'raw_comment' | 'reminders'
		>,
	) => Promise<NoteData | NoteData[]>;
	placeholder?: string;
	profileType: ProfileType;
	toolbarOpen?: boolean;
}

const DEFAULT_CONTACTED_VIA = '';
const DEFAULT_PUBLIC = true;
export default function NewNote({
	autofocus = false,
	buttonSize = 'default',
	footerVisible = true,
	isCreating,
	mixpanelIdentifier,
	namespace,
	onCreate,
	placeholder = 'Add a note here...',
	profileType,
	toolbarOpen = true,
}: Props) {
	const [serializedEditorState, setSerializedEditorState, clear] =
		useLocalStorage(namespace, '');
	const [comment, setComment] = useState(
		deserializeEditorState(serializedEditorState),
	);

	const [contactedVia, setContactedVia] = useState(DEFAULT_CONTACTED_VIA);
	const [dirty, setDirty] = useState(serializedEditorState !== '');
	const [isPublic, setIsPublic] = useState(DEFAULT_PUBLIC);
	const [reminder, setReminder] = useState<ReminderData | null>(null);
	const handleCommentChange = useCallback(
		(editorState: EditorState) => {
			if (!isEditorEmpty(editorState)) {
				setDirty(true);
				setSerializedEditorState(serializeEditorState(editorState));
				setComment(getTextContent(editorState));
			} else {
				// Do our best not to accumulate tons of empty editor states in localStorage
				// If the editor is cleared completely, remove the key
				clear();
				setComment('');
				setDirty(false);
			}
		},
		[clear, setSerializedEditorState],
	);
	const createDisabled =
		!dirty || isCreating || contactedVia === OTHER_OPTION;
	const handleCreate = useCallback(
		async (clearEditor?: () => void) => {
			if (createDisabled) return;
			await onCreate({
				comment,
				contacted_via: contactedVia,
				public: isPublic,
				raw_comment: serializedEditorState,
				reminders: reminder ? [reminder] : [],
			});

			trackEvent(
				'Add Note',
				mixpanelIdentifier,
				profileType === 'lpfundraising'
					? 'lp-fundraising'
					: profileType,
				{
					contacted_via: contactedVia,
					notification_method: reminder?.notification_method || null,
					public: isPublic,
				},
			);

			if (clearEditor) clearEditor();
			clear();
			setContactedVia(DEFAULT_CONTACTED_VIA);
			setDirty(false);
			setIsPublic(DEFAULT_PUBLIC);
			setReminder(null);
		},
		[
			clear,
			comment,
			contactedVia,
			createDisabled,
			isPublic,
			mixpanelIdentifier,
			onCreate,
			profileType,
			reminder,
			serializedEditorState,
		],
	);
	const user = useUser();
	const template =
		user.username in USER_TEMPLATES
			? USER_TEMPLATES[user.username as keyof typeof USER_TEMPLATES]
			: null;

	return (
		<Container className="NewNote">
			<Editor
				analyticsComponentIdentifier={mixpanelIdentifier}
				analyticsViewType={
					profileType === 'lpfundraising'
						? 'lp-fundraising'
						: profileType
				}
				editable
				autofocus={autofocus}
				control={
					<AddButton
						buttonSize={buttonSize}
						disabled={createDisabled}
						onClick={handleCreate}
					/>
				}
				extraToolbarControls={
					template ? (
						<TemplatePlugin
							analyticsComponentIdentifier={mixpanelIdentifier}
							analyticsViewType={
								profileType === 'lpfundraising'
									? 'lp-fundraising'
									: profileType
							}
							template={template}
						/>
					) : null
				}
				footer={
					footerVisible && (
						<Footer>
							<PrivacyIconButton
								title={
									isPublic ? 'Private: On' : 'Private: Off'
								}
								onClick={() => {
									setIsPublic((prev) => !prev);
								}}
								size={buttonSize}
							>
								{isPublic ? <PublicIcon /> : <PrivateIcon />}
							</PrivacyIconButton>
							<Reminder
								disabled={isCreating}
								onChange={setReminder}
								reminder={reminder}
							/>
							<ContactedDropdown
								contactedVia={contactedVia}
								disabled={isCreating}
								onChange={setContactedVia}
							/>
						</Footer>
					)
				}
				initialContent={serializedEditorState}
				namespace={namespace}
				onChange={handleCommentChange}
				onEnter={handleCreate}
				placeholder={placeholder}
				toolbarOpen={toolbarOpen}
			/>
		</Container>
	);
}
