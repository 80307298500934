// @flow

import numeral from 'numeral';
import React from 'react';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import rangeSearchFilter from './range-filter';
import type { RangeDisplayProps } from './range-filter';

const MAX_POSITION = 50;
const MAX_VALUE = 5e6;

const convertPositionToValue = ([min, max]) => {
	const convert = (position) => {
		const base = ((position - 1) % 9) + 1;
		const exponent = Math.floor((position - 1) / 9) + 1;

		return base * 10 ** exponent;
	};

	return [
		min === 0 ? null : convert(min),
		max === MAX_POSITION ? null : convert(max),
	];
};

const convertValueToPosition = ([min, max]) => {
	const convert = (value) => {
		const floor = Math.floor(Math.log10(value));

		return 9 * (floor - 1) + value / 10 ** floor;
	};

	return [
		min === null || min === 0 ? 0 : convert(min),
		max === null || max === MAX_VALUE ? MAX_POSITION : convert(max),
	];
};

const convertValueToText = ([min, max]) => [
	numeral(min === null ? 0 : min)
		.format('0a')
		.toUpperCase(),
	numeral(max === null ? MAX_VALUE : max)
		.format('0a')
		.toUpperCase(),
];

class EmployeeCountSearchFilterDisplay extends React.Component<RangeDisplayProps> {
	static defaultProps = {
		punctuator: '',
	};

	render() {
		const [min, max] = this.props.value;
		const [minText, maxText] = convertValueToText(this.props.value);

		if (min === null && max !== null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'has' : 'have'
					} less than `}
					<DescriptionValue onRemove={this.props.onRemoveMax}>
						{maxText}
					</DescriptionValue>
					{' employees'}
					{this.props.punctuator}
				</FilterDescription>
			);
		} else if (min !== null && max === null) {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'has' : 'have'
					} at least `}
					<DescriptionValue onRemove={this.props.onRemoveMin}>
						{minText}
					</DescriptionValue>
					{' employees'}
					{this.props.punctuator}
				</FilterDescription>
			);
		} else {
			return (
				<FilterDescription>
					{`${
						this.props.resultCount === 1 ? 'has' : 'have'
					} between `}
					<DescriptionValue onRemove={this.props.onRemoveMin}>
						{minText}
					</DescriptionValue>
					{' and '}
					<DescriptionValue onRemove={this.props.onRemoveMax}>
						{maxText}
					</DescriptionValue>
					{' employees'}
					{this.props.punctuator}
				</FilterDescription>
			);
		}
	}
}

export default rangeSearchFilter(
	'Employees',
	'data.employee_count',
	EmployeeCountSearchFilterDisplay,
	{
		convertPositionToValue,
		convertValueToPosition,
		convertValueToText,
		max: MAX_VALUE,
	},
	'employee-count-filter',
);
