export default class DefaultMap<K, V> extends Map<K, V> {
	private _getDefault: () => V;

	constructor(getDefault: () => V) {
		super();
		this._getDefault = getDefault;
	}

	get(key: K): V {
		let value = super.get(key);
		if (typeof value === 'undefined') {
			value = this._getDefault();
			super.set(key, value);
		}
		return value;
	}
}
