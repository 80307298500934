import { ActionTypes } from '../constants';
import createReducer from '../utils/createReducer';
import dateFieldParser from '../utils/date-field-parser';

const parseLastContact = dateFieldParser('last_contact_with_drive');

const parseOriginalAddedAt = dateFieldParser('original_added_at');

const addFromWatchListPeople = (state, entities) => {
	if (entities.length === 0) return state;
	const nextState = { ...state };

	for (const entity of entities) {
		if (entity.type === 'people') {
			nextState[entity.id] = {
				...nextState[entity.id],
				...parseOriginalAddedAt(parseLastContact(entity)),
			};
			if (Array.isArray(entity.notes)) {
				nextState[entity.id].notes = entity.notes.map(({ id }) => id);
			}
		}
	}

	return nextState;
};

export default createReducer(
	{},
	{
		[ActionTypes.CANDIDATE_POOL_ITEMS_ADD_SUCCESS]: (
			state,
			{ payload: { items } },
		) =>
			addFromWatchListPeople(
				state,
				items.map(({ entity }) => entity),
			),
		[ActionTypes.CANDIDATE_POOL_ITEM_ADD_SUCCESS]: (
			state,
			{ payload: { item } },
		) => addFromWatchListPeople(state, [item.entity]),
		[ActionTypes.CANDIDATE_POOL_LOAD_SUCCESS]: (
			state,
			{ payload: { items } },
		) =>
			addFromWatchListPeople(
				state,
				items.map(({ entity }) => entity),
			),
		[ActionTypes.SOCIAL_PROFILE_URL_EDIT_REQUEST]: (state, action) => ({
			...state,
			[action.payload.id]: {
				...state[action.payload.id],
				...action.payload,
			},
		}),
		[ActionTypes.LOAD]: (state, { payload }) => {
			if (payload.type === 'people') {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						...parseLastContact(payload),
					},
				};
			}

			return state;
		},
		[ActionTypes.MERGE_SUCCESS]: (state, action) => {
			if (action.payload.merged.type === 'people') {
				return {
					...state,
					[action.payload.merged.id]: {
						...state[action.payload.merged.id],
						...action.payload.merged.data,
					},
				};
			}

			return state;
		},
		[ActionTypes.NOTES_ADD_SUCCESS]: (state, { payload }) => {
			if (payload.type === 'people') {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						notes: payload.notes.map(({ id }) => id),
					},
				};
			}
			return state;
		},
		[ActionTypes.NOTES_DELETE_SUCCESS]: (state, { payload }) => {
			for (const id of Object.keys(state)) {
				if (
					state[id].notes
					&& state[id].notes.some((noteId) => noteId === payload.id)
				) {
					return {
						...state,
						[id]: {
							...state[id],
							notes: state[id].notes.filter(
								(noteId) => noteId !== payload.id,
							),
						},
					};
				}
			}
			return state;
		},
		[ActionTypes.NOTES_LOAD_SUCCESS]: (state, { payload }) => {
			if (payload.type === 'people' && !!payload.id) {
				return {
					...state,
					[payload.id]: {
						...state[payload.id],
						notes: payload.notes.map(({ id }) => id),
					},
				};
			} else {
				const nextState = { ...state };

				payload.notes
					.filter(({ target }) => target && target.type === 'people')
					.forEach((note) => {
						const entityId = note.target.id;

						nextState[entityId] = {
							...state[entityId],
							...note.target,
							notes: [
								...((nextState[entityId]
									&& nextState[entityId].notes)
									|| []),
								note.id,
							],
						};
					});

				return nextState;
			}
		},
		[ActionTypes.PARCHMENT_DELETE]: (state, { payload }) => {
			for (const id of Object.keys(state)) {
				if (
					state[id].parchments
					&& state[id].parchments.some(
						(parchmentId) => parchmentId === payload.id,
					)
				) {
					return {
						...state,
						[id]: {
							...state[id],
							parchments: state[id].parchments.filter(
								(parchmentId) => parchmentId !== payload.id,
							),
						},
					};
				}
			}
			return state;
		},
		[ActionTypes.PERSONPROFILE_SUCCESS]: (state, action) => ({
			...state,
			[action.payload.id]: {
				...state[action.payload.id],
				...action.payload,
			},
		}),
		[ActionTypes.SEARCH_SUCCESS]: (state, action) => {
			const newState = { ...state };

			action.payload.hits
				.map((hit) => hit.data)
				.filter((hit) => hit.type === 'people')
				.forEach((hit) => {
					newState[hit.id] = {
						...state[hit.id],
						...hit,
					};
				});

			return newState;
		},
		[ActionTypes.WATCHLIST_ADD_ITEM_SUCCESS]: (
			state,
			{ payload: { item } },
		) => addFromWatchListPeople(state, [item.entity]),
		[ActionTypes.WATCHLIST_LOAD_SUCCESS]: (state, { payload: { items } }) =>
			addFromWatchListPeople(
				state,
				items.map(({ entity }) => entity),
			),
	},
);
