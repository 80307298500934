// @flow

import React from 'react';

import { markRobot } from '../../actions';
import Authorized from '../../components/Authorized';
import type { SelectedSet } from '../view';

import AdminButton from './admin-button';

type Props = {
	selected: SelectedSet,
};

export default class RobotButton extends React.Component<Props> {
	handleRobot = () => {
		markRobot(this.props.selected.people);
	};

	render() {
		const {
			selected: { companies, people },
		} = this.props;

		return (
			<Authorized auth="contacts.mark_robot">
				<AdminButton
					enabled={companies.length === 0 && people.length >= 1}
					onClick={this.handleRobot}
				>
					Robot
				</AdminButton>
			</Authorized>
		);
	}
}
