import React from 'react';
import type { ReactNode, VoidFunctionComponent } from 'react';

import './admin-button.scss';

type ButtonProps = {
	children?: ReactNode;
	enabled: boolean;
	onClick: () => void;
};

const SearchAdminToolbarButton: VoidFunctionComponent<ButtonProps> = ({
	children,
	enabled,
	onClick,
}: ButtonProps) => (
	<button
		className="SearchAdminToolbarButton"
		disabled={!enabled}
		onClick={onClick}
		type="button"
	>
		{children}
	</button>
);

export default SearchAdminToolbarButton;
