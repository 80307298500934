// @flow

import moment from 'moment';
import React from 'react';

import DescriptionValue from './description-value';
import FilterDescription from './filter-description';
import minimumSearchFilter from './minimum-filter';
import type { MinimumDisplayProps } from './minimum-filter';

const convertPositionToValue = (position) => {
	if (position === 0) {
		return null;
	}

	return moment()
		.startOf('month')
		.add(position - 3 * 12, 'months')
		.unix();
};

const convertValueToPosition = (value) => {
	if (
		value === null
		|| moment()
			.subtract(3 * 12, 'months')
			.isSame(value * 1000, 'month')
	) {
		return 0;
	}

	for (let i = 1; i <= 36; i++) {
		if (
			moment()
				.add(i - 3 * 12, 'months')
				.isSame(value * 1000, 'month')
		) {
			return i;
		}
	}

	throw new Error(`Unable to determine month position for ${value}`);
};

const convertValueToText = (value) => {
	if (value === null) {
		return 'Anytime';
	}

	return moment(value * 1000).format('MMMM YYYY');
};

const RecencySearchFilterDisplay = ({
	onRemove,
	punctuator = '',
	resultCount,
	value,
}: MinimumDisplayProps) =>
	value == null ? null : (
		<FilterDescription>
			{`${resultCount === 1 ? 'has' : 'have'} made an investment since `}
			<DescriptionValue
				onRemove={onRemove}
				punctuator={punctuator}
				value={value}
			>
				{moment(value * 1000).format('MMMM YYYY')}
			</DescriptionValue>
		</FilterDescription>
	);

export default minimumSearchFilter(
	'Recency',
	'investments.last_date',
	RecencySearchFilterDisplay,
	{
		convertPositionToValue,
		convertValueToPosition,
		convertValueToText,
		max: moment().startOf('month').unix(),
		min: moment()
			.startOf('month')
			.subtract(3 * 12, 'months')
			.unix(),
	},
	'investment-recency-filter',
);
