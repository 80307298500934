export const ActionTypes = {
	ADD_COMPANY_FUNNEL_ACTION: 'ADD_COMPANY_FUNNEL_ACTION',
	CANDIDATE_POOLS_INVALIDATE: 'CANDIDATE_POOLS_INVALIDATE',
	CANDIDATE_POOLS_LOAD_SUCCESS: 'CANDIDATE_POOLS_LOAD_SUCCESS',
	CANDIDATE_POOL_EDIT_SUCCESS: 'CANDIDATE_POOL_EDIT_SUCCESS',
	CANDIDATE_POOL_ITEMS_REMOVE_SUCCESS: 'CANDIDATE_POOL_ITEMS_REMOVE_SUCCESS',
	CANDIDATE_POOL_ITEMS_ADD_SUCCESS: 'CANDIDATE_POOL_ITEMS_ADD_SUCCESS',
	CANDIDATE_POOL_ITEM_ADD_SUCCESS: 'CANDIDATE_POOL_ITEM_ADD_SUCCESS',
	CANDIDATE_POOL_ITEM_EDIT_SUCCESS: 'CANDIDATE_POOL_ITEM_EDIT_SUCCESS',
	CANDIDATE_POOL_LOAD_SUCCESS: 'CANDIDATE_POOL_LOAD_SUCCESS',
	COMPANYPROFILE_SUCCESS: 'COMPANYPROFILE_SUCCESS',
	DELETE_LP_DATA: 'DELETE_LP_DATA',
	EDIT_LP_DATA: 'EDIT_LP_DATA',
	FUNDTRACKER_LOAD_FAILURE: 'FUNDTRACKER_LOAD_FAILURE',
	FUNDTRACKER_LOAD_REQUEST: 'FUNDTRACKER_LOAD_REQUEST',
	FUNDTRACKER_LOAD_SUCCESS: 'FUNDTRACKER_LOAD_SUCCESS',
	LOAD: 'LOAD',
	LOAD_COMPANIES: 'LOAD_COMPANIES',
	MARK_ROBOT_FAILURE: 'MARK_ROBOT_FAILURE',
	MARK_ROBOT_REQUEST: 'MARK_ROBOT_REQUEST',
	MARK_ROBOT_SUCCESS: 'MARK_ROBOT_SUCCESS',
	MERGE_FAILURE: 'MERGE_FAILURE',
	MERGE_REQUEST: 'MERGE_REQUEST',
	MERGE_SUCCESS: 'MERGE_SUCCESS',
	NOTES_ADD_SUCCESS: 'NOTES_ADD_SUCCESS',
	NOTES_DELETE_SUCCESS: 'NOTES_DELETE_SUCCESS',
	NOTES_EDIT: 'NOTES_EDIT',
	NOTES_LOAD_SUCCESS: 'NOTES_LOAD_SUCCESS',
	PERSONPROFILE_SUCCESS: 'PERSONPROFILE_SUCCESS',
	SEARCH_SUCCESS: 'SEARCH_SUCCESS',
	SEMIANNUAL_LOAD_FAILURE: 'SEMIANNUAL_LOAD_FAILURE',
	SEMIANNUAL_LOAD_REQUEST: 'SEMIANNUAL_LOAD_REQUEST',
	SEMIANNUAL_LOAD_SUCCESS: 'SEMIANNUAL_LOAD_SUCCESS',
	SEMIANNUAL_SAVE: 'SEMIANNUAL_SAVE',
	SEMIANNUALS_LOAD_FAILURE: 'SEMIANNUALS_LOAD_FAILURE',
	SEMIANNUALS_LOAD_REQUEST: 'SEMIANNUALS_LOAD_REQUEST',
	SEMIANNUALS_LOAD_SUCCESS: 'SEMIANNUALS_LOAD_SUCCESS',
	SOCIAL_PROFILE_URL_EDIT_FAILURE: 'SOCIAL_PROFILE_URL_EDIT_FAILURE',
	SOCIAL_PROFILE_URL_EDIT_REQUEST: 'SOCIAL_PROFILE_URL_EDIT_REQUEST',
	SOCIAL_PROFILE_URL_EDIT_SUCCESS: 'SOCIAL_PROFILE_URL_EDIT_SUCCESS',
	WATCHLIST_ADD_ITEM_FAILURE: 'WATCHLIST_ADD_ITEM_FAILURE',
	WATCHLIST_ADD_ITEM_REQUEST: 'WATCHLIST_ADD_ITEM_REQUEST',
	WATCHLIST_ADD_ITEM_SUCCESS: 'WATCHLIST_ADD_ITEM_SUCCESS',
	WATCHLIST_CREATE: 'WATCHLIST_CREATE',
	WATCHLIST_DELETE: 'WATCHLIST_DELETE',
	WATCHLIST_EDIT: 'WATCHLIST_EDIT',
	WATCHLIST_ITEM_EDIT_FAILURE: 'WATCHLIST_ITEM_EDIT_FAILURE',
	WATCHLIST_ITEM_EDIT_REQUEST: 'WATCHLIST_ITEM_EDIT_REQUEST',
	WATCHLIST_ITEM_EDIT_SUCCESS: 'WATCHLIST_ITEM_EDIT_SUCCESS',
	WATCHLIST_LOAD_FAILURE: 'WATCHLIST_LOAD_FAILURE',
	WATCHLIST_LOAD_REQUEST: 'WATCHLIST_LOAD_REQUEST',
	WATCHLIST_LOAD_SUCCESS: 'WATCHLIST_LOAD_SUCCESS',
	WATCHLIST_REMOVE_ITEMS_FAILURE: 'WATCHLIST_REMOVE_ITEMS_FAILURE',
	WATCHLIST_REMOVE_ITEMS_REQUEST: 'WATCHLIST_REMOVE_ITEMS_REQUEST',
	WATCHLIST_REMOVE_ITEMS_SUCCESS: 'WATCHLIST_REMOVE_ITEMS_SUCCESS',
	WATCHLISTS_INVALIDATE: 'WATCHLISTS_INVALIDATE',
	WATCHLISTS_LOAD_FAILURE: 'WATCHLISTS_LOAD_FAILURE',
	WATCHLISTS_LOAD_REQUEST: 'WATCHLISTS_LOAD_REQUEST',
	WATCHLISTS_LOAD_SUCCESS: 'WATCHLISTS_LOAD_SUCCESS',
} as const;

declare global {
	interface Window {
		ActionTypes: typeof ActionTypes;
	}
}

window.ActionTypes = ActionTypes;

export const Colors = {
	primaryDriveBlue: '#007dc2',
	secondaryDarkDriveBlue: '#0d4b6e',
	herbieTeal: '#6fb9e0',
	charcoalGray: '#4a4a4a',
	backgroundGray: '#f8f8f8',
	black: '#000000',
	driveGreen: '#5db6a3',
};
