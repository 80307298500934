import React from 'react';
import type { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import type { User } from '../authentication';
import { selectUser } from '../selectors';
import assertExhaustive from '../utils/assert-exhaustive';

type PermissionPredicate = (user: User) => boolean;

export type Permission = string | PermissionPredicate;

export function authorize(permission: Permission): PermissionPredicate {
	switch (typeof permission) {
		case 'function':
			return permission;

		case 'string': {
			const name: string = permission;

			return (user: User) => user.permissions.includes(name);
		}

		default:
			return assertExhaustive(permission);
	}
}

export function hasConnectedCalendar(user: User): boolean {
	return (
		user.oauth_scopes['https://www.googleapis.com/auth/calendar'] === true
	);
}

export function hasConnectedInbox(user: User): boolean {
	return (
		user.oauth_scopes['https://www.googleapis.com/auth/gmail.metadata']
		=== true
	);
}

export const isInvestor = (user: User): boolean =>
	user.groups.includes('Investors');

export const isSeedManager = (user: User): boolean =>
	user.groups.includes('F4 General Managers');

export const isStaff = (user: User): boolean => user.is_staff;

export const isTalentPartner = (user: User): boolean =>
	user.groups.includes('Talent Partners');

export function hasTenant(user: User): boolean {
	return user.tenant != null;
}

export const isDriveTenant = (user: User): boolean =>
	user.tenant != null && user.tenant.name === 'Drive Capital';

export function and(...permissions: Array<Permission>): PermissionPredicate {
	const allOf = permissions.map(authorize);
	return (user: User) => allOf.every((fn) => fn(user));
}

export function or(...permissions: Array<Permission>): PermissionPredicate {
	const anyOf = permissions.map(authorize);
	return (user: User) => anyOf.some((fn) => fn(user));
}

export function not(permission: Permission): PermissionPredicate {
	return (user: User) => !authorize(permission)(user);
}

export function useAuthorized(auth: Permission): boolean {
	const user = useSelector(selectUser);
	const authorized = user != null && authorize(auth)(user);
	return authorized;
}

interface Props {
	auth: Permission;
	children: ReactElement | ((authorized: boolean) => ReactElement | null);
}

export default function Authorized({
	auth,
	children,
}: Props): ReactElement | null {
	const authorized = useAuthorized(auth);

	if (React.isValidElement(children)) {
		if (authorized) {
			return React.Children.only(children);
		} else {
			return null;
		}
	} else if (typeof children === 'function') {
		return children(authorized);
	}

	return null;
}
