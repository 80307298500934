import d3 from 'd3';
import React from 'react';

import { get } from '../../utils/api';
import HerbieLoader from '../herbie-loader';

import DiscreteBarChart from './charts/discrete-bar-chart';
import GenericChart from './charts/generic-chart';
import StatsDetailListItem from './stats-detail-list-item';

const apiUrl = '/stats/companies/contacted/age';
let data = null;
const yAxisTickFormat = d3.format('.0%');

const formatData = (rows) => [
	{
		key: 'Age',
		values: rows.map(({ age, pct }) => ({
			label: `${age}-${age + 1}`,
			value: pct,
		})),
	},
];

export default class CompaniesAge extends React.Component {
	static getDetailCard() {
		return CompaniesAgeDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				labelRotation={0}
				onDataFetch={this.handleDataFetch}
				title="Startup Age at First Contact"
				xAxisLabel="Age (years)"
				yAxisLabel="Portion of Startups"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class CompaniesAgeDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/companies_age.png"
				statName="Startup Age at First Contact"
			/>
		);
	}
}
