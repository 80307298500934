// @flow

import { combineReducers } from 'redux';

import { type Action } from '../actions';
import { authenticationReducer } from '../authentication';
import type { State as AuthenticationState } from '../authentication';
import { reducer as onePagers, type OnePagersState } from '../one-pagers';
import talent, { type CandidatePoolState } from '../talent/reducers';

import companiesById from './companiesById';
import merged from './merged';
import notes from './notes';
import peopleById from './peopleById';
import robots from './robots';
import semiAnnuals from './semiAnnuals';
import watchLists from './watchLists';

export type State = {
	candidatePools: CandidatePoolState,
	companiesById: any,
	marketMaps: any,
	merged: any,
	notes: any,
	onePagers: OnePagersState,
	peopleById: any,
	robots: any,
	semiAnnuals: any,
	user: AuthenticationState,
	watchLists: any,
};

export default combineReducers<_, Action>({
	...talent,
	companiesById,
	merged,
	notes,
	onePagers,
	peopleById,
	robots,
	semiAnnuals,
	user: authenticationReducer,
	watchLists,
});
