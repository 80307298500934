import React from 'react';
import styled from 'styled-components';

import type { FooterArgs } from '../table';
import CurrencyColumn from '../table/columns/currency';

const Footer = styled.th`
	font-variant-numeric: tabular-nums;
	white-space: nowrap;

	/* Increase specificity to override default styling. */
	&& {
		text-align: right;
	}
`;

export default class LPCurrencyColumn<
	T extends Record<string, any>,
> extends CurrencyColumn<T> {
	footer({ props, rows }: FooterArgs<T>): JSX.Element {
		return (
			<Footer {...props}>
				{this.format(
					rows.reduce((accum: number, row) => {
						const val = this.select(row);
						return accum + (val === null ? 0 : val);
					}, 0),
				)}
			</Footer>
		);
	}
}
