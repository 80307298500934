import styled from 'styled-components';

import { backgroundGray } from '../colors';

export const Split = styled.div`
	background-color: ${backgroundGray.string()};
	background-image: url('https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/herbie-transparent-large.png');
	background-position: calc((100% - 400px) * 0.6 + 400px) 50%;
	background-repeat: no-repeat;
	background-size: 70%;
	display: flex;
	flex: 1 1 0;
	flex-direction: row;
	overflow: hidden;

	@media (max-width: 768px) {
		background-position: 55% 50%;
	}
`;

export const Sidebar = styled.aside`
	background-color: white;
	display: flex;
	flex: 0 0 400px;
	flex-direction: column;
	overflow: hidden;

	@media (max-width: 768px) {
		flex: 1 0 0;

		&:not(:last-child) {
			display: none;
		}
	}
`;
