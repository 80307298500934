import { css } from 'styled-components';

const effects = {
	shadow: {
		elevated: css`
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
		`,
		shadow: css`
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
		`,
		hover: css`
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
		`,
		focus: css`
			box-shadow: 0 0 0 4px rgba(15, 98, 254, 0.2);
		`,
	},
} as const;

export default effects;
