import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export default function ErrorFallback({ className }: { className?: string }) {
	return (
		<Container className={className}>
			<h1>Something broke 💩</h1>
			<p>The developer responsible has been yelled at.</p>
		</Container>
	);
}
