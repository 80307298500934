import React from 'react';
import styled, { keyframes } from 'styled-components';

import * as colors from '../colors';

const Container = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	padding: 80px 60px;
`;

const WheelsAnimate = keyframes`
	0%   { transform: translateY(0px); }
	50%  { transform: translateY(9px); }
	100% { transform: translateY(0px); }
`;

const BodyAnimate = keyframes`
	0%   { transform: translateY(0px); }
	50%  { transform: translateY(5px); }
	100% { transform: translateY(0px); }
`;

const SmokeOne = keyframes`
	0%   { opacity: 0; transform: translate(0, 0); }
	20%  { opacity: 0; transform: translate(0, 0); }
	22%  { opacity: 1; }
	76%  { opacity: 0; transform: translate(-200%, -180%); }
	77%  { transform: translate(0, 0); }
	100% { transform: translate(0, 0); }
`;

const SmokeTwo = keyframes`
	0%   { opacity: 0; transform: translate(0, 0); }
	2%   { opacity: 1; }
	40%  { opacity: 0; transform: translate(-220%, -235%); }
	41%  { transform: translate(0, 0); }
	100% { transform: translate(0, 0); }
`;

const SmokeThree = keyframes`
	0%   { opacity: 0; transform: translate(0, 0); }
	40%  { opacity: 0; transform: translate(0, 0); }
	42%  { opacity: 1; }
	99%  { opacity: 0; transform: translate(-270%, -50%); }
	100% { transform: translate(0, 0); }
`;

const HerbieBody = styled.g`
	transform: translateY(0px);
	animation: ${BodyAnimate} 0.4s ease infinite;
`;

const HerbieWheels = styled.g`
	transform: translateY(0px);
	animation: ${WheelsAnimate} 0.4s ease infinite;
`;

const HerbieSvg = styled.div`
	min-height: 200px;
	min-width: 200px;
	width: 200px;

	text-align: center;

	.cls-1 {
		fill: ${colors.herbieGray.string()};
	}
	.cls-2 {
		fill: ${colors.white.string()};
	}
	.cls-3 {
		fill: ${colors.herbieDarkerGray.string()};
	}
	.cls-4 {
		fill: ${colors.herbieDarkestGray.string()};
	}
	.cls-11,
	.cls-13,
	.cls-5,
	.cls-6,
	.cls-7,
	.cls-8,
	.cls-9 {
		fill: none;
	}
	.cls-11,
	.cls-12,
	.cls-5,
	.cls-6,
	.cls-7,
	.cls-8,
	.cls-9 {
		stroke: ${colors.herbieBlack.string()};
	}
	.cls-5,
	.cls-8,
	.cls-9 {
		stroke-linecap: round;
	}
	.cls-5,
	.cls-6,
	.cls-8 {
		stroke-linejoin: round;
	}
	.cls-11,
	.cls-5,
	.cls-6,
	.cls-9 {
		stroke-width: 4px;
	}
	.cls-11,
	.cls-12,
	.cls-13,
	.cls-7,
	.cls-9 {
		stroke-miterlimit: 10;
	}
	.cls-12,
	.cls-7,
	.cls-8 {
		stroke-width: 2px;
	}
	.cls-10 {
		font-size: 48px;
		fill: ${colors.herbieBlack.string()};
		font-family: inherit;
		font-weight: 700;
		letter-spacing: -0.15em;
	}
	.cls-12,
	.cls-14 {
		fill: ${colors.herbieMediumGray.string()};
	}
	.cls-13 {
		stroke: ${colors.herbieWindows.string()};
	}
	.cls-15,
	.cls-16,
	.cls-17 {
		fill: ${colors.white.darken(0.09).string()};
	}
`;

const Svg = styled.svg`
	width: 200px;
`;

const SmokeOnePath = styled.path`
	transform: translate(0, 0);
	opacity: 0;
	animation: ${SmokeOne} 1.3s ease infinite;
`;

const SmokeTwoPath = styled.path`
	transform: translate(0, 0);
	opacity: 0;
	animation: ${SmokeTwo} 1.3s ease infinite;
`;

const SmokeThreePath = styled.path`
	transform: translate(0, 0);
	opacity: 0;
	animation: ${SmokeThree} 1.3s ease infinite;
`;

const Herbie = (
	<HerbieSvg>
		<Svg viewBox="-90 0 668.28 220.02">
			<g className="herbie">
				<HerbieBody>
					<path
						className="cls-1"
						d="M501.75,124.12V123c-.39-1.84-.93-4.15-1.6-6.72l.64-.17c2.5.67,10.12,2.07,12.46,3.1,1.4.62,3,3,3.43,4.43,2,6.56-1.1,10-7.89,11a28.35,28.35,0,0,1-3,.48A31.28,31.28,0,0,1,500,135c-3.78-.61-3.66-3-3.2-9.89a1.48,1.48,0,0,1,.42-.77,1.3,1.3,0,0,1,1.06-.3C499.79,124.08,500.87,124.1,501.75,124.12Z"
					/>
					<path
						className="cls-2"
						d="M466,79.62c1.61.44,3.22.94,4.82,1.48,8,2.69,8,2.84,16,5.66.28.1.76.32,1.36.61,4.28,5.39,7.71,14.19,10.15,22.17.72,2.35,1.35,4.63,1.89,6.73.67,2.57,1.21,4.88,1.6,6.72v1.13c-.88,0-2,0-3.45-.09a1.3,1.3,0,0,0-1.06.3,1.48,1.48,0,0,0-.42.77c-.46,6.9-.58,9.28,3.2,9.89v.25c-1.33,5.62-5.28,5-6.73,2.77-4.26-6.61-5.94-13.85-10-20.88-3.86-6.33-8.57-12-16-15.64-14.39-7.45-32.57-6.73-46,2-11.56,7.5-16.8,19-20.5,31.52a35.5,35.5,0,0,1-3.61,8.94c-3.36,5.95-9.63,7.73-15.33,7.32-5.54-.39-10.36-.67-15.54-.75-20.81,0-41.65,0-63,0-33.68,0-64.94-.39-100-.39-4.52-.72-5.78-2.41-7.05-7.56-1.56-7.18-2.46-12.73-4.47-19C181.27,94.14,146.6,89,125.27,103.06c-8.15,5.37-13.61,13.13-18.63,21.4-4.43,7.31-10.5,13.45-18.44,17-6.43,2.84-13.54,3.28-20.45,2.49-.73-.08-3.06-1.36-3.36-2.43a11.05,11.05,0,0,1,.66-7.27l0-.15c3.84.43,7.69.79,11.73.93,6.1.22,8-1.56,10-7.4,1.25-3.64-.11-5-4-5.07-1.7,0-3.41-.06-5.11-.13V122c1.33-1.5,5.45-5.27,6.53-6.94a4.63,4.63,0,0,0,.64-2.08h.47c2.31.63,5.9,1.76,7.79-.65,5.14-6.52,9.57-12.18,14.61-18.77.91-1.19,1.46-3.85,2-5.35l-.07-.24a11.83,11.83,0,0,0,3.42-1.56l.32-.2c7.8-5,15.67-9.67,24.9-11.64,1.15-.24,2.32-.45,3.51-.6,16.09-2.08,30.38,1.74,42.44,12.23,14.42,12.54,19.76,29.73,22.93,47.91,1,5.88.8,4.53,1.3,8.71l0,.43c8,0,16.2-.18,23.81-.18l20,.33c9.48.13,19,.21,28.45.15,26.61-.15,60.2-.56,86.92-1h.67l.09-.3c.5-1.5,1-3.63,1.3-5.16,2.94-13.55,8.61-25.68,18-36,10.86-11.94,24.48-18.87,40-22.5a74.47,74.47,0,0,1,21.84-1.8A81,81,0,0,1,466,79.62Z"
					/>
					<path
						className="cls-1"
						d="M495.07,91c7.63,3.64,11.44,12.31,4,18.3l-.83.25c-2.44-8-5.87-16.78-10.15-22.17C490.35,88.47,494.18,90.56,495.07,91Z"
					/>
					<path
						className="cls-2"
						d="M466.14,78.81a4.82,4.82,0,0,0-4.84-5.74,39.58,39.58,0,0,0-8.48.17c-1,.15-1.77,1.43-2.62,2.42l15.64,3.11Zm19.9,6.3a20.8,20.8,0,0,1,2.08,2.26c-.6-.29-1.08-.51-1.36-.61-7.95-2.82-8-3-16-5.66-1.6-.54-3.21-1-4.82-1.48a81,81,0,0,0-16.34-2.83l0-.27c-9.62-.74-33.39-3.46-42.36-3.88C393.16,72,379,71,364.88,70.12l-.58-2c-2.67-5.17-5.08-10.49-8.06-15.47-4.95-8.29-9.08-16.5-14.55-24.46a45.91,45.91,0,0,0-8.41-8.54c-5.75-4.3-12.82-5.15-19.5-5.84-13.94-1.45-28-2.28-42-2.73-11-.35-33.16-.13-44.08,1.33-29.51,4-41.36,8.94-54.54,16.81-13.49,8.06-25.36,19.34-36.88,29.9l-1,1c-7.68,7.52-15.38,15-23,22.61l-1.83-1.85c6.06-7,5.91-7.33.84-16.27-.43-.77-1.27-2.94-1.6-3.77l-.54-.64q5.52-4.65,11.08-9.24l.55.66c3.83,3.86,4.37,5.85,10.1.61,7.42-6.79,15.71-12.62,23.32-19.2,2.65-2.28,4.68-6.31,6.1-9.48l-2-.1c1.69-.73,3.28-1.42,4.58-2.1l.1,0c4.13-2.66,16.92-8.85,21.45-10.12a231.31,231.31,0,0,1,40.42-7.33A375.34,375.34,0,0,1,273.3,2.2c17.13.59,20.81.59,43.55,3.07a38.87,38.87,0,0,1,22.94,10.84c7.91,7.52,14.52,17.6,19.73,27.19,3.22,5.94,7.23,11.85,13.28,12.27,6.83.47,16.68-.48,23.49-.9,11.06-.69,26.19-.23,37,2.07a128.43,128.43,0,0,1,23.48,7C471,69.51,478.6,75.63,486,85.1Z"
					/>
					<path
						className="cls-1"
						d="M461.3,73.07a4.82,4.82,0,0,1,4.84,5.74l-.3,0L450.2,75.66c.85-1,1.65-2.27,2.62-2.42A39.58,39.58,0,0,1,461.3,73.07Z"
					/>
					<path
						className="cls-2"
						d="M449.66,76.52l0,.27a74.47,74.47,0,0,0-21.84,1.8c-15.53,3.63-29.15,10.56-40,22.5-9.4,10.33-15.07,22.46-18,36-.34,1.53-.8,3.66-1.3,5.16l-.09.3h-.67l0-1c-.17-14.67-1.9-55.33-2.4-70l-.42-1.48C379,71,393.16,72,407.3,72.64,416.27,73.06,440,75.78,449.66,76.52Z"
					/>
					<path
						className="cls-2"
						d="M367.7,141.6l0,1c-26.72.42-60.31.83-86.92,1-9.49.06-19,0-28.45-.15v-.56c-.17-23-.34-52-.5-75V67.6l2.8,0c5.4,0,10.47,0,15.64,0,11,.07,22,0,33,0,10.17.08,20.33.81,30.5,1h3c9.36.4,18.72.94,28.08,1.52l.42,1.48C365.8,86.27,367.53,126.93,367.7,141.6ZM275.8,75c.53,0,1.61-.74,1.5-1.44s-1.32-.93-2-1c-5.83-.24-11.5-.17-17.34-.33l-.73.07c-.92.43-1.78,1-1.78,1.72S256.65,76,257.3,76C263.47,75.83,269.64,75.47,275.8,75Z"
					/>
					<path
						className="cls-2"
						d="M364.3,68.1l.58,2c-9.36-.58-18.72-1.12-28.08-1.52h-3c-10.17-.17-20.33-.9-30.5-1-11-.08-22,0-33,0-5.17,0-10.24,0-15.64,0l-2.8,0h-.06c-5,.33-10,.34-15,.47-16.17.42-32.34.59-48.5,1.07-9.51.28-40.23,2.67-50,5.43-9.23,2-17.1,6.67-24.9,11.64l-.32.2-.34-.56c-.17-.66-1.11-2.45-.86-2.7l.45-.44c7.62-7.58,15.32-15.09,23-22.61l1-1c11.52-10.56,23.39-21.84,36.88-29.9,13.18-7.87,25-12.85,54.54-16.81,10.92-1.46,33.07-1.68,44.08-1.33,14,.45,28,1.28,42,2.73,6.68.69,13.75,1.54,19.5,5.84a45.91,45.91,0,0,1,8.41,8.54c5.47,8,9.6,16.17,14.55,24.46C359.22,57.61,361.63,62.93,364.3,68.1Zm-19.5-7.54c5.47-.17,7.5-3.3,4.94-7.93-3.59-6.47-7.61-12.7-11.44-19-6.77-11.2-18.39-12.35-29.52-13.33-13.93-1.22-28-1.24-42-1.63-5.15-.15-7.58,1.81-7.92,7-.62,9.31-1,18.66-1,28,0,5.52,2,7,7.4,7q27.5,0,55,0c1.8,0,1.35.06,7.74.06h.76C334.13,60.5,339.47,60.73,344.8,60.56ZM247.91,27.61c.5-5.59-3.49-8.69-9.12-9.06a61,61,0,0,0-6.49,0h-.11c-3.82.3-7.24.86-10.88,1.44-9.43,1.52-19.36,3.46-28.42,6.81C183,30.53,173.84,36,168.18,46c-5,8.78,1,15.66,8.62,15.55,21-.28,42-.57,63-1,5-.12,6.85-2.44,7-7.45C247.09,44.6,247.16,36.07,247.91,27.61Z"
					/>
					<path
						className="cls-3"
						d="M349.74,52.63c2.56,4.63.53,7.76-4.94,7.93s-10.67-.06-16,.08H328c-6.39,0-5.94-.06-7.74-.06q-27.5,0-55,0c-5.4,0-7.39-1.48-7.4-7,0-9.33.36-18.68,1-28,.34-5.16,2.77-7.12,7.92-7,14,.39,28.05.41,42,1.63,11.13,1,22.75,2.13,29.52,13.33C342.13,39.93,346.15,46.16,349.74,52.63Z"
					/>
					<path d="M277.3,73.6c.11.7-1,1.4-1.5,1.44-6.16.43-12.33.79-18.5,1-.65,0-1.85-1.24-1.85-1.91s.86-1.29,1.78-1.72l.73-.07c5.84.16,11.51.09,17.34.33C276,72.67,277.18,72.86,277.3,73.6Z" />
					<path
						className="cls-2"
						d="M252.36,142.84v.56l-20-.33c-7.61,0-15.8.18-23.81.18l0-.43c-.5-4.18-.28-2.83-1.3-8.71-3.17-18.18-8.51-35.37-22.93-47.91C172.16,75.71,157.87,71.89,141.78,74c-1.19.15-2.36.36-3.51.6,9.8-2.76,40.52-5.15,50-5.43,16.16-.48,32.33-.65,48.5-1.07,5-.13,10-.14,15-.47h.06v.24C252,90.84,252.19,119.84,252.36,142.84Z"
					/>
					<path
						className="cls-3"
						d="M232.3,18.6a61,61,0,0,1,6.49,0c5.63.37,9.62,3.47,9.12,9.06-.75,8.46-.82,17-1.1,25.49-.16,5-2,7.33-7,7.45-21,.46-42,.75-63,1-7.62.11-13.59-6.77-8.62-15.55,5.66-10,14.77-15.5,24.71-19.18,9.06-3.35,19-5.29,28.42-6.81,3.64-.58,7.06-1.14,10.88-1.44h.11Z"
					/>
					<path
						className="cls-3"
						d="M158.27,23.43l2,.1c-1.42,3.17-3.45,7.2-6.1,9.48-7.61,6.58-15.9,12.41-23.32,19.2-5.73,5.24-6.27,3.25-10.1-.61l-.55-.66q13.67-11.3,27.63-22.24C150.38,26.74,154.55,25,158.27,23.43Z"
					/>
					<path
						className="cls-2"
						d="M112.71,85.85l.34.56A11.83,11.83,0,0,1,109.63,88a11,11,0,0,1-2.83.35V87.6c-1.53-3.27,1.54-4.27,3-6l.65-.75,1.83,1.85-.45.44C111.6,83.4,112.54,85.19,112.71,85.85Z"
					/>
					<path
						className="cls-2"
						d="M111.31,64.59c5.07,8.94,5.22,9.22-.84,16.27l-.65.75c-1.48,1.72-4.55,2.72-3,6v.72A56.85,56.85,0,0,0,95.6,90c-1.1.22-2.2.45-3.3.65a6.09,6.09,0,0,0-2.48,1c-3.9,2.92-6.83,15.08-5.09,20a3.57,3.57,0,0,1,.14,1.34,4.63,4.63,0,0,1-.64,2.08c-1.08,1.67-5.2,5.44-6.53,6.94v.41c-2.92-.1-5.85-.26-8.77-.42l0-.36c.57-1.47,1.39-3.24,2.28-5.07,1.44-3,3.06-6.12,4.12-8.43,2.69-5.9,5.83-11.6,8.33-17.58.67-1.6,2.68-5.2-2.37-5-2.35.09-2.58-1.89-1.42-3.35a10.51,10.51,0,0,1,3.93-2.54c4.36-2,9.56-2.06,13-6s6.57-8.51,10.58-11.91l1.79-1.51.54.64C110,61.65,110.88,63.82,111.31,64.59Z"
					/>
					<path
						className="cls-2"
						d="M109.63,88l.07.24c-.5,1.5-1,4.16-2,5.35-5,6.59-9.47,12.25-14.61,18.77-1.89,2.41-5.48,1.28-7.79.65h-.47a3.57,3.57,0,0,0-.14-1.34h.49c1.67-2.67,4.7-6.72,6-9.55,1.63-3.53,3.1-8.13,4.43-11.79L95.6,90a56.85,56.85,0,0,1,11.2-1.65A11,11,0,0,0,109.63,88Z"
					/>
					<path
						className="cls-4"
						d="M95.6,90l.06.31c-1.33,3.66-2.8,8.26-4.43,11.79-1.31,2.83-4.34,6.88-6,9.55h-.49c-1.74-4.92,1.19-17.08,5.09-20a6.09,6.09,0,0,1,2.48-1C93.4,90.42,94.5,90.19,95.6,90Z"
					/>
					<path
						className="cls-1"
						d="M68.92,122c2.92.16,5.85.32,8.77.42,1.7.07,3.41.11,5.11.13,3.89,0,5.25,1.43,4,5.07-2,5.84-3.9,7.62-10,7.4-4-.14-7.89-.5-11.73-.93-2.9-.31-5.79-.67-8.77-1-3.22-.36-5.68-1.8-6-5.47-.36-4.23,1.45-7.88,5-8.93,3.91-1.14,11-1.74,15-2.57l.92.45c-.89,1.83-1.71,3.6-2.28,5.07Z"
					/>
					<path
						className="cls-5"
						d="M486,85.1c-7.43-9.47-15-15.59-29.27-21.38a128.43,128.43,0,0,0-23.48-7c-10.8-2.3-25.93-2.76-37-2.07-6.81.42-16.66,1.37-23.49.9-6.05-.42-10.06-6.33-13.28-12.27-5.21-9.59-11.82-19.67-19.73-27.19A38.87,38.87,0,0,0,316.85,5.27C294.11,2.79,290.43,2.79,273.3,2.2a375.34,375.34,0,0,0-48.48,1.64,231.31,231.31,0,0,0-40.42,7.33c-4.53,1.27-17.32,7.46-21.45,10.12"
					/>
					<path className="cls-5" d="M486.13,85.23l-.09-.12" />
					<path
						className="cls-6"
						d="M65.05,134.22a11.05,11.05,0,0,0-.66,7.27c.3,1.07,2.63,2.35,3.36,2.43,6.91.79,14,.35,20.45-2.49,7.94-3.52,14-9.66,18.44-17,5-8.27,10.48-16,18.63-21.4,21.33-14,56-8.92,66.45,20.48,2,6.27,2.91,11.82,4.47,19,1.27,5.15,2.53,6.84,7.05,7.56,35.07,0,66.33.39,100,.39,21.35,0,42.19,0,63,0,5.18.08,10,.36,15.54.75,5.7.41,12-1.37,15.33-7.32a35.5,35.5,0,0,0,3.61-8.94c3.7-12.55,8.94-24,20.5-31.52,13.47-8.74,31.65-9.46,46-2,7.45,3.66,12.16,9.31,16,15.64,4,7,5.73,14.27,10,20.88,1.45,2.25,5.4,2.85,6.73-2.77"
					/>
					<path
						className="cls-7"
						d="M499.1,109.29c7.41-6,3.6-14.66-4-18.3-.89-.43-4.72-2.52-6.95-3.62-.6-.29-1.08-.51-1.36-.61-7.95-2.82-8-3-16-5.66-1.6-.54-3.21-1-4.82-1.48a81,81,0,0,0-16.34-2.83,74.47,74.47,0,0,0-21.84,1.8c-15.53,3.63-29.15,10.56-40,22.5-9.4,10.33-15.07,22.46-18,36-.34,1.53-.8,3.66-1.3,5.16"
					/>
					<path
						className="cls-8"
						d="M208.49,143.25c8,0,16.2-.18,23.81-.18l20,.33c9.48.13,19,.21,28.45.15,26.61-.15,60.2-.56,86.92-1h.67"
					/>
					<path
						className="cls-7"
						d="M328.8,60.64c5.33-.14,10.67.09,16-.08s7.5-3.3,4.94-7.93c-3.59-6.47-7.61-12.7-11.44-19-6.77-11.2-18.39-12.35-29.52-13.33-13.93-1.22-28-1.24-42-1.63-5.15-.15-7.58,1.81-7.92,7-.62,9.31-1,18.66-1,28,0,5.52,2,7,7.4,7q27.5,0,55,0c1.8,0,1.35.06,7.74.06Z"
					/>
					<path
						className="cls-8"
						d="M61.8,121.6c2.37.11,4.74.25,7.12.38,2.92.16,5.85.32,8.77.42,1.7.07,3.41.11,5.11.13,3.89,0,5.25,1.43,4,5.07-2,5.84-3.9,7.62-10,7.4-4-.14-7.89-.5-11.73-.93-2.9-.31-5.79-.67-8.77-1-3.22-.36-5.68-1.8-6-5.47-.36-4.23,1.45-7.88,5-8.93,3.91-1.14,11-1.74,15-2.57"
					/>
					<path
						className="cls-8"
						d="M450.3,76.57l-.64,0c-9.62-.74-33.39-3.46-42.36-3.88C393.16,72,379,71,364.88,70.12c-9.36-.58-18.72-1.12-28.08-1.52h-3c-10.17-.17-20.33-.9-30.5-1-11-.08-22,0-33,0-5.17,0-10.24,0-15.64,0l-2.8,0h-.06c-5,.33-10,.34-15,.47-16.17.42-32.34.59-48.5,1.07-9.51.28-40.23,2.67-50,5.43"
					/>
					<path
						className="cls-8"
						d="M251.86,67.84c.16,23,.33,52,.5,75"
					/>
					<path
						className="cls-6"
						d="M162.85,21.33c-1.3.68-2.89,1.37-4.58,2.1-3.72,1.59-7.89,3.31-10.39,5.27q-13.95,11-27.63,22.24-5.56,4.59-11.08,9.24l-1.79,1.51c-4,3.4-7.1,7.9-10.58,11.91s-8.63,4.08-13,6a10.51,10.51,0,0,0-3.93,2.54c-1.16,1.46-.93,3.44,1.42,3.35,5-.18,3,3.42,2.37,5-2.5,6-5.64,11.68-8.33,17.58-1.06,2.31-2.68,5.46-4.12,8.43-.89,1.83-1.71,3.6-2.28,5.07"
					/>
					<path
						className="cls-8"
						d="M160.32,23.53c-1.42,3.17-3.45,7.2-6.1,9.48-7.61,6.58-15.9,12.41-23.32,19.2-5.73,5.24-6.27,3.25-10.1-.61"
					/>
					<path
						className="cls-8"
						d="M52.3,122.6c4.66,2.58,12,3.65,18,4.39,4.44.55,8,.11,12.49.11"
					/>
					<path
						className="cls-8"
						d="M508.8,124.1c-3.73,0-4.75,0-7.05,0-.88,0-2,0-3.45-.09a1.3,1.3,0,0,0-1.06.3,1.48,1.48,0,0,0-.42.77c-.46,6.9-.58,9.28,3.2,9.89a31.28,31.28,0,0,0,5.78.09,28.35,28.35,0,0,0,3-.48c6.79-1,9.86-4.41,7.89-11-.42-1.39-2-3.81-3.43-4.43-2.34-1-10-2.43-12.46-3.1"
					/>
					<path
						className="cls-9"
						d="M486,85.11a20.8,20.8,0,0,1,2.08,2.26c4.28,5.39,7.71,14.19,10.15,22.17.72,2.35,1.35,4.63,1.89,6.73.67,2.57,1.21,4.88,1.6,6.72"
					/>
					<path className="cls-5" d="M486,85.1h0" />
					<path className="cls-9" d="M485.84,84.93l.19.17" />
					<path
						className="cls-8"
						d="M109.7,88.21c-.5,1.5-1,4.16-2,5.35-5,6.59-9.47,12.25-14.61,18.77-1.89,2.41-5.48,1.28-7.79.65"
					/>
					<path
						className="cls-8"
						d="M109.71,60.82c.33.83,1.17,3,1.6,3.77,5.07,8.94,5.22,9.22-.84,16.27l-.65.75c-1.48,1.72-4.55,2.72-3,6"
					/>
					<path
						className="cls-8"
						d="M112.71,85.85c-.17-.66-1.11-2.45-.86-2.7l.45-.44c7.62-7.58,15.32-15.09,23-22.61l1-1c11.52-10.56,23.39-21.84,36.88-29.9,13.18-7.87,25-12.85,54.54-16.81,10.92-1.46,33.07-1.68,44.08-1.33,14,.45,28,1.28,42,2.73,6.68.69,13.75,1.54,19.5,5.84a45.91,45.91,0,0,1,8.41,8.54c5.47,8,9.6,16.17,14.55,24.46,3,5,5.39,10.3,8.06,15.47l.58,2,.42,1.48c.5,14.67,2.23,55.33,2.4,70"
					/>
					<path
						className="cls-7"
						d="M465.89,79.94l.09-.32a5.8,5.8,0,0,0,.16-.81,4.82,4.82,0,0,0-4.84-5.74,39.58,39.58,0,0,0-8.48.17c-1,.15-1.77,1.43-2.62,2.42-.13.15-.26.3-.4.44"
					/>
					<path
						className="cls-8"
						d="M208.45,142.82c-.5-4.18-.28-2.83-1.3-8.71-3.17-18.18-8.51-35.37-22.93-47.91C172.16,75.71,157.87,71.89,141.78,74c-1.19.15-2.36.36-3.51.6-9.23,2-17.1,6.67-24.9,11.64l-.32.2A11.83,11.83,0,0,1,109.63,88a11,11,0,0,1-2.83.35A56.85,56.85,0,0,0,95.6,90c-1.1.22-2.2.45-3.3.65a6.09,6.09,0,0,0-2.48,1c-3.9,2.92-6.83,15.08-5.09,20a3.57,3.57,0,0,1,.14,1.34,4.63,4.63,0,0,1-.64,2.08c-1.08,1.67-5.2,5.44-6.53,6.94"
					/>
					<path
						className="cls-8"
						d="M95.66,90.28c-1.33,3.66-2.8,8.26-4.43,11.79-1.31,2.83-4.34,6.88-6,9.55"
					/>
					<path
						className="cls-7"
						d="M232.2,18.6h0c-3.82.3-7.24.86-10.88,1.44-9.43,1.52-19.36,3.46-28.42,6.81C183,30.53,173.84,36,168.18,46c-5,8.78,1,15.66,8.62,15.55,21-.28,42-.57,63-1,5-.12,6.85-2.44,7-7.45.28-8.5.35-17,1.1-25.49.5-5.59-3.49-8.69-9.12-9.06a61,61,0,0,0-6.49,0h-.1"
					/>
					<line
						className="cls-7"
						x1="232.37"
						y1="18.6"
						x2="232.3"
						y2="18.6"
					/>
					<ellipse
						className="cls-8"
						cx="308.06"
						cy="105.93"
						rx="27"
						ry="25.58"
					/>
					<text
						className="cls-10"
						transform="translate(288.66 122) scale(0.75 1)"
					>
						11
					</text>
					<line
						className="cls-11"
						x1="294.75"
						y1="121.1"
						x2="306.72"
						y2="121.1"
					/>
					<line
						className="cls-11"
						x1="310.37"
						y1="121.1"
						x2="322.35"
						y2="121.1"
					/>
				</HerbieBody>
				<HerbieWheels>
					<circle
						className="cls-12"
						cx="150.36"
						cy="141.91"
						r="38.1"
					/>
					<circle
						className="cls-1"
						cx="150.54"
						cy="142.51"
						r="25.54"
					/>
					<circle
						className="cls-13"
						cx="150.91"
						cy="142.6"
						r="18.26"
					/>
					<circle
						className="cls-14"
						cx="446.3"
						cy="141.98"
						r="38.1"
					/>
					<circle
						className="cls-1"
						cx="446.48"
						cy="142.57"
						r="25.54"
					/>
					<circle
						className="cls-13"
						cx="446.85"
						cy="142.67"
						r="18.26"
					/>
				</HerbieWheels>
				<g>
					<SmokeOnePath
						className="cls-15"
						d="M22.2,150.46c.79,1.17,1.63,2.24,2.28,3.4a5.81,5.81,0,0,1-2.58,8.23c-4.25,2.16-10.54.39-13-3.67a8.36,8.36,0,0,1-1-6,4.62,4.62,0,0,0-3-.62,3.83,3.83,0,0,1-3.73-3,2,2,0,0,1,1.31-2.4c.83-.44,1.64-.91,2.64-1.46-.89-.73-1.54-1.31-2.24-1.82-2.36-1.74-3.07-4.19-2.89-7a4.13,4.13,0,0,1,2.14-3,11,11,0,0,1,4.65-1.74c3-.46,6,.68,7.35,4.79.26.82.4,1,1.31,1.32.34-.68.74-1.35,1-2.06,1.56-4.06,5.28-4.49,8.14-3.91,5.15,1,9.64,6.64,9.65,11.91a7.32,7.32,0,0,1-7.35,7.31,5.29,5.29,0,0,1-1.74-.45A3.13,3.13,0,0,0,22.2,150.46Z"
					/>
					<SmokeTwoPath
						className="cls-16"
						d="M20.61,133.69c.41-1.13.72-1.91,1-2.7a7.31,7.31,0,0,1,4.69-4.65,4.26,4.26,0,0,1,4.36.7,10.31,10.31,0,0,1,3.76,5.34,6.26,6.26,0,0,1-2.5,6.84c-.58.39-1.2.76-1.11,1.73.71.1,1.42.25,2.14.3,3.33.23,5.43,1.76,6.26,5.87,1.16,5.75-3.6,12.64-9.3,13.67a7.39,7.39,0,0,1-8-5.12,8.05,8.05,0,0,1-.06-1.89,2.74,2.74,0,0,0-1.16-2.44l-.59.83c-.5.68-1,1.4-1.5,2A5.87,5.87,0,0,1,9.15,154a10.83,10.83,0,0,1-.76-11.72,7.68,7.68,0,0,1,5-3.57l1-.23a4.61,4.61,0,0,0-.33-3A3.77,3.77,0,0,1,16,131a2.16,2.16,0,0,1,2.48.65C19.11,132.29,19.78,132.89,20.61,133.69Z"
					/>
					<SmokeThreePath
						className="cls-17"
						d="M23.24,139.82a43,43,0,0,1,2.29-4.51,6.18,6.18,0,0,1,8.35-1.92c4.61,2.56,6.7,9.73,4.2,14.37-1,1.9-2,2.7-5,4.23a4.82,4.82,0,0,0,1.15,3.24c1.2,1.63.22,4.75-1.68,5.44a2.59,2.59,0,0,1-2.36-.56l-2.54-1.48c-.2,1.37-.36,2.46-.51,3.54-.38,2.85-2.13,4.7-4.51,6a3.93,3.93,0,0,1-3.25.17,10.28,10.28,0,0,1-6.57-6.05c-1-2.52-.2-4.83,1.45-6.85.49-.6,1.1-1.1.79-2.1-.26,0-.56-.06-.85-.06a5.3,5.3,0,0,0-.69.06c-5.49.69-9-1.9-9.65-7.41a13.61,13.61,0,0,1,5.41-12.9c4.35-3.36,8.76-1.41,11.33,1.88a5.55,5.55,0,0,1,.82,2.34A3.4,3.4,0,0,0,23.24,139.82Z"
					/>
				</g>
			</g>
		</Svg>
	</HerbieSvg>
);

const HerbieLoader = (): JSX.Element => <Container>{Herbie}</Container>;
export default HerbieLoader;
