import React, { useCallback, useEffect, useState } from 'react';

import { type IntervalValue } from './types';

/**
 * In Firefox, clicking the up/down arrows in a type="text" input does focus the input. If we rely solely on
 * blur events to submit the form, using the arrows won't work. To support Firefox, we can detect the native
 * event and submit the form as other browsers would.
 */
function eventInsertedReplacementText(
	event: React.BaseSyntheticEvent<Event, EventTarget & HTMLInputElement>,
) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
	return (event.nativeEvent as any).inputType === 'insertReplacementText';
}

type InputValidation =
	| true
	| { lowerBoundError: string | null; upperBoundError: string | null };

export default function useIntervalFilter({
	defaultValue,
	onSubmit,
	validateInputs,
}: {
	defaultValue: IntervalValue | null;
	onSubmit: (value: IntervalValue<string>) => void;
	validateInputs: (
		lowerBound: string | null,
		upperBound: string | null,
	) => InputValidation;
}) {
	const defaultLowerBound = defaultValue?.lowerBound?.toString() ?? null;
	const [lowerBound, setLowerBound] = useState<string | null>(
		defaultLowerBound,
	);
	const defaultUpperBound = defaultValue?.upperBound?.toString() ?? null;
	const [upperBound, setUpperBound] = useState<string | null>(
		defaultUpperBound,
	);

	const [lowerBoundError, setLowerBoundError] = useState<string | null>(null);
	const [upperBoundError, setUpperBoundError] = useState<string | null>(null);

	useEffect(() => {
		setLowerBound(defaultLowerBound);
		setUpperBound(defaultUpperBound);
	}, [defaultLowerBound, defaultUpperBound]);

	const attemptSubmit = useCallback(
		({
			currentLowerBound,
			currentUpperBound,
		}: { currentLowerBound?: string; currentUpperBound?: string } = {}) => {
			const validation = validateInputs(
				currentLowerBound || lowerBound,
				currentUpperBound || upperBound,
			);
			if (validation === true) {
				setLowerBoundError(null);
				setUpperBoundError(null);
				onSubmit({
					lowerBound: currentLowerBound || lowerBound,
					upperBound: currentUpperBound || upperBound,
				});
			} else {
				setLowerBoundError(validation.lowerBoundError);
				setUpperBoundError(validation.upperBoundError);
			}
		},
		[lowerBound, onSubmit, upperBound, validateInputs],
	);

	const handleLowerBoundChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setLowerBound(event.target.value);
			if (eventInsertedReplacementText(event)) {
				attemptSubmit({ currentLowerBound: event.target.value });
			}
		},
		[attemptSubmit],
	);
	const handleUpperBoundChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setUpperBound(event.target.value);
			if (eventInsertedReplacementText(event)) {
				attemptSubmit({ currentUpperBound: event.target.value });
			}
		},
		[attemptSubmit],
	);

	const handleBlur = useCallback(() => {
		attemptSubmit();
	}, [attemptSubmit]);

	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				attemptSubmit();
			} else if (event.key === 'Escape') {
				event.currentTarget.blur();
			}
		},
		[attemptSubmit],
	);

	return {
		handleBlur,
		handleKeyDown,
		handleLowerBoundChange,
		handleUpperBoundChange,
		lowerBound,
		lowerBoundError,
		upperBound,
		upperBoundError,
	};
}
