// @flow

import React from 'react';
import './radio-filter-option.scss';

type Props = {
	checked: boolean,
	label: string,
	name: string,
	onChange: (value: Array<string>) => void,
	value: Array<string>,
};

export default class RadioSearchFilterOption extends React.Component<Props> {
	handleChange = () => {
		this.props.onChange(this.props.value);
	};

	render() {
		const { checked, label, name } = this.props;

		return (
			<label className="RadioSearchFilterOption">
				<input
					checked={checked}
					className="RadioSearchFilterOption-input"
					name={name}
					onChange={this.handleChange}
					type="radio"
				/>
				<span className="RadioSearchFilterOption-label">{label}</span>
			</label>
		);
	}
}
