import styled from 'styled-components';

import { white } from '../colors';

const ListSearchField = styled.input`
	background: ${white.string()}
		url('https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/ic_search.png');
	background-position: 8px 12px;
	background-repeat: no-repeat;
	background-size: 22px;
	box-shadow: 0 2px 4px 0 rgba(225, 225, 225, 0.5);
	border-radius: 5px;
	-webkit-appearance: none;
	padding: 12px 10px 12px 36px;
	border: none;
	font-size: inherit;
	min-width: 400px;

	&:focus {
		outline: none;
		box-shadow: 0 2px 3px 3px rgba(225, 225, 225, 0.6);
	}

	@media (max-width: 720px) {
		min-width: 100%;
		flex: 1;
		margin-bottom: 10px;
	}

	@media (max-width: 500px) {
		box-shadow: none;
	}
`;

export default ListSearchField;
