import numeral from 'numeral';
import React from 'react';

import * as api from '../../../utils/api';
import HerbieLoader from '../../herbie-loader';
import DiscreteBarChart from '../charts/discrete-bar-chart';
import GenericChart from '../charts/generic-chart';
import StatsDetailListItem from '../stats-detail-list-item';

const apiUrl = '/stats/semiannuals/portfolio_cash_available_by_company?fund=1';
let data = null;

const yAxisTickFormat = (d) => numeral(d).format('0.0a').toUpperCase();
const formatData = (dataArr) => [
	{
		key: 'Cash + Debt Available',
		values: dataArr
			.map((company) => ({
				label: company.fused_company__name,
				value:
					(company.cash_on_hand || 0) + (company.debt_available || 0),
			}))
			.sort((a, b) => b.value - a.value),
	},
];

export default class SemiAnnualFund1PortfolioCashAvailableByCompany extends React.Component {
	static getDetailCard() {
		return SemiAnnualFund1PortfolioCashAvailableByCompanyDetailCard;
	}

	static getKey() {
		return apiUrl;
	}

	fetchData = () => {
		return api.get(apiUrl).then(formatData);
	};

	handleDataFetch = (formattedData) => {
		data = formattedData;
	};

	render() {
		return (
			<GenericChart
				data={data}
				fetchData={this.fetchData}
				loadingComponent={HerbieLoader}
				margin={{ bottom: 75 }}
				onDataFetch={this.handleDataFetch}
				title="Current Portfolio Cash + Debt Available"
				xAxisLabel="Company"
				yAxisLabel="Available Cash + Debt"
				yAxisTickFormat={yAxisTickFormat}
			>
				{DiscreteBarChart}
			</GenericChart>
		);
	}
}

class SemiAnnualFund1PortfolioCashAvailableByCompanyDetailCard extends React.Component {
	static getKey() {
		return apiUrl;
	}

	render() {
		return (
			<StatsDetailListItem
				id={apiUrl}
				pictureUrl="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/stats/portfolio_cash_available_by_company.png"
				statName="Fund I Portfolio Cash + Debt Available by Company"
			/>
		);
	}
}
