import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import * as colors from '../../colors';
import Dropdown from '../../components/dropdown';
import { loadCandidatePools } from '../../talent/actions';
import type { CandidatePool } from '../../talent/reducers';
import {
	selectAreCandidatePoolsLoaded,
	selectCandidatePools,
} from '../../talent/selectors';
import type { CandidatePoolIndexApiResult } from '../../talent/types';
import { get } from '../../utils/api';
import fuzzyFilter from '../../utils/fuzzy-filter';
import reportError from '../../utils/sentry';
import { LoadingDots, WatchListIcon } from '../icons';

const StyledDropdown = styled(Dropdown)`
	margin-left: 12px;
	padding-top: 6px;
	z-index: 24; // One more than the largest z-index in src/table
`;

const DropdownBody = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 350px;
	min-height: 200px;
`;

const DropdownList = styled.ul`
	flex: 1;
	list-style-type: none;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-left: 0;
`;

const DropdownListItem = styled.li<{ selected: boolean }>`
	align-items: baseline;
	background-color: ${({ selected }) =>
		selected ? colors.alabasterWhite.string() : 'white'};
	color: ${({ selected }) =>
		selected ? colors.primaryDriveBlue.string() : 'inherit'};
	cursor: pointer;
	display: flex;
	flex-direction: row;
	padding: 6px 12px;
	transition: background-color 0.1s linear;

	@media (pointer: coarse) {
		padding: 12px 12px;
	}
`;

const DropdownSearch = styled.input`
	background-color: ${colors.alabasterWhite.string()};
	border: none;
	border-radius: none;
	font-size: inherit;
	margin: 0;
	outline: none;
	padding: 12px;
	width: 200px;
	height: 50px;
`;

const LoadingDotsContainer = styled.div`
	width: 100%;
	min-width: 200px;
	height: 100%;
	min-height: 50px;

	display: flex;
	justify-content: center;
	align-items: center;
`;
const StyledLoadingDots = styled(LoadingDots)`
	height: 30px;
	width: 30px;
	color: ${colors.charcoalGray.string()};
`;

const StyledWatchListIcon = styled(WatchListIcon)`
	height: 20px;
	width: 20px;
	fill: ${colors.charcoalGray.string()};

	&:hover {
		transition: all 0.15s ease-in-out;
		cursor: pointer;
		color: ${colors.black.string()};
		g {
			stroke-width: 4;
		}
	}
`;

function CandidatePoolItem({
	onClick,
	onMouseEnter,
	onMouseLeave,
	pool,
	selected,
}: {
	onClick: (id: number) => void;
	onMouseEnter: (id: number) => void;
	onMouseLeave: () => void;
	selected: boolean;
	pool: CandidatePool;
}): JSX.Element {
	return (
		<DropdownListItem
			onMouseLeave={onMouseLeave}
			onClick={() => onClick(pool.id)}
			onMouseEnter={() => onMouseEnter(pool.id)}
			selected={selected}
		>
			{pool.name.replace('Candidate Pool -', '')}
		</DropdownListItem>
	);
}

type ViewingState = 'CLICKED' | 'COPYING' | 'NORMAL';

interface Props {
	onCopy: (args: {
		candidatePoolId: number;
		jobOrderId: number;
	}) => Promise<void>;
}

function useComponent({ onCopy }: Props) {
	const [search, setSearch] = useState<string>('');
	const [selectedPoolId, setSelectedPoolId] = useState<number>(-1);
	const [viewingState, setViewingState] = useState<ViewingState>('NORMAL');
	const candidatePoolsAreLoaded = useSelector(selectAreCandidatePoolsLoaded);
	const candidatePools = useSelector(selectCandidatePools);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!candidatePoolsAreLoaded) {
			get<Array<CandidatePoolIndexApiResult>>('/talent/candidate-pools')
				.then((pools) => dispatch(loadCandidatePools(pools)))
				.catch(reportError);
		}
	}, [candidatePoolsAreLoaded, dispatch]);

	const handleControlClick = useCallback(() => {
		setViewingState((currentState) => {
			if (currentState === 'NORMAL') {
				return 'CLICKED';
			} else if (currentState === 'CLICKED') {
				return 'NORMAL';
			} else {
				return currentState;
			}
		});
	}, []);
	const handleClose = () => setViewingState('NORMAL');
	const handleItemClick = useCallback(
		async (poolId: number) => {
			const candidatePool = candidatePools.filter(
				(pool) => pool.id === poolId,
			)[0];
			if (!candidatePool || !candidatePool.job_order_id) return;

			setViewingState('COPYING');
			try {
				await onCopy({
					candidatePoolId: candidatePool.id,
					jobOrderId: candidatePool.job_order_id,
				});
				setSearch('');
				setSelectedPoolId(-1);
				setViewingState('NORMAL');
			} catch (error) {
				reportError(error as Error);
			}
		},
		[candidatePools, onCopy],
	);
	const handleItemMouseLeave = useCallback(() => {
		setSelectedPoolId(-1);
	}, []);
	const handleSearch = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			setSearch(event.currentTarget.value);
			setSelectedPoolId(-1);
		},
		[],
	);

	const filteredPools = useMemo(
		() => fuzzyFilter(candidatePools, search, (pool) => pool.name),
		[candidatePools, search],
	);
	const handleSearchKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			switch (event.key) {
				case 'ArrowDown': {
					event.preventDefault();
					const poolIndex = filteredPools.findIndex(
						(pool) => pool.id === selectedPoolId,
					);
					setSelectedPoolId(
						filteredPools[
							Math.min(poolIndex + 1, filteredPools.length - 1)
						].id,
					);
					break;
				}
				case 'ArrowUp': {
					event.preventDefault();
					const poolIndex = filteredPools.findIndex(
						(pool) => pool.id === selectedPoolId,
					);
					setSelectedPoolId(
						filteredPools[Math.max(poolIndex - 1, 0)].id,
					);
					break;
				}
				case 'Enter': {
					event.preventDefault();
					void handleItemClick(selectedPoolId);
					break;
				}
				default: {
					// do nothing
				}
			}
		},
		[filteredPools, handleItemClick, selectedPoolId],
	);

	return {
		candidatePools: filteredPools,
		handleClose,
		handleControlClick,
		handleItemClick,
		handleItemMouseEnter: setSelectedPoolId,
		handleItemMouseLeave,
		handleSearch,
		handleSearchKeyDown,
		search,
		selectedPoolId,
		viewingState,
	};
}

export default function CopyToCandidatePool(props: Props): JSX.Element {
	const {
		candidatePools,
		handleClose,
		handleControlClick,
		handleItemClick,
		handleItemMouseEnter,
		handleItemMouseLeave,
		handleSearch,
		handleSearchKeyDown,
		search,
		selectedPoolId,
		viewingState,
	} = useComponent(props);

	return (
		<StyledDropdown
			align="left"
			control={<StyledWatchListIcon onClick={handleControlClick} />}
			onClose={handleClose}
			open={viewingState !== 'NORMAL'}
		>
			<DropdownBody>
				{viewingState !== 'COPYING' ? (
					<DropdownSearch
						autoFocus
						onChange={handleSearch}
						onKeyDown={handleSearchKeyDown}
						placeholder="Search for candidate pools"
						value={search}
					/>
				) : (
					<LoadingDotsContainer>
						<StyledLoadingDots />
					</LoadingDotsContainer>
				)}
				<DropdownList>
					{candidatePools.map((candidatePool) => (
						<CandidatePoolItem
							key={candidatePool.id}
							selected={candidatePool.id === selectedPoolId}
							pool={candidatePool}
							onClick={handleItemClick}
							onMouseEnter={handleItemMouseEnter}
							onMouseLeave={handleItemMouseLeave}
						/>
					))}
				</DropdownList>
			</DropdownBody>
		</StyledDropdown>
	);
}
