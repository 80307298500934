import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

import { SortableHeader, sortDirections } from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';
import assertExhaustive from '../../utils/assert-exhaustive';
import noop from '../../utils/noop';
import useEditableField, {
	EditingState,
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from '../../utils/state-machine/editable-field';

type Data = string;

const textClassName = 'HerbieTable__cell__text';
const Cell = styled.td`
	line-height: 20px;
	overflow: hidden;
	white-space: nowrap;
`;

const ReadOnlyCell = styled.td`
	line-height: 20px;
	/*
	 * I know this says max-width, but it functions more like min-width when a
	 * cell's contents are too long. A column's intrinsic width starts at the
	 * width of its widest cell's contents. If the sum of the columns'
	 * intrinsic widths exceeds that of the container, the table will scroll
	 * horizontally. If there is additional horizontal space, the browser will
	 * distribute it among the columns. Since this column's contents are
	 * unknown, we want to prevent if from occupying too much space in a space-
	 * constrained layout, so we set its max width here. If the table needs to
	 * scroll, this column will not exceed the max width set here, and any cell
	 * contents that exceed this width will truncate with an ellipsis and a
	 * tooltip. If the browser has extra space to distribute, it will allocate
	 * it to this column, extending or eliminating the truncation point.
	 */
	max-width: 40ch;
	overflow: hidden;
	padding: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

// Cells often receive a border from table styling rules. Since we use the
// `border-box` box model, this would count against our minimum width if we
// applied it to the cell, so we instead apply the minimum width to the cell's
// contents.
const DeferredCellContents = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 0;
	min-width: 20ch;
	padding: 10px;
`;

const Input = styled.input.attrs({
	type: 'text',
})`
	appearance: none;
	background-color: transparent;
	border: none;
	font-size: inherit;
	line-height: 20px;
	margin: 0;
	min-width: 100%;
	overflow: hidden;
	padding: 10px;
	text-overflow: ellipsis;
	width: 20ch;
	white-space: nowrap;
`;

const SavingInput = styled(Input)`
	background-color: #eee;
`;

const SuccessInput = styled(Input)`
	background-color: #dfd;
`;

const ErrorText = styled.span`
	color: #800;
	overflow: hidden;
	padding: 5px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

type TextCellProps = {
	initialValue: Data;
	onChange: (value: Data, signal: AbortSignal) => Promise<Data>;
};

function EditableTextCell({ initialValue, onChange, ...props }: TextCellProps) {
	const [currentState, transition] = useEditableField<Data>();

	if (currentState instanceof NormalState) {
		return (
			<Cell {...props}>
				<Input
					// We know that a change won't happen here, but React
					// throws a warning if we have a controlled component
					// without a change handler.
					onChange={noop}
					onFocus={() => {
						transition(currentState.onEdit(initialValue));
					}}
					title={initialValue}
					value={initialValue}
				/>
			</Cell>
		);
	} else if (currentState instanceof EditingState) {
		return (
			<Cell {...props}>
				<Input
					onBlur={() => {
						transition(
							currentState.value === initialValue
								? currentState.onCancel()
								: currentState.onSubmit(onChange),
						);
					}}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						transition(currentState.onChange(event.target.value));
					}}
					onKeyDown={(
						event: React.KeyboardEvent<HTMLInputElement>,
					) => {
						switch (event.key) {
							case 'Enter':
								transition(currentState.onSubmit(onChange));
								return;
							case 'Escape':
								transition(currentState.onChange(initialValue));
								return;
							default: // Do nothing
						}
					}}
					value={currentState.value}
				/>
			</Cell>
		);
	} else if (currentState instanceof SavingState) {
		return (
			<Cell {...props}>
				<SavingInput
					disabled
					readOnly
					title={currentState.value}
					value={currentState.value}
				/>
			</Cell>
		);
	} else if (currentState instanceof SuccessState) {
		return (
			<Cell {...props}>
				<SuccessInput
					disabled
					readOnly
					title={currentState.value}
					value={currentState.value}
				/>
			</Cell>
		);
	} else if (currentState instanceof ErrorState) {
		return (
			<Cell {...props}>
				<ErrorText>{currentState.error.message}</ErrorText>
			</Cell>
		);
	} else {
		throw assertExhaustive(currentState);
	}
}

export default class TextColumn<T extends Record<string, any>>
	implements IColumn<T>
{
	name: string;
	private initialSortDirection: SortDirection;
	private select: (row: T) => Data;
	private update: null | ((value: Data) => Partial<T>);
	private _sort: null | ((direction: SortDirection, a: T, b: T) => number);

	constructor({
		name,
		select,
		update = null,
		sort = null,
		initialSortDirection = sortDirections.ascending,
	}: {
		name: string;
		select: (row: T) => Data;
		update?: null | ((value: Data) => Partial<T>);
		sort?: null | ((direction: SortDirection, a: T, b: T) => number);
		initialSortDirection?: SortDirection;
	}) {
		this.name = name;
		this.select = select;
		this.update = update;
		this._sort = sort;
		this.initialSortDirection = initialSortDirection;

		this.cell.displayName = 'TextColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ deferred, onChange, props, row }: CellArgs<T>) => {
		const className = classNames(props.className, textClassName);
		const value = this.select(row);

		const update = this.update;
		if (!update || !onChange) {
			return (
				<ReadOnlyCell {...props} title={value} className={className}>
					{value}
				</ReadOnlyCell>
			);
		}

		if (deferred) {
			return (
				<td {...props} title={value} className={className}>
					<DeferredCellContents>{value}</DeferredCellContents>
				</td>
			);
		}

		return (
			<EditableTextCell
				className={className}
				initialValue={value}
				onChange={async (newValue, signal) => {
					const patch = update(newValue);
					const response: T = await onChange(row, patch, signal);
					const updatedValue: Data = this.select(response);
					return updatedValue;
				}}
				{...props}
			/>
		);
	});

	header({ onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		return (
			<SortableHeader
				initialSortDirection={this.initialSortDirection}
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				{this.name}
			</SortableHeader>
		);
	}

	search(query: string, row: T): boolean {
		const value = this.select(row);
		return value.toLowerCase().includes(query);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		if (this._sort) return this._sort(direction, a, b);

		const aVal: Data = this.select(a);
		const bVal: Data = this.select(b);

		if (aVal === bVal) return 0;
		if (aVal === '') return 1;
		if (bVal === '') return -1;
		return direction === sortDirections.ascending
			? aVal.localeCompare(bVal)
			: bVal.localeCompare(aVal);
	}

	toCSV(row: T): string {
		const value = this.select(row);
		return value;
	}
}
