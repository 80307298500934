import React, { useCallback, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import {
	NewCheckIcon as UnstyledCheckIcon,
	LoadingDots as UnstyledLoadingDots,
	XIcon as UnstyledXIcon,
} from '../components/icons';
import type { StageTransitionMultiValueDataRule } from '../pipelines';
import { colors, fonts } from '../theme';

import useEditableTransitionDatum, {
	EditingState,
	ErrorState,
	NormalState,
	SavingState,
	SuccessState,
} from './use-editable-transition-datum';

const BORDER_HEIGHT = 1;
const BORDER_RADIUS = 4;

const Form = styled.form`
	${fonts.paragraph.paragraph};
	align-items: baseline;
	column-gap: 4px;
	display: grid;
	grid-template:
		'button button' 20px
		'label input' min-content
		/ min-content 1fr;
	position: relative;
`;
const Label = styled.label`
	color: ${colors.text.secondary};
	grid-area: label;
	overflow: hidden;
	padding-left: 5px;
	white-space: nowrap;
`;
const Input = styled.input<{ renderBorder: boolean }>`
	grid-area: input;
	color: ${colors.text.primary};
	margin-top: 0px;
	padding: 4px;
	outline: none;
	border-radius: ${BORDER_RADIUS}px;
	border-top-right-radius: ${({ renderBorder }) =>
		renderBorder ? '0px' : `${BORDER_RADIUS}px`};
	border: ${BORDER_HEIGHT}px solid
		${({ renderBorder }) =>
			renderBorder ? colors.button.secondaryBorder : 'transparent'};
	width: 100%;

	&:hover {
		cursor: text;
	}
`;
const SelectContainer = styled.div<{ renderBorder: boolean }>`
	grid-area: input;
	border-radius: ${BORDER_RADIUS}px;
	border-top-right-radius: ${({ renderBorder }) =>
		renderBorder ? '0px' : `${BORDER_RADIUS}px`};
	border: ${BORDER_HEIGHT}px solid
		${({ renderBorder }) =>
			renderBorder ? colors.button.secondaryBorder : 'transparent'};
	padding: 4px;
`;
const Select = styled.select`
	appearance: none;
	outline: none;
	color: ${colors.text.primary};
	border: none;
	background-color: transparent;
	width: 100%;
	white-space: normal;
	text-overflow: ellipsis;

	&:hover {
		cursor: pointer;
	}
`;
const Extra = styled.input`
	color: ${colors.text.primary};
	border: none;
	padding: 0px;
	padding-left: 4px;
	margin: 0px;
	outline: none;
	width: 100%;
	border-radius: ${BORDER_RADIUS}px;

	white-space: normal;
	text-overflow: ellipsis;
`;
const TextArea = styled.textarea<{ renderBorder: boolean }>`
	grid-area: input;
	color: ${colors.text.primary};
	resize: none;
	border-radius: ${BORDER_RADIUS}px;
	margin-top: 0;
	padding: 4px;
	border-top-right-radius: ${({ renderBorder }) =>
		renderBorder ? '0px' : `${BORDER_RADIUS}px`};
	border: ${BORDER_HEIGHT}px solid
		${({ renderBorder }) =>
			renderBorder ? colors.button.secondaryBorder : 'transparent'};
	outline: none;
	overflow: hidden;
	width: 100%;
	background: transparent;
`;
const ErrorMessage = styled.div`
	position: absolute;
	bottom: 100%;
	left: 0;

	width: 100%;
	border-radius: ${BORDER_RADIUS}px;

	background-color: ${colors.data.red.layerSubtle};
	color: ${colors.text.danger};
	text-align: center;
	padding: 8px;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 45%;
		border: solid 10px transparent;
		border-top-color: ${colors.data.red.layerSubtle};
		z-index: 2;
	}
`;

const IconButtonGroup = styled.div`
	grid-area: button;
	display: flex;
	justify-content: flex-end;
`;
const IconButton = styled.button`
	border: none;
	background-color: ${colors.button.secondary};

	border-top: 1px solid ${colors.button.secondaryBorder};

	&:first-child {
		border-left: 1px solid ${colors.button.secondaryBorder};
		border-top-left-radius: ${BORDER_RADIUS}px;
	}

	&:not(:first-child) {
		border-left: 1px solid ${colors.button.secondaryBorder};
	}

	&:last-child {
		border-right: 1px solid ${colors.button.secondaryBorder};
		border-top-right-radius: ${BORDER_RADIUS}px;
	}

	&:hover {
		cursor: pointer;
	}

	&:disabled {
		cursor: not-allowed;
	}

	> svg {
		fill: ${colors.icon.secondary};
	}
`;
const SaveIcon = styled(UnstyledCheckIcon)`
	height: 16px;
	width: 16px;
`;
const ResetIcon = styled(UnstyledXIcon)`
	height: 16px;
	width: 16px;
`;
const SavingIcon = styled(UnstyledLoadingDots)`
	height: 16px;
	width: 16px;
	margin: 1px 4px 0 4px;

	fill: ${colors.icon.secondary};
`;

function TextInput({
	disabled,
	name,
	onChange,
	onFocus,
	onKeyDown,
	renderBorder,
	value,
}: {
	disabled: boolean;
	name: string;
	onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onFocus: () => void;
	onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
	renderBorder: boolean;
	value: string | number;
}): JSX.Element {
	const textarea = useRef<HTMLTextAreaElement | null>(null);

	useLayoutEffect(() => {
		if (textarea.current) {
			textarea.current.style.height = '0px';

			const calculatedHeight =
				textarea.current.scrollHeight + BORDER_HEIGHT * 2;
			textarea.current.style.height = `${calculatedHeight}px`;
		}
	});

	return (
		<TextArea
			disabled={disabled}
			id={name}
			name={name}
			onChange={onChange}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			ref={textarea}
			renderBorder={renderBorder}
			placeholder="--"
			value={value}
		/>
	);
}

export type TransitionData =
	| {
			type: 'DATE';
			value: string | null;
			extra: null;
	  }
	| {
			type: 'TEXT';
			value: string | null;
			extra: null;
	  }
	| {
			type: 'NUMERIC';
			value: number | null;
			extra: null;
	  }
	| {
			type: 'MULTI_VALUE';
			value: string | null;
			extra: string | null;
	  };

interface Props {
	initialData: TransitionData;
	onEnter: (
		ruleId: number,
		type: TransitionData['type'],
		value: Exclude<TransitionData['value'], null>,
		extra: string | null,
	) => Promise<void>;
	ruleId: number;
	ruleOptions: StageTransitionMultiValueDataRule['options'];
	ruleTitle: string;
}

export default function EditableTransitionDatum({
	initialData,
	onEnter,
	ruleId,
	ruleOptions,
	ruleTitle,
}: Props): JSX.Element {
	const [formState, transitionFormState] =
		useEditableTransitionDatum<TransitionData>();
	const numericInput = useRef<HTMLInputElement | null>(null);
	const multiSelectExtraInput = useRef<HTMLInputElement | null>(null);
	const inputId = `rule-${ruleId}`;

	const handleChange = useCallback(
		(
			event: React.ChangeEvent<
				HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
			>,
		) => {
			if (formState instanceof EditingState) {
				const newValue = {
					...formState.value,
				};
				if (newValue.type === 'NUMERIC') {
					const parsed = parseFloat(event.target.value);
					if (Number.isNaN(parsed)) {
						newValue.value = null;
					} else {
						newValue.value = parsed;
					}
				} else {
					newValue.value = event.target.value;
				}
				transitionFormState(formState.onChange(newValue));
			}
		},
		[formState, transitionFormState],
	);
	const handleKeyDown = useCallback(
		(
			event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			if (event.key === 'Escape' && formState instanceof EditingState) {
				event.stopPropagation();
				event.preventDefault();
				event.currentTarget.blur();
				transitionFormState(formState.onCancel());
			}
		},
		[formState, transitionFormState],
	);

	const handleFocus = useCallback(() => {
		if (formState instanceof NormalState) {
			transitionFormState(formState.onEdit(initialData));
		}
	}, [formState, initialData, transitionFormState]);

	const handleReset = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			if (formState instanceof EditingState) {
				event.preventDefault();
				transitionFormState(formState.onCancel());
			}
		},
		[formState, transitionFormState],
	);

	const handleSubmit = useCallback(
		(event: React.SyntheticEvent) => {
			event.preventDefault();
			if (formState instanceof EditingState) {
				numericInput.current?.blur();
				multiSelectExtraInput.current?.blur();
				transitionFormState(
					formState.onSubmit(async (datum) => {
						await onEnter(
							ruleId,
							datum.type,
							datum.value === null ? '' : datum.value,
							datum.extra,
						);
						return {
							...datum,
						};
					}),
				);
			}
		},
		[formState, onEnter, ruleId, transitionFormState],
	);

	const currentData = 'value' in formState ? formState.value : initialData;
	const extraRequired =
		currentData.type === 'MULTI_VALUE'
		&& ruleOptions.find(({ value }) => value === currentData.value)
			?.requireExtra;
	const disabled =
		formState instanceof SavingState || formState instanceof SuccessState;
	const disableSubmit =
		currentData.type === 'DATE' && currentData.value === '';

	const cleanState =
		currentData.value === initialData.value
		&& currentData.extra === initialData.extra;

	const inputValue = currentData.value === null ? '' : currentData.value;

	return (
		<Form onReset={handleReset} onSubmit={handleSubmit}>
			<Label htmlFor={inputId}>{ruleTitle}:</Label>
			<IconButtonGroup>
				{formState instanceof EditingState && !cleanState && (
					<IconButton disabled={disableSubmit} type="submit">
						<SaveIcon />
					</IconButton>
				)}
				{formState instanceof EditingState && !cleanState && (
					<IconButton type="reset">
						<ResetIcon />
					</IconButton>
				)}
				{formState instanceof SavingState && (
					<IconButton disabled>
						<SavingIcon />
					</IconButton>
				)}
			</IconButtonGroup>
			{formState instanceof ErrorState && (
				<ErrorMessage>
					{' '}
					An error has occurred. Please try again.
				</ErrorMessage>
			)}
			{currentData.type === 'TEXT' && (
				<TextInput
					disabled={disabled}
					name={inputId}
					onChange={handleChange}
					onFocus={handleFocus}
					onKeyDown={handleKeyDown}
					renderBorder={
						!cleanState || formState instanceof SavingState
					}
					value={inputValue}
				/>
			)}
			{currentData.type === 'DATE' && (
				<Input
					disabled={disabled}
					id={inputId}
					name={inputId}
					onChange={handleChange}
					onFocus={handleFocus}
					renderBorder={
						!cleanState || formState instanceof SavingState
					}
					required
					type="date"
					value={inputValue}
				/>
			)}
			{currentData.type === 'NUMERIC' && (
				<Input
					disabled={disabled}
					id={inputId}
					name={inputId}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onFocus={handleFocus}
					ref={numericInput}
					placeholder="--"
					renderBorder={
						!cleanState || formState instanceof SavingState
					}
					step="any"
					required
					type="number"
					value={inputValue}
				/>
			)}
			{currentData.type === 'MULTI_VALUE' && (
				<SelectContainer
					renderBorder={
						!cleanState || formState instanceof SavingState
					}
				>
					<Select
						disabled={disabled}
						id={inputId}
						name={inputId}
						onChange={handleChange}
						onFocus={handleFocus}
						value={inputValue}
					>
						{ruleOptions.map(({ label, value }) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</Select>
					{extraRequired && (
						<Extra
							autoFocus
							disabled={disabled}
							onChange={(event) => {
								if (formState instanceof EditingState) {
									transitionFormState(
										formState.onChange({
											...currentData,
											extra: event.target.value,
										}),
									);
								}
							}}
							onFocus={handleFocus}
							onKeyDown={handleKeyDown}
							placeholder="Please specify"
							ref={multiSelectExtraInput}
							required
							value={currentData.extra || ''}
						/>
					)}
				</SelectContainer>
			)}
		</Form>
	);
}
