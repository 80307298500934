import d3 from 'd3';
import identity from 'lodash.identity';
import nv from 'nvd3';
import type { Margin, Nvd3Element } from 'nvd3';
import React, { Component } from 'react';

import './line-chart.scss';

type Props = {
	data: Array<{
		key: string;
		values: Array<ValueData>;
	}>;
	forceY?: number | Array<number>;
	margin?: Margin;
	options?: Record<string, unknown>;
	title?: string;
	xAxisLabel?: string;
	xAxisTickFormat?: (axis: string | number) => string;
	yAxisLabel?: string;
	yAxisTickFormat?: (axis: string | number) => string;
} & typeof defaultProps;

const defaultProps = {
	margin: {},
	options: {},
	title: '',
	xAxisLabel: '',
	xAxisTickFormat: identity,
	yAxisLabel: '',
	yAxisTickFormat: identity,
};

interface ValueData {
	label: string;
	value: number;
}

export default class LineChart extends Component<Props> {
	static defaultProps = defaultProps;

	buildLineChart = (ref: SVGSVGElement | null): void => {
		if (!ref) return;

		nv.addGraph((): Nvd3Element => {
			const chart = nv.models
				.lineChart()
				.margin(this.props.margin)
				.options(this.props.options);
			const {
				title,
				xAxisLabel,
				xAxisTickFormat,
				yAxisLabel,
				yAxisTickFormat,
			} = this.props;

			chart.xAxis.axisLabel(xAxisLabel).tickFormat(xAxisTickFormat);
			chart.yAxis.axisLabel(yAxisLabel).tickFormat(yAxisTickFormat);

			if (typeof this.props.forceY !== 'undefined') {
				chart.forceY(
					Array.isArray(this.props.forceY)
						? this.props.forceY
						: [this.props.forceY],
				);
			}

			if (ref.parentElement) {
				chart.tooltip.chartContainer(ref.parentElement);
			}

			const selectedChart = d3
				.select(ref)
				.datum(this.props.data)
				.call(chart);

			// Get Chart width the top spacing for title alignment
			const { width, top } = ref.getBoundingClientRect();

			selectedChart
				.append('text')
				.attr('x', width / 2)
				.attr('y', top / 5)
				.attr('font-size', 16)
				.attr('text-anchor', 'middle')
				.text(title);

			nv.utils.windowResize(() => chart.update());
			return chart;
		});
	};

	render() {
		return <svg className="LineChart" ref={this.buildLineChart} />;
	}
}
