import { useRef } from 'react';

import reportError from '../../utils/sentry';

interface Options<T> {
	/**
	 * Provide a custom heuristic that allows intentional logical changes while
	 * still warning for referentially unequal values that are logically equal.
	 */
	isAcceptableChange?: (previousValue: T, currentValue: T) => boolean;
}

function defaultIsAcceptableChange() {
	return false;
}

function useStableRef<T>(
	value: T,
	name: string,
	{ isAcceptableChange = defaultIsAcceptableChange }: Options<T> = {},
): void {
	const previousValue = useRef<T>(value);
	/**
	 * We only want to trigger on the first change to avoid spamming the
	 * console with duplicate alerts.
	 */
	const triggered = useRef<boolean>(false);

	if (
		!triggered.current
		&& value !== previousValue.current
		&& !isAcceptableChange(previousValue.current, value)
	) {
		triggered.current = true;
		reportError(
			new Error(
				`\`${name}\` changed between renders but should be constant.`,
			),
		);
	}

	previousValue.current = value;
}

/**
 * In production, we don't want to pay the performance cost for this check, so
 * we turn it into a no-op with the same type signature.
 */
function useStableRefProduction<T>(
	/* eslint-disable @typescript-eslint/no-unused-vars -- These are intentionally ignored. */
	value: T,
	name: string,
	options?: Options<T>,
	/* eslint-enable no-unused-vars */
): void {
	// noop in production
}

export default process.env.NODE_ENV === 'production'
	? useStableRefProduction
	: useStableRef;
