import * as Sentry from '@sentry/react';

import { getUser } from '../authentication';

export default function reportError(error: unknown, customMsg = ''): void {
	const user = getUser();
	if (user) {
		Sentry.setUser({ ID: user.id, email: user.email });
	}
	console.error(customMsg, error);
	Sentry.captureException(error);
}
