const keys = {
	TAB: 9,
	ENTER: 13,
	ESC: 27,
	LEFT: 37,
	UP: 38,
	RIGHT: 39,
	DOWN: 40,
};

export default keys;
