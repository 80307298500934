import React, { useState } from 'react';

import '../views/Login.scss';
import { trackEvent } from '../utils/analytics';
import { login } from '../utils/api';
import type { ResponseError } from '../utils/api';
import useAbortSignal from '../utils/hooks/use-abort-signal';
import useAnalytics from '../utils/hooks/use-analytics';
import reportError from '../utils/sentry';

interface Props {
	emailOrUsername: string;
	// Redux login
	login: (token: string) => void;
	password: string;
}

interface State {
	code: string;
	submitting: boolean;
	errorMessage: string | null;
}

export default function TwoFactorForm(props: Props): JSX.Element {
	const [state, setState] = useState<State>({
		code: '',
		submitting: false,
		errorMessage: null,
	});
	const signal = useAbortSignal();
	useAnalytics({
		eventName: 'Prompted For Security Code',
		componentIdentifier: 'two-factor-form',
		viewType: 'login',
	});

	return (
		<form
			className="Login-form"
			onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
				event.preventDefault();
				setState({
					...state,
					errorMessage: null,
					submitting: true,
				});
				login(
					props.emailOrUsername,
					props.password,
					state.code,
					signal,
				).then(
					({ token }) => {
						trackEvent(
							'Attempt Two Factor',
							'two-factor-form',
							'login',
							{
								success: true,
							},
						);
						props.login(token);
					},
					(error: ResponseError) => {
						if (error.name !== 'AbortError') {
							trackEvent(
								'Attempt Two Factor',
								'two-factor-form',
								'login',
								{ success: false },
							);
							reportError(error);
							setState({
								...state,
								submitting: false,
								errorMessage:
									'That code is incorrect or expired.',
							});
						}
					},
				);
			}}
		>
			<div className="Form-container">
				<p className="Login-message">
					Enter your two-factor verification code.
				</p>
				<input
					autoComplete="one-time-code"
					autoFocus
					disabled={state.submitting}
					className="Login-form-input"
					maxLength={6}
					minLength={6}
					pattern="\d*"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setState({
							...state,
							code: event.target.value,
						});
					}}
					placeholder="Verification code"
					type="text"
				/>
				<input
					className="Login-form-submit"
					type="submit"
					value="Verify"
					disabled={state.submitting}
				/>
				{state.errorMessage && (
					<p className="Login-message Login-error">
						{state.errorMessage}
					</p>
				)}
			</div>
		</form>
	);
}
