import styled from 'styled-components';

import { backgroundGray } from '../colors';

const Body = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	background-color: ${backgroundGray.string()};
	min-height: 0px;
`;

export default Body;
