import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { type CompanyOutreach } from './types';
import { useEmailThreadUrl, useOpenEmailThread } from './utils';

interface Props {
	analyticsComponentName: string;
	className?: string;
	companyOutreach: CompanyOutreach;
	onClick?: () => void;
}

export default React.forwardRef(function OpenEmailLink(
	{ analyticsComponentName, className, companyOutreach, onClick }: Props,
	ref: React.ForwardedRef<HTMLAnchorElement>,
) {
	const openEmailThread = useOpenEmailThread();
	const emailThreadUrl = useEmailThreadUrl();

	const handleOpenEmailClick = useCallback(() => {
		openEmailThread(
			companyOutreach.id,
			companyOutreach.outreachLinkBase,
			false,
			analyticsComponentName,
		);
		onClick?.();
	}, [
		analyticsComponentName,
		companyOutreach.id,
		companyOutreach.outreachLinkBase,
		onClick,
		openEmailThread,
	]);
	const url = emailThreadUrl(companyOutreach.outreachLinkBase);

	if (!url) return null;

	const linkProps = url.opensNewTab
		? { target: '_blank', rel: 'noopener noreferrer' }
		: {};

	return (
		<Link
			className={className}
			onClick={handleOpenEmailClick}
			ref={ref}
			to={url.url}
			{...linkProps}
		>
			Open in email
		</Link>
	);
});
