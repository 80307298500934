import React, { useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';
import {
	isRouteErrorResponse,
	Navigate,
	Outlet,
	useLocation,
	useMatch,
	useRouteError,
} from 'react-router-dom';
import styled from 'styled-components';

import ErrorFallback from '../components/error-fallback';
import FourOhFourPage from '../components/four-oh-four-page';
import Header from '../components/header';
import View from '../components/view';
import { selectUser } from '../selectors';
import reportError from '../utils/sentry';

const StyledErrorFallback = styled(ErrorFallback)`
	padding-top: 60px;
`;

interface ReferrerLocationState {
	referrer: string;
}

const hasReferrerState = (
	state: unknown | null,
): state is ReferrerLocationState =>
	(state as ReferrerLocationState)?.referrer != null;

interface Props {
	unauthenticatedPaths: Array<string>;
}

function useMatches(patterns: Array<string>): Array<boolean> {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	return patterns.map((pattern) => useMatch(pattern) != null);
}

export default function Root({ unauthenticatedPaths }: Props) {
	const user = useSelector(selectUser);
	const routerLocation = useLocation();
	const referrer = hasReferrerState(routerLocation.state)
		? routerLocation.state.referrer
		: null;
	const pathname = routerLocation.pathname;
	const matches = useMatches(unauthenticatedPaths);

	const isLoggedIn = user != null;
	const isUnauthenticatedPath = matches.some((v) => v);

	const redirectToLogin = !isLoggedIn && !isUnauthenticatedPath;
	if (redirectToLogin) {
		return <Navigate state={{ referrer: pathname }} to="login" replace />;
	}

	const redirectToReferrer =
		isLoggedIn && referrer != null && referrer.indexOf('logout') < 0;
	if (redirectToReferrer) {
		return <Navigate to={referrer} replace />;
	}
	const redirectHome =
		!redirectToReferrer && isLoggedIn && isUnauthenticatedPath;
	if (redirectHome) {
		return <Navigate to="/" />;
	}

	return (
		<DocumentTitle title="Herbie">
			<Outlet />
		</DocumentTitle>
	);
}

export function ErrorPage() {
	const error = useRouteError();
	const user = useSelector(selectUser);
	const routerError = isRouteErrorResponse(error) && error.status === 404;

	useEffect(() => {
		if (!routerError && user) {
			reportError(error);
		}
	}, [error, routerError, user]);

	if (routerError) {
		return <FourOhFourPage />;
	}

	console.error(error);
	if (!user) {
		return <Navigate to="/login" />;
	}

	return (
		<View>
			<Header />
			<StyledErrorFallback />
		</View>
	);
}
