import type { ComponentType, ReactNode } from 'react';

import type { SortDirection } from './sort';
import { stickiness } from './stickiness';
import type { Stickiness } from './stickiness';

export type HeaderProps = Record<string, any>;

export interface AddPickerResult {
	id: number;
	name: string;
	type: 'companies' | 'people';
}

export interface HeaderArgs {
	itemsSelected: 'NONE' | 'SOME' | 'ALL';
	onAdd?: (value: AddPickerResult) => void;
	onSelect?: (selection: 'ALL' | 'NONE') => void;
	onSort: (direction: SortDirection) => void;
	props: HeaderProps;
	sort: SortDirection | null;
	sortIndex: number | null;
}

export type CellProps = Record<string, any> & { className?: string };

export interface CellArgs<T> {
	// When `deferred` is `true`, the cell has not yet been shown to the user,
	// and it may optionally choose to switch to take some rendering shortcuts
	// for the sake of initial rendering performance. For example, it can wait
	// to lazy-load an image on demand, and interactive cells can show a read-
	// only representation without setting up any of the state and handlers.
	// The only constraint is that the deferred and visible outputs should have
	// the same dimensions to prevent table layout jumping as cells become
	// visible.
	readonly deferred: boolean;
	readonly focused: boolean;
	readonly index: number;
	readonly onChange?: (
		row: T,
		patch: Partial<T>,
		signal: AbortSignal,
	) => Promise<T>;
	readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	readonly onFocus?: (index: number | null) => void;
	readonly onRowKeyDown?: (
		event: React.KeyboardEvent<HTMLInputElement>,
		row: T,
	) => void;
	readonly onSelect?: (selection: {
		rowKey: React.Key;
		selected: boolean;
	}) => void;
	readonly props: CellProps;
	readonly row: T;
	readonly rowKey: React.Key;
	readonly selected: boolean;
}

export type FooterProps = Record<string, any>;

export interface FooterArgs<T> {
	props: FooterProps;
	rows: Array<T>;
}

// Declaring the types this way permits all columns to specify a width, but all
// sticky columns must specify a width.
interface Position {
	sticky?: Stickiness;
	width: number;
}

export interface IColumn<T> {
	// Properties
	readonly position?: Position;
	readonly name?: string;

	// Presentation
	readonly header?: (args: HeaderArgs) => JSX.Element;
	readonly cell: ComponentType<CellArgs<T>>;
	readonly footer?: (
		args: FooterArgs<T>,
	) => ReactNode | { [name: string]: ReactNode };

	// Behavior
	readonly search?: (query: string, row: T) => boolean;
	readonly sort?: (direction: SortDirection, a: T, b: T) => number;
	readonly toCSV?: (row: T) => string;
}

export function isStickyLeft<T>(column: IColumn<T>): boolean {
	return (
		column.position != null && column.position.sticky === stickiness.left
	);
}
