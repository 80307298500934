import { hellip } from './chars';

/*
 * When a search matches words in long content like descriptions and notes,
 * the few highlighted words get lost among all the others in the paragraph. We
 * want to show the highlighted words with just enough context that they make
 * sense. Common terms for this sort of algorithm include excerpt, fragment, or
 * snippet.
 *
 * A naive algorithm would include the match and a hard-coded number of
 * additional words before and after. This can lead to awkward truncations. For
 * example, if the match occurs near the beginning of a sentence, there's no
 * need to return the last few words of the preceding sentence that aren't
 * relevant to the match's sentence and likely won't make sense on their own
 * anyway. Suboptimal truncation makes it harder for readers to understand the
 * match's context at a glance because they start reading a trailing sentence
 * fragment, can't understand it, throw it away, and finally get to the actual
 * match's sentence.
 *
 * Algolia supports snippeting, but experiments with their algorithm show it to
 * be fairly naive, regularly truncating in places that won't make sense to
 * users. More advanced algorithms exist in search-focused server-side tools
 * like Lucene and Solr, but I was not able to find an open-source client-side
 * implementation.
 *
 * This algorithm works by expanding from either side of a match looking for
 * boundaries where truncation could reasonably occur. After factoring in a
 * length penalty, it truncates at the boundary with the lowest cost. For
 * example, an excerpt might include an entire sentence if it's short, but a
 * longer sentence would break at a clause boundary. Parsing the text using NLP
 * would be most accurate but is expensive to client-side at runtime. Instead,
 * we'll look for common punctuation and words and hope that's good enough.
 */

/*******************************************************************************
 * DATA TYPES
 ******************************************************************************/

const BOUNDARY = {
	/** e.g. colon or semicolon */
	clause: 'clause',
	/** comma followed by whitespace */
	comma: 'comma',
	/** but, and, because, while, etc. */
	conjunction: 'conjunction',
	/** a special boundary, the edge of another excerpt */
	excerpt: 'excerpt',
	/** new lines */
	line: 'line',
	/** at, by, for, on, to, with, etc. */
	preposition: 'preposition',
	/** period followed by whitespace and a capital letter */
	sentence: 'sentence',
	/** beginning or end of the string */
	string: 'string',
	/** words separated by whitespace */
	word: 'word',
} as const;

type BoundaryType = keyof typeof BOUNDARY;

interface Boundary {
	/**
	 * The boundary's position is in characters relative to the edge of the
	 * match, so a boundary before the match will have a negative position.
	 */
	position: [number, number];
	type: BoundaryType;
}

const TOKEN = {
	clause: 'clause',
	comma: 'comma',
	conjunction: 'conjunction',
	line: 'line',
	number: 'number',
	other: 'other',
	preposition: 'preposition',
	punctuation: 'punctuation',
	sentence: 'sentence',
	space: 'space',
	word: 'word',
} as const;

type TokenType = keyof typeof TOKEN;

interface Token {
	range: [number, number];
	type: TokenType;
	value: string;
}

/*******************************************************************************
 * PUBLIC API
 ******************************************************************************/

/**
 * Select contextual excerpts around matches.
 *
 * The input and output arrays have context excerpts in the even indices and
 * matched text in the odd indices. The output array may not be the same size as
 * the input. Arrays must always have an odd number of elements; they must start
 * and end with context excerpt text. Excerpt text may be empty if a match abuts
 * an excerpt boundary.
 */
export default function excerpt(input: Array<string>): Array<string> {
	// We must have at least [excerpt, match, excerpt] to excerpt match context.
	if (input.length === 1) {
		return input;
	}

	// We can have additional repeating [..., match, excerpt] strings.
	if (input.length % 2 !== 1) {
		throw new RangeError(
			'Input array must start and end with excerpt text.',
		);
	}

	// Reassemble the original raw string for tokenization.
	const originalString = input.join('');
	const boundaries = findBoundaries(originalString);

	const output = [];

	// Keep track of our index in the original string.
	let characterIndex = input[0].length;

	// First excerpt the prefix string before the first match.
	output.push(
		originalString
			.slice(excerptBefore(boundaries, characterIndex), characterIndex)
			.trimStart(),
	);

	// Now iterate each region between matches.
	for (let i = 2; i + 2 < input.length; i += 2) {
		// Push the previous match.
		output.push(input[i - 1]);
		characterIndex += input[i - 1].length;

		// Excerpt betwen the previous and next matches.
		const cutBetween = excerptBetween(
			boundaries,
			characterIndex,
			characterIndex + input[i].length,
		);
		// If we don't have anything to cut, use the whole between string.
		if (cutBetween == null) {
			output.push(input[i]);
		} else {
			// Cut this between string into after context for the previous match
			// and before context for the next match.
			const [afterContextEndIndex, beforeContextStartIndex] = cutBetween;
			const afterContext = originalString
				.slice(characterIndex, afterContextEndIndex)
				.trimEnd();
			const beforeContext = originalString
				.slice(
					beforeContextStartIndex,
					characterIndex + input[i].length,
				)
				.trimStart();

			output.push(`${afterContext}${hellip} ${beforeContext}`);
		}

		characterIndex += input[i].length;
	}

	// Push the last match
	output.push(input[input.length - 2]);
	characterIndex += input[input.length - 2].length;
	// Finally push the last excerpt suffix.
	output.push(
		originalString
			.slice(characterIndex, excerptAfter(boundaries, characterIndex))
			.trimEnd(),
	);

	return output;
}

/*******************************************************************************
 * PRIVATE API
 ******************************************************************************/

/** @private */
function excerptBefore(boundaries: Array<Boundary>, endIndex: number): number {
	let bestCost = Number.POSITIVE_INFINITY;
	let bestBoundaryIndex = null;
	// Linearly find the lowest-cost boundary within our end index.
	for (
		let i = 0;
		i < boundaries.length && boundaries[i].position[1] <= endIndex;
		i++
	) {
		const currentCost = cost(endIndex, boundaries[i], 1);
		if (currentCost < bestCost) {
			bestCost = currentCost;
			bestBoundaryIndex = i;
		}
	}

	// If we weren't able to find a suitable boundary, return the whole before.
	if (bestBoundaryIndex == null) {
		return endIndex;
	}

	return boundaries[bestBoundaryIndex].position[1];
}

/** @private */
function excerptBetween(
	boundaries: Array<Boundary>,
	startIndex: number,
	endIndex: number,
): [number, number] | null {
	const boundariesBetween = boundaries.filter(
		(boundary) =>
			startIndex <= boundary.position[0]
			&& boundary.position[1] <= endIndex,
	);

	const leftContextEndIndex = excerptAfter(boundariesBetween, startIndex);
	const rightContextStartIndex = excerptBefore(boundariesBetween, endIndex);

	/** How many extra characters is it worth to combine two excerpts together
	 * rather than cutting text between them? */
	const excerptStitchingValue = 10;

	// If our excerpts are far apart, cut the text between them.
	if (leftContextEndIndex + excerptStitchingValue < rightContextStartIndex) {
		return [leftContextEndIndex, rightContextStartIndex];
	}

	// If our excerpts overlap or are close enough together to combine, don't
	// cut at all.
	return null;
}

/** @private */
function excerptAfter(boundaries: Array<Boundary>, startIndex: number): number {
	let i = 0;
	// Seek linearly through the list for the first boundary after our start
	// index. If this is slow, do a binary search instead.
	while (i < boundaries.length && boundaries[i].position[0] < startIndex) {
		i++;
	}

	// Now find the lowest-cost remaining boundary.
	let bestCost = Number.POSITIVE_INFINITY;
	let bestBoundaryIndex = null;
	for (; i < boundaries.length; i++) {
		const currentCost = cost(startIndex, boundaries[i], 0);
		if (currentCost < bestCost) {
			bestCost = currentCost;
			bestBoundaryIndex = i;
		}
	}

	// If we weren't able to find a suitable boundary, return the whole after.
	if (bestBoundaryIndex == null) {
		return startIndex;
	}

	return boundaries[bestBoundaryIndex].position[0];
}

/**
 * How many extra characters is it worth to reach each type of boundary?
 *
 * These values are starting points and should be tuned empirically toward
 * whatever feels like the best result.
 */
const boundaryValues = {
	[BOUNDARY.string]: 70,
	[BOUNDARY.line]: 60,
	[BOUNDARY.excerpt]: 55,
	[BOUNDARY.sentence]: 50,
	[BOUNDARY.clause]: 30,
	[BOUNDARY.conjunction]: 20,
	[BOUNDARY.comma]: 20,
	[BOUNDARY.preposition]: 10,
	[BOUNDARY.word]: 0,
};

/** @private */
function cost(characterIndex: number, boundary: Boundary, side: 0 | 1): number {
	/**
	 * If all reasonably-close boundaries are of the same type, how many
	 * characters of context should we provide? For example, if we just have
	 * a bunch of regular words, how many characters worth of words should we
	 * include? Assume that words are ~5 letters plus 1 space.
	 */
	const desiredContextCharacters = 20; // ~4 words

	const lengthIfTruncatedHere = Math.abs(
		boundary.position[side] - characterIndex,
	);
	const lengthCost = Math.abs(
		lengthIfTruncatedHere - desiredContextCharacters,
	);

	return lengthCost - boundaryValues[boundary.type];
}

/** @private */
export function findBoundaries(string: string): Array<Boundary> {
	const boundaries: Array<Boundary> = [
		{ position: [0, 0], type: BOUNDARY.string },
	];

	const tokens = tokenize(string);

	let i = 0;
	while (i < tokens.length) {
		const token = tokens[i];
		switch (token.type) {
			case TOKEN.clause: {
				// Try to match `clause space`. If followed by a line token or
				// the end of the string, the line or string boundary will take
				// precedence anyway, and we won't care about the clause
				// boundary. If followed by anything else, it doesn't look like
				// an actual clause boundary.
				const nextToken = tokens[i + 1];
				if (nextToken && nextToken.type === TOKEN.space) {
					boundaries.push({
						// The previous sentence ends at the beginning of the
						// following space, and the next sentence begins at the
						// end of the space.
						position: nextToken.range,
						type: BOUNDARY.clause,
					});

					// Advance past the space token because we matched it.
					i++;
				}
				break;
			}

			case TOKEN.comma:
				// TODO: Should this match anything about following tokens?
				boundaries.push({
					position: token.range,
					type: BOUNDARY.comma,
				});
				break;

			case TOKEN.conjunction:
				// TODO: Should this match anything about surrounding tokens?
				boundaries.push({
					position: token.range,
					type: BOUNDARY.conjunction,
				});
				break;

			case TOKEN.line:
				// Match `line`
				boundaries.push({
					position: token.range,
					type: BOUNDARY.line,
				});
				break;

			case TOKEN.number:
				// Numbers are treated like regular word boundaries.
				boundaries.push({
					position: token.range,
					type: BOUNDARY.word,
				});
				break;

			case TOKEN.other:
				// Do nothing. We wouldn't even know what type of boundary to
				// create.
				break;

			case TOKEN.preposition:
				boundaries.push({
					// We don't know where the prepositional phrase ends, so set
					// a zero-width boundary at its start. If this were full NLP
					// rather than a quick-and-dirty hack, we'd have a sentence
					// graph and be able to include the whole propositional
					// phrase.
					position: [token.range[0], token.range[0]],
					type: BOUNDARY.preposition,
				});
				break;

			case TOKEN.punctuation:
				// Do nothing. Punctuation by itself does not create a boundary.
				break;

			case TOKEN.sentence: {
				// Try to match `sentence space`. If followed by a line token or
				// the end of the string, the line or string boundary will take
				// precedence anyway, and we won't care about the sentence
				// boundary. If followed by anything else, it doesn't look like
				// an actual sentence boundary. "E.B. White" would have a false
				// positive in the middle, but this is quick and dirty.
				// TODO: Should this allow optional trailing punctuation?
				const nextToken = tokens[i + 1];
				if (nextToken && nextToken.type === TOKEN.space) {
					boundaries.push({
						// The previous sentence ends at the beginning of the
						// following space, and the next sentence begins at the
						// end of the space.
						position: nextToken.range,
						type: BOUNDARY.sentence,
					});

					// Advance past the space token because we matched it.
					i++;
				}
				// If no match, this isn't an actual sentence. Do nothing.
				break;
			}

			case TOKEN.space:
				// Do nothing. Spaces do not create boundaries.
				break;

			case TOKEN.word:
				boundaries.push({
					position: token.range,
					type: BOUNDARY.word,
				});
				break;

			default: // Do nothing.
		}

		// Advance to the next token.
		i++;
	}

	if (i !== tokens.length) {
		throw new Error('Failed to read entire token stream.');
	}

	boundaries.push({
		position: [string.length, string.length],
		type: BOUNDARY.string,
	});

	return boundaries;
}

/*******************************************************************************
 * TOKENIZER
 ******************************************************************************/

/** A non-exhaustive selection of common conjunction words. */
const conjunctions = new Set([
	// Coordinating
	'and',
	'but',
	'for',
	'nor',
	'or',
	'so',
	'yet',
	// Subordinating
	'after',
	'although',
	'because',
	'before',
	'if',
	'since',
	'though',
	'unless',
	'until',
	'when',
	'whenever',
	'where',
	'wherever',
	'whether',
	'while',
]);

/** A non-exhaustive selection of sommon common preposition words. */
const prepositions = new Set([
	'about',
	'above',
	'across',
	'after',
	'against',
	'along',
	'amid',
	'among',
	'around',
	'as',
	'at',
	'before',
	'behind',
	'below',
	'beneath',
	'beside',
	'between',
	'by',
	'down',
	'during',
	'except',
	'following',
	'for',
	'from',
	'in',
	'inside',
	'into',
	'like',
	'mid',
	'near',
	'next',
	'of',
	'off',
	'on',
	'outside',
	'over',
	'past',
	'per',
	'prior',
	'regarding',
	'than',
	'through',
	'to',
	'up',
	'with',
	'within',
	'without',
]);

/** @private */
export function tokenize(string: string): Array<Token> {
	const tokens: Array<Token> = [];

	let index = 0;
	let value: string | null = null;
	let substring = '';

	function push(type: TokenType): void {
		if (value === null) return;

		tokens.push({
			range: [index, index + value.length],
			type,
			value,
		});
	}

	function matchRegex(pattern: RegExp): string | null {
		const match = pattern.exec(substring);
		return match && match[0];
	}

	while (index < string.length) {
		substring = string.slice(index);

		// Newline characters
		if ((value = matchRegex(/^(?:\r\n|\r|\n)+/u))) {
			push(TOKEN.line);
		}
		// All other whitespace
		else if ((value = matchRegex(/^\s+/u))) {
			push(TOKEN.space);
		}

		// Numbers
		else if ((value = matchRegex(/^\d+(?:\.\d+)/u))) {
			push(TOKEN.number);
		}

		// Sentence-ending punctuators
		// We will have false positives on e.g. "Washington, D.C." that should
		// be handled when we pull boundaries out of the tokens.
		else if ((value = matchRegex(/^[.?!]/u))) {
			push(TOKEN.sentence);
		}
		// Clause-ending punctuators
		else if ((value = matchRegex(/^[:;]/u))) {
			push(TOKEN.clause);
		}
		// Grouping punctuators
		else if (substring[0] === ',') {
			value = ',';
			push(TOKEN.comma);
		}
		// All other punctuators
		else if ((value = matchRegex(/^[^\w\s]+/u))) {
			push(TOKEN.punctuation);
		}

		// Different types of words
		else if ((value = matchRegex(/^\w+/u))) {
			if (conjunctions.has(value)) {
				push(TOKEN.conjunction);
			} else if (prepositions.has(value)) {
				push(TOKEN.preposition);
			} else {
				push(TOKEN.word);
			}
		}

		// Uhh not sure what else, take it and move on
		else {
			value = substring[0];
			push(TOKEN.other);
		}

		index += value.length;
	}

	return tokens;
}
