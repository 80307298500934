import React, { useState } from 'react';
import styled from 'styled-components';

import Dropdown, {
	DropdownOption,
	DropdownOptions,
} from '../../theme/components/aria-dropdown';
import Input from '../../theme/components/text-input';

const StyledDropdown = styled(Dropdown)`
	.HerbieDropdown__button {
		justify-content: space-between;
		width: 100%;
	}
`;
const StyledDropdownOptions = styled(DropdownOptions)`
	&[data-trigger='Select'] {
		width: var(--trigger-width);
	}

	.HerbieDropdown__option-list {
		max-height: 400px;
	}
`;

interface Props {
	elementId: string;
	options: Array<{ label: string; value: string; requireExtra: boolean }>;
	required: boolean;
	title: string;
}

export default function SelectRule({
	elementId,
	required,
	title,
	options,
}: Props): JSX.Element {
	const [currentValue, setCurrentValue] = useState<string | null>(null);
	const optionIdx = options.findIndex(
		(option) => option.value === currentValue,
	);
	const currentlySelectedOption = options[optionIdx];

	return (
		<>
			<StyledDropdown
				id={elementId}
				label={title}
				name={elementId}
				onChange={(key) => setCurrentValue(key.toString())}
				placeholder="Select a Value"
				required={required}
				value={currentValue}
			>
				<StyledDropdownOptions>
					{options.map(({ label, value }) => (
						<DropdownOption key={value} value={value}>
							{label}
						</DropdownOption>
					))}
				</StyledDropdownOptions>
			</StyledDropdown>
			{currentlySelectedOption?.requireExtra && (
				<Input
					name={`${elementId}-extra`}
					placeholder="Please Specify"
					required
				/>
			)}
		</>
	);
}
