// @flow

import { useCallback, useRef } from 'react';

import useStableRef from '../utils/hooks/use-stable-ref';

// The consuming component can pass any of the standard IntersectionObserver
// constructor options except root, which we'll receive in the
// observationRootRefCallback after the first render.
type UseObserveVisibilityOptions = $Diff<
	IntersectionObserverOptions,
	{| root: mixed |},
>;

export default function useObserveVisibility(
	options: UseObserveVisibilityOptions,
): [
	(scrollContainer: HTMLElement | null) => void,
	(target: HTMLElement, callback: () => void) => void,
] {
	useStableRef(options, 'options');

	// Once we have access to the scroll container HTML element, we'll create
	// an IntersectionObserver instance and persist it here between renders.
	const intersectionObserverRef = useRef<IntersectionObserver | null>(null);

	// React calls ref callbacks starting at the bottom of the element tree, so
	// the scroll container's children's ref callbacks will run and request
	// observation before the scroll container's ref callback has created the
	// IntersectionObserver. The scroll container's ref callback will create
	// the IntersectionObserver then activate all the pending observation
	// requests that have been persisted here.
	const pendingObservationRequestsRef = useRef<
		Array<(observer: IntersectionObserver) => void>,
	>([]);

	// We delegate handling of visibility changes to each subscriber
	// individually, so we need to be able to map from the target element in
	// each IntersectionObserverEntry to the corresponding subscriber callback.
	// We use a WeakMap so that removing a subscriber's DOM element from the
	// DOM will allow the garbage collector to remove the subscriber's entry
	// from the map, avoiding a memory leak.
	const observationTargetCallbackMapRef = useRef<
		WeakMap<HTMLElement, () => void>,
	>(new WeakMap());

	// We'll return this ref callback from the hook so the consuming component
	// can attach it to the root scroll container element.
	const observationRootRefCallback = useCallback(
		(scrollContainer: HTMLElement | null): void => {
			if (!scrollContainer) return;

			const intersectionObserver = new IntersectionObserver(
				(entries, observer) => {
					for (const entry of entries) {
						if (entry.isIntersecting) {
							// For our purposes, becoming visible is a one-way
							// operation: once an element becomes visible, it
							// will prepare itself for interaction, and it will
							// remain in that state even if the user later
							// scrolls it back out of the viewport. We can
							// therefore stop observing it once it becomes
							// visible.
							observer.unobserve(entry.target);

							const observationCallback =
								observationTargetCallbackMapRef.current.get(
									entry.target,
								);
							if (observationCallback) observationCallback();
						}
					}
				},
				{ ...options, root: scrollContainer },
			);
			intersectionObserverRef.current = intersectionObserver;

			// Now that we've created the IntersectionObserver, we can
			// subscribe all of the pending observation requests that were
			// enqueued before this callback.
			let pendingObservationRequest;
			while (
				(pendingObservationRequest =
					pendingObservationRequestsRef.current.shift())
			) {
				pendingObservationRequest(intersectionObserver);
			}
		},
		[
			options,
			intersectionObserverRef,
			observationTargetCallbackMapRef,
			pendingObservationRequestsRef,
		],
	);

	// We'll return this callback from the hook, and the consuming component
	// will pass it down as a prop to its children to allow them to subscribe
	// to visibility notifications.
	const observeVisibility = useCallback(
		(target: HTMLElement, callback: () => void): void => {
			const intersectionObserver = intersectionObserverRef.current;
			if (intersectionObserver) {
				// For rows that are added to the table after we've already
				// created the IntersectionObserver in the ref callback, we can
				// subscribe immediately.
				observationTargetCallbackMapRef.current.set(target, callback);
				intersectionObserver.observe(target);
			} else {
				// For rows that are present in the table at initial render, we
				// likely won't have an IntersectionObserver yet, so queue a
				// pending observation request.
				pendingObservationRequestsRef.current.push((observer) => {
					observationTargetCallbackMapRef.current.set(
						target,
						callback,
					);
					observer.observe(target);
				});
			}
		},
		[
			intersectionObserverRef,
			pendingObservationRequestsRef,
			observationTargetCallbackMapRef,
		],
	);

	return [observationRootRefCallback, observeVisibility];
}
