// Inspired by https://github.com/facebook/lexical/blob/04e5e4ab483e7cf452d8421f1874ab619c5c3cd9/packages/lexical-react/src/LexicalContentEditable.tsx
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { Props as ContentEditableProps } from '@lexical/react/LexicalContentEditable';
import React, { useLayoutEffect } from 'react';

export default React.forwardRef(function ContentEditable(
	{
		ariaActiveDescendant,
		ariaAutoComplete,
		ariaControls,
		ariaDescribedBy,
		ariaExpanded,
		ariaLabel,
		ariaLabelledBy,
		ariaMultiline,
		ariaOwns,
		ariaRequired,
		autoCapitalize,
		className,
		id,
		role = 'textbox',
		spellCheck = true,
		style,
		tabIndex,
		...rest
	}: ContentEditableProps,
	ref: React.ForwardedRef<HTMLDivElement> | null,
) {
	const [editor] = useLexicalComposerContext();
	const [isEditable, setEditable] = React.useState(false);

	// Lexical maintains it's own state and operates directly on the root element.
	// However, we may want to use the root element for other things, so we'll
	// share our forwarded ref with lexical
	const wrapperRef = React.useCallback(
		(rootElement: HTMLDivElement | null) => {
			editor.setRootElement(rootElement);
			if (typeof ref === 'function') {
				ref(rootElement);
			} else if (ref) {
				ref.current = rootElement;
			}
		},
		[editor, ref],
	);
	useLayoutEffect(() => {
		setEditable(editor.isEditable());
		return editor.registerEditableListener((currentIsEditable) => {
			setEditable(currentIsEditable);
		});
	}, [editor]);

	return (
		<div
			{...rest}
			aria-activedescendant={!isEditable ? void 0 : ariaActiveDescendant}
			aria-autocomplete={!isEditable ? 'none' : ariaAutoComplete}
			aria-controls={!isEditable ? void 0 : ariaControls}
			aria-describedby={ariaDescribedBy}
			aria-expanded={
				!isEditable
					? void 0
					: role === 'combobox'
					? !!ariaExpanded
					: void 0
			}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
			aria-multiline={ariaMultiline}
			aria-owns={!isEditable ? void 0 : ariaOwns}
			aria-readonly={!isEditable ? true : void 0}
			aria-required={ariaRequired}
			autoCapitalize={autoCapitalize}
			className={className}
			contentEditable={isEditable}
			id={id}
			ref={wrapperRef}
			role={role}
			spellCheck={spellCheck}
			style={style}
			tabIndex={tabIndex}
		/>
	);
});
