// @flow

import classNames from 'classnames';
import React from 'react';
import type {
	ChildrenArray as ReactChildrenArray,
	Node as ReactNode,
} from 'react';

import './multi-select-filter-option.scss';
import noop from '../../utils/noop';

type Props = {
	children?: ReactChildrenArray<ReactNode>,
	index: number,
	onClick: (value: any) => void,
	onMouseEnter?: (index: number) => void,
	selected: boolean,
	value: any,
};

export default class MultiSelectSearchFilterOption extends React.Component<Props> {
	static defaultProps = {
		mouseEnter: noop,
		selected: false,
	};

	handleClick = (event: SyntheticMouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		this.props.onClick(this.props.value);
	};

	handleMouseEnter = () => {
		if (this.props.onMouseEnter) this.props.onMouseEnter(this.props.index);
	};

	render() {
		const body = this.props.children || this.props.value;
		const classes = classNames('MultiSelectSearchFilterOption', {
			'MultiSelectSearchFilterOption--selected': this.props.selected,
		});

		return (
			<div
				className={classes}
				onClick={this.handleClick}
				onMouseEnter={this.handleMouseEnter}
				title={body}
			>
				{body}
			</div>
		);
	}
}
