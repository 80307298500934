// @flow

import React, { type Node as ReactNode } from 'react';

type Props = {
	children?: ReactNode,
};

const FilterDescription = ({ children }: Props) => (
	<span className="FilterDescription">{children}</span>
);

export default FilterDescription;
